/**
 *
 *
 */
import { useCallback, useState } from 'react';

import { ParkingSearchInput } from '../../../../components';

type UseCollapsibleParkingFormParams = {
  isExpanded?: boolean;
  onSubmit(searchInput: ParkingSearchInput): unknown;
  onCollapsedClick?(): unknown;
};

export function useCollapsibleParkingForm({
  isExpanded,
  onCollapsedClick,
  onSubmit,
}: UseCollapsibleParkingFormParams) {
  const [internalIsExpanded, setInternalIsExpanded] = useState(Boolean(isExpanded));

  const handleOnCollapsedClick = useCallback(() => {
    if (typeof onCollapsedClick === 'function') {
      onCollapsedClick();
    }

    if (typeof isExpanded === 'undefined') {
      // Uncontrolled...
      setInternalIsExpanded(true);
    }
  }, [onCollapsedClick, isExpanded]);

  const handleOnSubmit = useCallback(
    (sInput: ParkingSearchInput) => {
      if (typeof isExpanded === 'undefined') {
        // Uncontrolled, collapse form
        setInternalIsExpanded(false);
      }

      if (typeof onSubmit === 'function') {
        onSubmit(sInput);
      }
    },
    [isExpanded, onSubmit],
  );

  const handleOnCloseClick = useCallback(() => {
    setInternalIsExpanded(false);
  }, []);

  return {
    isExpanded: internalIsExpanded,
    handleOnSubmit,
    handleOnCollapsedClick,
    handleOnCloseClick,
  };
}
