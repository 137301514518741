/**
 * # Search Result Card
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

import './styles.scss';

export type SearchResultCardProps = {
  onClick?(e: React.MouseEvent): unknown;
  className?: string;
  children?: React.ReactNode;
  asPlaceholder?: boolean;
};

export const SearchResultCard = React.forwardRef<HTMLDivElement, SearchResultCardProps>(
  ({ children, onClick, className, asPlaceholder }, ref) => {
    const hasClickHandler = !!onClick && typeof onClick === 'function';
    const classNames = clsx(
      'yilu-SearchResultItem',
      { 'has-click-handler': hasClickHandler },
      className,
    );

    return (
      <section data-testid="search-result-card" className={classNames} ref={ref}>
        {hasClickHandler ? (
          <button
            data-testid="search-result-button"
            className={clsx('yilu-SearchResultItem__Main', 'yilu-SearchResultItem__Button')}
            onClick={onClick}
          >
            {children}
          </button>
        ) : (
          <div data-testid="search-result-button" className="yilu-SearchResultItem__Main">
            {Boolean(asPlaceholder) ? (
              <ElementsSDK.CardPlaceholder className="yilu-SearchResultCard__Placeholder" />
            ) : (
              children
            )}
          </div>
        )}
      </section>
    );
  },
);

SearchResultCard.displayName = 'SearchResultCard';
