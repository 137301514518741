import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerSIP from 'components/BannerSIP';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import ProductValues from 'components/ProductValues';

import { ParkingSearchForm } from '../../components';
import { useParkingSearchInputPage } from './hooks';
import './styles.scss';

const SearchInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { isPageReady, parkingSearchFormProps, productValues } = useParkingSearchInputPage();

  return (
    <div className="parking-sip">
      {isPageReady ? (
        <>
          <BannerSIP title={t('SIMPLETRIP PARKING TITLE')} />
          <div className="form-container-wrapper">
            <div
              className="form-container parking-sip-form"
              aria-label="Reserve a parking spot for your upcoming flight"
              role="heading"
              aria-level={1}
            >
              <ParkingSearchForm {...parkingSearchFormProps} />
            </div>
          </div>
          <div className="parking-product-values">
            <ProductValues title={t('parking.USP.Title')} items={productValues} />
          </div>
        </>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default SearchInputPage;
