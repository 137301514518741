import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { GetBookingByBookingIdResponse } from '@yiluhub/yilu-amp-types';
import clsx from 'clsx';
import React from 'react';

export type BookingStateHeaderProps = {
  bookingState?: GetBookingByBookingIdResponse['state'];
  headerStateText: {
    loading: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
    failed: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
    completed: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
  };
};

export const BookingStateHeader: React.FC<BookingStateHeaderProps> = ({
  bookingState,
  headerStateText,
}) => {
  let headerProps;

  switch (bookingState) {
    case 'PENDING':
    case 'STARTED':
      headerProps = {
        icon: <ElementsSDK.Icon.Time />,
        title: headerStateText.loading.title,
        subtitle: headerStateText.loading.subtitle,
        isLoading: false,
      };
      break;
    case 'FAILED':
    case 'PAYMENT_FAILED':
    case 'ERRORED':
      headerProps = {
        icon: <ElementsSDK.Icon.Close />,
        title: headerStateText.failed.title,
        subtitle: headerStateText.failed.subtitle,
        isLoading: false,
      };
      break;
    case 'COMPLETION_IN_FUTURE':
    case 'COMPLETED':
      headerProps = {
        icon: <ElementsSDK.Icon.Check />,
        title: headerStateText.completed.title,
        subtitle: headerStateText.completed.subtitle,
        isLoading: false,
      };
      break;
    default:
      headerProps = {
        isLoading: true,
      };
      break;
  }

  return (
    <ElementsSDK.Header
      {...headerProps}
      className={clsx(!!bookingState && `yilu-payment-bcp-header--${bookingState.toLowerCase()}`)}
    />
  );
};
