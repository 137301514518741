import {
  HighPassCategory,
  HighPassWidgetProps,
} from 'modules/highpass/components/HighPassWidget/types';
import { useHighPassPage } from 'modules/highpass/utils/useHighPassPage';

/**
 * Retrieve data for the swiss travel search input page.
 */
export const useHighPassLoungesPage = () => {
  const { isPageReady, HIGHPASS_API_KEY, language, populateSearchData } = useHighPassPage();

  const highPassProps: HighPassWidgetProps | null = HIGHPASS_API_KEY
    ? {
        apikey: HIGHPASS_API_KEY,
        predefinedSearchData: populateSearchData || undefined,
        categories: [HighPassCategory.BusinessLounge],
        lang: language,
      }
    : null;

  return {
    isPageReady,
    highPassProps,
  };
};
