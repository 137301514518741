import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAssetPaths } from 'context/EnterprisePartnerConfiguration';

import { useParkingAirports } from '../../../hooks/useParkingAirports';
import { getParkingSearchInputPageQueryParams } from '../../../utils/query-params';
import useParkingSearchFormProps from './useParkingSearchFormProps';
import useSetAirportDetails from './useSetAirportDetails';
import useTracking from './useTracking';

export const useParkingSearchInputPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const assetPaths = useAssetPaths();
  const sipImagePaths = assetPaths?.parking?.sipImagePaths;

  const productValuesItems = useMemo(() => {
    return [
      {
        title: t('Find Your Perfect Parking Space'),
        text: t('Find the cheapest, most convenient parking spot at the airport'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[0] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/parking/usp/USP1-min.jpg',
      },
      {
        title: t('Prepay And Reserve Your Spot'),
        text: t('Book a spot in just a few clicks and save up to 50% of regular rates'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[1] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/parking/usp/USP2-min.jpg',
      },
      {
        title: t('Arrive & Park'),
        text: t('Enter using your mobile parking pass, your space is waiting'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[2] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/parking/usp/USP3-min.jpg',
      },
    ];
  }, [sipImagePaths, t]);

  const parkingSearchInputPageQueryParams = useMemo(() => {
    return getParkingSearchInputPageQueryParams(location);
  }, [location]);

  const parkingAirports = useParkingAirports();

  const isPageReady =
    Boolean(parkingSearchInputPageQueryParams.airportDetails) ||
    !parkingSearchInputPageQueryParams.airportIataCode;

  useTracking(isPageReady);

  useSetAirportDetails(isPageReady, parkingSearchInputPageQueryParams, parkingAirports.allAirports);

  const parkingSearchFormProps = useParkingSearchFormProps(
    parkingSearchInputPageQueryParams,
    parkingAirports.allAirports,
    parkingAirports.popularAirportIATAs,
  );

  return {
    isPageReady,
    parkingSearchFormProps,
    productValues: productValuesItems,
  };
};
