/**
 * # Parking Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import {
  DateFormat,
  concatenateDateTime,
  formatLocalDate,
  getDuration,
  useIsDesktop,
} from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TermsCheck from 'components/TermsCheck';

import './styles.scss';
import { ParkingProductSummaryProps } from './types';

export const ParkingProductSummary: React.FC<ParkingProductSummaryProps> = ({
  displayingTimezone,
  enterDate,
  enterTime,
  exitDate,
  exitTime,
  parkingName,
  address,
  children,
  price,
  currency,
  showSelection = true,
  termsDetails,
  isLoading,
  isDisabled,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const enterDateTime = concatenateDateTime(enterDate, enterTime);
  const exitDateTime = concatenateDateTime(exitDate, exitTime);

  const totalDays = getDuration(enterDateTime, exitDateTime).days;
  const totalSecondaryLabel =
    totalDays > 1
      ? t('parkingProductSummary.duration.label', {
          days: String(totalDays),
        })
      : t('parkingProductSummary.duration.label.singular', {
          days: String(totalDays),
        });

  const extendedView = (
    <>
      <ElementsSDK.Typography className="yilu-ProductSummary__Title" variant="h6" bold uppercase>
        {t('parkingProductSummary.your.selection.label')}
      </ElementsSDK.Typography>
      {address && (
        <div>
          <ElementsSDK.Typography variant={isDesktop ? 'h4' : 'h5'} bold>
            {parkingName}
          </ElementsSDK.Typography>

          <div className="yilu-ProductSummary__ParkingAddress">
            <ElementsSDK.Typography variant="p2">
              <span dangerouslySetInnerHTML={{ __html: address }}></span>
            </ElementsSDK.Typography>
          </div>
        </div>
      )}
      {isDesktop && (
        <div className="yilu-ProductSummary__Itinerary">
          <div>
            <div className="yilu-ProductSummary__List" data-testid="timeline-ol">
              <div className="yilu-ProductSummary__List__Item">
                <ElementsSDK.Typography
                  variant="p2"
                  className={'yilu-ProductSummary__ItineraryLabel'}
                >
                  {t('parkingProductSummary.Itinerary.Enter')}
                </ElementsSDK.Typography>
                <ElementsSDK.Typography variant="p1" bold>
                  {formatLocalDate(
                    enterDateTime,
                    DateFormat.SHORTENED_TEXT_WITH_DATE,
                    displayingTimezone,
                  )}
                </ElementsSDK.Typography>
              </div>
              <div className="yilu-ProductSummary__List__Item">
                <ElementsSDK.Typography
                  variant="p2"
                  className={'yilu-ProductSummary__ItineraryLabel'}
                >
                  {t('parkingProductSummary.Itinerary.Exit')}
                </ElementsSDK.Typography>
                <ElementsSDK.Typography variant="p1" bold>
                  {formatLocalDate(
                    exitDateTime,
                    DateFormat.SHORTENED_TEXT_WITH_DATE,
                    displayingTimezone,
                  )}
                </ElementsSDK.Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className={'ProductSummary__Itinerary--mobile'}>
          <ElementsSDK.Typography bold variant="p2">
            {formatLocalDate(
              enterDateTime,
              DateFormat.SHORTENED_TEXT_WITH_DATE_EXCEPT_YEAR,
              displayingTimezone,
            )}
          </ElementsSDK.Typography>
          <ElementsSDK.Icon.ArrowRight className={'yilu-ProductSummaryMobile__ItineraryArrow'} />
          <ElementsSDK.Typography bold variant="p2">
            {formatLocalDate(
              exitDateTime,
              DateFormat.SHORTENED_TEXT_WITH_DATE_EXCEPT_YEAR,
              displayingTimezone,
            )}
          </ElementsSDK.Typography>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={clsx(showSelection && 'yilu-ProductSummaryParking')}>
        {showSelection && extendedView}
        {children}
        <ElementsSDK.PriceSummary
          className={clsx('yilu-ProductSummaryParking__PriceSummary', {
            'yilu-ProductSummaryParking__PriceSummary--footer': !isDesktop,
          })}
          price={price}
          currency={currency}
          isLoading={isLoading}
          isDisabled={isDisabled}
          {...props}
          onSubmit={onClick}
          termsComponent={
            termsDetails && (
              <TermsCheck
                termsUrl={termsDetails?.termsUrl || ''}
                productDetails={termsDetails?.productDetails}
              />
            )
          }
          isTotalHighlighted
          totalSecondaryLabel={totalSecondaryLabel}
          priceTypographySize="extraLarge"
        />
      </div>
    </>
  );
};
