import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { LoungeSearchResultsPageQueryParams } from '../../../utils/query-params';

const useSetAirportDetails = (
  isPageReady: boolean,
  loungeSearchResultsPageQueryParams: LoungeSearchResultsPageQueryParams,
  loungeAirports: ElementsSDK.FilteredAirportDetails[],
) => {
  const navigate = useNavigate();
  useEffect(() => {
    const nextLoungeSearchResultsPageQueryParams = { ...loungeSearchResultsPageQueryParams };
    let selectedAirport;

    if (loungeAirports && loungeAirports.length > 0) {
      selectedAirport = loungeAirports.find(
        (airport) =>
          airport.iataCode.toLowerCase() ===
          loungeSearchResultsPageQueryParams.airportIataCode?.toLowerCase(),
      );

      if (selectedAirport && selectedAirport.localizations) {
        nextLoungeSearchResultsPageQueryParams.airportDetails = {
          localizations: {
            de: selectedAirport.localizations.de,
            en: selectedAirport.localizations.en,
          },
          iataCode: selectedAirport.iataCode,
          latitude: selectedAirport.latitude,
          longitude: selectedAirport.longitude,
        };

        delete nextLoungeSearchResultsPageQueryParams.airportIataCode;
        navigate(
          {
            pathname: routes.LOUNGES_SEARCH_RESULTS,
            search: getURLSearchQuery(nextLoungeSearchResultsPageQueryParams),
          },
          { replace: true },
        );
      }
    }
  }, [isPageReady, loungeSearchResultsPageQueryParams, navigate, loungeAirports]);
};

export default useSetAirportDetails;
