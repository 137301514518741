/**
 * # Yilu App
 *
 *
 */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { UiUtilities } from '@yiluhub/ui-sdk-react';

// Expose same instance for cross-lib dependencies
import { useTranslation } from 'react-i18next';

import 'normalize.css/normalize.css';
import './vendor/modernizr-custom';
import './init';

import App from './App';
import {
  YiluFeatures,
  YiluOverrides,
  YiluAssetPathOverrides,
} from './types';
import { SupportedFeatureFlags } from './applicationConstants';
import { initializeDatadog } from './datadogInit';
import './styles/main.scss';

// expose available routes
export { default as routes } from './router/routes';

// expose access to yilu env util functions
export { yiluEnv } from './utils';

// expose feature flag and override keys
export { YILU_FEATURE, YILU_OVERRIDE_KEY } from './applicationConstants';

const sharedDependencies = {
  ReactI18next: { useTranslation },
};

export { sharedDependencies };

export type YiluAppConfig = {
  rootNode: HTMLElement;
  features: Partial<YiluFeatures>;
  overrides: Partial<YiluOverrides>;
  assetPathOverrides?: Partial<YiluAssetPathOverrides>;
  iconOverrides?: Record<string, (className: string) => React.ReactElement>;
  defaultPath?: string;
};

const defaultConfig: YiluAppConfig = {
  rootNode: document.getElementById('root')!,
  features: SupportedFeatureFlags.reduce((features, featureName) => {
    features[featureName] = false;
    return features;
  }, {} as YiluFeatures),
  overrides: {},
};

/**
 * Render the Yilu application.
 *
 * @param config - configuration to override defaults
 */
export function renderYiluApp(config?: Partial<YiluAppConfig>) {
  initializeDatadog();

  const mergedConfig: YiluAppConfig = {
    ...defaultConfig,
    ...config,
    features: {
      ...defaultConfig.features,
      ...(config ? config.features : null),
    },
    overrides: {
      ...(config ? config.overrides : null),
    },
    assetPathOverrides: {
      ...(config ? config.assetPathOverrides : null),
    },
  };

  const yiluConfig = UiUtilities.getYiluConfig();
  yiluConfig.icons = config ? config.iconOverrides : undefined;

  ReactDOM.render(
    <Suspense fallback="...">
      <div className="yilu-root">
        <App
          features={mergedConfig.features}
          overrides={mergedConfig.overrides}
          assetPathOverrides={mergedConfig.assetPathOverrides}
          defaultPath={mergedConfig.defaultPath}
        />
      </div>
    </Suspense>,
    mergedConfig.rootNode,
  );
}
