import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { FC } from 'react';

import { LoungeOpeningHours } from 'modules/lounges/components';
import { SameMessageType } from 'modules/lounges/components/LoungeOpeningHours';
import type { OpeningHours } from 'modules/lounges/types';

export type LoungeMetaListProps = {
  isHolidayExtras?: boolean;
  location?: string;
  openingHours?: OpeningHours[];
  searchedDateOfWeek: number;
};
export const LoungeMetaList: FC<LoungeMetaListProps> = ({
  isHolidayExtras,
  location,
  openingHours,
  searchedDateOfWeek,
}) => {
  const metaList = [];

  if (!isHolidayExtras && location) {
    metaList.push(
      <div className="yilu-ProductDetails__Lounges__Meta">
        <ElementsSDK.Icon.PinOutline className="yilu-ProductDetails__Lounge__LoungeIcon" />
        <ElementsSDK.Typography>{location}</ElementsSDK.Typography>
      </div>,
    );
  }

  if (openingHours && openingHours?.length > 0) {
    metaList.push(
      <div className="yilu-ProductDetails__Lounges__Meta">
        <ElementsSDK.Icon.Time className="yilu-ProductDetails__Lounge__LoungeIcon" />
        <div>
          <LoungeOpeningHours
            key="open-hours"
            openingHours={openingHours}
            searchedDateOfWeek={searchedDateOfWeek}
            sameMessageType={isHolidayExtras ? SameMessageType.DAILY : SameMessageType.PRECISE}
          />
        </div>
      </div>,
    );
  }

  return (
    <>
      {metaList.length > 0 && (
        <div
          className={clsx(
            'yilu-ProductDetails__Lounges__MetaList',
            'yilu-ProductDetails__Lounges_Info',
          )}
        >
          {metaList}
        </div>
      )}
    </>
  );
};
