/**
 * # Parking Search Form
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useParkingSearchForm } from './hooks/useParkingSearchForm';
import './styles.scss';
import { ParkingSearchFormProps } from './types';

export const ParkingSearchForm: React.FC<ParkingSearchFormProps> = ({
  className,
  searchInput,
  flightTime,
  displayingTimezone,
  parkingAirports,
  popularAirportsIATAs,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    isFormValid,
    enterDateTime,
    exitDateTime,
    selectedAirportName,
    handleOnAddressSelect,
    handleOnSubmitButtonClick,
    handleOnFormSubmit,
    setEnterDateTime,
    setExitDateTime,
  } = useParkingSearchForm({ onSubmit, searchInput, flightTime });
  const { isDesktop } = UiUtilities.useIsDesktop();
  const isMobile = UiUtilities.useMediaMatch('--viewport-lte-m');
  const exitDateId = useMemo(() => uuidv4(), []);
  const entryDateId = useMemo(() => uuidv4(), []);
  const addressId = useMemo(() => uuidv4(), []);

  const TIME_INTERVAL = 30;
  const enterDateTimeShort = dayjs(enterDateTime || undefined).format(
    UiUtilities.DateFormat.SHORT_DATE,
  );

  return (
    <form className={clsx(className, 'yilu-ParkingSearchForm')} onSubmit={handleOnFormSubmit}>
      {flightTime && (
        <section className="yilu-ParkingSearchForm__flight-departure">
          {t('Flight departure')} —{' '}
          <span className="yilu-ParkingSearchForm__flight-departure-date">
            {flightTime &&
              UiUtilities.formatLocalDate(
                flightTime,
                UiUtilities.DateFormat.DATE_WITH_TIME,
                displayingTimezone,
              )}
          </span>
        </section>
      )}
      <section className="yilu-ParkingSearchForm__inputs">
        <ElementsSDK.FilteredAirportSearchInput
          id={addressId}
          label={isDesktop ? t('Where') : undefined}
          classname="yilu-ParkingSearchForm__inputs__address"
          onAddressSelect={handleOnAddressSelect}
          value={selectedAirportName}
          popularAirportsIATAs={popularAirportsIATAs}
          airportList={parkingAirports}
          placeholder={t('City or airport')}
        />
        <ElementsSDK.InputField.DateTimePicker
          id={entryDateId}
          name={entryDateId}
          className="yilu-ParkingSearchForm__inputs__entry"
          label={isDesktop ? t('Enter') : undefined}
          onChange={setEnterDateTime}
          dateFormat={UiUtilities.DateFormat.DATE}
          initialValue={enterDateTime!}
          timeInterval={TIME_INTERVAL}
          minDate={dayjs().format(UiUtilities.DateFormat.SHORT_DATE)}
        />
        <ElementsSDK.InputField.DateTimePicker
          id={exitDateId}
          name={exitDateId}
          className="yilu-ParkingSearchForm__inputs__exit"
          label={isDesktop ? t('Exit') : undefined}
          onChange={setExitDateTime}
          dateFormat={UiUtilities.DateFormat.DATE}
          initialValue={exitDateTime!}
          timeInterval={TIME_INTERVAL}
          minDate={enterDateTimeShort}
          boundaryDate={enterDateTimeShort}
          boundaryType="start"
        />
        <ElementsSDK.Button
          aria-label={t('Search')}
          className="yilu-ParkingSearchForm__submit-btn"
          disabled={!isFormValid}
          onClick={handleOnSubmitButtonClick}
          Icon={isMobile ? undefined : ElementsSDK.Icon.Magnifier}
        >
          {isMobile ? t('Search') : undefined}
        </ElementsSDK.Button>
      </section>
    </form>
  );
};
