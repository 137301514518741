import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { ChangeEvent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import Header from '../components/Header';
import { ReservationInformationFormContentProps } from '../components/types';
import { MESSAGE_TO_DRIVER_NAME, ProductConsumerAttributeType, getValidationRules } from '../utils';
import './styles.scss';

enum RideValues {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  messageToDriver = 'messageToDriver',
  flightNumber = 'flightNumber',
}

type FormFields = {
  [key: string]: string;
};

const RidesReservationInformationForm: React.FC<ReservationInformationFormContentProps> = ({
  fields,
  control,
  trigger,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const envVariables = yiluEnv.getVariables();

  const formFields: FormFields = {
    firstName: watch(RideValues.firstName) || '',
    lastName: watch(RideValues.lastName) || '',
    email: watch(RideValues.email) || '',
    messageToDriver: watch(RideValues.messageToDriver) || '',
    flightNumber: watch(RideValues.flightNumber) || '',
  };

  const phoneNumber: { number?: string; code?: string } = (watch(RideValues.phoneNumber) as any as {
    number?: string;
    code?: string;
  }) || {
    number: '',
    code: '',
  };

  const getSessionStorageValues = (key: string) => {
    const savedItem = sessionStorage.getItem(key);
    if (savedItem) {
      setValue(key, JSON.parse(savedItem));
    }
  };

  useEffect(() => {
    Object.entries(RideValues).forEach(([, value]) => {
      getSessionStorageValues(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsToRender = fields.filter((item) => {
    return Object.values(RideValues).includes(item.name as RideValues);
  });

  return (
    <div className="yilu-RidesReservationForm">
      <Header heading={t('heading')} className="yilu-RidesReservationForm__header" />
      {fieldsToRender.map((field) => {
        if (field.type === ProductConsumerAttributeType.PHONE_NUMBER) {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.PhoneInputField
                    dataTestId="customer-phone"
                    label={t(field.name)}
                    className="yilu-RidesReservationForm__field"
                    phoneValue={phoneNumber?.number}
                    codeValue={phoneNumber?.code}
                    onChange={(value) => {
                      sessionStorage.setItem(field.name, JSON.stringify(value));
                      setValue(name, value);
                      onChange(value);
                      isTouched && trigger(field.name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    defaultCountry={envVariables?.PHONE_COUNTRY_CODE || 'DE'}
                    phoneRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.type === ProductConsumerAttributeType.EMAIL) {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.Select
                    isEmailDropdown
                    showDropdownIcon={false}
                    dataTestId="rides-reservation-email"
                    label={t(field.name)}
                    name={name}
                    type={field.type === ProductConsumerAttributeType.EMAIL ? 'email' : ''}
                    className="yilu-RidesReservationForm__field"
                    value={formFields[field.name]}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) => {
                      sessionStorage.setItem(field.name, JSON.stringify(e.target.value));
                      setValue(name, e.target.value);
                      onChange(e);
                      isTouched && trigger(name);
                    }}
                    onSelect={(selectedValue: unknown) => {
                      sessionStorage.setItem(field.name, JSON.stringify(selectedValue));
                      setValue(name, selectedValue);
                      onChange(selectedValue);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        }

        return (
          <Controller
            key={field.name}
            name={field.name}
            control={control}
            rules={getValidationRules(field)}
            render={({
              field: { onChange, onBlur, name, ref },
              fieldState: { isTouched, error },
            }) => {
              const formValue =
                field.name !== 'flightNumber'
                  ? formFields[field.name]
                  : formFields[field.name].toUpperCase();
              return (
                <ElementsSDK.InputField.TextInput
                  label={t(field.name)}
                  name={name}
                  className={
                    field.name === MESSAGE_TO_DRIVER_NAME
                      ? 'yilu-RidesReservationForm__area'
                      : `yilu-RidesReservationForm__field .${field.name}`
                  }
                  value={formValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    sessionStorage.setItem(field.name, JSON.stringify(e.target.value));
                    setValue(name, e.target.value);
                    onChange(e);
                    isTouched && trigger(name);
                  }}
                  onBlur={() => {
                    onBlur();
                    trigger(name);
                  }}
                  inputRef={ref}
                  errorMessage={error?.message && t(error.message)}
                  textarea={field.name === MESSAGE_TO_DRIVER_NAME}
                />
              );
            }}
          />
        );
      })}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default RidesReservationInformationForm;
