import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BacklinkNavigator from 'components/Header/BacklinkNavigator';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { ParkingSearchForm } from '../../components';
import { ParkingCollapsibleForm } from './ParkingCollapsibleForm';
import { ParkingSearchResults } from './ParkingSearchResults';
import { useParkingSearchResultsPage } from './hooks';
import './styles.scss';

const SearchResultsPage: React.FC = () => {
  const {
    collapsibleParkingFormProps,
    parkingSearchFormProps,
    parkingSearchResultsProps,
    backLinkProps,
    showBufferWarning,
    isPageReady,
  } = useParkingSearchResultsPage();

  const { t } = useTranslation();
  return (
    <>
      <BacklinkNavigator backLinkProps={backLinkProps} />
      <div className="parking-srp">
        {isPageReady ? (
          <>
            <div className="Parking-Search-Results__Form--mobile">
              <ParkingCollapsibleForm {...collapsibleParkingFormProps} />
            </div>
            <div className="Parking-Search-Results__Form--desktop">
              <div className="Parking-Search-Results__Form">
                <ParkingSearchForm {...parkingSearchFormProps} />
              </div>
            </div>
            {parkingSearchResultsProps && (
              <div className="Parking-Search-Results__Section">
                <div className="Parking-Search-Results__Results">
                  {showBufferWarning && (
                    <ElementsSDK.Message
                      type={ElementsSDK.MessageType.WARNING}
                      content={t('parking.srp.buffer.warning')}
                      className="Parking-Search-Results__Section-Warning"
                    />
                  )}
                  <ParkingSearchResults {...parkingSearchResultsProps} />
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingPageAnimation />
        )}
      </div>
    </>
  );
};

export default SearchResultsPage;
