export enum LoungeFieldName {
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  MOBILE_PHONE = 'mobilePhone',
  AIRLINE_GROUP = 'airlineGroup',
  IS_ECONOMY_PREMIUM = 'isEconomyPremium',
  DESTINATION_TYPE = 'destinationType',
  PNR_BOOKING_CODE = 'pnr',
  FLIGHT_CODE = 'flightCode',
  ADDRESS = 'addressLine1',
  TOWN = 'town',
  POST_CODE = 'postCode',
  COUNTRY = 'country',
}
