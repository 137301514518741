import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AirportTransferSearchForm } from '../../../components';
import CollapsedAirportTransfer from './CollapsedAirportTransfer';
import { useAirportTransferCollapsibleForm } from './hooks/useAirportTransferCollapsibleForm';
import './styles.scss';
import { AirportTransferCollapsibleFormProps } from './types';

export const AirportTransferCollapsibleForm: React.FC<AirportTransferCollapsibleFormProps> = ({
  googleMapsAPIKey,
  searchInput = {},
  minDateTimeInput,
  maxTravellersCount,
  isExpanded,
  stationAirports,
  className,
  showCloseButton,
  onSubmit,
  onCollapsedClick,
  onOpenDateTimeInput,
  onError,
}) => {
  const { t } = useTranslation();

  const {
    isExpandedInternal,
    renderedLocationName,
    renderedAirportName,
    renderedDirection,
    handleOnSubmit,
    handleOnCollapsedClick,
    handleOnCloseClick,
  } = useAirportTransferCollapsibleForm({
    googleMapsAPIKey,
    searchInput,
    isExpanded,
    onSubmit,
    onCollapsedClick,
    onError,
  });

  return (
    <div className={clsx(className, 'yilu-CollapsibleForm__AirportTransfer')}>
      {isExpandedInternal ? (
        <section
          className={clsx({
            'yilu-CollapsibleForm__AirportTransfer__expanded_wrapper': true,
            'yilu-CollapsibleForm__AirportTransfer__expanded_wrapper_has-close-button':
              showCloseButton,
          })}
        >
          <AirportTransferSearchForm
            className="yilu-AirportTransferCollapsibleForm__Form"
            googleMapsAPIKey={googleMapsAPIKey}
            stationAirports={stationAirports}
            onSubmit={handleOnSubmit}
            onError={onError}
            searchInput={searchInput}
            minDateTimeInput={minDateTimeInput}
            maxTravellersCount={maxTravellersCount}
            onOpenDateTimeInput={onOpenDateTimeInput}
          />
          {showCloseButton && (
            <button
              onClick={handleOnCloseClick}
              className="yilu-CollapsibleForm__AirportTransfer__CollapseButton"
              aria-label={t('Close')}
            >
              <ElementsSDK.Icon.Close />
            </button>
          )}
        </section>
      ) : (
        <button
          aria-label="collapsed-button"
          onClick={handleOnCollapsedClick}
          className={clsx(
            'yilu-CollapsibleForm__AirportTransfer__collapsed_wrapper',
            'yilu-CollapsibleForm__AirportTransfer__ExpandButton',
          )}
        >
          <CollapsedAirportTransfer
            locationAddressName={renderedLocationName}
            airportName={renderedAirportName}
            travellersCount={searchInput && searchInput.travellersCount}
            direction={renderedDirection}
          />
        </button>
      )}
    </div>
  );
};
