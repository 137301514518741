import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

export type BacklinkNavigatorProps = {
  backLinkProps: ElementsSDK.BacklinkProps;
};

const BacklinkNavigator: React.FC<BacklinkNavigatorProps> = ({ backLinkProps }) => {
  if (!backLinkProps.label) return null;

  return (
    <div className="backlink-navigator-wrapper">
      <div className="backlink-wrapper">
        <ElementsSDK.Backlink {...backLinkProps} className="header-backlink-navigator" />
      </div>
    </div>
  );
};

export default BacklinkNavigator;
