import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

import './styles.scss';

type CategoryBoxProps = {
  category: string;
  description?: string;
  showDivider?: boolean;
};

/**
 * UI container for information categories,
 * has a title, description and bottom divider
 */
const CategoryBox: React.FC<CategoryBoxProps> = ({
  category,
  description,
  showDivider = false,
  children,
}) => (
  <div
    className={clsx('yilu-CategoryBox', {
      'yilu-CategoryBox--show-divider': showDivider,
    })}
  >
    <div className="yilu-CategoryBox-title-container">
      <ElementsSDK.Typography variant="h3" className="yilu-CategoryBox-title">
        {category}
      </ElementsSDK.Typography>
      {description && (
        <ElementsSDK.Typography variant="p1" className="yilu-CategoryBox-description">
          {description}
        </ElementsSDK.Typography>
      )}
    </div>
    {children}
  </div>
);

export default CategoryBox;
