import { TransferDirection } from 'applicationConstants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { airportUtils, paramConverters } from 'utils';

import {
  RideSearchInputPageQueryParams,
  RideSearchResultsPageQueryParams,
} from 'modules/rides/utils/query-params';

export const useAirportDetails = (
  pageQueryParams: RideSearchInputPageQueryParams | RideSearchResultsPageQueryParams,
  nextRoute: string,
) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!pageQueryParams.airportIataCode) {
        return;
      }

      const direction = pageQueryParams.direction;

      const airportDetails = await airportUtils.getAirportDetails(pageQueryParams.airportIataCode);
      if (!airportDetails) return;

      const nextPageQueryParams = {
        ...pageQueryParams,
        ...(direction === TransferDirection.FROM_AIRPORT && {
          originLocation: `${airportDetails.latitude},${airportDetails.longitude}`,
        }),
        ...(direction === TransferDirection.TO_AIRPORT && {
          destinationLocation: `${airportDetails.latitude},${airportDetails.longitude}`,
        }),
      };

      delete nextPageQueryParams.airportIataCode;
      delete nextPageQueryParams.direction;

      navigate(
        {
          pathname: nextRoute,
          search: paramConverters.getURLSearchQuery(nextPageQueryParams),
        },
        { replace: true },
      );
    })();
  }, [navigate, pageQueryParams, nextRoute]);
};

export default useAirportDetails;
