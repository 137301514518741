import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

export type ErrorMessageProps = {
  errorMessage: string;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
  return (
    <ElementsSDK.Typography isError className="yilu-ErrorMessage">
      {errorMessage}
    </ElementsSDK.Typography>
  );
};
