import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type BookingEmailSupportProps = {
  supportEmailAddress: string;
};

export const BookingEmailSupport: React.FC<BookingEmailSupportProps> = ({
  supportEmailAddress,
}) => {
  const { t } = useTranslation();

  return (
    <div className="yilu-bcp__Support">
      <ElementsSDK.Typography variant="h2" className="yilu-bcp__SupportHeader">
        {t('We are here for you')}
      </ElementsSDK.Typography>
      <ElementsSDK.Typography variant="p1" className="yilu-bcp__SupportText">
        {t('Further assistance')}
      </ElementsSDK.Typography>
      <ElementsSDK.Link className="yilu-bcp__SupportLink" href={`mailto:${supportEmailAddress}`}>
        {supportEmailAddress}
      </ElementsSDK.Link>
    </div>
  );
};
