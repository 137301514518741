import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import { getBrowserLocale } from 'widgets/GlobalDefaults';

import './styles.scss';

const ItalianBottomBanner: React.FC = () => {
  const browserLocale = getBrowserLocale();

  if (browserLocale !== 'it') return null;

  return (
    <ElementsSDK.BottomBanner
      title="La tua lingua selezionata"
      className="yilu-ItalianBottomBanner"
    >
      <ElementsSDK.Typography variant="p2" className="yilu-ItalianBottomBanner__Content">
        La lingua selezionata non è supportata. Il contenuto del sito web è fornito in inglese o
        tedesco.
      </ElementsSDK.Typography>
    </ElementsSDK.BottomBanner>
  );
};

export default ItalianBottomBanner;
