import { useState } from 'react';

import { FormError } from './types';

export const useReservationError = () => {
  const [formError, setFormError] = useState<FormError>({
    hasError: false,
    name: null,
    message: '',
  });

  return {
    formError,
    setFormError,
  };
};
