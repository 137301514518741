import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { TFunction } from 'i18next';

/**
 * Format durations.
 *
 * @return Formatted duration for date ranges.
 */
export const formatDuration = (t: TFunction, startDate: string | number, endDate?: string) => {
  const duration = UiUtilities.getDuration(startDate, endDate);
  const formatted: string[] = [];

  if (duration.days > 1) {
    formatted.push(t('{{days}} days', { days: duration.days }));
  }

  if (duration.days === 1) {
    formatted.push(t('1 day'));
  }

  if (duration.hours > 1) {
    formatted.push(t('{{hours}} hours', { hours: duration.hours }));
  }

  if (duration.hours === 1) {
    formatted.push(t('1 hour'));
  }

  if (duration.minutes > 1) {
    formatted.push(t('{{minutes}} minutes', { minutes: duration.minutes }));
  }

  if (duration.minutes === 1) {
    formatted.push(t('1 minute'));
  }

  return formatted.join(' ');
};
