/**
 * # Rides Search Results Page Hooks
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { setSrpSession } from 'utils/searchSession';
import { sendGAEvent, sendSelectedIndexSearchEvent } from 'utils/tracking';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { RideInteractiveProductSummaryProps, RidesSearchFormProps } from 'modules/rides/components';

import useAirportDetails from '../../../hooks/useAirportDetails';
import { getRidesSearchResultsPageQueryParams } from '../../../utils/query-params';
import { RidesSearchResultsProps } from '../RidesSearchResults/types';

/**
 * Retrieve the coordinates from a location
 *
 * @param [location] - location descriptor in the form of "latitude,longitude"
 *
 * @returns Potential coordinates of a location
 */
export function getLocationCoordinates(location?: string) {
  if (!location) return;
  const [latitude, longitude] = location.split(',');

  return {
    latitude: Number(latitude),
    longitude: Number(longitude),
  };
}

const yiluEnv = getVariables();

export const useSearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchResults = useRef<SearchItem[]>([]);
  const searchResultsPageQueryParams = useMemo(() => {
    return getRidesSearchResultsPageQueryParams(location);
  }, [location]);

  useAirportDetails(searchResultsPageQueryParams, routes.RIDES_SEARCH_RESULTS);

  const ridesSearchFormProps = useMemo(() => {
    const _props: RidesSearchFormProps = {
      searchInput: {
        originLocation: searchResultsPageQueryParams.originLocation,
        destinationLocation: searchResultsPageQueryParams.destinationLocation,
        transferDate: searchResultsPageQueryParams.transferDate,
        transferTime: searchResultsPageQueryParams.transferTime,
        travellersCount: searchResultsPageQueryParams.travellersCount,
      },
      googleMapsAPIKey: yiluEnv.GOOGLE_MAPS_KEY!,
      onOpenDateTimeInput(e) {
        const isMobile = UiUtilities.getMediaMatch('--viewport-lte-m');
        if (!isMobile) {
          return;
        }

        requestAnimationFrame(() => {
          const target = e.target as HTMLElement;
          target.scrollIntoView({
            behavior: 'smooth',
          });
        });
      },
      onSubmit(searchParams) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const originAddressCoordinates = searchParams.originAddress.coordinates!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const destinationAddressCoordinates = searchParams.destinationAddress.coordinates!;

        const nextSearchInput = {
          originLocation: `${originAddressCoordinates.latitude},${originAddressCoordinates.longitude}`,
          destinationLocation: `${destinationAddressCoordinates.latitude},${destinationAddressCoordinates.longitude}`,
          transferDate: searchParams.transferDate,
          transferTime: searchParams.transferTime,
          travellersCount: searchParams.travellersCount,
        };

        const nextSearchResultsPageQueryParams = {
          ...searchResultsPageQueryParams,
          ...nextSearchInput,
        };

        navigate({
          pathname: routes.RIDES_SEARCH_RESULTS,
          search: getURLSearchQuery(nextSearchResultsPageQueryParams),
        });
      },
      onError(error) {
        throw error;
      },
    };

    return _props;
  }, [searchResultsPageQueryParams, navigate]);

  const ridesSearchResultsProps = useMemo(() => {
    const searchParams = {
      originAddressCoordinates: getLocationCoordinates(
        searchResultsPageQueryParams.originLocation,
      )!,
      destinationAddressCoordinates: getLocationCoordinates(
        searchResultsPageQueryParams.destinationLocation,
      )!,
      transferDate: searchResultsPageQueryParams.transferDate!,
      transferTime: searchResultsPageQueryParams.transferTime!,
      passengerCount: searchResultsPageQueryParams.travellersCount!,
    };

    const isMissingRequiredParameters = Object.keys(searchParams).some((key) => {
      return (searchParams as Record<string, unknown>)[key] === undefined;
    });

    if (isMissingRequiredParameters) return null;

    const _props: RidesSearchResultsProps = {
      searchParams,
      onSearchResults(results: SearchItem[]) {
        setSrpSession(searchParams);
        searchResults.current = results;

        let eventCategory = 'ride-srp';
        let eventLabel = 'ride_srp_loaded';
        if (!results.length) {
          eventCategory = 'ride-srp-null';
          eventLabel = 'ride_srp_null_loaded';
        }
        sendGAEvent({ event: 'page_loaded', category: eventCategory, label: eventLabel });
      },
      async onSearchResultClick(searchResult: SearchItem) {
        setSrpSession(searchParams);

        sendSelectedIndexSearchEvent(searchResult.id, searchResults.current, 'ride_srp');

        // Using the cached address requests/address detail lookups from AddressInputs
        const addressRequestsCache: any = UiUtilities.getYiluCaches()['addressRequests'];

        const originAddressRequest = addressRequestsCache[
          searchResultsPageQueryParams.originLocation!
        ] as Promise<ElementsSDK.GoogleSearchAddress>;

        const destinationAddressRequest = addressRequestsCache[
          searchResultsPageQueryParams.destinationLocation!
        ] as Promise<ElementsSDK.GoogleSearchAddress>;
        Promise.all([originAddressRequest, destinationAddressRequest]).then(
          ([originAddress, destinationAddress]) => {
            const productDetailsPageQueryParams = {
              searchResultID: searchResult.id,
              originAddressName: originAddress.name,
              destinationAddressName: destinationAddress.name,
              transferTime: searchParams.transferTime,
              transferDate: searchParams.transferDate,
              itemId: searchResult.id,
              catalogId: searchResult.item.catalogId,
              priceId: searchResult.item.defaultPrice.id,
            };
            navigate({
              pathname: routes.RIDES_TIP,
              search: getURLSearchQuery(productDetailsPageQueryParams),
            });
          },
        );
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [searchResultsPageQueryParams, navigate]);

  const rideProductInteractiveSummaryProps = useMemo(() => {
    if (!searchResultsPageQueryParams) {
      return null;
    }

    const _props: RideInteractiveProductSummaryProps = {
      whereFrom: searchResultsPageQueryParams.originLocation!,
      whereTo: searchResultsPageQueryParams.destinationLocation!,
      travelDate: `${searchResultsPageQueryParams?.transferDate}T${searchResultsPageQueryParams?.transferTime}:00`,
      price: undefined!,
      currency: undefined!,
      showPriceSummary: false,
      travellersCount: searchResultsPageQueryParams?.travellersCount,
      carrierName: undefined,
      carrierLogoUrl: undefined,
      providerClassification: undefined,
      showEdit: true,
      googleMapsAPIKey: yiluEnv.GOOGLE_MAPS_KEY!,
      isEstimatedPriceActive: true,
      onSubmit() {},
      onSearch(whereTo, whereFrom, travelDate, travellersCount) {
        const nextSearchInput = {
          originLocation: `${whereFrom?.coordinates?.latitude},${whereFrom?.coordinates?.longitude}`,
          destinationLocation: `${whereTo?.coordinates?.latitude},${whereTo?.coordinates?.longitude}`,
          transferDate: travelDate?.replace(/T.*/, ''),
          travellersCount,
          transferTime: travelDate?.replace(/.*T/, ''),
        };

        const searchResultsPageQueryParams = {
          ...nextSearchInput,
        };
        navigate({
          pathname: routes.RIDES_SEARCH_RESULTS,
          search: getURLSearchQuery(searchResultsPageQueryParams),
        });
      },
    };
    return _props;
  }, [navigate, searchResultsPageQueryParams]);

  return {
    ridesSearchFormProps,
    ridesSearchResultsProps,
    rideProductInteractiveSummaryProps,
  };
};
