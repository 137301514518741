/**
 * # Airport Transfer Search Results Page Hooks
 *
 *
 */
import { UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useStationAirports } from '../../../hooks/useStationAirports';
import { getAirportTransferSearchResultsPageQueryParams } from '../../../utils/query-params';
import { AirportTransferCollapsibleFormProps } from '../AirportTransferCollapsibleForm/types';
import useAirportTransferSearchFormProps from './useAirportTransferSearchFormProps';
import { useAirportTransferSearchResultsProps } from './useAirportTransferSearchResultsProps';
import useSetAirportDetails from './useSetAirportDetails';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

export const useAirportTransferSearchResultsPage = () => {
  const location = useLocation();

  const airportTransferSearchResultsPageQueryParams = useMemo(() => {
    return getAirportTransferSearchResultsPageQueryParams(location);
  }, [location]);

  const getTranslationForHeadingAndSubheading = () => {
    const currentDateInCEST = dayjs().tz('Europe/Berlin').format(UiUtilities.DateFormat.SHORT_DATE);
    let subheadingTranslation = 'bvgBanner.subheading.currentDate';
    // search is for a date in future
    if (
      dayjs(airportTransferSearchResultsPageQueryParams.transferDate).isAfter(currentDateInCEST)
    ) {
      subheadingTranslation = 'bvgBanner.subheading.futureDate';
    }

    return subheadingTranslation;
  };
  const isPageReady =
    Boolean(airportTransferSearchResultsPageQueryParams.airportDetails) ||
    !airportTransferSearchResultsPageQueryParams.airportIataCode;

  const stationAirports = useStationAirports();

  useSetAirportDetails(isPageReady, airportTransferSearchResultsPageQueryParams, stationAirports);

  const airportTransferSearchFormProps = useAirportTransferSearchFormProps(
    airportTransferSearchResultsPageQueryParams,
    stationAirports,
  );

  const collapsibleAirportTransferSearchFormProps = useMemo(() => {
    const _props: AirportTransferCollapsibleFormProps = {
      ...airportTransferSearchFormProps,
      showCloseButton: true,
    };
    return _props;
  }, [airportTransferSearchFormProps]);

  const { airportTransferSearchResultsProps, searchResultsCurrent } =
    useAirportTransferSearchResultsProps(
      airportTransferSearchResultsPageQueryParams,
      stationAirports,
    );

  const showHexBanner =
    airportTransferSearchResultsProps &&
    airportTransferSearchResultsProps.searchParams.carrier === 'HEXR' &&
    searchResultsCurrent.length > 0;

  const showCatBanner =
    airportTransferSearchResultsProps &&
    airportTransferSearchResultsProps.searchParams.carrier === 'CATR' &&
    searchResultsCurrent.length > 0;

  const showBVGBanner =
    airportTransferSearchResultsProps &&
    airportTransferSearchResultsProps.searchParams.carrier === 'BVBV' &&
    searchResultsCurrent.length > 0;

  return {
    airportTransferSearchFormProps,
    airportTransferSearchResultsProps,
    collapsibleAirportTransferSearchFormProps,
    showHexBanner,
    showCatBanner,
    showBVGBanner,
    getTranslationForHeadingAndSubheading,
    isPageReady,
  };
};
