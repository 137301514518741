import React, { useCallback, useState } from 'react';

function useLoungeCollapsibleTrigger() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnCollapsedClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const onKeyPressCollapsedHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsExpanded(!isExpanded);
      }
    },
    [isExpanded],
  );

  return {
    isExpanded,
    handleOnCollapsedClick,
    onKeyPressCollapsedHandler,
    setIsExpanded,
  };
}

export default useLoungeCollapsibleTrigger;
