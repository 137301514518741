/**
 * # Swiss Travel Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProductSummaryProps } from 'components/ProductSummary';
import ProductSummary from 'components/ProductSummary';

import './styles.scss';

export type SBBProductSummaryProps = Omit<ProductSummaryProps, 'extendedView'> & {
  duration: number;
  date: string;
  passengersCount: number;
};

export const SBBProductSummary: React.FC<SBBProductSummaryProps> = ({
  duration,
  date,
  passengersCount,
  price,
  currency,
  isLoading,
  termsDetails,
  onClick,
}) => {
  const { t } = useTranslation();
  const extendedView = (
    <>
      <ElementsSDK.Typography variant="h6" uppercase>
        {t('sbb.your_selection')}
      </ElementsSDK.Typography>
      <div className={'yilu-ProductSummary__Itinerary yilu-ProductSummary__Itinerary__Seperator '}>
        <ElementsSDK.Typography
          variant="h4"
          className={
            'yilu-ProductSummary__Row yilu-ProductSummary__Itinerary yilu-ProductSummary__Days '
          }
        >
          {t('sbb.travel_pass')} {duration} Days
        </ElementsSDK.Typography>
        <ol className={'yilu-ProductSummary__List'}>
          <li className={'yilu-ProductSummary__Row'}>
            <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__Column'}>
              {t('sbb.date_from')}:
            </ElementsSDK.Typography>
            <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__ColumnAddress'}>
              {dayjs(date).format('DD MMMM')}
            </ElementsSDK.Typography>
          </li>
          <li className={'yilu-ProductSummary__Row'}>
            <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__Column'}>
              {t('sbb.passenger_count')}:
            </ElementsSDK.Typography>
            <div className={'yilu-ProductSummary__ColumnAddress'}>
              <ElementsSDK.Typography size="medium">{passengersCount}</ElementsSDK.Typography>
            </div>
          </li>
        </ol>
      </div>
    </>
  );

  return (
    <ProductSummary
      extendedView={extendedView}
      isLoading={isLoading}
      onClick={onClick}
      termsDetails={termsDetails}
      price={price}
      currency={currency}
      submitBtnLabel={t('sbb.pay_now_cta')}
    />
  );
};
