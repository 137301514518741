/**
 * # Ride Interactive Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate, useIsDesktop } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TermsCheck from '../../../../components/TermsCheck';
import './styles.scss';
import { RideInteractiveProductSummaryProps, searchState } from './types';

export const RideInteractiveProductSummary: React.FC<RideInteractiveProductSummaryProps> = ({
  price,
  currency,
  onSubmit,
  isLoading,
  isDisabled,
  submitBtnLabel,
  children,
  whereTo,
  whereFrom,
  travelDate,
  travellersCount,
  isEstimatedPriceActive,
  hasFooter,
  carrierLogoUrl,
  providerClassification,
  carrierName,
  displayingTimezone,
  onSearch,
  showEdit = false,
  showPriceSummary = true,
  googleMapsAPIKey,
  termsDetails,
  driverWaitTime,
  name,
  luggage,
  maxSeats,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const [isEditable, setIsEditable] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const whereFromIntialValue = ElementsSDK.useAddressDetails(googleMapsAPIKey, whereFrom);
  const whereToInitialValue = ElementsSDK.useAddressDetails(googleMapsAPIKey, whereTo);

  const [search, setSearch] = useState<searchState>({
    whereTo: whereToInitialValue,
    whereFrom: whereFromIntialValue,
    travelDate,
    travellersCount,
  });

  const [inputSearch, setInputSearch] = useState<searchState>({
    whereTo: whereToInitialValue,
    whereFrom: whereFromIntialValue,
    travelDate,
    travellersCount,
  });

  useEffect(() => {
    setSearch((search) => ({
      ...search,
      whereTo: whereToInitialValue,
      whereFrom: whereFromIntialValue,
    }));
    setInputSearch((search) => ({
      ...search,
      whereTo: whereToInitialValue,
      whereFrom: whereFromIntialValue,
    }));
  }, [whereFromIntialValue, whereToInitialValue]);

  const date = formatLocalDate(
    search.travelDate!.replace(/T.*/, ''),
    'ddd, DD MMM YYYY',
    displayingTimezone,
  );
  const time = formatLocalDate(search.travelDate, DateFormat.TIME, displayingTimezone);

  const onCrossPressed = () => {
    setSearch({ ...inputSearch });
    setIsEditable(false);
  };

  const onSearchPressed = () => {
    setIsEditable(false);
    onSearch(search.whereTo, search.whereFrom, search.travelDate, search.travellersCount);
    setInputSearch({ ...search });
  };
  const ExtendedProductSummary = (
    <>
      {isEditable ? (
        <div className="yilu-ProductSummary__Ride__Btn__Editable--mobile">
          <button onClick={onCrossPressed} className="yilu-ProductSummary__Ride__Btn--mobile">
            <ElementsSDK.Icon.Cross className="yilu-ProductSummary__Ride__Icon_Editable--mobile" />
          </button>
        </div>
      ) : (
        <ElementsSDK.Typography variant="h6" className="yilu-ProductSummaryRide__Header">
          {t('rides.interactivesummary.title.text')}
          {!isDesktop && !showEdit && (
            <button
              onClick={() => setIsCollapsed((prevState) => !prevState)}
              className="yilu-ProductSummary__Ride__Btn--mobile"
            >
              <ElementsSDK.Icon.ArrowDownChevron
                className={clsx([
                  { 'yilu-ProductSummary__Ride__Icon-up': !isCollapsed },
                  { 'yilu-ProductSummary__Ride__Icon-down': isCollapsed },
                ])}
              />
            </button>
          )}

          {!isDesktop && showEdit && (
            <button
              onClick={() => setIsEditable(true)}
              className="yilu-ProductSummary__Ride__Btn--mobile"
            >
              <ElementsSDK.Icon.EditOutline className="yilu-ProductSummary__Ride__Icon--mobile" />
            </button>
          )}
        </ElementsSDK.Typography>
      )}
      <div
        className={clsx('yilu-ProductSummaryRide__InfoContainer', {
          'yilu-ProductSummaryRides--mobile-collapsed': isCollapsed,
        })}
      >
        {isEditable ? (
          <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
            <ElementsSDK.AddressInput
              onAddressSelect={(airport) =>
                setSearch({ ...search, whereFrom: airport as ElementsSDK.GoogleSearchAddress })
              }
              placeholder={search.whereFrom?.name}
              Icon={ElementsSDK.Icon.PinOutline}
              value={search.whereFrom?.name}
              googleMapsAPIKey={googleMapsAPIKey}
            />
          </div>
        ) : (
          <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__RideTimeCarrier">
            <ElementsSDK.Typography variant="h4" className="yilu-ProductSummaryRide__Value">
              {date}
            </ElementsSDK.Typography>
            <div className="yilu-ProductSummary__OperatorLogoWrapper">
              <img
                className="yilu-ProductSummary__OperatorLogo"
                src={carrierLogoUrl}
                alt={t('Operator logo')}
              />
              <ElementsSDK.Typography size="medium" className="yilu-ProductSummary__MinorOffset">
                {carrierName}
              </ElementsSDK.Typography>
            </div>
          </div>
        )}
        <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__Location">
          {isEditable ? (
            <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
              <ElementsSDK.AddressInput
                onAddressSelect={(airport) =>
                  setSearch({ ...search, whereTo: airport as ElementsSDK.GoogleSearchAddress })
                }
                placeholder={search.whereTo?.name}
                Icon={ElementsSDK.Icon.PinOutline}
                value={search.whereTo?.name}
                googleMapsAPIKey={googleMapsAPIKey}
              />
            </div>
          ) : (
            <div>
              <ElementsSDK.Typography variant="h4" className="yilu-ProductSummaryRide__Value">
                {t('rides.summary.from.label')} {search.whereFrom?.name}
              </ElementsSDK.Typography>
              <ElementsSDK.Typography size="medium" className="yilu-ProductSummaryRide__Value">
                {t('rides.summary.to.label')} {search.whereTo?.name}
              </ElementsSDK.Typography>
            </div>
          )}
        </div>
        <div
          className={clsx(!isDesktop && 'yilu-ProductSummaryRide__InputContainer', {
            'yilu-ProductSummaryRide__InputContainer__Date': !isEditable,
          })}
        >
          <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__Travellers">
            {isEditable ? (
              <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
                <ElementsSDK.InputField.TextInput
                  icon={<ElementsSDK.Icon.PersonOutline />}
                  className="yilu-productSummaryRide__Passenger__Icon"
                  value={search.travellersCount.toString()}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      travellersCount: parseInt((e.target as HTMLInputElement).value),
                    })
                  }
                  type="number"
                />
              </div>
            ) : (
              <>
                <div className="yilu-ProductSummary__Ride__SummaryItem">
                  <ElementsSDK.Icon.HandShake className="yilu-ProductSummary__Ride__Icon" />
                  <ElementsSDK.Typography size="medium" className="yilu-ProductSummaryRide__Value">
                    {t('rides.summary.pick_up_time.text', { time })}
                  </ElementsSDK.Typography>
                </div>

                {driverWaitTime && (
                  <div className="yilu-ProductSummary__Ride__SummaryItem">
                    <ElementsSDK.Icon.Time className="yilu-ProductSummary__Ride__Icon" />
                    <ElementsSDK.Typography
                      size="medium"
                      className="yilu-ProductSummaryRide__Value"
                    >
                      {t('rides.summary.waiting_time.text', { time: driverWaitTime })}
                    </ElementsSDK.Typography>
                  </div>
                )}
                {providerClassification && (
                  <div className="yilu-ProductSummary__Ride__SummaryItem">
                    <ElementsSDK.Icon.Taxi className="yilu-ProductSummary__Ride__Icon" />
                    <ElementsSDK.Typography
                      size="medium"
                      className="yilu-ProductSummaryRide__Value"
                    >
                      {providerClassification}
                      <br />
                      {name && `(${t('rides.summary.example.text')} ${name})`}
                    </ElementsSDK.Typography>
                  </div>
                )}
                {maxSeats && (
                  <div className="yilu-ProductSummary__Ride__SummaryItem">
                    <ElementsSDK.Icon.Seat className="yilu-ProductSummary__Ride__Icon" />
                    <ElementsSDK.Typography
                      size="medium"
                      className="yilu-ProductSummaryRide__Value"
                    >
                      {t('rides.summary.max_passengers.text', {
                        maxSeats,
                      })}
                    </ElementsSDK.Typography>
                  </div>
                )}
                {luggage && (
                  <div className="yilu-ProductSummary__Ride__SummaryItem">
                    <ElementsSDK.Icon.CustomLuggage className="yilu-ProductSummary__Ride__Icon" />
                    <ElementsSDK.Typography
                      size="medium"
                      className="yilu-ProductSummaryRide__Value"
                    >
                      {t('rides.summary.luggage.text', { luggage })}
                    </ElementsSDK.Typography>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div
      className={clsx({
        'yilu-ProductSummaryRides--mobile': !isDesktop,
        'yilu-ProductSummaryRides--desktop': isDesktop,
        'yilu-ProductSummaryRides': !isEditable,
        'yilu-ProductSummaryRides--mobile-collapsed': isCollapsed,
      })}
    >
      {ExtendedProductSummary}
      {children}
      {isEditable ? (
        <ElementsSDK.Button
          className="yilu-PriceSummary__Button"
          disabled={isDisabled}
          onClick={onSearchPressed}
          loading={isLoading}
        >
          {t('Search')}
        </ElementsSDK.Button>
      ) : (
        showPriceSummary && (
          <ElementsSDK.PriceSummary
            className={clsx('yilu-ProductSummaryRide__PriceSummary', {
              'yilu-ProductSummaryRide__PriceSummary--footer': !isDesktop || hasFooter,
            })}
            price={price}
            currency={currency}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onSubmit={() => onSubmit(search.travellersCount, search.travelDate!)}
            isEstimatedPriceActive={isEstimatedPriceActive}
            submitBtnLabel={submitBtnLabel}
            isTotalHighlighted={true}
            showTravellerCount={!isDesktop}
            travellerCount={travellersCount}
            termsComponent={
              termsDetails && (
                <TermsCheck
                  termsUrl={termsDetails?.termsUrl || ''}
                  productDetails={termsDetails?.productDetails}
                />
              )
            }
          />
        )
      )}
    </div>
  );
};
