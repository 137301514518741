import { createContext } from 'react';

export enum PaymentMethods {
  CREDIT_CARD = 'creditCard',
  APPLE_PAY = 'applePay',
  GOOGLE_PAY = 'googlePay',
}

export type PaymentInputContextType = {
  paymentMethods?: PaymentMethods[];
  setPaymentMethods: (method: PaymentMethods[]) => void;
  currentPaymentMethod?: PaymentMethods;
  setCurrentPaymentMethod: (paymentMethod: PaymentMethods) => void;
  isTermsAccepted: boolean;
  onTermsAcceptedChange: (terms: boolean) => void;
  hasFormTriggered: boolean;
  setHasFormTriggered: (hasSubmitted: boolean) => void;
  paymentMethodOnSubmit: () => void;
  setPaymentMethodOnSubmit: (method: () => void) => void;
};

export const PaymentInputContext = createContext<PaymentInputContextType | undefined>(undefined);
