import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';
import { setSrpSession } from 'utils/searchSession';

import routes from 'router/routes';

import { LoungeSearchResultsPageQueryParams } from '../../../utils/query-params';
import { LoungeSearchResultsProps } from '../LoungeSearchResults/types';

export const useLoungeSearchResultsProps = (
  loungeSearchResultsPageQueryParams: LoungeSearchResultsPageQueryParams,
  setIsLoading: (isLoading: boolean) => void,
) => {
  const searchResults = useRef<SearchItem[]>([]);
  const navigate = useNavigate();
  const loungeSearchResultsViewProps = useMemo(() => {
    const { iataCode, latitude, longitude } =
      loungeSearchResultsPageQueryParams.airportDetails || {};
    const searchParams = {
      entryDate: loungeSearchResultsPageQueryParams.entryDate,
      iata: iataCode!,
      adults: loungeSearchResultsPageQueryParams.adults,
      children: loungeSearchResultsPageQueryParams.children,
      infants: loungeSearchResultsPageQueryParams.infants,
      fcle: loungeSearchResultsPageQueryParams.fcle,
      coordinates: { latitude, longitude } as ElementsSDK.Coordinates,
    };

    const isMissingRequiredParameters = Object.keys(searchParams).some((key) => {
      return (
        (searchParams as Record<string, string | number | boolean | ElementsSDK.Coordinates>)[
          key
        ] === undefined
      );
    });

    if (isMissingRequiredParameters) return null;

    const setLoungeSrpSession = () => {
      setSrpSession({
        entryDate: searchParams.entryDate,
        airportIataCode: searchParams.iata,
        adults: searchParams.adults,
        children: searchParams.children,
        infants: searchParams.adults,
        fcle: searchParams.fcle,
      });
    };

    const _props: LoungeSearchResultsProps = {
      searchParams,
      onLoading() {
        setIsLoading(true);
      },
      onSearchResults(results: SearchItem[]) {
        setIsLoading(false);
        setLoungeSrpSession();

        searchResults.current = results;
        if (!results.length) {
          yiluTracking.sendGAEvent({
            event: 'page_loaded',
            category: 'lounge-srp-null',
            label: 'lounge_srp_null_loaded',
          });
        } else {
          yiluTracking.sendGAEvent({
            event: 'page_loaded',
            category: 'lounge-srp',
            label: 'lounge_srp_loaded',
          });
        }
      },
      onSearchResultClick(searchResult: SearchItem) {
        setLoungeSrpSession();

        yiluTracking.sendSelectedIndexSearchEvent(
          searchResult.id,
          searchResults.current,
          'lounge_srp',
        );

        const loungeProductDetailsPageQueryParams = {
          searchResultID: searchResult.id,
        };

        navigate({
          pathname: routes.LOUNGES_PDP,
          search: paramConverters.getURLSearchQuery(loungeProductDetailsPageQueryParams),
        });
      },
      onError(error: Error) {
        setIsLoading(false);
        throw error;
      },
    };

    return _props;
  }, [setIsLoading, loungeSearchResultsPageQueryParams, navigate]);

  return loungeSearchResultsViewProps;
};
