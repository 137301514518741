/**
 * # Lounge Collapsible Form
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

import { LoungeSearchForm } from '../../../components';
import useCollapsibleTrigger from './hooks/useCollapsibleTrigger';
import useLoungeCollapsibleForm from './hooks/useLoungeCollapsibleForm';
import './styles.scss';
import { LoungeCollapsibleFormProps } from './types';

export const LoungeCollapsibleForm: React.FC<LoungeCollapsibleFormProps> = ({
  className,
  searchInput,
  onSubmit,
  ...props
}) => {
  const { isExpanded, handleOnCollapsedClick, onKeyPressCollapsedHandler, setIsExpanded } =
    useCollapsibleTrigger();

  const { handleOnSubmit, date, travellersCount, iataCode } = useLoungeCollapsibleForm({
    setIsExpanded,
    onSubmit,
    searchInput,
  });

  return (
    <div className={clsx(className, 'yilu-LoungeCollapsibleForm')}>
      <div
        className={'yilu-LoungeCollapsibleForm__header'}
        onClick={handleOnCollapsedClick}
        onKeyPress={onKeyPressCollapsedHandler}
        role="button"
        tabIndex={0}
      >
        <div className={'yilu-LoungeCollapsibleForm__summary'}>
          <div
            className={clsx(
              'yilu-LoungeCollapsibleForm__item',
              'yilu-LoungeCollapsibleForm__location',
            )}
          >
            <ElementsSDK.Icon.Pin />
            <span className={'yilu-LoungeCollapsibleForm__value'}>{iataCode}</span>
          </div>
          <div
            className={clsx('yilu-LoungeCollapsibleForm__item', 'yilu-LoungeCollapsibleForm__date')}
          >
            <ElementsSDK.Icon.Calendar />
            <span className={'yilu-LoungeCollapsibleForm__value'}>{date}</span>
          </div>
          <div
            className={clsx(
              'yilu-LoungeCollapsibleForm__item',
              'yilu-LoungeCollapsibleForm__travellers',
            )}
          >
            <ElementsSDK.Icon.Person />
            <span className={'yilu-LoungeCollapsibleForm__value'}>{travellersCount}</span>
          </div>
        </div>

        <div className={clsx({ isExpanded }, 'yilu-LoungeCollapsibleForm__arrow')}>
          <ElementsSDK.Icon.ArrowDownChevron />
        </div>
      </div>
      {isExpanded && (
        <LoungeSearchForm
          className={'yilu-LoungeCollapsibleForm__Form'}
          onSubmit={handleOnSubmit}
          searchInput={searchInput}
          {...props}
        />
      )}
    </div>
  );
};
