import { SearchItem } from '@yiluhub/yilu-amp-types';

export type RidesResultCardData = {
  price: number;
  currency: string;
  model?: string;
  imageUrl?: string;
  carrierLogoUrl?: string;
  carrierName?: string;
  cancellationUrl?: string;
  providerClassification?: string;
  maxSeats?: string | number;
  luggage?: string;
  driversWaitingMinutesIncluded?: string | number;
  arrivalDateTime?: string;
  departureDateTime?: string;
};

export const useRidesData = (data: SearchItem): RidesResultCardData => {
  if (!data) {
    return getDefaultValues();
  }

  const extractAmpLoungeProductAttribute = (data: SearchItem, key: string) => {
    return data?.item?.productDetails[0]?.productDescription.productAttributes.find(
      (attribute) => attribute.key === key,
    )?.value;
  };

  const getProductDescription = (data: SearchItem) => {
    return data?.item?.productDetails[0]?.productDescription;
  };

  const productDescription = getProductDescription(data);
  const ridesData = {
    price: data?.item?.defaultPrice.price.amount / 100 || 0,
    currency: data?.item?.defaultPrice.price.currency,
    model: productDescription?.name,
    imageUrl: productDescription?.imageUrls[0],
    carrierLogoUrl: extractAmpLoungeProductAttribute(data, 'carrier_logo_url'),
    carrierName: extractAmpLoungeProductAttribute(data, 'carrier_name'),
    cancellationUrl: extractAmpLoungeProductAttribute(data, 'cancellation_url'),
    providerClassification: extractAmpLoungeProductAttribute(data, 'provider_classification'),
    maxSeats: extractAmpLoungeProductAttribute(data, 'max_seats'),
    luggage: extractAmpLoungeProductAttribute(data, 'luggage'),
    driversWaitingMinutesIncluded: extractAmpLoungeProductAttribute(data, 'driver_waiting_time'),
    arrivalDateTime: extractAmpLoungeProductAttribute(data, 'arrival_time'),
    departureDateTime: extractAmpLoungeProductAttribute(data, 'departure_time'),
  };

  return ridesData;
};

const getDefaultValues = (): RidesResultCardData => {
  return {
    price: 0,
    currency: '',
    model: '',
    imageUrl: '',
    carrierLogoUrl: '',
    carrierName: '',
    cancellationUrl: '',
    providerClassification: '',
    maxSeats: '',
    luggage: '',
    driversWaitingMinutesIncluded: '',
    arrivalDateTime: '',
    departureDateTime: '',
  };
};
