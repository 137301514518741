import dayjs from 'dayjs';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import { CommonQueryParams, getCommonQueryParams } from 'utils/paramConverters';

export type SwissTravelSearchInputPageQueryParams = CommonQueryParams & {
  duration?: number;
  classfare?: number;
  passengercount?: number;
  date?: string;
};

/**
 * Returns the query parameters for the Swiss travel Search Input Page.
 *
 * @param location - location descriptor
 */
export const getSwissTravelSearchInputPageQueryParams = (
  location: Location = window.location as any,
): SwissTravelSearchInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  let travelDuration = queryParams.duration && parseInt(queryParams.duration);
  let passengerCount = parseInt(queryParams.passengercount);
  if (travelDuration >= 9 && travelDuration <= 14) {
    travelDuration = 8;
  }
  if (travelDuration === 5) travelDuration = 4;
  if (travelDuration === 7) travelDuration = 6;
  if (travelDuration < 3) travelDuration = 3;
  if (travelDuration > 15) travelDuration = 15;

  if (passengerCount > 6) passengerCount = 6;
  if (queryParams.classfare && queryParams.classfare !== '1' && queryParams.classfare !== '2') {
    queryParams.classfare = 2;
  }
  if (dayjs(queryParams.date).isBefore(dayjs(new Date()).format('YYYY-MM-DD'))) {
    queryParams.date = undefined;
  }

  const commonQueryParams = getCommonQueryParams();
  const searchInputSwissTravelParams = {
    duration: travelDuration,
    classfare: queryParams.classfare && parseInt(queryParams.classfare),
    passengercount: passengerCount,
    date: queryParams.date,
  };

  return {
    ...commonQueryParams,
    ...searchInputSwissTravelParams,
  };
};

export type SwissTravelTravellerInputPageQueryParams = CommonQueryParams & {
  searchResultID: string;
};

/**
 * Returns the query parameters for the Swiss travel Traveller Information page.
 *
 * @param location - location descriptor
 */
export const getSwissTravelTravellerInputPageQueryParams = (
  location: Location = window.location as any,
): SwissTravelTravellerInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const productDetailsParams = {
    searchResultID: queryParams.searchResultID,
  };

  return {
    ...commonQueryParams,
    ...productDetailsParams,
  };
};

/**
 * Returns the query parameters for the Swiss travel Payment Input Page.
 *
 * @param location - location descriptor
 */
export const getSwissTravelPaymentInputPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const paymentInputPageParams = {
    searchResultID: queryParams.searchResultID,
    bookingIntentId: queryParams.bookingIntentId,
    shoppingCartId: queryParams.shoppingCartId,
  };

  return {
    ...commonQueryParams,
    ...paymentInputPageParams,
  };
};
