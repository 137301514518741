export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>?/gm, '');
};

export const breakCommaReplaceHtml = (html: string): string => {
  return html.replace(/<br \/>/gm, ', ');
};

//get anchor tag href from string
export const getAnchorTagHref = (html: string): string => {
  const regex = /href="([^"]*)"/;
  const href = html.match(regex);
  return href ? href[1] : '';
};
