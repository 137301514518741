import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';

import { sendGAEvent } from 'utils/tracking';

import { SBBProductSummaryProps } from '../../../components/SBBProductSummary';
import { getProductDetails } from '../../../utils/getProductDetails';

const useProductSummaryProps = (
  currentProduct: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  isReservationUpdateLoading: boolean,
) => {
  const { duration, startDate, passengersCount, price, currency } =
    getProductDetails(currentProduct);

  const productSummaryProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const props_: SBBProductSummaryProps = {
      duration,
      date: startDate,
      passengersCount,
      price,
      currency,
      onClick() {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'tip_cta_clicked',
            category: 'airport-transfer-sbb-tip',
            label: 'airport_transfer_sbb_tip_cta_clicked',
          });
        }
      },
      isLoading: isReservationUpdateLoading,
    };

    return props_;
  }, [
    currentProduct,
    reservationFormRef,
    isReservationUpdateLoading,
    currency,
    price,
    passengersCount,
    duration,
    startDate,
  ]);

  return productSummaryProps;
};

export default useProductSummaryProps;
