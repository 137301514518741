import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export const SecurePayment: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="yilu-payment__Secure-payment-container">
      <div className="yilu-payment__Secure-payment-container-wrapper">
        <ElementsSDK.Icon.Lock className="yilu-payment-secure-badge__icon" />
        <ElementsSDK.Typography
          inline
          size="small"
          bold
          className="yilu-payment-secure-bagde__text"
        >
          {t('Secure checkout')}
        </ElementsSDK.Typography>
      </div>
      <ElementsSDK.Icon.PoweredByStripe className="yilu-payment-secure-bagde__provider" />
    </div>
  );
};
