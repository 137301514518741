import { UserProfile } from '@yiluhub/yilu-amp-types';
import Cookies from 'js-cookie';

const COOKIE_CONSENT_KEY = 'yilu-user-preferences';

type ConsentCookie = {
  consent: UserProfile['consent'];
  isActionTriggered: boolean;
};

export const saveConsentToCookie = (consent: ConsentCookie) => {
  const consentString = JSON.stringify(consent);
  Cookies.set(COOKIE_CONSENT_KEY, consentString, { expires: 365 });
};

export const getConsentFromCookie = (): ConsentCookie | undefined => {
  const savedCookie = Cookies.get(COOKIE_CONSENT_KEY);
  return savedCookie ? JSON.parse(savedCookie) : undefined;
};
