import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerSIP from 'components/BannerSIP';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { LoungeSearchForm } from '../../components';
import LoungeProductValues from './LoungeProductValues';
import { useLoungeSearchInputPage } from './hooks';
import './styles.scss';

const SearchInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { loungeSearchFormProps, isPageReady, productValueItems } = useLoungeSearchInputPage();

  return (
    <div className="lounges-sip">
      {isPageReady ? (
        <>
          <BannerSIP
            title={t('SIMPLETRIP LOUNGES TITLE')}
            subtitle={t('SIMPLETRIP LOUNGES SUB TITLE')}
          />
          <div className="form-container-wrapper">
            <div
              className="form-container lounges-sip-form"
              aria-label="Purchase lounge access for your next travel"
              role="heading"
              aria-level={1}
            >
              <LoungeSearchForm {...loungeSearchFormProps} />
            </div>
            <LoungeProductValues productValueItems={productValueItems} />
          </div>
        </>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default SearchInputPage;
