import { SearchItem } from '@yiluhub/yilu-amp-types';

import { extractAmpProductAttribute } from 'utils/ampUtils';

export type AirportTransferProductDetails = {
  price: number;
  currency: string;
  carrierLogoUrl: string;
  carrierName: string;
  arrivalDateTime: string;
  departureDateTime: string;
  origin: Location;
  destination: Location;
  interconnectionTransfersCount: number | null;
  travellersCount: number;
  carrierLuggagePolicyUrl: string;
  id: string;
  catalogId: string;
  serviceProvider: string;
  termsUrl: string;
  searchResultId: string;
  providerId: string;
  priceOptionId: string;
  flexibilityPolicyCode?: AirportTransferProductDetailsFlexibilityPolicyCode;
};

export type Location = {
  name?: string | null;
  address?: string | null;
  city?: string | null;
  country?: string | null;
  zip?: string | null;
  lat?: number | null | string;
  long?: number | null | string;
};

export enum AirportTransferProductDetailsFlexibilityPolicyCode {
  REFU = 'REFU',
  PREF = 'PREF',
  NREF = 'NREF',
}

export const getAirportTransferProductData = (
  data: SearchItem | null,
): AirportTransferProductDetails => {
  if (!data) {
    return getDefaultDetails();
  }

  const price = data.item.priceOptions ? data.item.priceOptions[0].price.amount / 100 : 0;
  const currency = data.item.priceOptions ? data.item.priceOptions[0].price.currency : '';
  const priceOptionId = data.item.priceOptions ? data.item.priceOptions[0].id : '';

  return {
    id: data.id,
    catalogId: data.item.catalogId,
    price: price,
    currency: currency,
    serviceProvider: extractAmpProductAttribute(data, 'service_provider') || '',
    carrierLogoUrl: extractAmpProductAttribute(data, 'carrier_logo_url') || '',
    carrierName: extractAmpProductAttribute(data, 'carrier_name') || '',
    arrivalDateTime: extractAmpProductAttribute(data, 'arrival_time') || '',
    departureDateTime: extractAmpProductAttribute(data, 'departure_time') || '',
    travellersCount: data.item.quantity,
    origin: {
      name: extractAmpProductAttribute(data, 'departure_station_name'),
      address: extractAmpProductAttribute(data, 'departure_station_address'),
      city: extractAmpProductAttribute(data, 'departure_station_city'),
      country: extractAmpProductAttribute(data, 'departure_station_country'),
      zip: extractAmpProductAttribute(data, 'departure_station_zip'),
      lat: extractAmpProductAttribute(data, 'departure_station_lat'),
      long: extractAmpProductAttribute(data, 'departure_station_lon'),
    },
    destination: {
      name: extractAmpProductAttribute(data, 'arrival_station_name'),
      address: extractAmpProductAttribute(data, 'arrival_station_address'),
      city: extractAmpProductAttribute(data, 'arrival_station_city'),
      country: extractAmpProductAttribute(data, 'arrival_station_country'),
      zip: extractAmpProductAttribute(data, 'arrival_station_zip'),
      lat: extractAmpProductAttribute(data, 'arrival_station_lat'),
      long: extractAmpProductAttribute(data, 'arrival_station_lon'),
    },
    interconnectionTransfersCount: null,
    carrierLuggagePolicyUrl: extractAmpProductAttribute(data, 'luggage_policy_url') || '',
    termsUrl: extractAmpProductAttribute(data, 'terms_url') || '',
    searchResultId: data.id,
    providerId: '', // update this for AMP
    priceOptionId,
    flexibilityPolicyCode: extractAmpProductAttribute(
      data,
      'flexibility_policy_code',
    ) as AirportTransferProductDetailsFlexibilityPolicyCode,
  };
};

const getDefaultDetails = (): AirportTransferProductDetails => {
  return {
    id: '',
    price: 0,
    catalogId: '',
    serviceProvider: '',
    currency: '',
    carrierLogoUrl: '',
    carrierName: '',
    arrivalDateTime: '',
    departureDateTime: '',
    origin: getDefaultLocation(),
    destination: getDefaultLocation(),
    interconnectionTransfersCount: 0,
    travellersCount: 1,
    carrierLuggagePolicyUrl: '',
    termsUrl: '',
    searchResultId: '',
    providerId: '',
    priceOptionId: '',
  };
};

const getDefaultLocation = (): Location => {
  return {
    name: '',
    address: '',
    city: '',
    country: '',
    zip: '',
    lat: '',
    long: '',
  };
};
