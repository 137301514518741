export type SearchData = {
  DepartureIata: string; // Three-letter IATA code
  ArrivalIata: string; // Three-letter IATA code
  DepartureTime: string; // ISO 8601 date-time string
  ArrivalTime: string; // ISO 8601 date-time string
  FlightNumber: string;
};

export enum HighPassCategory {
  FastTrack = 'FastTrack',
  VipService = 'VipService',
  MeetAndGreet = 'MeetAndGreet',
  BusinessLounge = 'BusinessLounge',
}

export type HighPassWidgetProps = {
  apikey: string; // GUID
  predefinedSearchData?: SearchData[];
  categories?: HighPassCategory[];
  lang?: string;
};

export type HighPassWidgetCustomization = {
  backgroundColor?: string; // Hex color code
  buttonColor?: string; // Hex color code
  inputColor?: string; // Hex colar code
  linkColor?: string; // Hex color code
  headingColor?: string; // Hex.color code
  defaultTextColor?: string; // Hex color code
  errorColor?: string; // Hex color code
  serviceDescriptionColor?: string; // Hex color code
  labelColor?: string; // Hex color code
  importantColor?: string; // Hex color code.
  headerTextColor?: string; // Hex color code
  descriptionTextColor?: string; // Hex color code
};
