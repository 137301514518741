import { getYiluConfig } from '@yiluhub/ui-utilities';
import { TFunction } from 'i18next';
import { MutableRefObject, useEffect, useReducer, useState } from 'react';

import { CustomErrorCodes } from '../types';

const yiluConfig = getYiluConfig();

type usePaymentInformationFormParamsType = {
  stripeFormStyle: stripe.elements.ElementsOptions['style'];
  t: TFunction;
  stripe: stripe.Stripe;
  setIsFormLoading: (isFormLoading: boolean) => void;
  cardNameElement: MutableRefObject<HTMLDivElement | undefined>;
  cardNumberElement: MutableRefObject<HTMLDivElement | null>;
  cardExpiryElement: MutableRefObject<HTMLDivElement | null>;
  cardCvcElement: MutableRefObject<HTMLDivElement | null>;
  setAreStripeElementsValid: (areStripeElementsValid: boolean) => void;
  hasFormTriggered: boolean;
};

interface ErrorsState {
  cardNumber?: string;
  cardExpiry?: string;
  cardCvc?: string;
}

export const useCreditCardForm = ({
  stripeFormStyle,
  t,
  stripe,
  setIsFormLoading,
  cardNameElement,
  cardNumberElement,
  cardExpiryElement,
  cardCvcElement,
  setAreStripeElementsValid,
  hasFormTriggered,
}: usePaymentInformationFormParamsType) => {
  const [cardBrand, setCardBrand] = useState('');
  const [cardNumberStripeElement, setCardNumberStripeElement] =
    useState<stripe.elements.Element | null>(null);
  const [loadedElements, setLoadedElements] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const [errors, setErrors] = useReducer(
    (prevErrors: ErrorsState, newErrors: ErrorsState) => ({ ...prevErrors, ...newErrors }),
    {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    },
  );
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCVCValid, setIsCardCVCValid] = useState(false);

  const focusOnNextInput = (
    event: stripe.elements.ElementChangeResponse,
    cardNameElement: HTMLElement,
    cardCvc: stripe.elements.Element,
  ) => {
    const { complete, elementType } = event;

    if (complete) {
      switch (elementType) {
        case 'cardNumber':
          cardNameElement.focus();
          break;
        case 'cardExpiry':
          cardCvc.focus();
          break;
        default:
          // Do nothing
          break;
      }
    }
  };

  useEffect(() => {
    if (
      stripe &&
      cardNameElement.current &&
      cardNumberElement.current &&
      cardExpiryElement.current &&
      cardCvcElement.current
    ) {
      setIsFormLoading(true);

      const elements = stripe.elements({
        locale: yiluConfig.locale || 'en',
      });

      const cardNumber = elements.create('cardNumber', {
        style: stripeFormStyle,
        placeholder: t('Credit Card Number Placeholder'),
      });

      const cardExpiry = elements.create('cardExpiry', {
        style: stripeFormStyle,
        placeholder: t('Expiration Date Placeholder'),
      });

      const cardCvc = elements.create('cardCvc', {
        style: stripeFormStyle,
        placeholder: t('CVC Code Placeholder'),
      });

      cardNumber.mount(cardNumberElement.current);
      cardExpiry.mount(cardExpiryElement.current);
      cardCvc.mount(cardCvcElement.current);

      setCardNumberStripeElement(cardNumber);

      cardNumber.on('change', (event) => {
        if (event && event.error && event.error.code) {
          setErrors({ cardNumber: event.error.code });
        } else if (event && event.empty) {
          setErrors({ cardNumber: CustomErrorCodes.INPUT_REQUIRED });
        } else {
          setErrors({ cardNumber: '' });
        }

        if (event && event.brand) {
          setCardBrand(event.brand);
          focusOnNextInput(event, cardNameElement.current as HTMLElement, cardCvc);
        }

        setIsCardNumberValid(event ? event.complete : false);
      });

      cardExpiry.on('change', (event) => {
        if (event && event.error && event.error.code) {
          setErrors({ cardExpiry: event.error.code });
        } else if (event && event.empty) {
          setErrors({ cardExpiry: CustomErrorCodes.INPUT_REQUIRED });
        } else {
          setErrors({ cardExpiry: '' });
        }

        event && focusOnNextInput(event, cardNameElement.current as HTMLElement, cardCvc);

        setIsCardExpiryValid(event ? event.complete : false);
      });

      cardCvc.on('change', (event) => {
        if (event && event.error && event.error.code) {
          setErrors({ cardCvc: event.error.code });
        } else if (event && event.empty) {
          setErrors({ cardCvc: CustomErrorCodes.INPUT_REQUIRED });
        } else {
          setErrors({ cardCvc: '' });
        }

        setIsCardCVCValid(event ? event.complete : false);
      });

      cardNumber.on('ready', () => {
        setLoadedElements((prev) => ({ ...prev, cardNumber: true }));
        cardNumber.focus();
      });

      cardExpiry.on('ready', () => {
        setLoadedElements((prev) => ({ ...prev, cardExpiry: true }));
      });

      cardCvc.on('ready', () => {
        setLoadedElements((prev) => ({ ...prev, cardCvc: true }));
      });

      return () => {
        cardNumber.unmount();
        cardExpiry.unmount();
        cardCvc.unmount();
      };
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasFormTriggered) {
      setErrors({
        cardNumber: !isCardNumberValid ? errors.cardNumber || CustomErrorCodes.INPUT_REQUIRED : '',
        cardExpiry: !isCardExpiryValid ? errors.cardExpiry || CustomErrorCodes.INPUT_REQUIRED : '',
        cardCvc: !isCardCVCValid ? errors.cardCvc || CustomErrorCodes.INPUT_REQUIRED : '',
      });
    }
  }, [
    errors.cardCvc,
    errors.cardExpiry,
    errors.cardNumber,
    isCardCVCValid,
    isCardExpiryValid,
    isCardNumberValid,
    hasFormTriggered,
  ]);

  // Check if the stripe elements are fully loaded and rendered
  useEffect(() => {
    if (loadedElements.cardNumber && loadedElements.cardExpiry && loadedElements.cardCvc) {
      setIsFormLoading(false);
    }
  }, [loadedElements, setIsFormLoading]);

  // Check if the stripe elements are filled with valid data
  useEffect(() => {
    setAreStripeElementsValid(isCardNumberValid && isCardExpiryValid && isCardCVCValid);
  }, [isCardNumberValid, isCardExpiryValid, isCardCVCValid, setAreStripeElementsValid]);

  return {
    cardBrand,
    cardNumberStripeElement,
    errors,
    stripe,
  };
};
