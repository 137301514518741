import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import { FlexibilityBannerIcon } from './icons/FlexibilityBannerIcon';
import './styles.scss';

export type FlexibilityBannerProps = {
  heading: string;
  subHeading: string;
};
export const FlexbilityBanner: React.FC<FlexibilityBannerProps> = ({ heading, subHeading }) => {
  return (
    <div className="yilu-Hex__Banner">
      <div className="Hex__Banner__wrapper">
        <ElementsSDK.Typography
          variant="h3"
          size="large"
          className="Hex__Banner__heading"
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
        <ElementsSDK.Typography
          variant="p1"
          size="medium"
          className="Hex__Banner__subheading"
          dangerouslySetInnerHTML={{
            __html: subHeading,
          }}
        />
      </div>
      <FlexibilityBannerIcon className="yilu-Hex__Icon" />
    </div>
  );
};

export default FlexbilityBanner;
