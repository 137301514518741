import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { YILU_OVERRIDE_KEY } from 'applicationConstants';
import React from 'react';
import { Link } from 'react-router-dom';

import { useComponentOverrides } from 'context';

import './styles.scss';

export type LogoProps = {
  link: string;
};

const Logo: React.FC<LogoProps> = (props) => {
  const renderLogo = useComponentOverrides(YILU_OVERRIDE_KEY.YILU_HEADER_LOGO);
  return (
    <>
      {renderLogo(
        (props) => (
          <Link className="logo-wrapper" to={props.link} reloadDocument>
            <ElementsSDK.Typography variant="h3" className="logo-content">
              Simpletrip
            </ElementsSDK.Typography>
          </Link>
        ),
        props,
      )}
    </>
  );
};

export default Logo;
