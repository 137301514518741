import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentLanguage } from 'utils/i18n';
import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';

const useReservationInformationFormProps = (
  currentParking: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  setIsReservationUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const navigate = useNavigate();
  const yiluEnv = getVariables();

  const travellerInformationFormProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    const parkingDetails = getParkingDetails(currentParking);

    const _props: TravellerInformationFormProps<AMP_VERTICALS.PARKING> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.PARKING,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId: parkingDetails.catalogId,
        locale: getCurrentLanguage(),
        quantity: 1,
        searchResultId: parkingDetails.searchResultId,
        selectedPriceId: parkingDetails.selectedPriceId,
      },
      serviceProviderId: parkingDetails.providerId,
      onUpdateBookingIntentSuccess(data) {
        const parkingPaymentInformationPageQueryParams = {
          searchResultID: parkingDetails.searchResultId,
          bookingIntentId: data.bookingIntentId,
          shoppingCartId: data.shoppingCartId,
        };

        navigate({
          pathname: routes.PARKING_PIP,
          search: getURLSearchQuery(parkingPaymentInformationPageQueryParams),
        });
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading) {
        setIsReservationUpdateLoading(isFormLoading);
      },
    };
    return _props;
  }, [
    yiluEnv.YILU_AMP_STORE_ID,
    currentParking,
    reservationFormRef,
    navigate,
    setIsReservationUpdateLoading,
  ]);

  return travellerInformationFormProps;
};

export default useReservationInformationFormProps;
