import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils/index';

import routes from 'router/routes';

import { TrackingParam } from 'widgets/GlobalDefaults';

import { ParkingProductSummaryProps } from 'modules/parking/components/ParkingProductSummary/types';
import { ProductDetails } from 'modules/parking/utils/getParkingDetails';

export function useParkingProductSummaryProps(
  parkingDetails: ProductDetails,
  parkingProductDetailsPageQueryParams: {
    searchResultID: any;
    entryDate: any;
    airportDetails: any;
    locale: string | TrackingParam;
    userId: string | TrackingParam;
    tracking: string | TrackingParam;
    touchpoint?: string | TrackingParam;
    entryTime: any;
    exitTime: any;
    fromOffer: any;
    loyaltyProgramId?: string | TrackingParam;
    flightTime: any;
    exitDate: any;
  },
) {
  const navigate = useNavigate();
  const parkingProductSummaryProps = useMemo(() => {
    if (!parkingDetails) {
      return null;
    }

    const _props: ParkingProductSummaryProps = {
      price: parkingDetails.price,
      currency: parkingDetails.currency,
      enterDate: UiUtilities.formatLocalDate(
        parkingDetails.arrival,
        UiUtilities.DateFormat.SHORT_DATE,
      ),
      enterTime: UiUtilities.formatLocalDate(parkingDetails.arrival, UiUtilities.DateFormat.TIME),
      exitDate: UiUtilities.formatLocalDate(
        parkingDetails.departure,
        UiUtilities.DateFormat.SHORT_DATE,
      ),
      exitTime: UiUtilities.formatLocalDate(parkingDetails.departure, UiUtilities.DateFormat.TIME),
      parkingName: parkingDetails.name,
      onClick() {
        yiluTracking.sendGAEvent({
          event: 'pdp_customization_clicked',
          category: 'parking_pdp',
          label: 'parking_pdp_customization_clicked',
        });

        const travellerInformationPageQueryParams = {
          searchResultID: parkingProductDetailsPageQueryParams.searchResultID,
        };

        navigate({
          pathname: routes.PARKING_TIP,
          search: paramConverters.getURLSearchQuery(travellerInformationPageQueryParams),
        });
      },
    };
    return _props;
  }, [parkingDetails, parkingProductDetailsPageQueryParams, navigate]);
  return parkingProductSummaryProps;
}
