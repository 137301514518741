import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export const LoadingPageAnimation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-page-container">
      <ElementsSDK.LoadingIndicatorSVG />
      <ElementsSDK.Typography size="small">{t('loading.text')}</ElementsSDK.Typography>
    </div>
  );
};
