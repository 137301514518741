/**
 * # Parking Product Details Page Hooks
 *
 *
 */
import { useCurrentProduct } from 'hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import routes from 'router/routes';

import { useParkingProductDetailsProps } from 'modules/parking/pages/ProductDetailsPage/hooks/useParkingProductDetailsProps';
import { useParkingProductSummaryProps } from 'modules/parking/pages/ProductDetailsPage/hooks/useParkingProductSummaryProps';
import { constructParkingBacklinkProps } from 'modules/parking/utils/constructParkingBackLinkProps';
import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';
import { getParkingProductDetailsPageQueryParams } from 'modules/parking/utils/query-params';
import { getParkingSrpSession } from 'modules/parking/utils/searchSession';

/**
 * Retrieve data for the parking product details page.
 */
export const useParkingProductDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parkingProductDetailsPageQueryParams = useMemo(() => {
    return getParkingProductDetailsPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(parkingProductDetailsPageQueryParams.searchResultID);

  const isPageReady = Boolean(currentProduct);
  const parkingDetails = getParkingDetails(currentProduct);

  const parkingProductDetailsProps = useParkingProductDetailsProps(
    parkingDetails,
    parkingProductDetailsPageQueryParams,
  );

  const parkingProductSummaryProps = useParkingProductSummaryProps(
    parkingDetails,
    parkingProductDetailsPageQueryParams,
  );

  const searchSession = getParkingSrpSession();

  const iata =
    parkingProductDetailsPageQueryParams?.airportDetails?.iataCode ||
    searchSession?.airportDetails?.iataCode;
  const entryDate = parkingProductDetailsPageQueryParams?.entryDate || searchSession?.entryDate;
  const entryTime = parkingProductDetailsPageQueryParams?.entryTime || searchSession?.entryTime;
  const exitDate = parkingProductDetailsPageQueryParams?.exitDate || searchSession?.exitDate;
  const exitTime = parkingProductDetailsPageQueryParams?.exitTime || searchSession?.exitTime;
  const flightTime = parkingProductDetailsPageQueryParams?.flightTime || searchSession?.flightTime;

  const backLinkProps = constructParkingBacklinkProps(
    iata ? `${t('parking.pdp.backlink.label')} ${iata.toUpperCase()}` : '',
    routes.PARKING_SEARCH_RESULTS,
    navigate,
    entryDate,
    entryTime,
    exitDate,
    exitTime,
    iata,
    flightTime,
  );

  return {
    isPageReady,
    parkingProductDetailsProps,
    parkingProductSummaryProps,
    backLinkProps,
  };
};
