export enum PhoneInputType {
  NUMBER = 'number',
  CODE = 'code',
}

export const getPhoneNumberFieldValues = (type: PhoneInputType, fieldName: string): string => {
  const savedItem = sessionStorage.getItem(fieldName);
  if (savedItem) {
    const phoneNumber: { number: string; code: string } = JSON.parse(savedItem);
    if (type === PhoneInputType.CODE) {
      return phoneNumber.code;
    } else {
      return phoneNumber.number;
    }
  }
  return '';
};
