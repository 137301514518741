import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { ParkingSearchInputPageQueryParams } from '../../../utils/query-params';

export const useSetAirportDetails = (
  isPageReady: boolean,
  parkingSearchInputPageQueryParams: ParkingSearchInputPageQueryParams,
  parkingAirports: ElementsSDK.FilteredAirportDetails[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const nextParkingSearchInputPageQueryParams = { ...parkingSearchInputPageQueryParams };
    const airportIataCode = parkingSearchInputPageQueryParams.airportIataCode;

    if (parkingAirports && parkingAirports.length > 0 && airportIataCode) {
      const deepLinkedAirport = parkingAirports.find(
        (airport: { iataCode: string }) =>
          airport.iataCode.toLowerCase() === airportIataCode?.toLowerCase(),
      );
      if (deepLinkedAirport && deepLinkedAirport.localizations) {
        nextParkingSearchInputPageQueryParams.airportDetails = {
          localizations: {
            de: deepLinkedAirport.localizations.de,
            en: deepLinkedAirport.localizations.en,
          },
          iataCode: deepLinkedAirport.iataCode,
          latitude: deepLinkedAirport.latitude,
          longitude: deepLinkedAirport.longitude,
        };
      }
      delete nextParkingSearchInputPageQueryParams.airportIataCode;

      navigate(
        {
          pathname: routes.PARKING_SEARCH,
          search: getURLSearchQuery(nextParkingSearchInputPageQueryParams),
        },
        { replace: true },
      );
    }
  }, [isPageReady, navigate, parkingAirports, parkingSearchInputPageQueryParams]);
};

export default useSetAirportDetails;
