/**
 * # Collapsible Parking Form
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ParkingSearchForm } from '../../../components';
import CollapsedParking from './CollapsedParking';
import { useCollapsibleParkingForm } from './hooks/useCollapsibleParkingForm';
import './styles.scss';
import { ParkingCollapsibleFormProps } from './types';

export const ParkingCollapsibleForm: React.FC<ParkingCollapsibleFormProps> = ({
  className,
  isExpanded,
  onCollapsedClick,
  showCloseButton,
  onSubmit,
  searchInput,
  flightTime,
  onError,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = UiUtilities.useMediaMatch('--viewport-lte-m');

  const {
    isExpanded: internalIsExpanded,
    handleOnSubmit,
    handleOnCollapsedClick,
    handleOnCloseClick,
  } = useCollapsibleParkingForm({ isExpanded, onCollapsedClick, onSubmit });

  return (
    <div data-testid="yilu-CollapsibleForm__Parking" className="yilu-CollapsibleForm__Parking">
      {internalIsExpanded ? (
        <section
          className={clsx(
            showCloseButton && 'yilu-CollapsibleForm__Parking__has-close-button',
            flightTime && 'yilu-CollapsibleForm__Parking__has-flight-time',
            'yilu-CollapsibleForm__Parking__expanded_wrapper',
          )}
        >
          <ParkingSearchForm
            searchInput={searchInput}
            onSubmit={handleOnSubmit}
            flightTime={flightTime}
            onError={onError}
            {...props}
          />
          {isMobile && showCloseButton && (
            <button
              onClick={handleOnCloseClick}
              className="yilu-CollapsibleForm__Parking__CollapseButton"
              aria-label={t('Close')}
            >
              <ElementsSDK.Icon.Close />
            </button>
          )}
        </section>
      ) : (
        <button
          aria-label="expand-button"
          onClick={handleOnCollapsedClick}
          className={clsx(
            'yilu-CollapsibleForm__Parking__collapsed_wrapper',
            'yilu-CollapsibleForm__Parking__ExpandButton',
          )}
        >
          <CollapsedParking searchInput={searchInput} flightTime={flightTime} />
        </button>
      )}
    </div>
  );
};
