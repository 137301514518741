export const PHONE_NUMBER_PREFIX_NAME = 'phoneNumberPrefix';
export const PHONE_NUMBER_SUFFIX_NAME = 'phoneNumberSuffix';
export const MESSAGE_TO_DRIVER_NAME = 'messageToDriver';
export const PHONE_NUMBER_NAME = 'phoneNumber';
export const EMAIL_NAME = 'email';
export const IS_ECONOMY_PREMIUM = 'isEconomyPremium';
export const AIRLINE_GROUP = 'airlineGroup';
export const PNR_BOOKING_CODE = 'pnr';

export const enum ProductConsumerAttributeType {
  STRING = 'STRING',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  COUNTRY = 'COUNTRY',
  RADIO = 'RADIOBUTTON',
  DATE = 'DATE',
  TIME = 'TIME',
}

export const enum ProductConsumerAttributeRequirement {
  MANDATORY = 'MANDATORY',
  RECOMMENDED = 'RECOMMENDED',
  OPTIONAL = 'OPTIONAL',
}
