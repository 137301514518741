import { BookingIntentDto } from '@yiluhub/yilu-amp-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ProductConsumerAttributeType } from './constants';

export const getBookingIntentFieldsPayload = (
  customerFields: Record<string, string | any>,
  bookingIntent: BookingIntentDto,
) => {
  const fieldsToUpdate = bookingIntent.travellerInfo.map((field) => {
    let fieldValue = customerFields[field.name];

    if (field.type === ProductConsumerAttributeType.PHONE_NUMBER) {
      fieldValue =
        !customerFields[field.name].code || !customerFields[field.name].number
          ? null
          : `+${customerFields[field.name].code}${customerFields[field.name].number}`;
    }

    // NOTE: Api expects HHmm time format instead of HH:mm.
    if (field.type === ProductConsumerAttributeType.TIME) {
      fieldValue = fieldValue ? fieldValue.replace(':', '') : null;
    }

    // NOTE: Api expects YYYY-MM-DD date format.
    if (field.type === ProductConsumerAttributeType.DATE) {
      dayjs.extend(customParseFormat);
      // replace all white spaces and the THH:mm part that might be in the input
      fieldValue = fieldValue ? fieldValue.replace(/\s/g, '').split('T')[0] : null;
      // check if the incoming input has a different format and change it
      if (dayjs(fieldValue, 'DD/MM/YYYY', true).isValid()) {
        fieldValue = dayjs(fieldValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
    }

    return {
      ...field,
      value: fieldValue,
    };
  });

  // clean up null values before return
  const fieldsToUpdateDefined = fieldsToUpdate.filter((field) => field.value);
  return fieldsToUpdateDefined;
};
