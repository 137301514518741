import { LegalContentType } from 'applicationConstants';
import { createContext } from 'react';

export type LegalModalContextType = {
  isLegalModalShown: boolean;
  showLegalBackBtn: boolean;
  title: string;
  trackingContentType: LegalContentType;
  openLegalModal: (contentType: LegalContentType, title: string, showClose?: boolean) => void;
  closeLegalModal: () => void;
};

export const LegalModalContext = createContext<LegalModalContextType | undefined>(undefined);
