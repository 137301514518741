import React from 'react';

import { HighPassWidget } from 'modules/highpass/components/HighPassWidget';

import '../styles.scss';
import { useHighPassVipServicePage } from './hooks';

const HighPassFastTrackPage: React.FC = () => {
  const { isPageReady, highPassProps } = useHighPassVipServicePage();

  return (
    <>
      {isPageReady && (
        <div className="hp-embed-page">
          <section className="hp-container">
            {highPassProps && <HighPassWidget {...highPassProps} />}
          </section>
        </div>
      )}
    </>
  );
};

export default HighPassFastTrackPage;
