/**
 * # Airport Transfer Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatUtils } from 'utils';

import ProductSummary from 'components/ProductSummary';

import { formatInterconnections } from '../../utils';
import './styles.scss';
import { AirportTransferProductSummaryProps } from './types';

export const AirportTransferProductSummary: React.FC<AirportTransferProductSummaryProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const { travellersCount } = props;

  const extendedView = <AirportTransferSummaryBox {...props} />;

  return (
    <ProductSummary extendedView={extendedView} {...props}>
      {travellersCount && (
        <div className={'yilu-ProductSummary__Row'}>
          <ElementsSDK.Typography
            size="medium"
            className={'yilu-ProductSummary__Minor'}
            data-testid="travellersCount"
          >
            {t('travellers.count', { travellersCount })}
          </ElementsSDK.Typography>
        </div>
      )}
    </ProductSummary>
  );
};

export const AirportTransferSummaryBox: React.FC<AirportTransferProductSummaryProps> = ({
  originName,
  destinationName,
  departureDateTime,
  arrivalDateTime,
  displayingTimezone,
  carrierName,
  interconnectionTransfersCount,
  travellersCount,
  onTravellersCountChange,
  travellersCounterDropdownProps,
  showError,
}) => {
  const { t } = useTranslation();
  const formattedDuration = formatUtils.formatDuration(t, departureDateTime, arrivalDateTime);
  const transferLabel = formatInterconnections(t, interconnectionTransfersCount);

  return (
    <>
      <ElementsSDK.Typography variant="h6" uppercase className={'yilu-ProductSummary__Heading'}>
        {t('Summary')}
      </ElementsSDK.Typography>
      <div className={'yilu-ProductSummary__Itinerary'}>
        <ElementsSDK.Typography variant="h4" className={'yilu-ProductSummary__Row'}>
          {formatLocalDate(departureDateTime, 'ddd, MMMM D', displayingTimezone)}
        </ElementsSDK.Typography>
        <ol className={'yilu-ProductSummary__List'}>
          <li className={'yilu-ProductSummary__Row'}>
            <ElementsSDK.Typography size="medium" bold className={'yilu-ProductSummary__Column'}>
              {formatLocalDate(departureDateTime, 'HH:mm', displayingTimezone)}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__ColumnAddress'}>
              {originName}
            </ElementsSDK.Typography>
          </li>
          <li className={'yilu-ProductSummary__Row'}>
            <ElementsSDK.Typography size="medium" bold className={'yilu-ProductSummary__Column'}>
              {formatLocalDate(arrivalDateTime, 'HH:mm', displayingTimezone)}
            </ElementsSDK.Typography>
            <div className={'yilu-ProductSummary__ColumnAddress'}>
              <ElementsSDK.Typography size="medium">{destinationName}</ElementsSDK.Typography>
              <div className={'yilu-ProductSummary__Row yilu-ProductSummary__RowDuration'}>
                <ElementsSDK.Typography size="medium" bold>
                  {formattedDuration}
                  {transferLabel && (
                    <ElementsSDK.Typography
                      size="medium"
                      className={'yilu-ProductSummary__MinorOffset'}
                    >
                      &middot; {transferLabel}
                    </ElementsSDK.Typography>
                  )}
                </ElementsSDK.Typography>
              </div>
            </div>
          </li>
        </ol>
        {onTravellersCountChange && (
          <>
            <div className="yilu-AirportTransferSummaryLounge__TravellersCounterContainer">
              <ElementsSDK.TravellersCounterDropdown
                isDisabled={false}
                travellersCount={travellersCount}
                maxTravellersCount={6}
                onChange={onTravellersCountChange}
                {...travellersCounterDropdownProps}
                generateOptionLabel={(option) => (option.value === 1 ? t('Guest') : t('Guests'))}
              />
            </div>
            {showError && (
              <ElementsSDK.Typography
                size="small"
                className="yilu-AirportTransferProductSummary_TravellersCount--error"
              >
                {t('airport-transfers.pdp.number-of-travellers-error.text')}
              </ElementsSDK.Typography>
            )}
          </>
        )}
      </div>
      <div className={'yilu-ProductSummary__Info'}>
        <ul>
          <li className={'yilu-ProductSummary__Row'}>
            <ElementsSDK.Typography
              size="medium"
              className={'yilu-ProductSummary__Column yilu-ProductSummary__Minor'}
            >
              {t('Operator')}
            </ElementsSDK.Typography>
            <div className={'yilu-ProductSummary__OperatorLogoWrapper'}>
              <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__MinorOffset'}>
                {carrierName}
              </ElementsSDK.Typography>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
