/**
 * # AirportTransfer Booking Info
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate } from '@yiluhub/ui-utilities';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import './styles.scss';

export type AirportTransferBookingInfoProps = {
  booking: Booking<'COMPLETED', 'BUS'>;
};

export const AirportTransferBookingInfo: React.FC<AirportTransferBookingInfoProps> = ({
  booking,
}) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  const originAddress = bookingData.origin && bookingData.origin.plainAddress;
  const destinationAddress = bookingData.destination && bookingData.destination.plainAddress;

  const originTimezone =
    bookingData.origin && tzlookup(bookingData.origin.latitude, bookingData.origin.longitude);
  const destinationTimezone =
    bookingData.destination &&
    tzlookup(bookingData.destination.latitude, bookingData.destination.longitude);

  const { firstName, lastName, email } = bookingData.customerDetails;

  return (
    <div className="yilu-AirportTransferBookingInfo">
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[
          formatLocalDate(
            bookingData.departureTime,
            DateFormat.FULL_TEXT_WITH_DATE,
            originTimezone,
          ),
        ]}
        subText={t('bcp.departureDate')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      {originAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[originAddress]}
          subText={t('bcp.departs')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={[`${firstName} ${lastName}`, email]}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
      {destinationAddress && destinationAddress && (
        <>
          <ElementsSDK.Divider />
          <ElementsSDK.Itinerary
            originHighlight={originAddress}
            originDepartureTime={bookingData.departureTime}
            destinationHighlight={destinationAddress}
            destinationArrivalTime={bookingData.arrivalTime}
            displayingTimezone={destinationTimezone}
            showHeader={false}
            icon={<ElementsSDK.Icon.Bus />}
          />
        </>
      )}
    </div>
  );
};
