import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

type OptionalCategoryBoxProps = {
  fields: { name: string }[];
  setValue: (name: string, value: any) => void;
  children: React.ReactNode;
};

export const OptionalCategoryBox = (props: OptionalCategoryBoxProps) => {
  const { t } = useTranslation();
  const sessionKey = 'expandedCategory';
  const isExpanded = sessionStorage.getItem(sessionKey) === 'true';
  const [expanded, setExpanded] = React.useState(isExpanded);

  const { fields, setValue, children } = props;

  // Clear all values when the accordion is collapsed
  const clearValues = useCallback(() => {
    fields.forEach((field) => {
      setValue(field.name, '');
    });
  }, [fields, setValue]);

  useEffect(() => {
    if (!expanded) {
      clearValues();
    }
  }, [expanded, clearValues]);

  useEffect(() => {
    sessionStorage.setItem(sessionKey, JSON.stringify(expanded));
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="yilu-OptionalCategoryBox">
      <button type="button" className="yilu-OptionalCategoryBox-label" onClick={toggleExpanded}>
        <ElementsSDK.Icon.PlusCircle />
        <div>
          <ElementsSDK.Typography bold>{t('tip.form.billingAddress.label')}</ElementsSDK.Typography>
          <div>
            <ElementsSDK.Typography>
              {t('tip.form.billingAddress.description')}
            </ElementsSDK.Typography>
          </div>
        </div>
        <ElementsSDK.Icon.ArrowDownChevron
          className={clsx(
            'yilu-OptionalCategoryBox-icon',
            expanded && 'yilu-OptionalCategoryBox-icon-rotate',
          )}
        />
      </button>

      <div
        className={clsx('yilu-OptionalCategoryBox-fields', {
          'yilu-OptionalCategoryBox-fields--expanded': expanded,
        })}
      >
        {expanded && children}
      </div>
    </div>
  );
};
