/**
 * # Traveller Input Page Hooks
 *
 *
 */
import { useCurrentProduct } from 'hooks';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getSwissTravelTravellerInputPageQueryParams } from '../../../utils/query-params';
import useProductSummary from './useProductSummary';
import useTracking from './useTracking';
import useTravellerInformationFormProps from './useTravellerInformationFormProps';

/**
 * Retrieve data for the traveller input page.
 */
export const useTravellerInputPage = () => {
  const location = useLocation();

  const travellerInputPageQueryParams = useMemo(() => {
    return getSwissTravelTravellerInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(travellerInputPageQueryParams.searchResultID);

  const reservationFormRef = useRef<HTMLFormElement>(null);
  const [isReservationUpdateLoading, setIsReservationUpdateLoading] = useState(false);

  const isPageReady = Boolean(currentProduct);

  useTracking(isPageReady);

  const travellerInformationFormProps = useTravellerInformationFormProps(
    currentProduct,
    reservationFormRef,
    setIsReservationUpdateLoading,
  );

  const swissTravelProductSummaryProps = useProductSummary(
    currentProduct,
    reservationFormRef,
    isReservationUpdateLoading,
  );

  return {
    isPageReady,
    travellerInformationFormProps,
    swissTravelProductSummaryProps,
  };
};
