import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { paramConverters, yiluTracking } from 'utils';
import { parseTravellersCount } from 'utils/paramConverters';

import routes from 'router/routes';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import {
  TravellersCount,
  useTravellersSelector,
} from 'modules/lounges/components/LoungeSearchForm/useTravellersSelector';
import { useOpeningTimesOptions } from 'modules/lounges/pages/ProductDetailsPage/hooks/useOpeningTimesOptions';
import { isLoungeBookable } from 'modules/lounges/utils/availability';
import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { MAX_LOUNGE_GUESTS } from '../../../utils/constants';
import { getLoungeTravellerInputPageQueryParams } from '../../../utils/query-params';
import useUpdateAmpSearchResult from './useUpdateAmpSearchResult';

const useLoungeProductSummaryProps = (
  currentLounge: SearchItem | undefined,
  {
    entryDate,
    setEntryDate,
  }: {
    entryDate?: string;
    setEntryDate: (date?: string) => void;
  },
) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    name,
    currency,
    price: productPrice,
    location: productLocation,
    convertedLocalDate,
    openingHours,
    optionId,
    travellersCount: initialTravellersCount,
    providerId,
    isLocked,
    isBookable,
    airportCoordinates,
  } = getProductDetails(currentLounge);

  const isHolidayExtras = isSpHolidayExtras(providerId);

  // Additional updates are needed for Holiday Extra Lounges. Until then, we disable the edit button for Holiday Extra.
  const isEditable = !isHolidayExtras;

  const searchResultId = currentLounge?.id;

  const [loadedLounge, setLoadedLounge] = useState(false);
  const [showBacklinkNavigator, setShowBacklinkNavigator] = useState(true);
  const [price, setPrice] = useState<number>(productPrice);
  const [singleProduct, setSingleProduct] = useState<boolean | undefined>(undefined);
  const [isPurchasable, setIsPurchasable] = useState(true);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [isTimeUnavailable, setIsTimeUnavailable] = useState(false);

  const updateSearchResult = useUpdateAmpSearchResult();

  const [travellersCount, setTravellersCount] = useState<TravellersCount>({
    adults: initialTravellersCount.adults || 1,
    children: initialTravellersCount.children || 0,
    infants: initialTravellersCount.infants || 0,
  });

  const getTotalPassengerCount = useCallback(() => {
    let totalTravellers = travellersCount.adults + travellersCount.children;

    // LHG do not support infants
    if (isHolidayExtras) {
      totalTravellers += travellersCount.infants;
    }

    return parseTravellersCount(totalTravellers, 1, MAX_LOUNGE_GUESTS);
  }, [isHolidayExtras, travellersCount]);

  const { travellerIncrements } = useTravellersSelector(travellersCount);

  const onTravellersCountChange = useCallback(
    (newTravellerCount: TravellersCount) => {
      const totalTravellerCount = getTotalPassengerCount();
      let newTotalTravellerCount = newTravellerCount.adults + newTravellerCount.children;

      // LHG do not support infants
      if (isHolidayExtras) {
        newTotalTravellerCount += newTravellerCount.infants;
      }

      // obtain new total price
      const pricePerPerson = totalTravellerCount > 1 ? price / totalTravellerCount : price;

      setPrice(pricePerPerson * newTotalTravellerCount);

      setTravellersCount(newTravellerCount);
      setIsPurchasable(true);
      setHasBeenUpdated(true);
    },
    [isHolidayExtras, price, getTotalPassengerCount, setTravellersCount],
  );

  const travellersFields = travellerIncrements?.map((traveller) => {
    return {
      ...traveller,
      value: travellersCount[traveller.id as keyof TravellersCount] || 0,
      onChange: (value: number) => {
        const newTravellerCount = {
          ...travellersCount,
          [traveller.id]: value,
        };
        onTravellersCountChange(newTravellerCount);
      },
    };
  });

  const onDateInputChange = useCallback(
    (newDate: string | null) => {
      if (newDate && newDate !== entryDate) {
        setEntryDate(newDate);
        setIsPurchasable(true);
        setHasBeenUpdated(true);
      }
    },
    [entryDate],
  );

  useEffect(() => {
    if (currentLounge && !loadedLounge) {
      setEntryDate(convertedLocalDate);
      setPrice(productPrice);
      setTravellersCount({
        adults: initialTravellersCount.adults || 1,
        children: initialTravellersCount.children || 0,
        infants: initialTravellersCount.infants || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLounge, loadedLounge]);

  useEffect(() => {
    if (location && currentLounge && !loadedLounge) {
      const travellerInputPageQueryParams = getLoungeTravellerInputPageQueryParams(location);

      if (travellerInputPageQueryParams?.singleProduct) {
        setShowBacklinkNavigator(false);
        setSingleProduct(travellerInputPageQueryParams?.singleProduct);
      }
    }
  }, [location, currentLounge, onTravellersCountChange, loadedLounge, optionId]);

  const openingTimesOptions = useOpeningTimesOptions({
    openingHours: openingHours,
    entryDate: entryDate,
    setIsTimeUnavailable: setIsTimeUnavailable,
  });

  const loungeProductSummaryProps = useMemo(() => {
    if (!currentLounge || !entryDate || !travellersCount || !price) {
      return null;
    }

    const isFcle = sessionStorage.getItem('fcle') === 'true';
    const totalPassengerCount = getTotalPassengerCount();

    let canContinue: boolean;

    // time is not validated on the server side, so we need to check if the time is available
    if (isTimeUnavailable) {
      canContinue = false;
    } else {
      // if the lounge fields have been updated, we can continue and validate via a fetch request
      canContinue = Boolean(hasBeenUpdated || (!isLocked && isPurchasable && isBookable));
    }

    let submitBtnLabel;
    if (canContinue) {
      submitBtnLabel = 'Confirm';
    } else if (isTimeUnavailable) {
      submitBtnLabel = 'lounge-not-available-time';
    } else if (isLocked) {
      submitBtnLabel = 'lounge-not-bookable';
    } else {
      submitBtnLabel = 'Lounges.unavailable.date';
    }

    const _props: LoungeInteractiveProductSummaryProps = {
      price,
      currency,
      name,
      entryDate,
      displayTimezone: airportCoordinates
        ? tzlookup(airportCoordinates.latitude, airportCoordinates.longitude)
        : '',
      dateInputMinDate: dayjs().format(UiUtilities.DateFormat.SHORT_DATE),
      location: productLocation,
      maxTravellersCount: isFcle ? totalPassengerCount : MAX_LOUNGE_GUESTS,
      travellersCount,
      travellersFields,
      showBacklinkNavigator,
      openingTimesOptions,
      openingHours,
      onDateInputChange,
      isEstimatedPriceActive: !isHolidayExtras,
      isEditingDisabled: isLocked,
      isSubmitButtonDisabled: !canContinue,
      isEditable,
      airportCoordinates,
      submitBtnLabel: t(submitBtnLabel),
      onSubmit: async () => {
        yiluTracking.sendGAEvent({
          event: 'pdp_customization_clicked',
          category: 'lounge_pdp',
          label: 'lounge_pdp_customization_clicked',
        });

        const handleSearchResultUpdate = async (updateFunc: () => Promise<any>) => {
          try {
            const body = await updateFunc();
            return body ? body : null;
          } catch (error) {
            console.error('Error fetching refined search results', error);
            setIsPurchasable(false);
            return null;
          }
        };

        let newSearchResultId;
        let isError = false;

        if (hasBeenUpdated) {
          let newSearchResult;
          if (searchResultId) {
            newSearchResult = await handleSearchResultUpdate(() =>
              updateSearchResult(
                searchResultId,
                travellersCount,
                entryDate || '',
                airportCoordinates,
              ),
            );
            newSearchResultId = newSearchResult?.id;
          }

          isError = newSearchResultId === null || newSearchResultId === undefined;

          if (!isError && newSearchResult?.item && !isHolidayExtras) {
            isError = !isLoungeBookable(newSearchResult);
          }

          if (isError) {
            setIsPurchasable(false);
            setHasBeenUpdated(false);
          }
        } else {
          newSearchResultId = currentLounge.id;
        }

        const catalogId = currentLounge.item.catalogId;

        const travellerInformationPageQueryParams = {
          searchResultID: newSearchResultId,
          catalogId,
          singleProduct: singleProduct,
        };

        if (!isError) {
          navigate({
            pathname: routes.LOUNGES_TIP,
            search: paramConverters.getURLSearchQuery(travellerInformationPageQueryParams),
          });
        }
      },
    };
    return _props;
  }, [
    isBookable,
    isLocked,
    currentLounge,
    entryDate,
    travellersCount,
    price,
    isHolidayExtras,
    getTotalPassengerCount,
    currency,
    name,
    productLocation,
    travellersFields,
    showBacklinkNavigator,
    openingHours,
    onDateInputChange,
    isPurchasable,
    t,
    hasBeenUpdated,
    singleProduct,
    searchResultId,
    updateSearchResult,
    navigate,
    isTimeUnavailable,
    openingTimesOptions,
    isEditable,
    airportCoordinates,
  ]);

  useEffect(() => {
    if (currentLounge && !loadedLounge && entryDate && travellersCount && price) {
      setLoadedLounge(true);
    }
  }, [currentLounge, loadedLounge, entryDate, travellersCount, price]);

  return loungeProductSummaryProps;
};

export default useLoungeProductSummaryProps;
