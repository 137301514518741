import { OpeningHours } from '../types';

export function roundUpToNearestMinutes(timeStr: string, minuteInterval = 15): string {
  const [hoursStr, minutesStr] = timeStr.split(':');
  let hours = parseInt(hoursStr);
  let minutes = parseInt(minutesStr);

  const remainder = minutes % minuteInterval;
  if (remainder !== 0) {
    minutes = minutes + (minuteInterval - remainder);
  }

  if (minutes === 60) {
    hours = (hours + 1) % 24; // Handle rollover to next day if hours reach 24
    minutes = 0;
  }

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}

/**
 * This function accepts an array of opening hours as input and returns an array of strings
 * representing the names of the days for which there are no defined intervals in the opening hours.
 *
 * This information can be used to disable corresponding days on a calendar input component.
 * @param openingHours - openingHours array`
 */
export const getDisabledDaysByOpeningHours = (openingHours: OpeningHours[] = []) => {
  return openingHours.reduce((days: string[], day) => {
    if (!day.intervals || day.intervals.length === 0) {
      days.push(day.day);
    }
    return days;
  }, []);
};
