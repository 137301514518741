import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { FC, JSX } from 'react';

import { PaymentMethods } from 'context/PaymentInput/context';

import { ApplePayCard } from './icons/ApplePayCard';
import { CreditCardCards } from './icons/CreditCardCards';
import { GooglePayCard } from './icons/GooglePayCard';
import './styles.scss';

interface PaymentMethodsProps {
  type: PaymentMethods;
  isSelected?: boolean;
  hasRadio?: boolean;
  onSelect: (type: PaymentMethods) => void;
}

export const PaymentMethod: FC<PaymentMethodsProps> = ({
  type,
  isSelected,
  onSelect,
  hasRadio = true,
}) => {
  const PAYMENT_METHODS: Record<PaymentMethods, { name: string; iconsComponent: JSX.Element }> = {
    [PaymentMethods.CREDIT_CARD]: {
      name: 'Credit/Debit Card',
      iconsComponent: CreditCardCards,
    },
    [PaymentMethods.GOOGLE_PAY]: {
      name: 'Google Pay',
      iconsComponent: GooglePayCard,
    },
    [PaymentMethods.APPLE_PAY]: {
      name: 'Apple Pay',
      iconsComponent: ApplePayCard,
    },
  };

  const paymentDetails = PAYMENT_METHODS?.[type];
  if (!paymentDetails) {
    return null;
  }
  const Icons = paymentDetails?.iconsComponent;

  return (
    <div className={clsx('yilu-payment-method', `yilu-payment-method--${type}`)}>
      <ElementsSDK.Typography className="yilu-payment-method__label" variant="h4">
        {hasRadio ? (
          <ElementsSDK.RadioButton
            id={`payment_method_${type}`}
            value={type}
            label={paymentDetails?.name}
            onChange={() => onSelect(type)}
            name={`payment_method`}
            checked={isSelected}
          />
        ) : (
          <div className="yilu-payment-method__label-text">{paymentDetails?.name}</div>
        )}
      </ElementsSDK.Typography>

      {Icons && <div className="yilu-payment-method__icons">{Icons}</div>}
    </div>
  );
};
