import { DateFormat } from '@yiluhub/ui-utilities';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { LoungeSearchInput } from '../../../../components';

export type UseLoungeCollapsibleFormParams = {
  searchInput?: LoungeSearchInput;
  onSubmit(searchInput: Required<LoungeSearchInput>): unknown;
  setIsExpanded(value: boolean): unknown;
};

function useLoungeCollapsibleForm({
  setIsExpanded,
  onSubmit,
  searchInput,
}: UseLoungeCollapsibleFormParams) {
  const { entryDate, adults, children, infants, airport } = searchInput || {};

  useEffect(() => {
    if (!entryDate || !adults || !airport) {
      setIsExpanded(true);
    }
  }, [entryDate, adults, airport, setIsExpanded]);

  const travellersTotal = (adults || 0) + (children || 0) + (infants || 0);

  const [renderedTravellersCount, setRenderedTravellersCount] = useState(
    travellersTotal ? travellersTotal : 1,
  );
  const [renderedDate, setRenderedDate] = useState(
    entryDate ? dayjs(entryDate).format(DateFormat.SHORTENED_TEXT_WITH_DATE) : '-',
  );
  const [renderedIataCode, setRenderedIataCode] = useState(airport ? airport.iataCode : '-');

  const handleOnSubmit = useCallback(
    (data: Required<LoungeSearchInput>) => {
      if (typeof setIsExpanded === 'function') {
        setIsExpanded(false);
      }

      if (typeof onSubmit === 'function') {
        onSubmit(data);
      }

      const { entryDate, adults = 1, children, infants, airport } = data || {};
      const travellersTotal = (adults || 0) + (children || 0) + (infants || 0);
      setRenderedDate(
        entryDate ? dayjs(entryDate).format(DateFormat.SHORTENED_TEXT_WITH_DATE) : '-',
      );
      setRenderedTravellersCount(travellersTotal);
      setRenderedIataCode(airport ? airport.iataCode : '-');
    },
    [setIsExpanded, onSubmit],
  );

  return {
    handleOnSubmit,
    iataCode: renderedIataCode,
    date: renderedDate,
    travellersCount: renderedTravellersCount,
  };
}

export default useLoungeCollapsibleForm;
