import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import ProgressBar from 'components/ProgressBar';
import { TravellerInformationForm } from 'components/TravellerInformationForm';

import { SBBProductSummary } from '../../components/SBBProductSummary';
import { useTravellerInputPage } from './hooks';
import './styles.scss';

const TravellerInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { isPageReady, travellerInformationFormProps, swissTravelProductSummaryProps } =
    useTravellerInputPage();

  return (
    <div className="airport-transfer-sbb-tip">
      {isPageReady && (
        <div className="form-container-wrapper">
          <div className="form-container container">
            <div className="content">
              <ProgressBar />
              <PageHeading text={t('Header - Personal Info')} />
              {travellerInformationFormProps && (
                <TravellerInformationForm {...travellerInformationFormProps} />
              )}
            </div>
            {swissTravelProductSummaryProps && (
              <SummaryLayout>
                <SBBProductSummary {...swissTravelProductSummaryProps} />
              </SummaryLayout>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TravellerInputPage;
