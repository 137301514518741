import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { sendLocaleGaEvent } from 'utils';
import { Languages } from 'utils/i18n';

import './styles.scss';

function updateLocaleInURL(newLocale: string): void {
  // Create a new URL object based on the current URL
  const currentURL = new URL(window.location.href);

  // Update the 'locale' parameter
  currentURL.searchParams.set('locale', newLocale);

  // Update the URL in the browser without reloading the page
  window.location.href = currentURL.toString();
}

const LocaleSelect = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language.toUpperCase();

  const options = Object.values(Languages).map((lang) => {
    return {
      value: lang,
      label: lang.toUpperCase(),
    };
  });

  const defaultOption = {
    value: currentLocale,
    label: currentLocale.toUpperCase(),
  };

  const onSelect = useCallback(
    (code) => {
      if (currentLocale !== code) {
        if (options.findIndex(({ value }) => value === code) !== -1) {
          sendLocaleGaEvent(`${code}-locale`);
          updateLocaleInURL(code);
          dayjs.locale(code);
        }
      }
    },
    [currentLocale, options],
  );

  return (
    <div className="yilu-LocaleSelect">
      <ElementsSDK.InputField.Select
        className="yilu-LocaleSelect--select"
        disabledTyping={true}
        onSelect={onSelect}
        options={options}
        dataTestId="locale-select"
        value={currentLocale}
        showDropdownIcon
        showSelectionCheckmark
        defaultOption={defaultOption}
        onOpen={() => sendLocaleGaEvent('locale-dropdown')}
      />
    </div>
  );
};

export default LocaleSelect;
