/**
 * # Rides Booking Info
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate } from '@yiluhub/ui-utilities';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import './styles.scss';

export type RidesBookingInfoProps = {
  booking: Booking<'COMPLETED', 'RIDE'>;
};

export const RidesBookingInfo: React.FC<RidesBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  const originAddress = bookingData.origin && bookingData.origin.plainAddress;
  const destinationAddress = bookingData.destination && bookingData.destination.plainAddress;
  const travelClass = bookingData.rideDetails.category;
  const originTimezone =
    bookingData.origin && tzlookup(bookingData.origin.latitude, bookingData.origin.longitude);

  const { firstName, lastName, email, phoneNumber, flightNumber } = bookingData.customerDetails;
  const renderedCustomerData = [`${firstName} ${lastName}`, email];
  if (!!phoneNumber) renderedCustomerData.push(phoneNumber);
  if (!!flightNumber) renderedCustomerData.push(flightNumber);

  return (
    <div className="yilu-RidesBookingInfo">
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[
          formatLocalDate(bookingData.departureTime, DateFormat.DATE_WITH_TIME, originTimezone),
        ]}
        subText={t('bcp.dateOfPickUp')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      {originAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[originAddress]}
          subText={t('bcp.departure')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      {destinationAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[destinationAddress]}
          subText={t('bcp.arrival')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      {travelClass && (
        <ElementsSDK.ContentBox
          mainTexts={[travelClass]}
          className="bcp-travel-class"
          subText={t('bcp.travelClass')}
          icon={<ElementsSDK.Icon.Taxi />}
        />
      )}
      {bookingData.rideDetails.notesToDriver &&
        bookingData.rideDetails.notesToDriver !== 'null' && (
          <ElementsSDK.ContentBox
            mainTexts={[bookingData.rideDetails.notesToDriver]}
            subText={t('bcp.messageToDriver')}
            icon={<ElementsSDK.Icon.Note />}
          />
        )}
      <ElementsSDK.ContentBox
        mainTexts={renderedCustomerData}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
    </div>
  );
};
