import { CommonQueryParams } from 'utils/paramConverters';

type SrpSession = CommonQueryParams | Record<string, any>;

const SEARCH_SESSION_KEY = 'yilu_search_session';

export function getSrpSession() {
  return JSON.parse(sessionStorage.getItem(SEARCH_SESSION_KEY) || '{}') as SrpSession;
}

// Set the session for the search results. This is used to store the search parameters for the search results page.
export function setSrpSession(data: SrpSession) {
  return sessionStorage.setItem(SEARCH_SESSION_KEY, JSON.stringify(data));
}
