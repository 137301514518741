import { PRICING_OPTION } from 'modules/lounges/utils/constants';

//NOTE: This is a temporary fix to obscure the pricing param in the URL
export const obscuredPriceParam: Record<string | PRICING_OPTION, string> = {
  pricing: 'xbIuSEiHHl',
  other: 'KVMMQAM9X3',
  lufthansa: 'aiDaSPlPSp',
  premium: 'JcQkjAtPvT',
  none: 'Z2uq1qZKg0',
};
export const obscurePriceParam = (param: string) => {
  return obscuredPriceParam[param] || param;
};
export const unobscurePriceParam = (param: string) => {
  return Object.entries(obscuredPriceParam).find(([, value]) => value === param)?.[0] || param;
};
