/**
 * # Airport Transfer Search Results
 *
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultCard, SearchResultNotFound } from 'components';

import { AirportTransferSearchResultCard } from '../AirportTransferSearchResultCard';
import { useAirportTransferSearchResultsLoader } from './hooks';
import { AirportTransferSearchResultsProps } from './types';

const PLACEHOLDER_COUNT = 9;
const NORESULTS_IMAGE = `https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/
generic-icons/no-results-box.svg`;

export const AirportTransferSearchResults: React.FC<AirportTransferSearchResultsProps> = ({
  searchParams,
  onSearchResults,
  onSearchResultClick,
  onNoSearchResults,
  onError,
}) => {
  const { t } = useTranslation();
  const { isLoading, pageNumber, totalPages, setLastElement, searchResults } =
    useAirportTransferSearchResultsLoader({
      searchParams,
      onSearchResults,
      onError,
    });

  return (
    <>
      {!isLoading && !searchResults.length && (
        <SearchResultNotFound
          onClickCallback={onNoSearchResults}
          imageUrl={NORESULTS_IMAGE}
          title={t('airporttransfer.noResults.title.text')}
          message={t('airporttransfer.noResults.message.text')}
          ctaText={t('airporttransfer.noResults.cta.text')}
        />
      )}
      {isLoading &&
        pageNumber === 0 &&
        Array(PLACEHOLDER_COUNT)
          .fill(0)
          .map((_, index) => <SearchResultCard asPlaceholder key={index} />)}
      <section>
        {searchResults.map((searchResult, index) => {
          const isLastElement =
            index === searchResults.length - 1 && !isLoading && pageNumber < totalPages - 1;
          return (
            <AirportTransferSearchResultCard
              cardRef={isLastElement ? setLastElement : undefined}
              key={searchResult.id}
              data={searchResult}
              onClick={onSearchResultClick}
            />
          );
        })}
        {isLoading && pageNumber !== 0 && <SearchResultCard asPlaceholder />}
      </section>
    </>
  );
};
