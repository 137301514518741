import { JFK_LOUNGE_ID } from 'modules/lounges/utils/constants';

import type { Amenities } from '../types';

export function filterLoungeAmenities(loungeOptionId: string | undefined, amenities: Amenities[]) {
  // alcohol is not available in JFK lounge
  if (loungeOptionId === JFK_LOUNGE_ID) {
    const alcoholIndex = amenities.findIndex((amenity) => amenity.name === 'alcoholicDrinks');

    if (alcoholIndex > -1) {
      amenities.splice(alcoholIndex, 1);
    }
  }
}
