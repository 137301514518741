import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React, { useEffect } from 'react';

import { sendGAEvent } from 'utils/tracking';

import BacklinkNavigator from 'components/Header/BacklinkNavigator';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { ParkingProductSummary } from 'modules/parking/components';

import { ParkingProductDetails } from './ParkingProductDetails';
import { useParkingProductDetailsPage } from './hooks';
import './styles.scss';

const ProductDetailsPage: React.FC = () => {
  const { isPageReady, parkingProductDetailsProps, parkingProductSummaryProps, backLinkProps } =
    useParkingProductDetailsPage();

  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    sendGAEvent({ event: 'page_loaded', category: 'parking-pdp', label: 'parking_pdp_loaded' });
  }, [isPageReady]);

  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');

  return (
    <>
      <BacklinkNavigator backLinkProps={backLinkProps} />
      <div className="parking-pdp">
        {isPageReady ? (
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                {parkingProductSummaryProps && !isDesktop && (
                  <ParkingProductSummary {...parkingProductSummaryProps} showSelection={false} />
                )}
                {parkingProductDetailsProps && (
                  <ParkingProductDetails {...parkingProductDetailsProps} />
                )}
              </div>
              {parkingProductSummaryProps && isDesktop && (
                <ParkingProductSummary {...parkingProductSummaryProps} />
              )}
            </div>
          </div>
        ) : (
          <LoadingPageAnimation />
        )}
      </div>
    </>
  );
};

export default ProductDetailsPage;
