/**
 * # High Pass Widget
 *
 *
 */
import React, { useCallback, useState } from 'react';

import { yiluEnv } from 'utils/index';

import {
  HighPassCategory,
  HighPassWidgetCustomization,
  HighPassWidgetProps,
} from 'modules/highpass/components/HighPassWidget/types';

import './styles.scss';

export const HighPassWidget: React.FC<HighPassWidgetProps> = ({
  apikey,
  lang,
  categories,
  predefinedSearchData,
}) => {
  const [loaded, setLoaded] = useState(false);

  const HIGHPASS_STYLES = yiluEnv.getVariables()?.HIGHPASS_STYLES as HighPassWidgetCustomization;

  const generateScriptUrl = useCallback(() => {
    const scriptUrl = new URL('https://highpass.aero/Widget/Init');
    scriptUrl.searchParams.append('apiKey', apikey);

    const {
      linkColor,
      serviceDescriptionColor,
      backgroundColor,
      importantColor,
      headingColor,
      errorColor,
      buttonColor,
      labelColor,
      defaultTextColor,
      inputColor,
      headerTextColor,
      descriptionTextColor,
    } = HIGHPASS_STYLES || {};

    scriptUrl.searchParams.append('BackgroundColor', backgroundColor || '#ffffff');
    scriptUrl.searchParams.append('ButtonColor', buttonColor || '#FFAD00');
    scriptUrl.searchParams.append('InputColor', inputColor || '#F8F9FD');
    scriptUrl.searchParams.append('LinkColor', linkColor || '#ac1804');
    scriptUrl.searchParams.append('HeadingColor', headingColor || '#05164D');
    scriptUrl.searchParams.append('DefaultTextColor', defaultTextColor || '#05164D');
    scriptUrl.searchParams.append('ErrorColor', errorColor || '#FF0000');
    scriptUrl.searchParams.append('ServiceDescriptionColor', serviceDescriptionColor || '#05164D');
    scriptUrl.searchParams.append('LabelColor', labelColor || '#333333');
    scriptUrl.searchParams.append('ImportantColor', importantColor || '#05164D');
    scriptUrl.searchParams.append('HeaderTextColor', headerTextColor || '#05164D');
    scriptUrl.searchParams.append('DescriptionTextColor', descriptionTextColor || '#05164D');

    const categoriesParam = categories || [
      HighPassCategory.FastTrack,
      HighPassCategory.VipService,
      HighPassCategory.MeetAndGreet,
    ];

    scriptUrl.searchParams.append('Categories', categoriesParam.join(','));
    scriptUrl.searchParams.append('lang', lang || 'en');

    if (predefinedSearchData) {
      const encodedData = encodeURIComponent(JSON.stringify(predefinedSearchData));
      scriptUrl.searchParams.append('predefinedSearch', encodedData);
    }

    return scriptUrl.toString();
  }, [HIGHPASS_STYLES, apikey, categories, lang, predefinedSearchData]);

  React.useEffect(() => {
    if (loaded) return;

    const script = document.createElement('script');
    script.src = generateScriptUrl();
    script.type = 'text/javascript';

    const scriptId = 'highpass-web-widget';
    document.getElementById(scriptId)?.appendChild(script);
    setLoaded(true);

    return () => {
      document.getElementById(scriptId)?.removeChild(script);
    };
  }, [loaded, generateScriptUrl]);

  return <div id="highpass-web-widget"></div>;
};
