import React from 'react';
import { YiluOverrides } from 'types';

import { ComponentOverridesContext } from './context';

type ComponentOverridesProviderProps = {
  overrides: Partial<YiluOverrides>;
};

export const ComponentOverridesProvider: React.FC<ComponentOverridesProviderProps> = ({
  children,
  overrides,
}) => (
  <ComponentOverridesContext.Provider value={overrides}>
    {children}
  </ComponentOverridesContext.Provider>
);
