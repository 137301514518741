import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import tzlookup from 'tz-lookup';

import { sendGAEvent } from 'utils/tracking';

import { AirportTransferProductSummaryProps } from 'modules/airport-transfer/components';
import { getAirportTransferProductData } from 'modules/airport-transfer/utils/getAmpAirportTransferData';

const useProductSummaryProps = (
  currentProduct: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  isReservationUpdateLoading: boolean,
) => {
  const productSummaryProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const {
      travellersCount,
      arrivalDateTime,
      departureDateTime,
      currency,
      price,
      origin,
      destination,
      carrierName,
      interconnectionTransfersCount,
    } = getAirportTransferProductData(currentProduct);

    const props_: AirportTransferProductSummaryProps = {
      originName: origin.name!,
      destinationName: destination.name!,
      departureDateTime: departureDateTime,
      arrivalDateTime: arrivalDateTime,
      displayingTimezone: tzlookup(destination.lat as number, destination.long as number),
      price: price,
      currency: currency,
      onClick() {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'tip_cta_clicked',
            category: 'airport-transfer-tip',
            label: 'airport_transfer_tip_cta_clicked',
          });
        }
      },
      isLoading: isReservationUpdateLoading,
      travellersCount: travellersCount,
      carrierName: carrierName,
      interconnectionTransfersCount: interconnectionTransfersCount || 0,
    };

    return props_;
  }, [currentProduct, reservationFormRef, isReservationUpdateLoading]);

  return productSummaryProps;
};

export default useProductSummaryProps;
