import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const Privacy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ElementsSDK.Typography
      className="yilu-PrivacyRules"
      variant="p2"
      dangerouslySetInnerHTML={{ __html: t('data.privacy.rules') }}
    />
  );
};

export default Privacy;
