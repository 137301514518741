import { useContext } from 'react';

import { LegalModalContext } from './context';

export const useLegalModalContext = () => {
  const context = useContext(LegalModalContext);

  if (context === undefined) {
    throw new Error('useLegalModalContext() must be used within LegalModalContext.Provider');
  }

  return context;
};

export { LegalModalContextProvider } from './Provider';
