export const convertToBase64 = (data: any) => {
  const jsonString = JSON.stringify(data);
  const utf8Bytes = new TextEncoder().encode(jsonString);
  const asciiString = String.fromCharCode(...(utf8Bytes as any));

  return btoa(asciiString);
};

export const decodeBase64 = (base64String: string) => {
  const asciiString = atob(base64String);
  const utf8Bytes = Uint8Array.from(asciiString, (char) => char.charCodeAt(0));
  const jsonString = new TextDecoder().decode(utf8Bytes);
  return JSON.parse(jsonString);
};
