import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BacklinkNavigator from 'components/Header/BacklinkNavigator';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { LoungeSearchForm } from '../../components';
import { LoungeCollapsibleForm } from './LoungeCollapsibleForm';
import { LoungeSearchResults } from './LoungeSearchResults';
import { useLoungeSearchResultsPage } from './hooks';
import './styles.scss';

const SearchResultsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    loungeSearchFormProps,
    loungeSearchResultsProps,
    airportName,
    isPageReady,
    backLinkProps,
    isLoading,
  } = useLoungeSearchResultsPage();

  return (
    <>
      <BacklinkNavigator backLinkProps={backLinkProps} />
      <div className="lounges-srp">
        {isPageReady ? (
          <>
            <div className="Lounge-Search-Results__Form--mobile">
              <LoungeCollapsibleForm {...loungeSearchFormProps} />
            </div>
            <div className="Lounge-Search-Results__Form--desktop">
              <div className="Lounge-Search-Results__Form">
                <LoungeSearchForm {...loungeSearchFormProps} />
              </div>
            </div>
            {loungeSearchResultsProps && (
              <div className="Lounge-Search-Results__Section">
                {!isLoading && (
                  <ElementsSDK.Typography
                    testid="loungeSearchResults__title"
                    variant="h3"
                    className="Lounge-Search-Results__Title"
                  >
                    {t('lounge.srp.title.plural', {
                      airportName,
                    })}
                  </ElementsSDK.Typography>
                )}
                <div className="Lounge-Search-Results__Results">
                  <LoungeSearchResults {...loungeSearchResultsProps} />
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingPageAnimation />
        )}
      </div>
    </>
  );
};

export default SearchResultsPage;
