import { getYiluCaches, setYiluCaches } from '@yiluhub/ui-utilities';
import {
  Airport,
  GetLocationsByStoreIdRequest,
  GetLocationsByStoreIdResponse,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { airportUtils, yiluEnv } from 'utils';

import { LOUNGES_POPULAR_AIRPORTS_PRIORITIZED } from '../utils/constants';

const POPULAR_AIRPORTS_NUMBER = 3;

export const useLoungeAirports = () => {
  const LOUNGE_AIRPORTS_ARRAY_CACHE_KEY = 'loungeAirports';
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_LOUNGE_VERTICAL_ID } =
    yiluEnv.getVariables();
  const [network, setNetwork] = useState({ isFetching: false, isFetched: false });

  const [allAirports, setLoungeAirports] = useState(
    getYiluCaches(LOUNGE_AIRPORTS_ARRAY_CACHE_KEY) as unknown as Array<Airport>,
  );

  const [popularAirportIATAs, setPopularAirportIATAs] = useState<string[] | undefined>(undefined);

  const fetchAllLoungeAirports = useCallback(async () => {
    try {
      setNetwork({ isFetching: true, isFetched: false });
      const response = await axios.get<GetLocationsByStoreIdResponse>(
        `${YILU_AMP_BACKEND_URL}/store/v1/stores/${YILU_AMP_STORE_ID}/locations`,
        {
          params: {
            verticalId: YILU_AMP_LOUNGE_VERTICAL_ID,
          } as GetLocationsByStoreIdRequest['parameters']['query'],
        },
      );
      const airportList = response.data?.map((element) => element.airport);
      setLoungeAirports(airportList);
      setYiluCaches(LOUNGE_AIRPORTS_ARRAY_CACHE_KEY, airportList);
      setPopularAirportIATAs(
        airportUtils.prioritizeAirports(
          airportList,
          LOUNGES_POPULAR_AIRPORTS_PRIORITIZED,
          POPULAR_AIRPORTS_NUMBER,
        ),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setNetwork({ isFetching: false, isFetched: true });
    }
  }, [YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_LOUNGE_VERTICAL_ID]);

  useEffect(() => {
    if (!network.isFetched && !network.isFetching) {
      void fetchAllLoungeAirports();
    }
  }, [network, allAirports, popularAirportIATAs, fetchAllLoungeAirports]);

  return { allAirports, popularAirportIATAs };
};
