import { yiluTracking } from 'utils/index';

import { PaymentMethods } from 'context/PaymentInput/context';

type useTrackingProps = {
  paymentMethod: PaymentMethods;
  productTrackingId: string;
};

const PAYMENT_EVENT_LOAD: Record<PaymentMethods | string, string> = {
  [PaymentMethods.APPLE_PAY]: 'Apay-load',
  [PaymentMethods.GOOGLE_PAY]: 'Gpay-load',
};

export const onLoadTracking = ({ productTrackingId, paymentMethod }: useTrackingProps) => {
  if (paymentMethod === PaymentMethods.GOOGLE_PAY || paymentMethod === PaymentMethods.APPLE_PAY) {
    yiluTracking.sendGAEvent({
      event: PAYMENT_EVENT_LOAD[paymentMethod],
      category: `${productTrackingId}-pip`,
      label: `${productTrackingId}-pip-wallet-load`,
    });
  }
};
export const onPaymentMethodClickTracking = (
  paymentMethod: PaymentMethods,
  productTrackingId: string,
) => {
  switch (paymentMethod) {
    case PaymentMethods.CREDIT_CARD:
      yiluTracking.sendGAEvent({
        event: 'card-click',
        category: `${productTrackingId}-pip`,
        label: `${productTrackingId}-pip-card-click`,
      });
      break;
    case PaymentMethods.GOOGLE_PAY:
      yiluTracking.sendGAEvent({
        event: 'Gpay-click',
        category: `${productTrackingId}-pip`,
        label: `${productTrackingId}-pip-wallet-click`,
      });
      break;
    case PaymentMethods.APPLE_PAY:
      yiluTracking.sendGAEvent({
        event: 'Apay-click',
        category: `${productTrackingId}-pip`,
        label: `${productTrackingId}-pip-wallet-click`,
      });
      break;
  }
};
