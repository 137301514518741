import { DateFormat } from '@yiluhub/ui-utilities';
import dayjs from 'dayjs';

export const concatDateAndTime = (date: string, time: string): string =>
  dayjs([date, time].join('T'), { utc: true }).toISOString();

export const formatParkingDateTime = (date: string): string => {
  return dayjs(date).format(DateFormat.SHORT_DATE_WITH_FULL_TIME_Z);
};

export const formatLocalDate = (date: string, format: DateFormat, timezone?: string): string => {
  return dayjs(date).tz(timezone).format(format);
};

type RoundedDownMinutes = 15 | 30 | 45 | 60;
export const roundDateTimeDown = (inputDate: string, minutes: RoundedDownMinutes) => {
  if (!dayjs(inputDate).isValid()) {
    return null;
  }

  const parsedDate = dayjs(inputDate, { format: 'YYYY-MM-DDTHH:mm' });
  const roundedDownMinutes = Math.floor(parsedDate.minute() / minutes) * minutes;
  return parsedDate.set('minute', roundedDownMinutes).format('YYYY-MM-DDTHH:mm');
};

export const roundUpToNext15MinutesInLocalTime = (dateTimeStr: string): string => {
  try {
    const FIFTEEN_MINUTES = 15;

    const hasZ = dateTimeStr.endsWith('Z');
    const timeWithoutZ = hasZ ? dateTimeStr.slice(0, -1) : dateTimeStr;

    const [datePart, timePart] = timeWithoutZ.split('T');
    if (!datePart || !timePart) throw new Error('Invalid time format');

    const [hours, minutes] = timePart.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) throw new Error('Invalid time values');

    const totalMinutes = hours * 60 + minutes;
    const roundedMinutes = Math.ceil(totalMinutes / FIFTEEN_MINUTES) * FIFTEEN_MINUTES;
    const roundedHours = Math.floor(roundedMinutes / 60);
    const roundedMinutesInHour = roundedMinutes % 60;

    const roundedTime = `${datePart}T${roundedHours
      .toString()
      .padStart(2, '0')}:${roundedMinutesInHour.toString().padStart(2, '0')}`;

    return hasZ ? `${roundedTime}Z` : roundedTime;
  } catch (error) {
    console.error(error);
    return dateTimeStr;
  }
};

export const roundUpToNext15MinutesInZulu = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const minutes = date.minute();

  if (minutes % 15 === 0) {
    return date.second(0).millisecond(0);
  }

  const remainder = 15 - (minutes % 15);
  return date.add(remainder, 'minute').second(0).millisecond(0);
};
