/**
 * # Rdes Search Result Page
 *
 *
 */
import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';

import { RideInteractiveProductSummary, RidesSearchForm } from 'modules/rides/components';

import { RidesSearchResults } from './RidesSearchResults';
import { useSearchResultsPage } from './hooks';
import './styles.scss';

export const SearchResultPage: React.FC = () => {
  const { ridesSearchFormProps, ridesSearchResultsProps, rideProductInteractiveSummaryProps } =
    useSearchResultsPage();
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');
  return (
    <div className="rides-srp">
      {!isDesktop && rideProductInteractiveSummaryProps && (
        <div className="Rides-Search-Form--mobile">
          <RideInteractiveProductSummary {...rideProductInteractiveSummaryProps} />
        </div>
      )}
      <div
        className="Rides-Search-Results__Form--desktop"
        aria-label="Book your ride"
        role="heading"
        aria-level={1}
      >
        <div className="Rides-Search-Results__Form">
          <RidesSearchForm {...ridesSearchFormProps} />
        </div>
      </div>
      {ridesSearchResultsProps && (
        <div className="Rides-Search-Results__Section">
          <div className="Rides-Search-Results__Results">
            <RidesSearchResults {...ridesSearchResultsProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResultPage;
