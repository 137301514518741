import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import {
  Booking,
  BookingProductType,
  GetBookingByBookingIdResponse,
} from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStateHeader } from './components';
import { useGetBooking } from './hooks';

export type BookingConfirmationProps = {
  bookingId: string;
  onError?: (error: Error) => unknown;
  onResponse?: (booking: GetBookingByBookingIdResponse) => unknown;
  children?: (booking: Booking<'COMPLETED', BookingProductType>) => React.ReactNode;
  headerStateText?: {
    completed: { subtitle: string };
  };
};

export const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  bookingId,
  onError,
  onResponse,
  children,
  headerStateText,
}) => {
  const { t } = useTranslation();

  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
  });

  return (
    <>
      <BookingStateHeader
        bookingState={booking ? booking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.lounges.headerTitle'),
            subtitle: headerStateText?.completed.subtitle || t('bcp.headerSubtitle'),
          },
        }}
      />
      <div className={'yilu-BookingConfirmation--Container'}>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-BookingConfirmation-formLoadingIndicator__loader" />
        ) : typeof children === 'function' ? (
          booking && children(booking as Booking<'COMPLETED', BookingProductType>)
        ) : null}
      </div>
    </>
  );
};
