/**
 * # Use Redirect On Lounge Not Found
 *
 *
 */
import { useEffect } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { useCurrentProductContext } from 'context';

import { getLoungeSearchInputPageQueryParams } from '../utils/query-params';

export function redirectToLoungeSip(
  navigate: ReturnType<typeof useNavigate>,
  location: Location = window.location as any,
) {
  navigate(
    {
      pathname: routes.LOUNGES_SEARCH,
      search: getURLSearchQuery(getLoungeSearchInputPageQueryParams(location)),
    },
    { replace: true },
  );
}

/**
 * useRedirectOnLoungeSearchResultNotFound
 *
 * @param searchResultID - search result identifier
 */
export function useRedirectOnLoungeSearchResultNotFound(searchResultID?: string) {
  const location = useLocation();
  const navigate = useNavigate();
  const { error: currentProductError } = useCurrentProductContext();

  useEffect(() => {
    if (currentProductError && searchResultID) {
      redirectToLoungeSip(navigate, location);
    }
  }, [currentProductError, searchResultID, navigate, location]);
}
