import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import routes from 'router/routes';

import { ParkingSearchFormProps } from '../../../components';
import { ParkingSearchResultsPageQueryParams } from '../../../utils/query-params';

const useParkingSearchFormProps = (
  parkingSearchResultsPageQueryParams: ParkingSearchResultsPageQueryParams,
  parkingAirports: ElementsSDK.FilteredAirportDetails[],
  popularAirportsIATAs?: string[],
) => {
  const navigate = useNavigate();
  const parkingSearchFormProps = useMemo(() => {
    const _props: ParkingSearchFormProps = {
      searchInput: {
        entryDate: parkingSearchResultsPageQueryParams.entryDate,
        entryTime: parkingSearchResultsPageQueryParams.entryTime,
        exitDate: parkingSearchResultsPageQueryParams.exitDate,
        exitTime: parkingSearchResultsPageQueryParams.exitTime,
        airportDetails: parkingSearchResultsPageQueryParams.airportDetails,
      },
      parkingAirports,
      popularAirportsIATAs,
      flightTime: parkingSearchResultsPageQueryParams.flightTime,
      onSubmit(searchInput) {
        const nextParkingSearchResultsPageQueryParams = {
          ...parkingSearchResultsPageQueryParams,
          ...searchInput,
        };

        if (!searchInput.airportDetails) {
          // Ensure to not carry airport details from a previous search
          delete nextParkingSearchResultsPageQueryParams.airportDetails;
        }

        navigate({
          pathname: routes.PARKING_SEARCH_RESULTS,
          search: paramConverters.getURLSearchQuery(nextParkingSearchResultsPageQueryParams),
        });

        yiluTracking.sendGAEvent({
          event: 'srp_cta_clicked',
          category: 'parking-srp',
          label: 'parking_srp_cta_clicked',
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [parkingSearchResultsPageQueryParams, navigate, parkingAirports, popularAirportsIATAs]);

  return parkingSearchFormProps;
};

export default useParkingSearchFormProps;
