import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import routes from 'router/routes';

import { constructFlightNumberSessionStorage } from 'modules/lounges/utils/constructFlightNumberSessionStorage';
import { constructLoungeBacklinkProps } from 'modules/lounges/utils/constructLoungeBackLinkProps';

import { useLoungeAirports } from '../../../hooks';
import { getLoungeSearchResultsPageQueryParams } from '../../../utils/query-params';
import useLoungeSearchFormProps from './useLoungeSearchFormProps';
import { useLoungeSearchResultsProps } from './useLoungeSearchResultsProps';
import useSetAirportDetails from './useSetAirportDetails';

/**
 * Retrieve data for the lounge search results page.
 */
export const useLoungeSearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedLocale = UiUtilities.getYiluConfig().locale || 'en';
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    constructFlightNumberSessionStorage(location);
  }, [location]);

  // Parse the query parameters of the page
  const loungeSearchResultsPageQueryParams = useMemo(() => {
    return getLoungeSearchResultsPageQueryParams(location);
  }, [location]);

  // Check if the page is ready to be rendered
  const isPageReady =
    Boolean(loungeSearchResultsPageQueryParams.airportDetails) ||
    !loungeSearchResultsPageQueryParams.airportIataCode;

  const loungeAirports = useLoungeAirports();

  useSetAirportDetails(isPageReady, loungeSearchResultsPageQueryParams, loungeAirports.allAirports);

  const loungeSearchFormProps = useLoungeSearchFormProps(
    loungeSearchResultsPageQueryParams,
    loungeAirports.allAirports,
    loungeAirports.popularAirportIATAs,
  );

  const loungeSearchResultsProps = useLoungeSearchResultsProps(
    loungeSearchResultsPageQueryParams,
    setIsLoading,
  );

  const { iataCode } = loungeSearchResultsPageQueryParams.airportDetails || {};
  const localizationsKey = selectedLocale as keyof {
    de: ElementsSDK.AirportLocalisedDetails;
    en: ElementsSDK.AirportLocalisedDetails;
  };

  const { entryDate, adults, children, infants } = loungeSearchResultsPageQueryParams;

  const backLinkProps = constructLoungeBacklinkProps(
    t('lounges.srp.backlink.label'),
    routes.LOUNGES_SEARCH,
    navigate,
    entryDate,
    iataCode,
    adults,
    children,
    infants,
  );

  const airportName =
    !!loungeAirports?.allAirports?.length &&
    loungeAirports.allAirports?.filter((airport) => airport.iataCode === iataCode)?.[0]
      ?.localizations[localizationsKey].name;

  return {
    loungeSearchFormProps,
    loungeSearchResultsProps,
    airportName,
    iataCode,
    isPageReady,
    backLinkProps,
    isLoading,
  };
};
