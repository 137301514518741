/**
 * # SearchResultNotFound
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type SearchResultNotFoundProps = {
  title?: string;
  message?: string;
  imageUrl?: string;
  ctaText?: string;
  onClickCallback?: () => unknown;
};

export const SearchResultNotFound: React.FC<SearchResultNotFoundProps> = ({
  title,
  message,
  imageUrl,
  ctaText,
  onClickCallback,
}) => {
  const { t } = useTranslation();

  return (
    <section className="yilu-SearchResultNotFound">
      {imageUrl && <img src={imageUrl} alt={title} />}
      <div className="yilu-SearchResultNotFound-text">
        <ElementsSDK.Typography variant="h4">
          {title || t('No results found')}
        </ElementsSDK.Typography>
        <ElementsSDK.Typography variant="p2">
          {message || t('No results found message')}
        </ElementsSDK.Typography>
      </div>
      {ctaText && (
        <ElementsSDK.Button outline={true} inverted={false} onClick={onClickCallback}>
          {ctaText || t('noResultsWithLink.cta.text')}
        </ElementsSDK.Button>
      )}
    </section>
  );
};
