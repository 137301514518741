import { createContext } from 'react';

export type UserProfileContextType = {
  userProfileState: {
    data: {
      consent: {
        necessary: boolean;
        statistics: boolean;
        personalization: boolean;
        comfort: boolean;
      };
      userId: string;
      storeId: string;
    };
    isUserProfileFetched: boolean;
    isUserProfileUpserted: boolean;
    userProfileUpsertedError: Error | null;
  };
  initialiseUserProfile: (userId: string) => void;
  upsertUserProfile: (userId?: string) => void;
};

export const UserProfileContext = createContext<UserProfileContextType | undefined>(undefined);
