export const enum AMP_VERTICALS {
  LOUNGES = 'LOUNGES',
  PARKING = 'PARKING',
  AIRPORT_TRANSFERS = 'AIRPORT_TRANSFERS',
  AIRPORT_TRANSFER_PASSES = 'AIRPORT_TRANSFER_PASSES',
  RIDES = 'RIDES',
  CAR_RENTAL = 'CAR_RENTAL',
}

export const enum LegalContentType {
  TRACKING = 'tracking',
  PRIVACY = 'privacy',
  TERMS = 'terms',
  IMPRINT = 'imprint',
}

export const enum TransferDirection {
  TO_AIRPORT = 'TO_AIRPORT',
  FROM_AIRPORT = 'FROM_AIRPORT',
}

export const enum YILU_FEATURE {
  SHOW_TRACKING_CONSENT = 'SHOW_TRACKING_CONSENT',
  SHOW_NAVIGATION_BAR = 'SHOW_NAVIGATION_BAR',
  SHOW_HIGHPASS_FAST_TRACK = 'SHOW_HIGHPASS_FAST_TRACK',
}

export const SupportedFeatureFlags = [
  YILU_FEATURE.SHOW_TRACKING_CONSENT,
  YILU_FEATURE.SHOW_NAVIGATION_BAR,
  YILU_FEATURE.SHOW_HIGHPASS_FAST_TRACK,
];

// To ensure unique override keys we define a list of available keys here
export const enum YILU_OVERRIDE_KEY {
  YILU_HEADER_LOGO = 'yiluHeaderLogo',
}
