import { useEffect, useState } from 'react';

/**
 * Load Stripe.js to the document body in an async way.
 * Source: https://github.com/stripe/react-stripe-elements/blob/master/demo/async/async.js#L99
 * @param onLoad function, it will be called when the stripe script is loaded
 */
function injectStripe(onLoad?: () => void) {
  // @ts-expect-error stripe is async loaded
  if (window.Stripe) {
    if (onLoad) {
      onLoad();
    }
    return;
  }

  const stripeJs = document.createElement('script');
  stripeJs.src = 'https://js.stripe.com/v3/';
  stripeJs.async = true;
  if (onLoad) {
    stripeJs.onload = onLoad;
  }
  document.body && document.body.appendChild(stripeJs);
}

function useStripe(stripeKey: string | null) {
  const [stripe, setStripe] = useState<stripe.Stripe | null>(null);

  useEffect(() => {
    // If we already have stripe loaded, or stripeKey is null, we don't need to do anything
    if (stripe || stripeKey === null) {
      return;
    }

    // Check if Stripe already exists in the window (probably from a previous mount)
    if (window.Stripe) {
      setStripe(window.Stripe(stripeKey));
      return;
    }

    // Once the Stripe.js is loaded, we set it to our internal state
    const onLoad = () => {
      setStripe(window.Stripe(stripeKey));
    };

    // Inject the Stripe.js
    injectStripe(onLoad);
  }, [stripe, stripeKey]);

  return stripe;
}

export default useStripe;
