import { yiluEnv } from 'utils/index';

import { HolidayExtraServiceProvider } from 'modules/lounges/utils/constants';

const DEFAULT_FAQ_TYPE_FORM_EN = 'PYMFNZzF';
const DEFAULT_FAQ_TYPE_FORM_DE = 'HJmEbrU2';

export function getFaqLink(
  currentLanguage: string,
  serviceProvider?: string,
  iata?: string,
  loungeName?: string,
  terminal?: string,
  bookingId?: string,
) {
  if (serviceProvider === HolidayExtraServiceProvider) {
    return undefined;
  }

  const yiluEnvVariables = yiluEnv.getVariables();

  let typeFormId: string | undefined;
  if (currentLanguage === 'en') {
    typeFormId = yiluEnvVariables?.FAQ_TYPE_FORM_EN || DEFAULT_FAQ_TYPE_FORM_EN;
  } else if (currentLanguage === 'de') {
    typeFormId = yiluEnvVariables?.FAQ_TYPE_FORM_DE || DEFAULT_FAQ_TYPE_FORM_DE;
  }

  if (!typeFormId) return undefined;

  const url = new URL(`https://form.typeform.com/to/${typeFormId}`);

  url.searchParams.set('typeform-medium', 'bcp');

  url.hash = `product_id=${loungeName}-${iata}-${terminal}&user_id=${bookingId}`;

  return url.toString();
}
