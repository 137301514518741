import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookingContactForm,
  BookingProviderInfo,
  BookingTerms,
} from 'components/BookingConfirmation/components';

import { RidesBookingInfo } from './RidesBookingInfo';

type RideBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'RIDE'>;
};

export const RideBookingConfirmation: React.FC<RideBookingConfirmationProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  return (
    <>
      {bookingData && (
        <>
          <BookingProviderInfo
            logoUrl={bookingData.rideDetails.logoUrl}
            name={booking.serviceProvider}
          />
          <ElementsSDK.Divider />
          <RidesBookingInfo booking={booking} />
          <BookingTerms termsUrl={bookingData.termsUrl} />
          <BookingContactForm
            contactFormText={t('rides.bcp.contact_form.text')}
            contactFormLink={t(`rides.bcp.contact_form.link`)}
            contactFormLinkTarget="_blank"
            contactFormLinkText={t('rides.bcp.contact_form_link.text')}
          />
        </>
      )}
    </>
  );
};
