import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, useObserverPagination } from '@yiluhub/ui-utilities';
import { GetProductsRequest, GetProductsResponse, SearchItem } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import tzlookup from 'tz-lookup';

import { yiluEnv } from 'utils';
import { roundUpToNext15MinutesInZulu } from 'utils/dateUtils';
import { getCurrentLanguage } from 'utils/i18n';

const DEFAULT_PAGE_SIZE = 10;

export type UseLoungeSearchResultsLoaderParams = {
  searchParams: {
    entryDate: string;
    adults: number;
    children?: number;
    infants?: number;
    iata: string;
    fcle?: boolean;
    coordinates?: ElementsSDK.Coordinates;
  };
  onSearchResults?: (results: SearchItem[]) => unknown;
  onError?: (error: Error) => unknown;
  onLoading?: () => unknown;
  pageSize?: number;
};

export function useLoungeSearchResultsLoader({
  searchParams,
  onSearchResults,
  onError,
  onLoading,
  pageSize = DEFAULT_PAGE_SIZE,
}: UseLoungeSearchResultsLoaderParams) {
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchID, setSearchID] = useState<string>();
  const [refreshPagination, setRefreshPagination] = useState(false);
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_LOUNGE_VERTICAL_ID } =
    yiluEnv.getVariables();

  const { fcle, coordinates, entryDate, iata, adults, children, infants } = searchParams;
  const latitude = coordinates ? coordinates.latitude : undefined;
  const longitude = coordinates ? coordinates.longitude : undefined;

  const timezone = latitude && longitude ? tzlookup(latitude, longitude) : undefined;

  const fetchLounges = useCallback(
    async (pageNumber: number) => {
      try {
        const request: GetProductsRequest['query'] = {
          storeId: YILU_AMP_STORE_ID,
          verticalId: YILU_AMP_LOUNGE_VERTICAL_ID,
          airport: iata,
          pageSize,
          pageNumber,
          searchID,
          adults,
          children,
          infants,
          startAt:
            entryDate && timezone
              ? roundUpToNext15MinutesInZulu(dayjs(entryDate).tz(timezone, true).utc()).format(
                  DateFormat.SHORT_DATE_WITH_FULL_TIME_Z,
                )
              : undefined,
          locale: getCurrentLanguage(),
          ficeOnly: fcle,
        };
        const response = await axios.get<GetProductsResponse>(
          `${YILU_AMP_BACKEND_URL}/product/v1/products`,
          {
            params: request,
          },
        );
        const body = response.data;
        const newSearchResults = body.items;
        const responsePage = body.page;

        const totalPages = Math.ceil(responsePage.total / responsePage.pageSize);
        setTotalPages(totalPages);
        setSearchID(body.searchID);
        if (onSearchResults) {
          onSearchResults(newSearchResults);
        }
        const combinedSearchResults = [...searchResults, ...newSearchResults];
        setSearchResults(combinedSearchResults);
      } catch (error) {
        console.error('Error loading lounge search results', error);
      } finally {
        setIsLoading(false);
        setRefreshPagination(false);
      }
    },
    [
      fcle,
      searchResults,
      onSearchResults,
      YILU_AMP_BACKEND_URL,
      YILU_AMP_STORE_ID,
      YILU_AMP_LOUNGE_VERTICAL_ID,
      iata,
      entryDate,
      pageSize,
      searchID,
      adults,
      children,
      infants,
      timezone,
    ],
  );

  const loadResults = async (pageNumber: number) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    onLoading && onLoading();

    try {
      void (await fetchLounges(pageNumber));
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error as Error);
      }
      console.error('Error loading lounge search results', error);
    } finally {
      setIsLoading(false);
      setRefreshPagination(false);
    }
  };

  const { setLastElement, pageNumber } = useObserverPagination({
    loadResults,
    totalPages,
    refreshPagination,
  });

  useEffect(() => {
    setSearchResults([]);
    setRefreshPagination(true);
    setSearchID(undefined);
  }, [searchParams]);

  return {
    isLoading,
    searchResults,
    totalPages,
    pageNumber,
    setLastElement,
  };
}
