import React from 'react';

import { LoungeFields } from 'components/TravellerInformationForm/Lounge/LoungeFields';
import { PersonalInfoCategory } from 'components/TravellerInformationForm/Lounge/PersonalInfoCategory';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import { OptionalCategoryBox } from 'components/TravellerInformationForm/components/OptionalCategoryBox';
import { ReservationInformationFormContentProps } from 'components/TravellerInformationForm/components/types';

const HA_PRIMARY_GUEST_FIELDS = [
  LoungeFieldName.TITLE,
  LoungeFieldName.FIRST_NAME,
  LoungeFieldName.LAST_NAME,
  LoungeFieldName.EMAIL,
  LoungeFieldName.MOBILE_PHONE,
];

const HA_BILLING_FIELDS = [
  LoungeFieldName.ADDRESS,
  LoungeFieldName.POST_CODE,
  LoungeFieldName.TOWN,
  LoungeFieldName.COUNTRY,
];

export const HolidayExtrasForm = ({
  control,
  trigger,
  watch,
  setValue,
  fields,
}: ReservationInformationFormContentProps) => {
  const personalFieldsToRender = fields.filter((field) =>
    HA_PRIMARY_GUEST_FIELDS.includes(field.name as LoungeFieldName),
  );

  const billingFieldsToRender = fields.filter((field) =>
    HA_BILLING_FIELDS.includes(field.name as LoungeFieldName),
  );

  const hasBillingFields = !!billingFieldsToRender?.length;

  return (
    <>
      <PersonalInfoCategory
        fields={personalFieldsToRender}
        control={control}
        trigger={trigger}
        watch={watch}
        setValue={setValue}
        showDivider={hasBillingFields}
      />
      {hasBillingFields && (
        <OptionalCategoryBox fields={billingFieldsToRender} setValue={setValue}>
          <LoungeFields
            control={control}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            fields={billingFieldsToRender}
          />
        </OptionalCategoryBox>
      )}
    </>
  );
};
