import { Airport } from '@yiluhub/yilu-amp-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { LoungeSearchInputPageQueryParams } from '../../../utils/query-params';

const useSetAirportDetails = (
  isPageReady: boolean,
  loungeSearchInputPageQueryParams: LoungeSearchInputPageQueryParams,
  loungeAirports: Airport[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const nextLoungeSearchInputPageQueryParams = { ...loungeSearchInputPageQueryParams };
    const airportIataCode = loungeSearchInputPageQueryParams.airportIataCode;

    if (loungeAirports && loungeAirports.length > 0 && airportIataCode) {
      const deepLinkedAirport = loungeAirports.find(
        (airport: { iataCode: string }) =>
          airport.iataCode.toLowerCase() === airportIataCode?.toLowerCase(),
      );

      if (deepLinkedAirport && deepLinkedAirport.localizations) {
        nextLoungeSearchInputPageQueryParams.airportDetails = {
          localizations: {
            de: deepLinkedAirport.localizations.de,
            en: deepLinkedAirport.localizations.en,
          },
          iataCode: deepLinkedAirport.iataCode,
        };
      }
      delete nextLoungeSearchInputPageQueryParams.airportIataCode;

      navigate(
        {
          pathname: routes.LOUNGES_SEARCH,
          search: getURLSearchQuery(nextLoungeSearchInputPageQueryParams),
        },
        { replace: true },
      );
    }
  }, [isPageReady, navigate, loungeAirports, loungeSearchInputPageQueryParams]);
};

export default useSetAirportDetails;
