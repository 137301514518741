import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { LegalContentType, YILU_FEATURE } from 'applicationConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsFeatureFlagEnabled, useLegalModalContext } from 'context';

import './styles.scss';

const Footer: React.FC = () => {
  const { openLegalModal } = useLegalModalContext();
  const isShowTrackingConsentEnabled = useIsFeatureFlagEnabled(YILU_FEATURE.SHOW_TRACKING_CONSENT);
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <ElementsSDK.Typography size="small" className="leftFooter">
          © {new Date().getFullYear()} Yilu - The smart platform for travel
        </ElementsSDK.Typography>
        <div className="rightFooter">
          {isShowTrackingConsentEnabled && (
            <div className="footerItem">
              <button
                onClick={() =>
                  openLegalModal(LegalContentType.TRACKING, t('cookiesBanner.trackingPreferences'))
                }
              >
                <ElementsSDK.Typography size="small">{t('Tracking')}</ElementsSDK.Typography>
              </button>
            </div>
          )}
          <div className="footerItem">
            <button onClick={() => openLegalModal(LegalContentType.PRIVACY, t('Privacy'))}>
              <ElementsSDK.Typography size="small">{t('Privacy')}</ElementsSDK.Typography>
            </button>
          </div>
          <div className="footerItem">
            <button onClick={() => openLegalModal(LegalContentType.TERMS, t('Terms of Use'))}>
              <ElementsSDK.Typography size="small">{t('Terms of Use')}</ElementsSDK.Typography>
            </button>
          </div>
          <div className="footerItem">
            <button onClick={() => openLegalModal(LegalContentType.IMPRINT, t('Imprint'))}>
              <ElementsSDK.Typography size="small">{t('Imprint')}</ElementsSDK.Typography>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
