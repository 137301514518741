import routes from '../router/routes';

export function isProductDetailsPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_PDP,
    routes.PARKING_PDP,
    routes.LOUNGES_PDP,
    routes.SWISS_TRAVEL_PASS_SEARCH,
  ].includes(pathname);
}

export function isTravellerInformationPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_TIP,
    routes.RIDES_TIP,
    routes.PARKING_TIP,
    routes.LOUNGES_TIP,
    routes.SWISS_TRAVEL_PASS_TIP,
  ].includes(pathname);
}

export function isPaymentInformationPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_PIP,
    routes.RIDES_PIP,
    routes.PARKING_PIP,
    routes.LOUNGES_PIP,
    routes.SWISS_TRAVEL_PASS_PIP,
  ].includes(pathname);
}

export function isBookingConfirmationPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_BCP,
    routes.RIDES_BCP,
    routes.PARKING_BCP,
    routes.LOUNGES_BCP,
    routes.SWISS_TRAVEL_PASS_BCP,
  ].includes(pathname);
}

export function isSearchInputPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_SEARCH,
    routes.RIDES_SEARCH,
    routes.PARKING_SEARCH,
    routes.LOUNGES_SEARCH,
    routes.SWISS_TRAVEL_PASS_SEARCH,
  ].includes(pathname);
}

export function isSearchResultsPage(pathname: string): boolean {
  return [
    routes.AIRPORT_TRANSFER_SEARCH_RESULTS,
    routes.RIDES_SEARCH_RESULTS,
    routes.PARKING_SEARCH_RESULTS,
    routes.LOUNGES_SEARCH_RESULTS,
  ].includes(pathname);
}

export function isHighPassPage(pathname: string): boolean {
  return [
    routes.HIGHPASS_LOUNGES,
    routes.HIGHPASS_FAST_TRACK,
    routes.HIGHPASS_MEET_GREET,
    routes.HIGHPASS_VIP_SERVICE,
  ].includes(pathname);
}
