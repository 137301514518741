/**
 * # Form Content
 *
 *
 */
import { AMP_VERTICALS } from 'applicationConstants';
import React from 'react';

import AirportTransferReservationInformationForm from '../AirportTransfer';
import LoungeReservationInformationForm from '../Lounge';
import ParkingReservationInformationForm from '../Parking';
import RidesReservationInformationForm from '../Rides';
import { ReservationInformationFormContentProps } from './types';

const FormContentComponents: Record<
  AMP_VERTICALS,
  React.FC<ReservationInformationFormContentProps>
> = {
  [AMP_VERTICALS.AIRPORT_TRANSFERS]: AirportTransferReservationInformationForm,
  [AMP_VERTICALS.AIRPORT_TRANSFER_PASSES]: AirportTransferReservationInformationForm,
  [AMP_VERTICALS.RIDES]: RidesReservationInformationForm,
  [AMP_VERTICALS.PARKING]: ParkingReservationInformationForm,
  [AMP_VERTICALS.LOUNGES]: LoungeReservationInformationForm,
  [AMP_VERTICALS.CAR_RENTAL]: () => null,
};

export type FormContentProps = ReservationInformationFormContentProps & {
  type: AMP_VERTICALS;
};

const FormContent: React.FC<FormContentProps> = ({ type, ...formContentProps }) => {
  const Component = FormContentComponents[type];
  return <Component {...formContentProps} />;
};

// eslint-disable-next-line import/no-default-export
export default FormContent;
