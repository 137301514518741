import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'utils/i18n';

import { getFaqLink } from 'modules/lounges/pages/BookingConfirmationPage/LoungeBookingConfirmation/utils';
import {
  LoungeServiceProvider,
  ProductDetails,
  isSpHolidayExtras,
} from 'modules/lounges/utils/getProductDetails';

import { LoungeProductDetailsProps } from '../LoungeProductDetails/types';

const useLoungeProductDetailsProps = (productDetails: ProductDetails, entryDate?: string) => {
  const { t } = useTranslation();

  const loungeProductDetailsProps = useMemo(() => {
    if (!productDetails) {
      return null;
    }

    const isHolidayExtras = isSpHolidayExtras(productDetails.providerId);

    let additionalInformation: LoungeProductDetailsProps['additionalInformation'] = [];
    let needToKnow: LoungeProductDetailsProps['needToKnow'] = [];

    if (productDetails.foodAndBeverage) {
      additionalInformation.push({
        title: t('Lounges.foodAndBeverage'),
        content: productDetails.foodAndBeverage,
      });
    }

    if (productDetails.entertainmentFacilities) {
      additionalInformation.push({
        title: t('Lounges.Entertainment'),
        content: productDetails.entertainmentFacilities,
      });
    }

    if (productDetails.accessible) {
      additionalInformation.push({
        title: t('Lounges.Accessibility'),
        content: productDetails.accessible,
      });
    }

    if (productDetails.childrenInfo) {
      needToKnow.push({
        title: t('Lounges.childrenPermitted'),
        content: productDetails.childrenInfo,
      });
    }

    if (productDetails.dressCode) {
      needToKnow.push({
        title: t('Lounges.dressCode'),
        content: productDetails.dressCode,
      });
    }

    if (productDetails.groups) {
      needToKnow.push({
        title: t('Lounges.groups'),
        content: productDetails.groups,
      });
    }

    const openingTimesMessage =
      isHolidayExtras &&
      productDetails.openingTime &&
      productDetails.closingTime &&
      t(`Lounge.OpeningTimesMessage.HolidayExtras`, {
        openingTime: productDetails.openingTime,
        closingTime: productDetails.closingTime,
      });

    const currentLanguage = getCurrentLanguage();
    const faqLink = getFaqLink(
      currentLanguage,
      productDetails.providerId,
      productDetails.iata,
      productDetails.terminal,
      productDetails.searchResultId,
    );

    const _props: LoungeProductDetailsProps = {
      name: productDetails.name,
      imageUrl: productDetails.thumbnailUrl,
      imageUrls: productDetails.imageUrls,
      location: productDetails.location || productDetails.directions || '',
      amenities: productDetails.amenities,
      openingHours: productDetails.openingHours,
      marketingText: productDetails.marketingText || productDetails.description || '',
      date: entryDate || productDetails.date,
      passengerCount: productDetails.passengerCount,
      adults: productDetails.travellersCount.adults,
      children: productDetails.travellersCount.children || 0,
      infants: productDetails.travellersCount.infants || 0,
      importantInformation: productDetails.importantInformation,
      checkInTime: productDetails.checkInTime,
      additionalInformation,
      needToKnow,
      openingTimesMessage: openingTimesMessage || '',
      iata: productDetails.iata,
      loungeOptionId: productDetails.optionId,
      freeCancellations: isHolidayExtras,
      serviceProviderType: productDetails.providerId as LoungeServiceProvider,
      faqLink,
    };

    return _props;
  }, [t, productDetails, entryDate]);

  return loungeProductDetailsProps;
};

export default useLoungeProductDetailsProps;
