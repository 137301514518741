/**
 * # Lounges Important Information
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type LoungeImportantInformationProps = {
  content: string;
};

const LoungeImportantInformation: React.FC<LoungeImportantInformationProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <div className="yilu-Lounges_ImportantInfoContainer">
      <ElementsSDK.Typography variant="h2">{t('Important Information')}</ElementsSDK.Typography>
      <ElementsSDK.Typography variant="p1" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default LoungeImportantInformation;
