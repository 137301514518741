import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

export type BookingContactFormProps = {
  contactFormText: string;
  contactFormLink: string;
  contactFormLinkTarget?: string;
  contactFormLinkText: string;
};

export const BookingContactForm: React.FC<BookingContactFormProps> = ({
  contactFormText,
  contactFormLink,
  contactFormLinkTarget,
  contactFormLinkText,
}) => {
  return (
    <div className="yilu-BookingContactForm">
      <ElementsSDK.Typography variant="p2">
        {contactFormText}
        <ElementsSDK.Link href={contactFormLink} target={contactFormLinkTarget}>
          {contactFormLinkText}
        </ElementsSDK.Link>
      </ElementsSDK.Typography>
    </div>
  );
};
