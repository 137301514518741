import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useEffect } from 'react';

import { useCurrentProductContext } from 'context';

/**
 * Use current product.
 *
 * @param searchResultId - search result identifier
 * @param loadedSearchItem
 */
export function useCurrentProduct(
  searchResultId: string | undefined,
  loadedSearchItem?: SearchItem | null,
) {
  const { data, loading, error, loadCurrentProduct, setCurrentProduct } =
    useCurrentProductContext();

  // Load current product by search result Id or loaded search item
  useEffect(() => {
    if (data || (!searchResultId && !loadedSearchItem)) {
      return;
    }

    if (searchResultId) {
      loadCurrentProduct(searchResultId);
    } else if (loadedSearchItem) {
      setCurrentProduct(loadedSearchItem);
    }
  }, [data, loadedSearchItem, searchResultId, loadCurrentProduct, setCurrentProduct]);

  const _searchResultId = searchResultId || (loadedSearchItem && loadedSearchItem.id);

  return {
    currentProduct: data && data.id === _searchResultId ? data : null,
    isCurrentProductLoading: loading,
    currentProductError: error,
  };
}
