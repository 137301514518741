import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import classnames from 'clsx';
import React from 'react';

import './styles.scss';

const Header: React.FC<HeaderProps> = ({ heading, className }) => (
  <div data-testid="header" className={classnames('yilu-CustomerInfoHeader', className)}>
    <ElementsSDK.Icon.Person />
    <span className="yilu-CustomerInfoHeader__text">{heading}</span>
  </div>
);

export interface HeaderProps {
  heading: string;
  className?: string;
}

export default Header;
