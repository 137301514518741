import { ElementsSDK } from '@yiluhub/ui-sdk-react';

export type countryISO3166Map = {
  alpha3: string;
  aplha2: string;
  country: { en: string; de: string };
};

// ISO 3166 international standard
export const countryCodeMap: countryISO3166Map[] = [
  { alpha3: 'AFG', aplha2: 'AF', country: { en: 'Afghanistan', de: 'Afghanistan' } },
  { alpha3: 'ALA', aplha2: 'AX', country: { en: 'Åland Islands', de: 'Ålandinseln' } },
  { alpha3: 'ALB', aplha2: 'AL', country: { en: 'Albania', de: 'Albanien' } },
  { alpha3: 'DZA', aplha2: 'DZ', country: { en: 'Algeria', de: 'Algerien' } },
  {
    alpha3: 'ASM',
    aplha2: 'AS',
    country: { en: 'American Samoa', de: 'Amerikanischen Samoa-Inseln' },
  },
  { alpha3: 'AND', aplha2: 'AD', country: { en: 'Andorra', de: 'Andorra' } },
  { alpha3: 'AGO', aplha2: 'AO', country: { en: 'Angola', de: 'Angola' } },
  { alpha3: 'AIA', aplha2: 'AI', country: { en: 'Anguilla', de: 'Anguilla' } },
  { alpha3: 'ATA', aplha2: 'AQ', country: { en: 'Antarctica', de: 'Antarktis' } },
  {
    alpha3: 'ATG',
    aplha2: 'AG',
    country: { en: 'Antigua and Barbuda', de: 'Antigua und Barbuda' },
  },
  { alpha3: 'ARG', aplha2: 'AR', country: { en: 'Argentina', de: 'Argentinien' } },
  { alpha3: 'ARM', aplha2: 'AM', country: { en: 'Armenia', de: 'Armenien' } },
  { alpha3: 'ABW', aplha2: 'AW', country: { en: 'Aruba', de: 'Aruba' } },
  { alpha3: 'AUS', aplha2: 'AU', country: { en: 'Australia', de: 'Australien' } },
  { alpha3: 'AUT', aplha2: 'AT', country: { en: 'Austria', de: 'Österreich' } },
  { alpha3: 'AZE', aplha2: 'AZ', country: { en: 'Azerbaijan', de: 'Aserbaidschan' } },
  { alpha3: 'BHS', aplha2: 'BS', country: { en: 'Bahamas', de: 'Bahamas' } },
  { alpha3: 'BHR', aplha2: 'BH', country: { en: 'Bahrain', de: 'Bahrain' } },
  { alpha3: 'BGD', aplha2: 'BD', country: { en: 'Bangladesh', de: 'Bangladesch' } },
  { alpha3: 'BRB', aplha2: 'BB', country: { en: 'Barbados', de: 'Barbados' } },
  { alpha3: 'BLR', aplha2: 'BY', country: { en: 'Belarus', de: 'Belarus' } },
  { alpha3: 'BEL', aplha2: 'BE', country: { en: 'Belgium', de: 'Belgien' } },
  { alpha3: 'BLZ', aplha2: 'BZ', country: { en: 'Belize', de: 'Belize' } },
  { alpha3: 'BEN', aplha2: 'BJ', country: { en: 'Benin', de: 'Benin' } },
  { alpha3: 'BMU', aplha2: 'BM', country: { en: 'Bermuda', de: 'Bermuda' } },
  { alpha3: 'BTN', aplha2: 'BT', country: { en: 'Bhutan', de: 'Bhutan' } },
  {
    alpha3: 'BOL',
    aplha2: 'BO',
    country: { en: 'Bolivia (Plurinational State of)', de: 'Bolivien' },
  },
  {
    alpha3: 'BES',
    aplha2: 'BQ',
    country: { en: 'Bonaire, Sint Eustatius and Saba', de: 'Bonaire, Sint Eustatius und Saba' },
  },
  {
    alpha3: 'BIH',
    aplha2: 'BA',
    country: { en: 'Bosnia and Herzegovina', de: 'Bosnien und Herzegowina' },
  },
  { alpha3: 'BWA', aplha2: 'BW', country: { en: 'Botswana', de: 'Botswana' } },
  { alpha3: 'BVT', aplha2: 'BV', country: { en: 'Bouvet Island', de: 'Bouvet Island' } },
  { alpha3: 'BRA', aplha2: 'BR', country: { en: 'Brazil', de: 'Brasilien' } },
  {
    alpha3: 'IOT',
    aplha2: 'IO',
    country: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium des Indischen Ozeans',
    },
  },
  { alpha3: 'BRN', aplha2: 'BN', country: { en: 'Brunei Darussalam', de: 'Brunei' } },
  { alpha3: 'BGR', aplha2: 'BG', country: { en: 'Bulgaria', de: 'Bulgarien' } },
  { alpha3: 'BFA', aplha2: 'BF', country: { en: 'Burkina Faso', de: 'Burkina Faso' } },
  { alpha3: 'BDI', aplha2: 'BI', country: { en: 'Burundi', de: 'Burundi' } },
  { alpha3: 'CPV', aplha2: 'CV', country: { en: 'Cabo Verde', de: 'Kap Verde' } },
  { alpha3: 'KHM', aplha2: 'KH', country: { en: 'Cambodia', de: 'Kambodscha' } },
  { alpha3: 'CMR', aplha2: 'CM', country: { en: 'Cameroon', de: 'Kamerun' } },
  { alpha3: 'CAN', aplha2: 'CA', country: { en: 'Canada', de: 'Kanada' } },
  { alpha3: 'CYM', aplha2: 'KY', country: { en: 'Cayman Islands', de: 'Cayman Inseln' } },
  {
    alpha3: 'CAF',
    aplha2: 'CF',
    country: { en: 'Central African Republic', de: 'Zentral­afrikanische Republik' },
  },
  { alpha3: 'TCD', aplha2: 'TD', country: { en: 'Chad', de: 'Tschad' } },
  { alpha3: 'CHL', aplha2: 'CL', country: { en: 'Chile', de: 'Chile' } },
  { alpha3: 'CHN', aplha2: 'CN', country: { en: 'China', de: 'Volksrepublik China' } },
  { alpha3: 'CXR', aplha2: 'CX', country: { en: 'Christmas Island', de: 'Weihnachtsinsel' } },
  {
    alpha3: 'CCK',
    aplha2: 'CC',
    country: { en: 'Cocos (Keeling) Islands', de: 'Kokosinseln (Keelinginseln)' },
  },
  { alpha3: 'COL', aplha2: 'CO', country: { en: 'Colombia', de: 'Kolumbien' } },
  { alpha3: 'COM', aplha2: 'KM', country: { en: 'Comoros', de: 'Komoren' } },
  { alpha3: 'COG', aplha2: 'CG', country: { en: 'Congo', de: 'Kongo, Republik' } },
  {
    alpha3: 'COD',
    aplha2: 'CD',
    country: { en: 'Congo, Democratic Republic of the', de: 'Kongo, Demokratische Republik' },
  },
  { alpha3: 'COK', aplha2: 'CK', country: { en: 'Cook Islands', de: 'Cookinseln' } },
  { alpha3: 'CRI', aplha2: 'CR', country: { en: 'Costa Rica', de: 'Costa Rica' } },
  { alpha3: 'CIV', aplha2: 'CI', country: { en: "Côte d'Ivoire", de: 'Elfenbeinküste' } },
  { alpha3: 'HRV', aplha2: 'HR', country: { en: 'Croatia', de: 'Kroatien' } },
  { alpha3: 'CUB', aplha2: 'CU', country: { en: 'Cuba', de: 'Kuba' } },
  { alpha3: 'CUW', aplha2: 'CW', country: { en: 'Curaçao', de: 'Curaçao' } },
  { alpha3: 'CYP', aplha2: 'CY', country: { en: 'Cyprus', de: 'Zypern' } },
  { alpha3: 'CZE', aplha2: 'CZ', country: { en: 'Czechia', de: 'Tschechien' } },
  { alpha3: 'DNK', aplha2: 'DK', country: { en: 'Denmark', de: 'Dänemark' } },
  { alpha3: 'DJI', aplha2: 'DJ', country: { en: 'Djibouti', de: 'Dschibuti' } },
  { alpha3: 'DMA', aplha2: 'DM', country: { en: 'Dominica', de: 'Dominica' } },
  {
    alpha3: 'DOM',
    aplha2: 'DO',
    country: { en: 'Dominican Republic', de: 'Dominikanische Republik' },
  },
  { alpha3: 'ECU', aplha2: 'EC', country: { en: 'Ecuador', de: 'Ecuador' } },
  { alpha3: 'EGY', aplha2: 'EG', country: { en: 'Egypt', de: 'Ägypten' } },
  { alpha3: 'SLV', aplha2: 'SV', country: { en: 'El Salvador', de: 'El Salvador' } },
  { alpha3: 'GNQ', aplha2: 'GQ', country: { en: 'Equatorial Guinea', de: 'Äquatorialguinea' } },
  { alpha3: 'ERI', aplha2: 'ER', country: { en: 'Eritrea', de: 'Eritrea' } },
  { alpha3: 'EST', aplha2: 'EE', country: { en: 'Estonia', de: 'Estland' } },
  { alpha3: 'SWZ', aplha2: 'SZ', country: { en: 'Eswatini', de: 'Eswatini' } },
  { alpha3: 'ETH', aplha2: 'ET', country: { en: 'Ethiopia', de: 'Äthiopien' } },
  {
    alpha3: 'FLK',
    aplha2: 'FK',
    country: { en: 'Falkland Islands (Malvinas)', de: 'Falklandinseln (Malvinas)' },
  },
  { alpha3: 'FRO', aplha2: 'FO', country: { en: 'Faroe Islands', de: 'Färöer Inseln' } },
  { alpha3: 'FJI', aplha2: 'FJ', country: { en: 'Fiji', de: 'Fidschi' } },
  { alpha3: 'FIN', aplha2: 'FI', country: { en: 'Finland', de: 'Finnland' } },
  { alpha3: 'FRA', aplha2: 'FR', country: { en: 'France', de: 'Frankreich' } },
  { alpha3: 'GUF', aplha2: 'GF', country: { en: 'French Guiana', de: 'Französisch-Guayana' } },
  {
    alpha3: 'PYF',
    aplha2: 'PF',
    country: { en: 'French Polynesia', de: 'Französisch Polynesien' },
  },
  {
    alpha3: 'ATF',
    aplha2: 'TF',
    country: { en: 'French Southern Territories', de: 'Südfranzösische Territorien' },
  },
  { alpha3: 'GAB', aplha2: 'GA', country: { en: 'Gabon', de: 'Gabun' } },
  { alpha3: 'GMB', aplha2: 'GM', country: { en: 'Gambia', de: 'Gambia' } },
  { alpha3: 'GEO', aplha2: 'GE', country: { en: 'Georgia', de: 'Georgien' } },
  { alpha3: 'DEU', aplha2: 'DE', country: { en: 'Germany', de: 'Deutschland' } },
  { alpha3: 'GHA', aplha2: 'GH', country: { en: 'Ghana', de: 'Ghana' } },
  { alpha3: 'GIB', aplha2: 'GI', country: { en: 'Gibraltar', de: 'Gibraltar' } },
  { alpha3: 'GRC', aplha2: 'GR', country: { en: 'Greece', de: 'Griechenland' } },
  { alpha3: 'GRL', aplha2: 'GL', country: { en: 'Greenland', de: 'Grönland' } },
  { alpha3: 'GRD', aplha2: 'GD', country: { en: 'Grenada', de: 'Grenada' } },
  { alpha3: 'GLP', aplha2: 'GP', country: { en: 'Guadeloupe', de: 'Guadeloupe' } },
  { alpha3: 'GUM', aplha2: 'GU', country: { en: 'Guam', de: 'Guam' } },
  { alpha3: 'GTM', aplha2: 'GT', country: { en: 'Guatemala', de: 'Guatemala' } },
  { alpha3: 'GGY', aplha2: 'GG', country: { en: 'Guernsey', de: 'Guernsey' } },
  { alpha3: 'GIN', aplha2: 'GN', country: { en: 'Guinea', de: 'Guinea' } },
  { alpha3: 'GNB', aplha2: 'GW', country: { en: 'Guinea-Bissau', de: 'Guinea-Bissau' } },
  { alpha3: 'GUY', aplha2: 'GY', country: { en: 'Guyana', de: 'Guyana' } },
  { alpha3: 'HTI', aplha2: 'HT', country: { en: 'Haiti', de: 'Haiti' } },
  {
    alpha3: 'HMD',
    aplha2: 'HM',
    country: { en: 'Heard Island and McDonald Islands', de: 'Heard Island und McDonald Islands' },
  },
  { alpha3: 'VAT', aplha2: 'VA', country: { en: 'Holy See', de: 'Heiliger Stuhl' } },
  { alpha3: 'HND', aplha2: 'HN', country: { en: 'Honduras', de: 'Honduras' } },
  { alpha3: 'HKG', aplha2: 'HK', country: { en: 'Hong Kong', de: 'Hongkong' } },
  { alpha3: 'HUN', aplha2: 'HU', country: { en: 'Hungary', de: 'Ungarn' } },
  { alpha3: 'ISL', aplha2: 'IS', country: { en: 'Iceland', de: 'Island' } },
  { alpha3: 'IND', aplha2: 'IN', country: { en: 'India', de: 'Indien' } },
  { alpha3: 'IDN', aplha2: 'ID', country: { en: 'Indonesia', de: 'Indonesien' } },
  { alpha3: 'IRN', aplha2: 'IR', country: { en: 'Iran (Islamic Republic of)', de: 'Iran' } },
  { alpha3: 'IRQ', aplha2: 'IQ', country: { en: 'Iraq', de: 'Irak' } },
  { alpha3: 'IRL', aplha2: 'IE', country: { en: 'Ireland', de: 'Irland' } },
  { alpha3: 'IMN', aplha2: 'IM', country: { en: 'Isle of Man', de: 'Isle of Man' } },
  { alpha3: 'ISR', aplha2: 'IL', country: { en: 'Israel', de: 'Israel' } },
  { alpha3: 'ITA', aplha2: 'IT', country: { en: 'Italy', de: 'Italien' } },
  { alpha3: 'JAM', aplha2: 'JM', country: { en: 'Jamaica', de: 'Jamaika' } },
  { alpha3: 'JPN', aplha2: 'JP', country: { en: 'Japan', de: 'Japan' } },
  { alpha3: 'JEY', aplha2: 'JE', country: { en: 'Jersey', de: 'Jersey' } },
  { alpha3: 'JOR', aplha2: 'JO', country: { en: 'Jordan', de: 'Jordanien' } },
  { alpha3: 'KAZ', aplha2: 'KZ', country: { en: 'Kazakhstan', de: 'Kasachstan' } },
  { alpha3: 'KEN', aplha2: 'KE', country: { en: 'Kenya', de: 'Kenia' } },
  { alpha3: 'KIR', aplha2: 'KI', country: { en: 'Kiribati', de: 'Kiribati' } },
  {
    alpha3: 'PRK',
    aplha2: 'KP',
    country: { en: "Korea (Democratic People's Republic of)", de: 'Korea, Nord' },
  },
  { alpha3: 'KOR', aplha2: 'KR', country: { en: 'Korea, Republic of', de: 'Korea, Süd' } },
  { alpha3: 'KWT', aplha2: 'KW', country: { en: 'Kuwait', de: 'Kuwait' } },
  { alpha3: 'KGZ', aplha2: 'KG', country: { en: 'Kyrgyzstan', de: 'Kirgisistan' } },
  { alpha3: 'LAO', aplha2: 'LA', country: { en: "Lao People's Democratic Republic", de: 'Laos' } },
  { alpha3: 'LVA', aplha2: 'LV', country: { en: 'Latvia', de: 'Lettland' } },
  { alpha3: 'LBN', aplha2: 'LB', country: { en: 'Lebanon', de: 'Libanon' } },
  { alpha3: 'LSO', aplha2: 'LS', country: { en: 'Lesotho', de: 'Lesotho' } },
  { alpha3: 'LBR', aplha2: 'LR', country: { en: 'Liberia', de: 'Liberia' } },
  { alpha3: 'LBY', aplha2: 'LY', country: { en: 'Libya', de: 'Libyen' } },
  { alpha3: 'LIE', aplha2: 'LI', country: { en: 'Liechtenstein', de: 'Liechtenstein' } },
  { alpha3: 'LTU', aplha2: 'LT', country: { en: 'Lithuania', de: 'Litauen' } },
  { alpha3: 'LUX', aplha2: 'LU', country: { en: 'Luxembourg', de: 'Luxemburg' } },
  { alpha3: 'MAC', aplha2: 'MO', country: { en: 'Macao', de: 'Macao' } },
  { alpha3: 'MDG', aplha2: 'MG', country: { en: 'Madagascar', de: 'Madagaskar' } },
  { alpha3: 'MWI', aplha2: 'MW', country: { en: 'Malawi', de: 'Malawi' } },
  { alpha3: 'MYS', aplha2: 'MY', country: { en: 'Malaysia', de: 'Malaysia' } },
  { alpha3: 'MDV', aplha2: 'MV', country: { en: 'Maldives', de: 'Malediven' } },
  { alpha3: 'MLI', aplha2: 'ML', country: { en: 'Mali', de: 'Mali' } },
  { alpha3: 'MLT', aplha2: 'MT', country: { en: 'Malta', de: 'Malta' } },
  { alpha3: 'MHL', aplha2: 'MH', country: { en: 'Marshall Islands', de: 'Marshallinseln' } },
  { alpha3: 'MTQ', aplha2: 'MQ', country: { en: 'Martinique', de: 'Martinique' } },
  { alpha3: 'MRT', aplha2: 'MR', country: { en: 'Mauritania', de: 'Mauretanien' } },
  { alpha3: 'MUS', aplha2: 'MU', country: { en: 'Mauritius', de: 'Mauritius' } },
  { alpha3: 'MYT', aplha2: 'YT', country: { en: 'Mayotte', de: 'Mayotte' } },
  { alpha3: 'MEX', aplha2: 'MX', country: { en: 'Mexico', de: 'Mexiko' } },
  {
    alpha3: 'FSM',
    aplha2: 'FM',
    country: { en: 'Micronesia (Federated States of)', de: 'Mikronesien' },
  },
  { alpha3: 'MDA', aplha2: 'MD', country: { en: 'Moldova, Republic of', de: 'Moldau' } },
  { alpha3: 'MCO', aplha2: 'MC', country: { en: 'Monaco', de: 'Monaco' } },
  { alpha3: 'MNG', aplha2: 'MN', country: { en: 'Mongolia', de: 'Mongolei' } },
  { alpha3: 'MNE', aplha2: 'ME', country: { en: 'Montenegro', de: 'Montenegro' } },
  { alpha3: 'MSR', aplha2: 'MS', country: { en: 'Montserrat', de: 'Montserrat' } },
  { alpha3: 'MAR', aplha2: 'MA', country: { en: 'Morocco', de: 'Marokko' } },
  { alpha3: 'MOZ', aplha2: 'MZ', country: { en: 'Mozambique', de: 'Mosambik' } },
  { alpha3: 'MMR', aplha2: 'MM', country: { en: 'Myanmar', de: 'Myanmar' } },
  { alpha3: 'NAM', aplha2: 'NA', country: { en: 'Namibia', de: 'Namibia' } },
  { alpha3: 'NRU', aplha2: 'NR', country: { en: 'Nauru', de: 'Nauru' } },
  { alpha3: 'NPL', aplha2: 'NP', country: { en: 'Nepal', de: 'Nepal' } },
  { alpha3: 'NLD', aplha2: 'NL', country: { en: 'Netherlands', de: 'Niederlande' } },
  { alpha3: 'NCL', aplha2: 'NC', country: { en: 'New Caledonia', de: 'Neu-Kaledonien' } },
  { alpha3: 'NZL', aplha2: 'NZ', country: { en: 'New Zealand', de: 'Neuseeland' } },
  { alpha3: 'NIC', aplha2: 'NI', country: { en: 'Nicaragua', de: 'Nicaragua' } },
  { alpha3: 'NER', aplha2: 'NE', country: { en: 'Niger', de: 'Niger' } },
  { alpha3: 'NGA', aplha2: 'NG', country: { en: 'Nigeria', de: 'Nigeria' } },
  { alpha3: 'NIU', aplha2: 'NU', country: { en: 'Niue', de: 'Niue' } },
  { alpha3: 'NFK', aplha2: 'NF', country: { en: 'Norfolk Island', de: 'Norfolkinsel' } },
  { alpha3: 'MKD', aplha2: 'MK', country: { en: 'North Macedonia', de: 'Nordmazedonien' } },
  {
    alpha3: 'MNP',
    aplha2: 'MP',
    country: { en: 'Northern Mariana Islands', de: 'Nördliche Marianneninseln' },
  },
  { alpha3: 'NOR', aplha2: 'NO', country: { en: 'Norway', de: 'Norwegen' } },
  { alpha3: 'OMN', aplha2: 'OM', country: { en: 'Oman', de: 'Oman' } },
  { alpha3: 'PAK', aplha2: 'PK', country: { en: 'Pakistan', de: 'Pakistan' } },
  { alpha3: 'PLW', aplha2: 'PW', country: { en: 'Palau', de: 'Palau' } },
  {
    alpha3: 'PSE',
    aplha2: 'PS',
    country: { en: 'Palestine, State of', de: 'Palästina, Bundesstaat' },
  },
  { alpha3: 'PAN', aplha2: 'PA', country: { en: 'Panama', de: 'Panama' } },
  { alpha3: 'PNG', aplha2: 'PG', country: { en: 'Papua New Guinea', de: 'Papua-Neuguinea' } },
  { alpha3: 'PRY', aplha2: 'PY', country: { en: 'Paraguay', de: 'Paraguay' } },
  { alpha3: 'PER', aplha2: 'PE', country: { en: 'Peru', de: 'Peru' } },
  { alpha3: 'PHL', aplha2: 'PH', country: { en: 'Philippines', de: 'Philippinen' } },
  { alpha3: 'PCN', aplha2: 'PN', country: { en: 'Pitcairn', de: 'Pitcairn' } },
  { alpha3: 'POL', aplha2: 'PL', country: { en: 'Poland', de: 'Polen' } },
  { alpha3: 'PRT', aplha2: 'PT', country: { en: 'Portugal', de: 'Portugal' } },
  { alpha3: 'PRI', aplha2: 'PR', country: { en: 'Puerto Rico', de: 'Puerto Rico' } },
  { alpha3: 'QAT', aplha2: 'QA', country: { en: 'Qatar', de: 'Katar' } },
  { alpha3: 'REU', aplha2: 'RE', country: { en: 'Réunion', de: 'Réunion' } },
  { alpha3: 'ROU', aplha2: 'RO', country: { en: 'Romania', de: 'Rumänien' } },
  { alpha3: 'RUS', aplha2: 'RU', country: { en: 'Russian Federation', de: 'Russland' } },
  { alpha3: 'RWA', aplha2: 'RW', country: { en: 'Rwanda', de: 'Ruanda' } },
  { alpha3: 'BLM', aplha2: 'BL', country: { en: 'Saint Barthélemy', de: 'St. Barthélemy' } },
  {
    alpha3: 'SHN',
    aplha2: 'SH',
    country: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      de: 'St. Helena, Himmelfahrt und Tristan da Cunha',
    },
  },
  {
    alpha3: 'KNA',
    aplha2: 'KN',
    country: { en: 'Saint Kitts and Nevis', de: 'St. Kitts und Nevis' },
  },
  { alpha3: 'LCA', aplha2: 'LC', country: { en: 'Saint Lucia', de: 'St. Lucia' } },
  {
    alpha3: 'MAF',
    aplha2: 'MF',
    country: { en: 'Saint Martin (French part)', de: 'Saint Martin (französischer Teil)' },
  },
  {
    alpha3: 'SPM',
    aplha2: 'PM',
    country: { en: 'Saint Pierre and Miquelon', de: 'Saint Pierre und Miquelon' },
  },
  {
    alpha3: 'VCT',
    aplha2: 'VC',
    country: { en: 'Saint Vincent and the Grenadines', de: 'St. Vincent und die Grenadinen' },
  },
  { alpha3: 'WSM', aplha2: 'WS', country: { en: 'Samoa', de: 'Samoa' } },
  { alpha3: 'SMR', aplha2: 'SM', country: { en: 'San Marino', de: 'San Marino' } },
  {
    alpha3: 'STP',
    aplha2: 'ST',
    country: { en: 'Sao Tome and Principe', de: 'São Tomé und Príncipe' },
  },
  { alpha3: 'SAU', aplha2: 'SA', country: { en: 'Saudi Arabia', de: 'Saudi-Arabien' } },
  { alpha3: 'SEN', aplha2: 'SN', country: { en: 'Senegal', de: 'Senegal' } },
  { alpha3: 'SRB', aplha2: 'RS', country: { en: 'Serbia', de: 'Serbien' } },
  { alpha3: 'SYC', aplha2: 'SC', country: { en: 'Seychelles', de: 'Seychellen' } },
  { alpha3: 'SLE', aplha2: 'SL', country: { en: 'Sierra Leone', de: 'Sierra Leone' } },
  { alpha3: 'SGP', aplha2: 'SG', country: { en: 'Singapore', de: 'Singapur' } },
  {
    alpha3: 'SXM',
    aplha2: 'SX',
    country: { en: 'Sint Maarten (Dutch part)', de: 'Sint Maarten (niederländischer Teil)' },
  },
  { alpha3: 'SVK', aplha2: 'SK', country: { en: 'Slovakia', de: 'Slowakei' } },
  { alpha3: 'SVN', aplha2: 'SI', country: { en: 'Slovenia', de: 'Slowenien' } },
  { alpha3: 'SLB', aplha2: 'SB', country: { en: 'Solomon Islands', de: 'Salomonen' } },
  { alpha3: 'SOM', aplha2: 'SO', country: { en: 'Somalia', de: 'Somalia' } },
  { alpha3: 'ZAF', aplha2: 'ZA', country: { en: 'South Africa', de: 'Südafrika' } },
  {
    alpha3: 'SGS',
    aplha2: 'GS',
    country: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Süd-Georgien und die südlichen Sandwich-Inseln',
    },
  },
  { alpha3: 'SSD', aplha2: 'SS', country: { en: 'South Sudan', de: 'Südsudan' } },
  { alpha3: 'ESP', aplha2: 'ES', country: { en: 'Spain', de: 'Spanien' } },
  { alpha3: 'LKA', aplha2: 'LK', country: { en: 'Sri Lanka', de: 'Sri Lanka' } },
  { alpha3: 'SDN', aplha2: 'SD', country: { en: 'Sudan', de: 'Sudan' } },
  { alpha3: 'SUR', aplha2: 'SR', country: { en: 'Suriname', de: 'Suriname' } },
  {
    alpha3: 'SJM',
    aplha2: 'SJ',
    country: { en: 'Svalbard and Jan Mayen', de: 'Spitzbergen und Jan Mayen' },
  },
  { alpha3: 'SWE', aplha2: 'SE', country: { en: 'Sweden', de: 'Schweden' } },
  { alpha3: 'CHE', aplha2: 'CH', country: { en: 'Switzerland', de: 'Schweiz' } },
  { alpha3: 'SYR', aplha2: 'SY', country: { en: 'Syrian Arab Republic', de: 'Syrien' } },
  {
    alpha3: 'TWN',
    aplha2: 'TW',
    country: { en: 'Taiwan, Province of China', de: 'Taiwan, Provinz Chinas' },
  },
  { alpha3: 'TJK', aplha2: 'TJ', country: { en: 'Tajikistan', de: 'Tadschikistan' } },
  { alpha3: 'TZA', aplha2: 'TZ', country: { en: 'Tanzania, United Republic of', de: 'Tansania' } },
  { alpha3: 'THA', aplha2: 'TH', country: { en: 'Thailand', de: 'Thailand' } },
  { alpha3: 'TLS', aplha2: 'TL', country: { en: 'Timor-Leste', de: 'Osttimor' } },
  { alpha3: 'TGO', aplha2: 'TG', country: { en: 'Togo', de: 'Togo' } },
  { alpha3: 'TKL', aplha2: 'TK', country: { en: 'Tokelau', de: 'Tokelau' } },
  { alpha3: 'TON', aplha2: 'TO', country: { en: 'Tonga', de: 'Tonga' } },
  {
    alpha3: 'TTO',
    aplha2: 'TT',
    country: { en: 'Trinidad and Tobago', de: 'Trinidad und Tobago' },
  },
  { alpha3: 'TUN', aplha2: 'TN', country: { en: 'Tunisia', de: 'Tunesien' } },
  { alpha3: 'TUR', aplha2: 'TR', country: { en: 'Turkey', de: 'Türkei' } },
  { alpha3: 'TKM', aplha2: 'TM', country: { en: 'Turkmenistan', de: 'Turkmenistan' } },
  {
    alpha3: 'TCA',
    aplha2: 'TC',
    country: { en: 'Turks and Caicos Islands', de: 'Turks und Caicosinseln' },
  },
  { alpha3: 'TUV', aplha2: 'TV', country: { en: 'Tuvalu', de: 'Tuvalu' } },
  { alpha3: 'UGA', aplha2: 'UG', country: { en: 'Uganda', de: 'Uganda' } },
  { alpha3: 'UKR', aplha2: 'UA', country: { en: 'Ukraine', de: 'Ukraine' } },
  {
    alpha3: 'ARE',
    aplha2: 'AE',
    country: { en: 'United Arab Emirates', de: 'Vereinigte Arabische Emirate' },
  },
  {
    alpha3: 'GBR',
    aplha2: 'GB',
    country: {
      en: 'United Kingdom of Great Britain and Northern Ireland',
      de: 'Vereinigtes Königreich',
    },
  },
  {
    alpha3: 'USA',
    aplha2: 'US',
    country: { en: 'United States of America', de: 'Vereinigte Staaten' },
  },
  {
    alpha3: 'UMI',
    aplha2: 'UM',
    country: {
      en: 'United States Minor Outlying Islands',
      de: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
    },
  },
  { alpha3: 'URY', aplha2: 'UY', country: { en: 'Uruguay', de: 'Uruguay' } },
  { alpha3: 'UZB', aplha2: 'UZ', country: { en: 'Uzbekistan', de: 'Usbekistan' } },
  { alpha3: 'VUT', aplha2: 'VU', country: { en: 'Vanuatu', de: 'Vanuatu' } },
  {
    alpha3: 'VEN',
    aplha2: 'VE',
    country: { en: 'Venezuela (Bolivarian Republic of)', de: 'Venezuela' },
  },
  { alpha3: 'VNM', aplha2: 'VN', country: { en: 'Viet Nam', de: 'Vietnam' } },
  {
    alpha3: 'VGB',
    aplha2: 'VG',
    country: { en: 'Virgin Islands (British)', de: 'Virgin Inseln (Britisch)' },
  },
  {
    alpha3: 'VIR',
    aplha2: 'VI',
    country: { en: 'Virgin Islands (U.S.)', de: 'Virgin Inseln (USA)' },
  },
  { alpha3: 'WLF', aplha2: 'WF', country: { en: 'Wallis and Futuna', de: 'Wallis und Futuna' } },
  { alpha3: 'ESH', aplha2: 'EH', country: { en: 'Western Sahara', de: 'Westsahara' } },
  { alpha3: 'YEM', aplha2: 'YE', country: { en: 'Yemen', de: 'Jemen' } },
  { alpha3: 'ZMB', aplha2: 'ZM', country: { en: 'Zambia', de: 'Sambia' } },
  { alpha3: 'ZWE', aplha2: 'ZW', country: { en: 'Zimbabwe', de: 'Simbabwe' } },
];

/**
 * Returns a list of countries with value as aplha2 country code
 * and label the localized country name
 *
 * @param locale
 */
export const getSortedCountriesAlpha2Options = (locale: 'en' | 'de' = 'en') => {
  return countryCodeMap
    .map((country) => ({
      value: country.aplha2,
      label: country.country[locale],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Returns a list of countries with value as aplha3 country code
 * and label the localized country name
 *
 * @param locale
 */
export const getSortedCountriesAlpha3Options = (locale: 'en' | 'de' = 'en') => {
  return countryCodeMap
    .map((country) => ({
      value: country.alpha3,
      label: country.country[locale],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Searches the countriesList and returns an alpha2 country option
 * with the specified countryCode or undefined if such country code does not exist.
 *
 * @param countryCode
 * @param countriesList
 */
export const findCountryAlpha2OptionByCode = (
  countryCode: string | undefined,
): countryISO3166Map | undefined => {
  return countryCode
    ? countryCodeMap.find(
        (countryOption) => countryOption.aplha2.toUpperCase() === countryCode.toUpperCase(),
      )
    : undefined;
};

/**
 * Searches the countriesList and returns the country info with the given code
 *
 * @param countryCode
 */
export const findCountryAlpha3OptionByCode = (
  countryCode: string | undefined,
): countryISO3166Map | undefined => {
  return countryCode
    ? countryCodeMap.find(
        (countryOption) => countryOption.alpha3.toUpperCase() === countryCode.toUpperCase(),
      )
    : undefined;
};
export const getDefaultCountryValue = (
  fieldName: string,
  language: 'en' | 'de',
): ElementsSDK.SelectOption | undefined => {
  const savedItem = sessionStorage.getItem(fieldName);
  const countryCode = savedItem ? JSON.parse(savedItem) : undefined;
  if (countryCode) {
    const countryMap: countryISO3166Map | undefined = findCountryAlpha3OptionByCode(countryCode);
    return countryMap ? { value: countryCode, label: countryMap.country[language] } : undefined;
  }
  return undefined;
};
