import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { roundUpToNext15MinutesInLocalTime } from 'utils/dateUtils';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import { LOUNGE_TIME_INTERVAL } from 'modules/lounges/components/LoungeInteractiveProductSummary/index';

import { getDisabledDaysByOpeningHours } from '../../utils/dateUtils';

export const EditableFields: React.FC<
  Pick<
    LoungeInteractiveProductSummaryProps,
    | 'entryDate'
    | 'isEditingDisabled'
    | 'maxTravellersCount'
    | 'travellersFields'
    | 'onDateInputChange'
    | 'dateInputMaxDate'
    | 'travellerSelectorProps'
    | 'openingTimesOptions'
    | 'openingHours'
  >
> = ({
  entryDate,
  isEditingDisabled,
  maxTravellersCount,
  travellersFields,
  onDateInputChange,
  dateInputMaxDate,
  travellerSelectorProps,
  openingHours,
  openingTimesOptions,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = UiUtilities.useIsDesktop();

  const roundedUpDate = useMemo(() => roundUpToNext15MinutesInLocalTime(entryDate), [entryDate]);

  const disabledWeekDays = useMemo(
    () => getDisabledDaysByOpeningHours(openingHours),
    [openingHours],
  );

  return (
    <>
      <ElementsSDK.InputField.DateTimePicker
        isDisabled={isEditingDisabled}
        className="yilu-ProductSummaryLounge__DateTimeInput"
        maxDate={dateInputMaxDate}
        minDate={dayjs().format(UiUtilities.DateFormat.SHORT_DATE)}
        onChange={onDateInputChange ? onDateInputChange : () => undefined}
        timeControlsEnabled
        timeInterval={LOUNGE_TIME_INTERVAL}
        dateFormat={isDesktop ? 'ddd, D MMM' : 'D MMM'}
        initialValue={roundedUpDate}
        disabledWeekDays={disabledWeekDays}
        timeOptions={openingTimesOptions}
      />
      <div className="yilu-ProductSummaryLounge__TravellersCounterContainer">
        <ElementsSDK.TravellersSelector
          isDisabled={isEditingDisabled}
          maxTravellersCount={maxTravellersCount}
          countLabelSingular={t('Guest')}
          countLabelPlural={t('Guests')}
          maxedOutMessage={t('Maximum guests reached', { limit: maxTravellersCount })}
          dataTestId={'lounge-search-form-travellers-selector'}
          travellers={travellersFields}
          {...travellerSelectorProps}
        />
      </div>
    </>
  );
};
