/**
 * # Airport Transfer Search Result Page
 *
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { AirportTransferSearchForm, FlexbilityBanner } from '../../components';
import { AirportTransferCollapsibleForm } from './AirportTransferCollapsibleForm';
import { AirportTransferSearchResults } from './AirportTransferSearchResults';
import { useAirportTransferSearchResultsPage } from './hooks';
import './styles.scss';

export const SearchResultPage: React.FC = () => {
  const {
    collapsibleAirportTransferSearchFormProps,
    airportTransferSearchFormProps,
    airportTransferSearchResultsProps,
    showHexBanner,
    showCatBanner,
    showBVGBanner,
    getTranslationForHeadingAndSubheading,
    isPageReady,
  } = useAirportTransferSearchResultsPage();

  const { t } = useTranslation();
  const bvgSubheading = getTranslationForHeadingAndSubheading();
  return (
    <div className="airport-transfer-srp">
      {isPageReady ? (
        <>
          <div
            className="AirportTransfer-Search-Results__Form--mobile"
            aria-label="Book your airport transfer"
            role="heading"
            aria-level={1}
          >
            <AirportTransferCollapsibleForm {...collapsibleAirportTransferSearchFormProps} />
          </div>
          <div
            className="AirportTransfer-Search-Results__Form--desktop"
            aria-label="Book your airport transfer"
            role="heading"
            aria-level={1}
          >
            <div className="AirportTransfer-Search-Results__Form">
              <AirportTransferSearchForm {...airportTransferSearchFormProps} />
            </div>
          </div>
          {showHexBanner && (
            <FlexbilityBanner
              heading={t('hexBanner.heading')}
              subHeading={t('hexBanner.subheading')}
            />
          )}
          {showCatBanner && (
            <FlexbilityBanner
              heading={t('catBanner.heading')}
              subHeading={t('catBanner.subheading')}
            />
          )}
          {showBVGBanner && (
            <FlexbilityBanner heading={t('bvgBanner.heading')} subHeading={t(bvgSubheading)} />
          )}
          {airportTransferSearchResultsProps && (
            <div className="AirportTransfer-Search-Results__Section">
              <div className="AirportTransfer-Search-Results__Results">
                <AirportTransferSearchResults {...airportTransferSearchResultsProps} />
              </div>
            </div>
          )}
        </>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default SearchResultPage;
