import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAssetPaths } from 'context/EnterprisePartnerConfiguration';

import { useStationAirports } from '../../../hooks/useStationAirports';
import { getAirportTransferSearchInputPageQueryParams } from '../../../utils/query-params';
import useAirportTransferSearchFormProps from './useAirportTransferSearchFormProps';
import useSetAirportDetails from './useSetAirportDetails';
import useTracking from './useTracking';

/**
 * Retrieve data for the airport transfer search input page.
 */
export const useAirportTransferSearchInputPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const airportTransferSearchInputPageQueryParams = useMemo(() => {
    return getAirportTransferSearchInputPageQueryParams(location);
  }, [location]);

  const stationAirports = useStationAirports();

  const isPageReady =
    Boolean(airportTransferSearchInputPageQueryParams.airportDetails) ||
    !airportTransferSearchInputPageQueryParams.airportIataCode;

  useTracking(isPageReady);

  useSetAirportDetails(isPageReady, airportTransferSearchInputPageQueryParams, stationAirports);

  const airportTransferSearchFormProps = useAirportTransferSearchFormProps(
    airportTransferSearchInputPageQueryParams,
    stationAirports,
  );

  const assetPaths = useAssetPaths();
  const sipImagePaths = assetPaths?.airportTransfer?.sipImagePaths;

  const productValueItems = useMemo(() => {
    return [
      {
        title: t('Search & Plan'),
        text: t('Find the cheapest, most convenient transport options'),
        imageUrl:
          sipImagePaths?.[0] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/airportTransfers/transfers-1.jpg',
      },
      {
        title: t('Quickly Compare'),
        text: t('Compare and find the best offers'),
        imageUrl:
          sipImagePaths?.[1] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/airportTransfers/transfers-2.jpg',
      },
      {
        title: t('Easily Book'),
        text: t('Secure booking, fast and easy'),
        imageUrl:
          sipImagePaths?.[2] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/airportTransfers/transfers-3.jpg',
      },
    ];
  }, [t, sipImagePaths]);

  return {
    airportTransferSearchFormProps,
    isPageReady,
    productValueItems,
  };
};
