/**
 * # Value Composition
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

export type ValueCompositionProps = {
  title: string;
  text: string;
  imageUrl?: string;
  Icon?: React.FC;
};

const ValueComposition: React.FC<ValueCompositionProps> = ({ imageUrl, title, text, Icon }) => {
  return (
    <div className="value-composition" data-testid="element-value-composition">
      {imageUrl && <img className="value-composition__image" src={imageUrl} alt={title} />}
      {Icon && <Icon />}
      <ElementsSDK.Typography variant="h4" className="value-composition__header">
        {title}
      </ElementsSDK.Typography>
      <ElementsSDK.Typography variant="p2" className="value-composition__content">
        {text}
      </ElementsSDK.Typography>
    </div>
  );
};

export default ValueComposition;
