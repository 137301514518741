import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeading } from 'components';
import BacklinkNavigator from 'components/Header/BacklinkNavigator';
import InteractiveSummaryLayout from 'components/Layout/InteractiveSummaryLayout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { LoungeInteractiveProductSummary } from 'modules/lounges/components';

import { LoungeProductDetails } from './LoungeProductDetails';
import { useLoungeDetailsContent, useLoungeProductDetailsPage } from './hooks';
import './styles.scss';

type ProductDetailsContentProps = {
  currentLounge: SearchItem;
};

const LoungeDetailsContent: React.FC<ProductDetailsContentProps> = ({ currentLounge }) => {
  const { t } = useTranslation();
  const { loungeProductSummaryProps, loungeProductDetailsProps, backLinkProps } =
    useLoungeDetailsContent(currentLounge);
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');
  const showBacklinkNavigator = loungeProductSummaryProps
    ? loungeProductSummaryProps.showBacklinkNavigator
    : true;

  return (
    <>
      {showBacklinkNavigator && <BacklinkNavigator backLinkProps={backLinkProps} />}
      <div className="lounges-pdp">
        <div className="form-container-wrapper">
          <div className="form-container container">
            <div className="content">
              <PageHeading text={t('Header - Details')} />
              {loungeProductSummaryProps && !isDesktop && (
                <InteractiveSummaryLayout>
                  <LoungeInteractiveProductSummary {...loungeProductSummaryProps} />
                </InteractiveSummaryLayout>
              )}
              {loungeProductDetailsProps && <LoungeProductDetails {...loungeProductDetailsProps} />}
            </div>
            {loungeProductSummaryProps && isDesktop && (
              <InteractiveSummaryLayout>
                <LoungeInteractiveProductSummary {...loungeProductSummaryProps} />
              </InteractiveSummaryLayout>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ProductDetailsPage: React.FC = () => {
  // get the current lounge, either from deeplink or from search results
  const { isPageReady, currentLounge } = useLoungeProductDetailsPage();

  return (
    <>
      {isPageReady && currentLounge ? (
        <LoungeDetailsContent currentLounge={currentLounge} />
      ) : (
        <div className="lounges-pdp">
          <LoadingPageAnimation />
        </div>
      )}
    </>
  );
};

export default ProductDetailsPage;
