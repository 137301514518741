import { CreateBookingIntentResponse } from '@yiluhub/yilu-amp-types';

/**
 *
 * @param type -
 * @param bookingIntent  -
 */
export function getFieldsDefinitions(bookingIntent: CreateBookingIntentResponse | null) {
  if (!bookingIntent) {
    return [];
  }

  return bookingIntent.travellerInfo;
}
