/**
 * # Parking Search Results Page Hooks
 *
 *
 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import routes from 'router/routes';

import { constructParkingBacklinkProps } from 'modules/parking/utils/constructParkingBackLinkProps';

import { useParkingAirports } from '../../../hooks/useParkingAirports';
import { getParkingSearchResultsPageQueryParams } from '../../../utils/query-params';
import { ParkingCollapsibleFormProps } from '../ParkingCollapsibleForm/types';
import useParkingSearchFormProps from './useParkingSearchFormProps';
import { useParkingSearchResultsProps } from './useParkingSearchResultsProps';
import useSetAirportDetails from './useSetAirportDetails';

export const useParkingSearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parkingSearchResultsPageQueryParams = useMemo(() => {
    return getParkingSearchResultsPageQueryParams(location);
  }, [location]);

  const isPageReady =
    Boolean(parkingSearchResultsPageQueryParams.airportDetails) ||
    !parkingSearchResultsPageQueryParams.airportIataCode;

  enum TouchpointId {
    BKCO = 'booking', // booking confirmation page
    BKCO_RB = 'r_bkco', // rebooking confirmation page
    MMB = 'mmb', // manage my booking page
    MODIFLY = 'modifly',
    YILU = 'yilu_internal',
    OTHER = 'other',
  }

  const touchpointsWithBufferWarning = [
    TouchpointId.BKCO,
    TouchpointId.MMB,
    TouchpointId.MODIFLY,
    TouchpointId.YILU,
    TouchpointId.OTHER,
  ];

  const showBufferWarning =
    touchpointsWithBufferWarning.includes(
      parkingSearchResultsPageQueryParams.touchpoint as TouchpointId,
    ) && Boolean(parkingSearchResultsPageQueryParams.entryTime);

  const parkingAirports = useParkingAirports();

  useSetAirportDetails(
    isPageReady,
    parkingSearchResultsPageQueryParams,
    parkingAirports.allAirports,
  );

  const parkingSearchFormProps = useParkingSearchFormProps(
    parkingSearchResultsPageQueryParams,
    parkingAirports.allAirports,
    parkingAirports.popularAirportIATAs,
  );

  const collapsibleParkingFormProps = useMemo(() => {
    const _props: ParkingCollapsibleFormProps = {
      ...parkingSearchFormProps,
      showCloseButton: true,
    };
    return _props;
  }, [parkingSearchFormProps]);

  const parkingSearchResultsProps = useParkingSearchResultsProps(
    parkingSearchResultsPageQueryParams,
  );

  const iata = parkingSearchResultsPageQueryParams?.airportDetails?.iataCode;
  const entryDate = parkingSearchResultsPageQueryParams?.entryDate;
  const entryTime = parkingSearchResultsPageQueryParams?.entryTime;
  const exitDate = parkingSearchResultsPageQueryParams?.exitDate;
  const exitTime = parkingSearchResultsPageQueryParams?.exitTime;

  const backLinkProps = constructParkingBacklinkProps(
    t('parking.srp.backlink.label'),
    routes.PARKING_SEARCH,
    navigate,
    entryDate,
    entryTime,
    exitDate,
    exitTime,
    iata,
  );

  return {
    collapsibleParkingFormProps,
    parkingSearchFormProps,
    parkingSearchResultsProps,
    backLinkProps,
    showBufferWarning,
    isPageReady,
  };
};
