import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { getShortDayName } from '@yiluhub/ui-utilities';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { matchAmenitiesWithIcons } from 'modules/lounges/components/LoungeAmenity';
import { isHolidayExtrasAvailable } from 'modules/lounges/utils/availability';

import type { Amenities, OpeningHours } from '../../../../types';

const MAX_LOUNGE_ICONS_COUNT = 7;

export function useLoungeSearchCardProps({
  date,
  isHolidayExtras,
  onClick,
  data,
  openingHours,
  searchedDateOfWeek,
  amenities,
  thumbnailUrl,
  imageUrls,
}: {
  date: string;
  isHolidayExtras?: boolean;
  onClick: ((data: SearchItem) => unknown) | undefined;
  data: SearchItem;
  openingHours: OpeningHours[];
  searchedDateOfWeek: number;
  amenities: Amenities[];
  thumbnailUrl: string;
  imageUrls: string[];
}) {
  const { t } = useTranslation();

  const isAvailable = isHolidayExtras ? isHolidayExtrasAvailable(date) : true;

  const onClickCb = useCallback(() => {
    onClick && onClick(data);
  }, [onClick, data]);

  const loungeOpeningHours = openingHours?.[searchedDateOfWeek];

  const openingHoursText =
    loungeOpeningHours?.intervals.length > 0
      ? `${loungeOpeningHours.intervals[0].startTime}` +
        ` - ` +
        `${loungeOpeningHours.intervals[0].endTime}`
      : t('Closed');

  const availableLoungeOptions = matchAmenitiesWithIcons(amenities);

  if (availableLoungeOptions.length > MAX_LOUNGE_ICONS_COUNT) {
    availableLoungeOptions.splice(MAX_LOUNGE_ICONS_COUNT);
    // To disable three dots on tooltip name assigned as undefined
    availableLoungeOptions.push({
      name: undefined,
      icon: ElementsSDK.Icon.ThreeDots,
      rank: availableLoungeOptions.length + 1,
    });
  }

  const dayName =
    loungeOpeningHours?.day && t(`Calendar.${getShortDayName(loungeOpeningHours.day)}`);

  const imageUrl = thumbnailUrl || imageUrls?.[0];
  return { isAvailable, onClickCb, openingHoursText, availableLoungeOptions, dayName, imageUrl };
}
