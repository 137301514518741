import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';

import { ParkingProviderId } from 'modules/parking/utils/getParkingDetails';

import { ProductConsumerAttributeRequirement, ProductConsumerAttributeType } from '../../utils';

export enum PARK_AERO_BILLING_ADDRESS_FIELDS {
  STREET = 'street',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  COUNTRY = 'country',
}

export enum HOLIDAY_EXTRAS_FIELD_NAMES {
  SALUTATION = 'salutation',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ADDRESS = 'addressLine',
  TOWN = 'town',
  POST_CODE = 'postCode',
  COUNTRY = 'country',
  NUMBER_OF_PASSENGERS = 'numberOfPassengers',
  PNR_LAST_NAMES = 'pnrLastNames',
  REGISTRATION = 'vehicle.registration',
  CAR_MAKE = 'vehicle.make',
  CAR_MODEL = 'vehicle.model',
  CAR_COLOUR = 'vehicle.colour',
  OUTBOUND_FLIGHT_NUMBER = 'journeyDetails.outboundFlightNumber',
  OUTBOUND_FLIGHT_TIME = 'journeyDetails.outboundFlightTime',
  INBOUND_FLIGHT_NUMBER = 'journeyDetails.inboundFlightNumber',
  INBOUND_FLIGHT_TIME = 'journeyDetails.outboundFlightTime',
  RETURN_TERMINAL = 'RetTerminal',
  OUTBOUND_TERMINAL = 'OutTerminal',
  CAR_PICKUP_TIME = 'CarPickupTime',
  CAR_DROP_OFF_TIME = 'CarDropoffTime',
  SHIP_NAME = 'ShipName',
  PIER_NAME = 'PierName',
  CHILD_SEAT = 'ChildSeat',
  ADDITIONAL_SERVICES = 'AddlServices',
}

export const HOLIDAY_EXTRAS_BILLING_ADDRESS_FIELDS = [
  HOLIDAY_EXTRAS_FIELD_NAMES.ADDRESS,
  HOLIDAY_EXTRAS_FIELD_NAMES.POST_CODE,
  HOLIDAY_EXTRAS_FIELD_NAMES.TOWN,
  HOLIDAY_EXTRAS_FIELD_NAMES.COUNTRY,
];

/**
 * NOTE: ANY ADDITION TO THIS LIST SHOULD ALSO BE ADDED TO THE TRANSLATIONS FOR FIELD LABELS
 */
const expectedSortOrder = [
  'company',
  'salutation',
  'title',
  'customer.givenNames',
  'firstName',
  'customer.familyName',
  'lastName',
  'customer.email',
  'email',
  HOLIDAY_EXTRAS_FIELD_NAMES.NUMBER_OF_PASSENGERS,
  'customer.diallingCode',
  'customer.telephone',
  'customer.mobile',
  'vehicle.registration',
  HOLIDAY_EXTRAS_FIELD_NAMES.ADDRESS,
  'customer.address',
  'street',
  'customer.town',
  'city',
  HOLIDAY_EXTRAS_FIELD_NAMES.TOWN,
  'customer.postcode',
  'zipCode',
  HOLIDAY_EXTRAS_FIELD_NAMES.POST_CODE,
  'customer.county',
  'country',
  'loyaltyId',
  'customer.passportNumber',
  'customer.specialRequest',
  'customer.invoiceRequested',
  'customer.companyName',
  'customer.vatNumber',
  'vehicle.make',
  'vehicle.model',
  'vehicle.colour',
  HOLIDAY_EXTRAS_FIELD_NAMES.CHILD_SEAT,
  HOLIDAY_EXTRAS_FIELD_NAMES.CAR_PICKUP_TIME,
  HOLIDAY_EXTRAS_FIELD_NAMES.CAR_DROP_OFF_TIME,
  'journeyDetails.outboundFlightNumber',
  'journeyDetails.outboundFlightTime',
  HOLIDAY_EXTRAS_FIELD_NAMES.OUTBOUND_TERMINAL,
  'journeyDetails.returnFlightNumber',
  'journeyDetails.returnFlightTime',
  HOLIDAY_EXTRAS_FIELD_NAMES.RETURN_TERMINAL,
  'journeyDetails.returningFrom',
  HOLIDAY_EXTRAS_FIELD_NAMES.SHIP_NAME,
  HOLIDAY_EXTRAS_FIELD_NAMES.PIER_NAME,
  HOLIDAY_EXTRAS_FIELD_NAMES.ADDITIONAL_SERVICES,
];

/**
 *  Sort the fields as per the expected ordered array.
 *  NOTE: filter InfoFieldType.COUNTRY as a hack for ParkAero ( we set it manual on submit )
 * @param fields
 */
export const getParkingFieldsToRender = (
  fields: ProductConsumerAttributeDto[],
  providerId?: string,
) => {
  const requiredFields = fields.filter((field) => {
    if (
      providerId === ParkingProviderId.PARK_AERO &&
      (field.name === 'salutation' ||
        field.name === 'company' ||
        field.name === PARK_AERO_BILLING_ADDRESS_FIELDS.COUNTRY ||
        field.name === 'loyaltyId')
    ) {
      return true;
    }

    if (
      providerId === ParkingProviderId.HOLIDAY_EXTRAS &&
      (field.name === HOLIDAY_EXTRAS_FIELD_NAMES.ADDRESS ||
        field.name === HOLIDAY_EXTRAS_FIELD_NAMES.TOWN ||
        field.name === HOLIDAY_EXTRAS_FIELD_NAMES.POST_CODE ||
        field.name === HOLIDAY_EXTRAS_FIELD_NAMES.COUNTRY)
    ) {
      return true;
    }

    return (
      field.requirement === ProductConsumerAttributeRequirement.MANDATORY &&
      field.type !== ProductConsumerAttributeType.COUNTRY
    );
  });

  return requiredFields.sort((a, b) => {
    return expectedSortOrder.indexOf(a.name) - expectedSortOrder.indexOf(b.name);
  });
};

export const getParkingSalutationValidationRules = (field: ProductConsumerAttributeDto) => {
  if (field.name === 'salutation') {
    return { required: 'tip.validation.required' };
  }
  return {
    required:
      field.requirement === 'OPTIONAL' ? 'tip.validation.required' : 'tip.validation.optional',
  };
};
