const SESSION_CONSENT_KEY = 'consentPopupDisplayed';
const PARAMS_TO_BE_REMOVED = 'paramsToBeRemoved';

export const getIsConsentPopupDisplayedOnce = () =>
  window.sessionStorage.getItem(SESSION_CONSENT_KEY);

export const setIsConsentPopupDisplayedOnce = () => {
  window.sessionStorage.setItem(SESSION_CONSENT_KEY, 'true');
};

export const setParamsToBeRemoved = (params: string[]) => {
  window.sessionStorage.setItem(PARAMS_TO_BE_REMOVED, JSON.stringify(params));
};

export const getParamsToBeRemoved = () => {
  return window.sessionStorage.getItem(PARAMS_TO_BE_REMOVED);
};
