import { useEffect } from 'react';

import { sendGAEvent } from 'utils/tracking';

const useTracking = (isPageReady: boolean) => {
  useEffect(() => {
    if (!isPageReady) {
      return;
    }
    sendGAEvent({ event: 'page_loaded', category: 'lounge-tip', label: 'lounge_tip_page_loaded' });
  }, [isPageReady]);
};

export default useTracking;
