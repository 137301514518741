/**
 * # Lounge Icons Matcher
 * # Ranks are based on the https://yiluts.atlassian.net/browse/AHR-136
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import type { Amenities } from '../../types';

export type AmenityWithIcon = {
  name: string | undefined;
  icon: React.FC;
  rank: number;
};

const matchAmenitiesWithIcons = (amenities: Amenities[]) => {
  const matchedAmenities = amenities.reduce((acc: AmenityWithIcon[], val: Amenities) => {
    if (val.exists) {
      switch (val.name) {
        case 'airConditioning':
          return [
            ...acc,
            { name: 'Air Conditioning', icon: ElementsSDK.Icon.AirConditioning, rank: 0 },
          ];
        case 'foodSnacks':
          return [...acc, { name: 'Food & Snacks', icon: ElementsSDK.Icon.Food, rank: 0 }];
        case 'alcoholicDrinks':
          return [...acc, { name: 'Alcoholic Drinks', icon: ElementsSDK.Icon.Wine, rank: 1 }];
        case 'showers':
          return [...acc, { name: 'Showers', icon: ElementsSDK.Icon.Shower, rank: 2 }];
        case 'relaxingRooms':
          return [...acc, { name: 'Relaxing rooms', icon: ElementsSDK.Icon.RelaxingRoom, rank: 3 }];
        case 'meetingRooms':
          return [...acc, { name: 'Meeting rooms', icon: ElementsSDK.Icon.MeetingRoom, rank: 4 }];
        case 'workingArea':
          return [...acc, { name: 'Working area', icon: ElementsSDK.Icon.Laptop, rank: 5 }];
        case 'accessibility':
          return [...acc, { name: 'Accessibility', icon: ElementsSDK.Icon.WheelChair, rank: 6 }];
        case 'printer':
          return [...acc, { name: 'Printer', icon: ElementsSDK.Icon.Printer, rank: 7 }];
        case 'roofTerrace':
          return [...acc, { name: 'Roof terrace', icon: ElementsSDK.Icon.RoofTerrace, rank: 8 }];
        case 'spa':
          return [...acc, { name: 'Spa', icon: ElementsSDK.Icon.RelaxingRoom, rank: 9 }];
        case 'officeUnits':
          return [...acc, { name: 'Office units', icon: ElementsSDK.Icon.DeskUnit, rank: 10 }];
        case 'lockers':
          return [...acc, { name: 'Lockers', icon: ElementsSDK.Icon.Lock, rank: 11 }];
        case 'magazinesAndNews':
          return [
            ...acc,
            { name: 'Magazines and news', icon: ElementsSDK.Icon.Newspaper, rank: 12 },
          ];
        case 'tv':
          return [...acc, { name: 'Television', icon: ElementsSDK.Icon.Tv, rank: 13 }];
        case 'restrooms':
          return [...acc, { name: 'Restrooms', icon: ElementsSDK.Icon.Restrooms, rank: 14 }];
        case 'wlanFacility':
          return [...acc, { name: 'Wireless network', icon: ElementsSDK.Icon.Wifi, rank: 15 }];
        case 'dataPortForLaptops':
          return [
            ...acc,
            { name: 'Data port for laptops', icon: ElementsSDK.Icon.ElectricalServices, rank: 16 },
          ];
        case 'smokingLounge':
          return [...acc, { name: 'Smoking room', icon: ElementsSDK.Icon.SmokeArea, rank: 17 }];
        case 'usbSockets':
          return [...acc, { name: 'Usb Sockets', icon: ElementsSDK.Icon.Usb, rank: 18 }];
        case 'wirelessCharging':
          return [
            ...acc,
            { name: 'Wireless charging', icon: ElementsSDK.Icon.InductiveCharging, rank: 19 },
          ];
        case 'copyMachine':
          return [...acc, { name: 'Copy machine', icon: ElementsSDK.Icon.Printer, rank: 20 }];
        case 'faxMachine':
          return [...acc, { name: 'Fax machine', icon: ElementsSDK.Icon.Printer, rank: 21 }];
        case 'freeLocalTelephoneCalls':
          return [
            ...acc,
            { name: 'Free local telephone calls', icon: ElementsSDK.Icon.Phone, rank: 22 },
          ];
        case 'creditCardTelephone':
          return [
            ...acc,
            { name: 'Credit card telephone', icon: ElementsSDK.Icon.Phone, rank: 23 },
          ];
        case 'germanMagazines':
          return [
            ...acc,
            { name: 'German newspapers', icon: ElementsSDK.Icon.Newspaper, rank: 24 },
          ];
        case 'internationalMagazines':
          return [
            ...acc,
            { name: 'International magazines', icon: ElementsSDK.Icon.Newspaper, rank: 25 },
          ];
        case 'pcWithInternetAccess':
          return [
            ...acc,
            { name: 'PC with internet access', icon: ElementsSDK.Icon.Laptop, rank: 26 },
          ];
        case 'usStandardSockets':
          return [...acc, { name: 'US standard sockets', icon: ElementsSDK.Icon.Outlet, rank: 27 }];
        case 'ukStandardSockets':
          return [...acc, { name: 'UK standard sockets', icon: ElementsSDK.Icon.Outlet, rank: 28 }];
        case 'euStandardSockets':
          return [...acc, { name: 'EU standard sockets', icon: ElementsSDK.Icon.Outlet, rank: 29 }];
        default:
          return acc;
      }
    }
    return acc;
  }, []);
  return matchedAmenities.sort((x: { rank: number }, y: { rank: number }) => x.rank - y.rank);
};

export { matchAmenitiesWithIcons };
