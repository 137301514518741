import { Airport } from '@yiluhub/yilu-amp-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { AirportTransferSearchInputPageQueryParams } from '../../../utils/query-params';

const useSetAirportDetails = (
  isPageReady: boolean,
  airportTransferSearchInputPageQueryParams: AirportTransferSearchInputPageQueryParams,
  airportTransferAirports: Airport[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const nextAirportTransferSearchInputPageQueryParams = {
      ...airportTransferSearchInputPageQueryParams,
    };
    const airportIataCode = airportTransferSearchInputPageQueryParams.airportIataCode;

    if (airportTransferAirports && airportTransferAirports.length > 0 && airportIataCode) {
      const deepLinkedAirportStation = airportTransferAirports.find(
        (airportStation) =>
          airportStation.iataCode.toLowerCase() === airportIataCode?.toLowerCase() ||
          airportStation.iataCode.toLowerCase() === airportIataCode?.toLowerCase(),
      );

      if (deepLinkedAirportStation && deepLinkedAirportStation.localizations) {
        nextAirportTransferSearchInputPageQueryParams.airportDetails = {
          localizations: {
            de: deepLinkedAirportStation.localizations!.de,
            en: deepLinkedAirportStation.localizations!.en,
          },
          iataCode: deepLinkedAirportStation.iataCode,
        };
      }
      delete nextAirportTransferSearchInputPageQueryParams.airportIataCode;

      navigate(
        {
          pathname: routes.AIRPORT_TRANSFER_SEARCH,
          search: getURLSearchQuery(nextAirportTransferSearchInputPageQueryParams),
        },
        { replace: true },
      );
    }
  }, [isPageReady, navigate, airportTransferAirports, airportTransferSearchInputPageQueryParams]);
};

export default useSetAirportDetails;
