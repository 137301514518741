import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

import './styles.scss';

export type BookingProviderInfoProps = {
  logoUrl?: string;
  name?: string;
  className?: string;
};

export const BookingProviderInfo: React.FC<BookingProviderInfoProps> = ({
  logoUrl,
  name,
  className,
}) => {
  const componentClass = 'yilu-BookingProviderInfo';
  const classes = clsx(componentClass, className);

  return (
    <section data-testid={componentClass} className={classes}>
      <div className={`${componentClass}__inner`}>
        {logoUrl && (
          <img className={`${componentClass}__img-logo`} src={logoUrl} alt={'provider logo'} />
        )}
        {name && <ElementsSDK.Typography variant="h4">{name}</ElementsSDK.Typography>}
      </div>
    </section>
  );
};
