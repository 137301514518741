import { TransferDirection } from 'applicationConstants';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import { PRODUCT_ROUTE } from 'router/routes';

import { GlobalConfigKeys, TrackingParam, globalConfigFields } from 'widgets/GlobalDefaults';

export type RequiredQueryParams<T> = Omit<T, GlobalConfigKeys>;

export type CommonQueryParams = Record<GlobalConfigKeys, string | TrackingParam>;

/**
 * Retrieve common query parameters which are used across the application.
 *
 * @param location - location descriptor
 *
 * @returns Common query parameters
 */
export function getCommonQueryParams(location: Location = window.location as any) {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = {} as CommonQueryParams;
  globalConfigFields.forEach((field) => {
    commonQueryParams[field] = queryParams[field];
  });

  return commonQueryParams;
}

/**
 * Returns the product from the URL address
 *
 * @param pathname - current pathname
 */
export function getProductFromUrl(pathname: string): string | undefined {
  return Object.values(PRODUCT_ROUTE)
    .sort((a, b) => b.length - a.length)
    .find((product) => pathname.match(new RegExp(product)));
}

/**
 * Returns the query parameters for the  Product Detail page.
 *
 * @param location - location descriptor
 */
export const getProductDetailPageQueryParams = (location: Location = window.location as any) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const productDetailPageParams = {
    searchResultID: queryParams.searchResultID,
    transferTime: queryParams.transferTime,
    transferDate: queryParams.transferDate,
    loungeId: queryParams.loungeId,
    singleProduct: queryParams.singleProduct,
  };

  return {
    ...commonQueryParams,
    ...productDetailPageParams,
  };
};

/**
 * Returns the query parameters for the Booking Confirmation page.
 *
 * @param location - location descriptor
 */
export const getBookingConfirmationPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const bookingConfirmationPageParams = {
    bookingId: queryParams.bookingId,
  };

  return {
    ...commonQueryParams,
    ...bookingConfirmationPageParams,
  };
};

export const parseTravellersCount = (
  travellersCount: number | undefined,
  minCount: number = 1,
  maxCount: number,
) => {
  if (travellersCount) {
    const count = Math.min(travellersCount, maxCount) || minCount;
    return count < minCount ? minCount : count;
  }
  return minCount;
};

/**
 * Create a URL search query based on a provided input object.
 *
 * @param queryParams - input object
 *
 * @returns URL Search query
 */
export const getURLSearchQuery = (queryParams: any) => {
  const commonQueryParams = getCommonQueryParams();
  const combinedQueryParams = {
    ...commonQueryParams,
    ...queryParams,
  };

  const valuedQueryParams = getValuedObject(combinedQueryParams);

  return valuedQueryParams ? `?${querystring.stringify(valuedQueryParams)}` : '';
};

/**
 *
 */
export function getValuedObject(obj: Record<string, string | number | undefined>): Object | null {
  const objectKeys = Object.keys(obj);
  const valuedObjectKeys = objectKeys.filter((key: string) => {
    const value = obj[key];
    return value !== undefined && value !== '';
  });

  if (!valuedObjectKeys.length) {
    return null;
  }

  return valuedObjectKeys.reduce((queryParamKeyValues: any, key: string) => {
    queryParamKeyValues[key] = obj[key];
    return queryParamKeyValues;
  }, {});
}

/**
 *
 * @param search - query string with parameters
 */
export function omitExternalQueryParameters(search: string) {
  const externalParameters = ['userId', 'loyaltyProgramId'];

  const queryParams: any = querystring.parse(search, {
    ignoreQueryPrefix: true,
  });

  externalParameters.forEach((parameter) => {
    if (queryParams[parameter]) {
      delete queryParams[parameter];
    }
  });

  const queryParamsCount = Object.keys(queryParams).length;

  return queryParamsCount > 0 ? `?${querystring.stringify(queryParams)}` : '';
}

export const parseDirection = (
  direction: TransferDirection | undefined,
  defaultDirection: TransferDirection,
) => {
  if (![TransferDirection.FROM_AIRPORT, TransferDirection.TO_AIRPORT].includes(direction!)) {
    return defaultDirection;
  }
  return direction!;
};
