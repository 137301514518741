import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import routes from 'router/routes';

import { LoungeSearchFormProps } from '../../../components';
import { LoungeSearchInputPageQueryParams } from '../../../utils/query-params';

const useLoungeSearchFormProps = (
  loungeSearchInputPageQueryParams: LoungeSearchInputPageQueryParams,
  loungeAirports: ElementsSDK.FilteredAirportDetails[],
  popularAirportsIATAs?: string[],
) => {
  const navigate = useNavigate();
  const loungeSearchFormProps = useMemo(() => {
    const { entryDate, airportDetails, adults, children, infants } =
      loungeSearchInputPageQueryParams;
    const _props: LoungeSearchFormProps = {
      searchInput: {
        entryDate,
        airport: airportDetails,
        adults,
        children,
        infants,
      },
      onSubmit(data) {
        const nextLoungeSearchResultsPageQueryParams = {
          adults: data.adults,
          children: data.children,
          infants: data.infants,
          entryDate: data.entryDate,
          airportDetails: data.airport,
        };

        navigate({
          pathname: routes.LOUNGES_SEARCH_RESULTS,
          search: paramConverters.getURLSearchQuery(nextLoungeSearchResultsPageQueryParams),
        });

        yiluTracking.sendGAEvent({
          event: 'sip_cta_clicked',
          category: 'lounges-sip',
          label: 'lounges_sip_cta_clicked',
        });
      },
      onError(error: Error) {
        throw error;
      },
      popularAirportsIATAs: popularAirportsIATAs,
      loungeAirports: loungeAirports,
    };
    return _props;
  }, [loungeSearchInputPageQueryParams, navigate, loungeAirports, popularAirportsIATAs]);

  return loungeSearchFormProps;
};

export default useLoungeSearchFormProps;
