import { getYiluCaches, setYiluCaches } from '@yiluhub/ui-utilities';
import { Airport, GetLocationsByStoreIdResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { airportUtils, yiluEnv } from 'utils';

import { PARKING_POPULAR_AIRPORTS_PRIORITIZED } from '../utils/constants';

const POPULAR_AIRPORTS_NUMBER = 3;

export const useParkingAirports = () => {
  const PARKING_AIRPORTS_ARRAY_CACHE_KEY = 'parkingAirports';
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_PARKING_VERTICAL_ID } =
    yiluEnv.getVariables();
  const [network, setNetwork] = useState({ isFetching: false, isFetched: false });

  const [allAirports, setParkingAirports] = useState(
    getYiluCaches(PARKING_AIRPORTS_ARRAY_CACHE_KEY) as unknown as Array<Airport>,
  );

  const [popularAirportIATAs, setPopularAirportIATAs] = useState<string[] | undefined>(undefined);

  const fetchAllParkingAirports = useCallback(async () => {
    try {
      setNetwork({ isFetching: true, isFetched: false });

      const response = await axios.get<GetLocationsByStoreIdResponse>(
        `${YILU_AMP_BACKEND_URL}/store/v1/stores/${YILU_AMP_STORE_ID}/locations`,
        { params: { verticalId: YILU_AMP_PARKING_VERTICAL_ID } },
      );

      const airportList = response.data?.map((element) => element.airport);

      setParkingAirports(airportList);
      setYiluCaches(PARKING_AIRPORTS_ARRAY_CACHE_KEY, airportList);
      setPopularAirportIATAs(
        airportUtils.prioritizeAirports(
          airportList,
          PARKING_POPULAR_AIRPORTS_PRIORITIZED,
          POPULAR_AIRPORTS_NUMBER,
        ),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setNetwork({ isFetching: false, isFetched: true });
    }
  }, [YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_PARKING_VERTICAL_ID]);

  useEffect(() => {
    if (!network.isFetched && !network.isFetching) {
      void fetchAllParkingAirports();
    } else if (Object.keys(allAirports).length !== 0 && !popularAirportIATAs) {
      setPopularAirportIATAs(
        airportUtils.prioritizeAirports(
          allAirports,
          PARKING_POPULAR_AIRPORTS_PRIORITIZED,
          POPULAR_AIRPORTS_NUMBER,
        ),
      );
    }
  }, [network, allAirports, popularAirportIATAs, fetchAllParkingAirports]);

  return { allAirports, popularAirportIATAs };
};
