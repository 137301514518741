import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { TransferDirection } from 'applicationConstants';
import dayjs from 'dayjs';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import {
  CommonQueryParams,
  getCommonQueryParams,
  parseDirection,
  parseTravellersCount,
} from 'utils/paramConverters';

import { TransferType } from './constants';

export type AirportTransferSearchInputPageQueryParams = CommonQueryParams & {
  travellersCount: number;
  direction: TransferDirection;
  transferType: TransferType;
  transferDate: string;
  transferTime: string;
  location?: string;
  airportDetails?: ElementsSDK.FilteredAirportDetails;
  airportIataCode?: string;
  carrier?: string;
  stationId?: string;
  currency?: string;
};

export type SwissTravelSearchInputPageQueryParams = CommonQueryParams & {
  duration?: number;
  classfare?: number;
  passengercount?: number;
  date?: string;
};

const DEFAULT_INITIAL_DIRECTION = TransferDirection.FROM_AIRPORT;
const DEFAULT_INITIAL_TRANSFER_TYPE = TransferType.DEPARTURE;
const DEFAULT_MAX_TRAVELLERS_COUNT = 9;
const DEFAULT_TRAVELLERS_COUNT = 1;
const DEFAULT_INITIAL_TIME_OFFSET_VALUE = 3;
const DEFAULT_INITIAL_TIME_OFFSET_UNIT = 'hour';

export const parseTransferType = (
  transferType: TransferType | undefined,
  defaultTransferType: TransferType = TransferType.DEPARTURE,
) => {
  if (![TransferType.DEPARTURE, TransferType.ARRIVAL].includes(transferType!)) {
    return defaultTransferType;
  }
  return transferType!;
};

/**
 * Returns the query parameters for the Airport Transfer Search Input page.
 *
 * @param location - location descriptor
 */
export const getAirportTransferSearchInputPageQueryParams = (
  location: Location = window.location as any,
): AirportTransferSearchInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const commonQueryParams = getCommonQueryParams();
  const searchInputAirportTransferParams = {
    location: queryParams.location,
    direction: parseDirection(queryParams.direction, DEFAULT_INITIAL_DIRECTION),
    transferType: parseTransferType(queryParams.transferType, DEFAULT_INITIAL_TRANSFER_TYPE),
    transferDate: queryParams.transferDate || dayjs().format(UiUtilities.DateFormat.SHORT_DATE),
    transferTime:
      queryParams.transferTime ||
      dayjs()
        .add(DEFAULT_INITIAL_TIME_OFFSET_VALUE, DEFAULT_INITIAL_TIME_OFFSET_UNIT)
        .format(UiUtilities.DateFormat.TIME),
    travellersCount: parseTravellersCount(
      queryParams.travellersCount,
      DEFAULT_TRAVELLERS_COUNT,
      DEFAULT_MAX_TRAVELLERS_COUNT,
    ),
    airportDetails: queryParams.airportDetails,
    airportIataCode: queryParams.airportDetails ? undefined : queryParams.airportIataCode,
    carrier: queryParams.carrier,
    stationId: queryParams.stationId,
    currency: queryParams.currency && queryParams.currency !== '' ? queryParams.currency : 'EUR',
  };

  if (!searchInputAirportTransferParams.airportDetails) {
    delete searchInputAirportTransferParams.airportDetails;
  }

  if (!searchInputAirportTransferParams.airportIataCode) {
    delete searchInputAirportTransferParams.airportIataCode;
  }

  return {
    ...commonQueryParams,
    ...searchInputAirportTransferParams,
  };
};

export type AirportTransferSearchResultsPageQueryParams = AirportTransferSearchInputPageQueryParams;

/**
 * Returns the query parameters for the Airport Transfer Search Results page.
 *
 * @param location - location descriptor
 */
export const getAirportTransferSearchResultsPageQueryParams = (
  location: Location = window.location as any,
): AirportTransferSearchResultsPageQueryParams => {
  const airportTransferSearchResultsPageQueryParams =
    getAirportTransferSearchInputPageQueryParams(location);

  return airportTransferSearchResultsPageQueryParams;
};

export type AirportTransferProductDetailsPageQueryParams = CommonQueryParams & {
  searchResultID: string;
};

/**
 * Returns the query parameters for the Airport Transfer Product Details page.
 *
 * @param location - location descriptor
 */
export const getAirportTransferProductDetailsPageQueryParams = (
  location: Location = window.location as any,
): AirportTransferProductDetailsPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const productDetailsParams = {
    searchResultID: queryParams.searchResultID,
  };

  return {
    ...commonQueryParams,
    ...productDetailsParams,
  };
};

export type AirportTransferTravellerInputPageQueryParams =
  AirportTransferProductDetailsPageQueryParams;

/**
 * Returns the query parameters for the Airport Transfer Traveller Information page.
 *
 * @param location - location descriptor
 */
export const getAirportTransferTravellerInputPageQueryParams = (
  location: Location = window.location as any,
): AirportTransferTravellerInputPageQueryParams => {
  return getAirportTransferProductDetailsPageQueryParams(location);
};

/**
 * Returns the query parameters for the AirportTransfer Payment Input Page.
 *
 * @param location - location descriptor
 */
export const getAirportTransferPaymentInputPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const paymentInputPageParams = {
    searchResultID: queryParams.searchResultID,
    bookingIntentId: queryParams.bookingIntentId,
    shoppingCartId: queryParams.shoppingCartId,
  };

  return {
    ...commonQueryParams,
    ...paymentInputPageParams,
  };
};
