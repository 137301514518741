/**
 * # Search Result Card - Airport Transfer
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { formatUtils } from 'utils';

import { SearchResultCard } from 'components';

import { getAirportTransferProductData } from 'modules/airport-transfer/utils/getAmpAirportTransferData';

import { formatInterconnections } from '../../../utils';
import './styles.scss';

export type AirportTransferSearchResultCardProps = {
  data: SearchItem;
  onClick?(data: SearchItem): unknown;
  cardRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>> | undefined;
};

export const AirportTransferSearchResultCard: React.FC<AirportTransferSearchResultCardProps> = ({
  data,
  onClick,
  cardRef,
}) => {
  const { t } = useTranslation();
  const {
    currency,
    price,
    carrierLogoUrl,
    departureDateTime,
    arrivalDateTime,
    origin,
    destination,
    interconnectionTransfersCount,
  } = getAirportTransferProductData(data);

  const onClickCb = useCallback(() => {
    onClick && onClick(data);
  }, [onClick, data]);

  const departureTimezone = tzlookup(origin?.lat as number, origin?.long as number);
  const arrivalTimezone = tzlookup(destination?.lat as number, destination?.long as number);

  const transfersText = formatInterconnections(t, interconnectionTransfersCount);
  const formattedDuration = formatUtils.formatDuration(t, departureDateTime, arrivalDateTime);

  // eslint-disable @typescript-eslint/no-non-null-assertion
  return (
    <SearchResultCard
      onClick={onClickCb}
      className="yilu-SearchResultItem__AirportTransfer"
      ref={cardRef}
    >
      <div className="yilu-AirportTransfer__Content">
        <div className="yilu-AirportTransfer__DepartureOrigin">
          <div className="yilu-AirportTransfer__DepartureWrapper">
            <ElementsSDK.Typography font="bold" size="large" aria-label={t('Bus departure time')}>
              {UiUtilities.formatLocalDate(departureDateTime, 'HH:mm', departureTimezone)}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography
              size="medium"
              aria-label={t('Bus duration')}
              className="yilu-AirportTransfer__Duration"
            >
              {formattedDuration} ({transfersText})
            </ElementsSDK.Typography>
          </div>
          <ElementsSDK.Typography size="medium" className="yilu-AirportTransfer__Origin">
            {origin?.name}
          </ElementsSDK.Typography>
        </div>
        <div className="yilu-AirportTransfer__ArrivalDestination">
          <ElementsSDK.Typography
            font="bold"
            size="large"
            className="yilu-AirportTransfer__ArrivalTime"
            aria-label={t('Bus arrival time')}
          >
            {UiUtilities.formatLocalDate(arrivalDateTime, 'HH:mm', arrivalTimezone)}
          </ElementsSDK.Typography>
          <ElementsSDK.Typography size="medium">{destination?.name}</ElementsSDK.Typography>
        </div>
      </div>
      <section className="yilu-SearchResultItem__AirportTransfer__Footer">
        <div className="yilu-SearchResultItem__AirportTransfer__ProviderInfo">
          {carrierLogoUrl && (
            <img
              src={carrierLogoUrl}
              alt="Provider logo"
              className="yilu-SearchResultItem__AirportTransfer__ProviderLogo"
            />
          )}
        </div>
        <div className="yilu-SearchResultItem__AirportTransfer__Price">
          <span
            className="yilu-SearchResultItem__AirportTransfer__NewPrice"
            aria-label={t('Price')}
          >
            <ElementsSDK.Typography
              size="extraSmall"
              className="yilu-SearchResultItem__AirportTransfer__NewPriceText"
            >
              {t('From')}{' '}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography variant="h3" inline>
              {UiUtilities.formatCurrency(price, currency)}
            </ElementsSDK.Typography>
          </span>
        </div>
        <ElementsSDK.Icon.ArrowRightChevron className="yilu-SearchResultItem__AirportTransfer__RightArrow" />
      </section>
    </SearchResultCard>
  );
};
