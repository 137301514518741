import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_LOUNGE_GUESTS,
  MAX_CHILDREN_LOUNGE_GUESTS,
  MAX_LOUNGE_GUESTS,
} from 'modules/lounges/utils/constants';

export type TravellersCount = {
  adults: number;
  children: number;
  infants: number;
};

export enum TravellerIncrementFields {
  adults = 'adults',
  children = 'children',
  infants = 'infants',
}

export type TravellerSelector = ElementsSDK.IncrementProps & {
  id: string;
};

export function useTravellersSelector(travellersCount: TravellersCount) {
  const { t } = useTranslation();

  const travellerIncrements: TravellerSelector[] = [
    {
      id: TravellerIncrementFields.adults,
      label: t('Adults (12+)'),
      value: travellersCount.adults,
      min: DEFAULT_LOUNGE_GUESTS,
      max: MAX_LOUNGE_GUESTS,
      onChange() {},
    },
    {
      id: TravellerIncrementFields.children,
      label: t('Children (2-11)'),
      value: travellersCount.children,
      min: 0,
      max: MAX_CHILDREN_LOUNGE_GUESTS,
      onChange() {},
      tooltip: t('Lounges.ChildrenTooltip'),
    },
    {
      id: TravellerIncrementFields.infants,
      label: t('Infants (0-1)'),
      value: travellersCount.infants,
      min: 0,
      max: MAX_CHILDREN_LOUNGE_GUESTS,
      onChange() {},
    },
  ];

  return {
    travellerIncrements,
  };
}
