import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const Tagline: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="tagline-wrapper">
      <ElementsSDK.Typography size="extraSmall" className="tagline-text">
        {t('Collaboration for ground transport')}
      </ElementsSDK.Typography>
    </div>
  );
};

export default Tagline;
