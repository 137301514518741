/**
 * # Rides Search Results
 *
 *
 */
import React from 'react';

import { SearchResultCard, SearchResultNotFound } from 'components';

import { RidesSearchResultCard } from '../RidesSearchResultCard';
import { useRidesSearchResultsLoader } from './hooks';
import { RidesSearchResultsProps } from './types';

const PLACEHOLDER_COUNT = 9;

export const RidesSearchResults: React.FC<RidesSearchResultsProps> = ({
  searchParams,
  onSearchResults,
  onSearchResultClick,
  onError,
  pageSize,
}) => {
  const { isLoading, pageNumber, totalPages, setLastElement, searchResults } =
    useRidesSearchResultsLoader({
      searchParams,
      onSearchResults,
      onError,
      pageSize,
    });

  return (
    <>
      {!isLoading && !searchResults.length && <SearchResultNotFound />}
      {isLoading &&
        pageNumber === 0 &&
        Array(PLACEHOLDER_COUNT)
          .fill(0)
          .map((_, index) => <SearchResultCard asPlaceholder key={index} />)}
      <section>
        {searchResults.map((searchResult, index) => {
          const isLastElement =
            index === searchResults.length - 1 && !isLoading && pageNumber < totalPages - 1;
          return (
            <RidesSearchResultCard
              cardRef={isLastElement ? setLastElement : undefined}
              key={searchResult.id}
              data={searchResult}
              onClick={onSearchResultClick}
            />
          );
        })}
        {isLoading && pageNumber !== 0 && <SearchResultCard asPlaceholder />}
      </section>
    </>
  );
};
