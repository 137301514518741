export interface FormError {
  hasError: boolean;
  name: string | null;
  message: string;
  type?: string;
}

export enum FormErrorType {
  API = 'API',
}
