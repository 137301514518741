import React, { FC, useCallback } from 'react';

import { PaymentMethods } from 'context/PaymentInput/context';

import { onPaymentMethodClickTracking } from 'components/PaymentInformation/utils/tracking';

import { PaymentMethod } from '../PaymentMethod';
import './styles.scss';

type PaymentMethodsProps = {
  currentPaymentMethod?: PaymentMethods;
  paymentMethods?: PaymentMethods[];
  onSelect: (type: PaymentMethods) => void;
  productTrackingId: string;
};

export const PaymentMethodsSelector: FC<PaymentMethodsProps> = ({
  paymentMethods,
  currentPaymentMethod,
  onSelect,
  productTrackingId,
}) => {
  const onMethodSelect = useCallback(
    (paymentMethod: PaymentMethods) => {
      onPaymentMethodClickTracking(paymentMethod, productTrackingId);
      onSelect(paymentMethod);
    },
    [onSelect, productTrackingId],
  );

  const PaymentMethodComponents = paymentMethods?.map((paymentMethod, key) => {
    return (
      <div key={key}>
        <PaymentMethod
          type={paymentMethod}
          isSelected={currentPaymentMethod === paymentMethod}
          onSelect={onMethodSelect}
          hasRadio={paymentMethods.length > 1}
        />
        {key + 1 !== paymentMethods.length && <span className="yilu-payment__Separator" />}
      </div>
    );
  });

  return <div className="yilu-payment-methods">{PaymentMethodComponents}</div>;
};
