import { FilteredAirportDetails } from '@yiluhub/ui-elements';
import {
  concatenateDateTime,
  convertToLocalDate,
  getYiluConfigLocale,
  handleValidateDateTimeInputError,
  isDateTimeInputValid,
  toBrowserTime,
} from '@yiluhub/ui-utilities';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { roundDateTimeDown } from '../../../../../utils/dateUtils';
import { ParkingSearchFormProps } from '../types';

const DEFAULT_PARKING_BUFFER_TIME = 90; // Minutes
const DEFAULT_PARKING_DURATION = 2; // Days

export type UseParkingSearchFormParams = Pick<
  ParkingSearchFormProps,
  'onSubmit' | 'searchInput' | 'flightTime' | 'onError'
>;

export const useParkingSearchForm = ({
  onSubmit,
  searchInput,
  flightTime,
  onError,
}: UseParkingSearchFormParams) => {
  const locale = getYiluConfigLocale();

  const {
    entryDate = null,
    entryTime = null,
    exitDate = null,
    exitTime = null,
    airportDetails = null,
  } = searchInput || {};

  let defaultEntryDateTime =
    entryTime && entryDate ? concatenateDateTime(entryDate, entryTime) : null;
  let defaultExitDateTime = exitTime && exitDate ? concatenateDateTime(exitDate, exitTime) : null;

  const ROUNDED_DOWN_MIN = 30;
  const roundedEntryDateTime =
    defaultEntryDateTime && roundDateTimeDown(defaultEntryDateTime, ROUNDED_DOWN_MIN);
  const roundedExitDateTime =
    defaultExitDateTime && roundDateTimeDown(defaultExitDateTime, ROUNDED_DOWN_MIN);

  if (roundedEntryDateTime) defaultEntryDateTime = roundedEntryDateTime;
  if (roundedExitDateTime) defaultExitDateTime = roundedExitDateTime;

  if (flightTime) {
    // Pre-fill the exit datetime if not explicitly provided,
    // but only if the entry was not also provided
    if (!entryTime && !exitTime && !entryDate && !exitDate) {
      defaultExitDateTime = toBrowserTime(
        dayjs(convertToLocalDate(flightTime))
          .add(DEFAULT_PARKING_DURATION, 'day')
          .subtract(DEFAULT_PARKING_BUFFER_TIME, 'minute')
          .toISOString(),
      );
    }

    if (!entryTime) {
      defaultEntryDateTime = toBrowserTime(
        dayjs(convertToLocalDate(flightTime))
          .subtract(DEFAULT_PARKING_BUFFER_TIME, 'minute')
          .toISOString(),
      );
    }
  }

  const [isFormValid, setIsFomValid] = useState(false);
  const [enterDateTime, setEnterDateTime] = useState<string | null>(defaultEntryDateTime);
  const [exitDateTime, setExitDateTime] = useState<string | null>(defaultExitDateTime);

  const [selectedAirportDetails, setSelectedAirportDetails] = useState(airportDetails);
  const [selectedAirportName, setSelectedAirportName] = useState<string | null>(
    airportDetails && airportDetails.localizations[locale].name,
  );

  useEffect(() => {
    // in case airportDetails come from the parent component as in deeplinking
    if (searchInput && searchInput.airportDetails) {
      setSelectedAirportDetails(searchInput.airportDetails);
      setSelectedAirportName(searchInput.airportDetails.localizations[locale].name);
    }
  }, [searchInput, locale]);

  useEffect(() => {
    if (
      !enterDateTime ||
      !exitDateTime ||
      !selectedAirportDetails ||
      !dayjs(enterDateTime).isValid() ||
      !dayjs(exitDateTime).isValid()
    ) {
      return setIsFomValid(false);
    }

    const enter = new Date(enterDateTime);
    const exit = new Date(exitDateTime);

    if (enter.getTime() >= exit.getTime()) {
      // Exit date cannot be same or after entry time
      return setIsFomValid(false);
    }

    setIsFomValid(true);
  }, [enterDateTime, exitDateTime, selectedAirportDetails]);

  const handleOnAddressSelect = useCallback(
    (airportDetails: FilteredAirportDetails) => {
      setSelectedAirportDetails(airportDetails);
      setSelectedAirportName(airportDetails.localizations[locale].name);
    },
    [locale],
  );

  const handleOnSubmitButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (isFormValid && selectedAirportDetails) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const [entryDate, entryTime] = enterDateTime!.split('T');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const [exitDate, exitTime] = exitDateTime!.split('T');
        onSubmit({
          entryDate,
          entryTime,
          exitDate,
          exitTime,
          airportDetails: selectedAirportDetails,
        });
      }
    },
    [exitDateTime, enterDateTime, onSubmit, isFormValid, selectedAirportDetails],
  );

  // Do not use the form's native submit handler, as this currently causes unexpected behaviour
  // with the date picker
  const handleOnFormSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
  }, []);

  const setDateTimeCallback = useCallback(
    (setter: (d: string | null) => void, dateTime: string | null) => {
      if (!isDateTimeInputValid(dateTime)) {
        handleValidateDateTimeInputError(onError);
      }
      setter(dateTime);
    },
    [onError],
  );

  return {
    isFormValid,
    enterDateTime,
    exitDateTime,
    selectedAirportDetails,
    selectedAirportName,
    handleOnAddressSelect,
    handleOnSubmitButtonClick,
    handleOnFormSubmit,
    setEnterDateTime: setDateTimeCallback.bind(null, setEnterDateTime),
    setExitDateTime: setDateTimeCallback.bind(null, setExitDateTime),
  };
};
