import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { CreateBookingResponse } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePaymentInputContext } from 'context/PaymentInput';

import PaymentModal from 'components/PaymentInformation/PaymentModal';

import { ErrorMessage } from '../ErrorMessage';
import { CreditCardForm } from './CreditCardForm';
import { PaymentMethodsSelector } from './PaymentMethodsSelector';
import { PaymentRequestInitializer } from './PaymentRequestInitializer';
import { SecurePayment } from './SecurePayment';
import { usePaymentInformation } from './hooks/usePaymentInformation';
import './styles.scss';

export interface PaymentInformationProps {
  ref: React.RefObject<HTMLFormElement>;
  amount: {
    price: number;
    currency: string;
  };
  stripeMerchantLabel: string;
  productTrackingId: string;
  serviceProviderId: string;
  bookingIntentId: string;
  userId: string;
  touchPointId: string;
  shoppingCartId: string;
  decisionId?: string;
  onPayment: (response: CreateBookingResponse) => void;
  onError: (error: Error) => void;
}

export const PaymentInformation = React.forwardRef<HTMLFormElement, PaymentInformationProps>(
  (
    {
      amount,
      stripeMerchantLabel,
      userId,
      productTrackingId,
      bookingIntentId,
      shoppingCartId,
      serviceProviderId,
      touchPointId,
      decisionId,
      onPayment,
      onError,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { hasFormTriggered, currentPaymentMethod, setCurrentPaymentMethod, paymentMethods } =
      usePaymentInputContext();
    const {
      clientSecret,
      createBookingError,
      handleOnOk,
      handlePaymentFailedError,
      isCreditCard,
      isFormLoading,
      isPaymentModalVisible,
      isPaymentRequestButton,
      paymentRequest,
      setIsCreateBookingLoading,
      stripe,
      handleOnSubmit,
    } = usePaymentInformation({
      stripeMerchantLabel,
      productTrackingId,
      shoppingCartId,
      bookingIntentId,
      serviceProviderId,
      amount,
      onError,
    });

    return (
      <form className="yilu-payment-information" ref={ref} onSubmit={handleOnSubmit}>
        {isFormLoading ? (
          <ElementsSDK.FormLoadingIndicator />
        ) : (
          <>
            <SecurePayment />
            <PaymentMethodsSelector
              currentPaymentMethod={currentPaymentMethod}
              paymentMethods={paymentMethods}
              onSelect={setCurrentPaymentMethod}
              productTrackingId={productTrackingId}
            />
            {isCreditCard && stripe && (
              <CreditCardForm
                touchPointId={touchPointId}
                decisionId={decisionId}
                userId={userId}
                bookingIntentId={bookingIntentId}
                clientSecret={clientSecret}
                stripe={stripe}
                onPayment={onPayment}
                onError={handlePaymentFailedError}
                setIsCreateBookingLoading={setIsCreateBookingLoading}
              />
            )}
            {isPaymentRequestButton && stripe && paymentRequest && (
              <PaymentRequestInitializer
                stripe={stripe}
                clientSecret={clientSecret}
                touchPointId={touchPointId}
                decisionId={decisionId}
                userId={userId}
                bookingIntentId={bookingIntentId}
                onPayment={onPayment}
                onError={handlePaymentFailedError}
                paymentRequest={paymentRequest}
                setIsCreateBookingLoading={setIsCreateBookingLoading}
              />
            )}
            {hasFormTriggered && !currentPaymentMethod && (
              <ErrorMessage errorMessage={t('Please select payment method')} />
            )}
            <PaymentModal
              isVisible={isPaymentModalVisible}
              errorMessage={createBookingError?.message}
              onConfirm={handleOnOk}
            />
          </>
        )}
      </form>
    );
  },
);
