/**
 * # Rides Search Input Page
 *
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerSIP from 'components/BannerSIP';
import ProductValues from 'components/ProductValues';

import { RidesSearchForm } from '../../components';
import { useSearchInputPage } from './hooks';
import './styles.scss';

const SearchInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { ridesSearchFormProps, productValueItems } = useSearchInputPage();

  return (
    <div className="rides-sip">
      <BannerSIP title={t('SIMPLETRIP SIP RIDES TITLE')} />
      <div className="form-container-wrapper">
        <div className="form-container" aria-label="Book your ride" role="heading" aria-level={1}>
          {ridesSearchFormProps && <RidesSearchForm {...ridesSearchFormProps} />}
        </div>
      </div>
      <div className="rides-product-values">
        <ProductValues title={t('rideDescriptionTitle')} items={productValueItems} />
      </div>
    </div>
  );
};
export default SearchInputPage;
