import React from 'react';

type LoungeAmenityProps = {
  icon: React.FC;
  className?: string;
};

export const LoungeAmenity: React.FC<LoungeAmenityProps> = ({ icon, className }) => {
  const IconComponent = icon;
  return <span className={className}>{<IconComponent />}</span>;
};

export * from './loungeIconMatcher';
