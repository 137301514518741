import { Booking } from '@yiluhub/yilu-amp-types';
import { useEffect, useState } from 'react';

export function useDetermineQrWalletVisibility(booking: Booking<'COMPLETED', 'LOUNGE'>) {
  const hasQrCodeValues = !!booking.product.data.qrCodeValues?.length;
  const [canShowQrWallet, setCanShowQrWallet] = useState(hasQrCodeValues);

  // temporary solution to stop 400 errors after booking has expired.
  useEffect(() => {
    const EXPIRES_IN_MIN = 15;
    const bookingExpireDate = new Date(
      new Date(booking.modifiedAt).getTime() + EXPIRES_IN_MIN * 60000,
    );

    const remainingTime = bookingExpireDate.getTime() - new Date().getTime();
    const timeoutDuration = remainingTime > 0 ? remainingTime : 0;

    const timeoutId = setTimeout(() => {
      setCanShowQrWallet(false);
      clearTimeout(timeoutId);
    }, timeoutDuration);

    return () => clearTimeout(timeoutId);
  }, [booking]);

  return {
    canShowQrWallet,
  };
}
