import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CategoryBox from 'components/TravellerInformationForm/components/CategoryBox';
import { ReservationInformationFormContentProps } from 'components/TravellerInformationForm/components/types';

import { LoungeFields } from '../LoungeFields';

type PersonalInfoCategoryProps = Pick<
  ReservationInformationFormContentProps,
  'control' | 'fields' | 'trigger' | 'setValue' | 'watch'
> & {
  showDivider?: boolean;
};

export const PersonalInfoCategory = ({ showDivider, ...props }: PersonalInfoCategoryProps) => {
  const { t } = useTranslation();

  return (
    <CategoryBox category={t('tip.loungeForm.primaryGuestCategory')} showDivider={showDivider}>
      <LoungeFields {...props} />

      <ElementsSDK.Typography
        variant="p3"
        className="yilu-LoungeReservationForm__Category-email-notice"
      >
        {t('tip.loungeForm.email.tooltip')}
      </ElementsSDK.Typography>
    </CategoryBox>
  );
};
