/**
 * Init
 *
 * Contains scripts that need to run before the app starts
 */

// Add custom Modernizr tests for iOS detection
(() => {
  const Modernizr = window.Modernizr;

  Modernizr.addTest('ipad', function () {
    return !!navigator.userAgent.match(/iPad/i);
  });

  Modernizr.addTest('iphone', function () {
    return !!navigator.userAgent.match(/iPhone/i);
  });

  Modernizr.addTest('ipod', function () {
    return !!navigator.userAgent.match(/iPod/i);
  });

  Modernizr.addTest('ios', function () {
    return Modernizr.ipad || Modernizr.ipod || Modernizr.iphone;
  });

  Modernizr.addTest('chrome', function () {
    return !!navigator.userAgent.match(/Chrome/i);
  });

  Modernizr.addTest('android', function () {
    return !!navigator.userAgent.match(/Android/i);
  });
})();
