import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import { CommonQueryParams, getCommonQueryParams } from 'utils/paramConverters';

export const DEFAULT_ENTRY_EXIT_TIME = '10:00';
const DEFAULT_PARKING_BUFFER_TIME = 90; // Minutes

export type ParkingSearchInputPageQueryParamsForm = {
  entryDate: string;
  entryTime: string;
  exitDate: string;
  exitTime: string;
  flightTime?: string;
  airportDetails?: ElementsSDK.FilteredAirportDetails;
  airportIataCode?: string;
};

export type ParkingSearchInputPageQueryParams = CommonQueryParams &
  ParkingSearchInputPageQueryParamsForm;

/**
 * Returns the query parameters for the Parking Search Input page.
 *
 * @param location - location descriptor
 */
export const getParkingSearchInputPageQueryParams = (
  location: Location = window.location as any,
): ParkingSearchInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);

  let flightTime = queryParams.flightTime ? queryParams.flightTime.replace('Z', '') : undefined;
  flightTime = UiUtilities.isDateTimeInputValid(flightTime) ? flightTime : undefined;

  const defaultEntryDayJS = flightTime ? dayjs(flightTime) : dayjs().add(1, 'day');
  const defaultEntryDate = defaultEntryDayJS.format('YYYY-MM-DD');
  const defaultEntryTime = flightTime
    ? dayjs(UiUtilities.convertToLocalDate(flightTime))
        .subtract(DEFAULT_PARKING_BUFFER_TIME, 'minute')
        .format(UiUtilities.DateFormat.TIME)
    : DEFAULT_ENTRY_EXIT_TIME;
  const defaultExitDate = dayjs(flightTime || defaultEntryDate)
    .add(1, 'week')
    .format(UiUtilities.DateFormat.SHORT_DATE);

  const searchInputParkingParams = {
    entryDate: queryParams.entryDate || defaultEntryDate,
    entryTime: queryParams.entryTime || defaultEntryTime,
    exitDate: queryParams.exitDate || defaultExitDate,
    exitTime: queryParams.exitTime || DEFAULT_ENTRY_EXIT_TIME,
    flightTime,
    airportDetails: queryParams.airportDetails,
    airportIataCode: queryParams.airportDetails ? undefined : queryParams.airportIataCode,
  };

  return {
    ...commonQueryParams,
    ...searchInputParkingParams,
  };
};

export type ParkingSearchResultsPageQueryParams = ParkingSearchInputPageQueryParams;

/**
 * Returns the query parameters for the Parking Search Results page.
 *
 * @param location - location descriptor
 */
export const getParkingSearchResultsPageQueryParams = (
  location: Location = window.location as any,
): ParkingSearchResultsPageQueryParams => {
  return getParkingSearchInputPageQueryParams(location);
};

/**
 * Returns the query parameters for the Parking Product Details page.
 *
 * @param location - location descriptor
 */
export const getParkingProductDetailsPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const parkingProductDetailsPageParams = {
    flightTime: queryParams.flightTime,
    airportDetails: queryParams.airportDetails && {
      ...queryParams.airportDetails,
      latitude: Number(queryParams.airportDetails.latitude),
      longitude: Number(queryParams.airportDetails.longitude),
    },
    searchResultID: queryParams.searchResultID,
    fromOffer: queryParams.fromOffer,
    entryDate: queryParams.entryDate,
    entryTime: queryParams.entryTime,
    exitDate: queryParams.exitDate,
    exitTime: queryParams.exitTime,
  };

  return {
    ...commonQueryParams,
    ...parkingProductDetailsPageParams,
  };
};

export type ParkingTravellerInputPageQueryParams = CommonQueryParams & {
  searchResultID: string;
};

/**
 * Returns the query parameters for the Parking Traveller Information page.
 *
 * @param location - location descriptor
 */
export const getParkingTravellerInputPageQueryParams = (
  location: Location = window.location as any,
): ParkingTravellerInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const parkingTravellerInputPageParams = {
    searchResultID: queryParams.searchResultID,
  };

  return {
    ...commonQueryParams,
    ...parkingTravellerInputPageParams,
  };
};

export type ParkingPaymentInputPageQueryParams = CommonQueryParams & {
  searchResultID: string;
  bookingIntentId: string;
  shoppingCartId?: string;
  userId?: string;
};

/**
 * Returns the query parameters for the Parking Payment Input page.
 *
 * @param location - location descriptor
 */
export const getParkingPaymentInputPageQueryParams = (
  location: Location = window.location as any,
): ParkingPaymentInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const parkingPaymentInputPageParams = {
    searchResultID: queryParams.searchResultID,
    bookingIntentId: queryParams.bookingIntentId,
    shoppingCartId: queryParams.shoppingCartId,
    userId: queryParams.userId,
  };

  return {
    ...commonQueryParams,
    ...parkingPaymentInputPageParams,
  };
};
