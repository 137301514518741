import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const Terms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ElementsSDK.Typography
      className="yilu-TermsOfUse"
      variant="p2"
      dangerouslySetInnerHTML={{ __html: t('terms.of.use') }}
    />
  );
};

export default Terms;
