import { LegalContentType, YILU_FEATURE } from 'applicationConstants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { yiluCookies, yiluSession } from 'utils';

import { useIsFeatureFlagEnabled } from 'context';

import { LegalModalContext, LegalModalContextType } from './context';

export const LegalModalContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const isModalEnabled = useIsFeatureFlagEnabled(YILU_FEATURE.SHOW_TRACKING_CONSENT);

  const [trackingContentType, setTrackingContentType] = useState(LegalContentType.TRACKING);
  const [title, setTitle] = useState(`${t('cookiesBanner.trackingPreferences')}`);
  const [showLegalBackBtn, setShowLegalBackBtn] = useState(false);
  const [isLegalModalShown, setIsLegalModalShown] = useState(false);

  useEffect(() => {
    const consentDisplayedOnceDuringSession = yiluSession.getIsConsentPopupDisplayedOnce();
    const cookieConsent = yiluCookies.getConsentFromCookie();
    const isConsentSetToNecessary = cookieConsent && cookieConsent.consent.necessary;

    setIsLegalModalShown(
      isModalEnabled &&
        !Boolean(isConsentSetToNecessary) &&
        !Boolean(consentDisplayedOnceDuringSession),
    );
  }, [isModalEnabled]);

  const openLegalModal = (
    contentType: LegalContentType,
    title: string,
    showLegalBackBtn?: boolean,
  ) => {
    setTrackingContentType(contentType);
    setTitle(title);
    setIsLegalModalShown(true);
    setShowLegalBackBtn(Boolean(showLegalBackBtn));
  };

  const closeLegalModal = () => {
    const cookieConsent = yiluCookies.getConsentFromCookie();

    if (trackingContentType !== LegalContentType.TRACKING || Boolean(cookieConsent)) {
      setIsLegalModalShown(false);
    }
  };

  const providerValue: LegalModalContextType = {
    isLegalModalShown,
    showLegalBackBtn,
    title,
    trackingContentType,
    openLegalModal,
    closeLegalModal,
  };

  return <LegalModalContext.Provider value={providerValue}>{children}</LegalModalContext.Provider>;
};
