import React from 'react';

/* eslint-disable max-len */
export const FlexibilityBannerIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="84"
    height="79"
    viewBox="0 0 84 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_919_17447)">
      <path
        d="M77.2661 59.8069L81.0157 60.3929C82.285 58.8561 83.16 57.0327 83.5646 55.0811C80.8228 57.3254 76.6532 56.1328 73.3286 57.3581C72.2945 57.7482 71.3616 58.366 70.5988 59.1657C69.836 59.9654 69.263 60.9264 68.9221 61.9777L67.0896 63.9493C68.7484 64.606 70.5298 64.8953 72.3112 64.7972C74.0925 64.6991 75.8315 64.216 77.4081 63.3812C78.288 62.9041 79.1043 62.3181 79.8377 61.637C78.5257 60.8109 77.2661 59.8069 77.2661 59.8069Z"
        fill="#F2F2F2"
      />
      <path
        d="M75.935 53.8101L79.5524 52.6623C80.0031 50.7207 79.9731 48.6985 79.4648 46.7712C78.0117 50.0028 73.7479 50.7949 71.3186 53.3743C70.5672 54.1847 70.0076 55.1537 69.6816 56.2096C69.3555 57.2656 69.2712 58.3814 69.435 59.4743L68.6741 62.0562C70.4516 61.9042 72.1751 61.3686 73.7258 60.4864C75.2764 59.6042 76.6174 58.3963 77.6563 56.9459C78.231 56.1265 78.7003 55.2379 79.053 54.3012C77.5102 54.1469 75.935 53.8101 75.935 53.8101Z"
        fill="#F2F2F2"
      />
      <path
        d="M83.5849 65.1438C83.5849 65.1631 83.5811 65.1823 83.5738 65.2002C83.5664 65.2181 83.5556 65.2343 83.5419 65.248C83.5282 65.2617 83.5119 65.2725 83.4941 65.2799C83.4762 65.2873 83.457 65.2911 83.4377 65.291H0.147211C0.108168 65.291 0.0707243 65.2755 0.0431169 65.2479C0.0155095 65.2203 0 65.1828 0 65.1438C0 65.1048 0.0155095 65.0673 0.0431169 65.0397C0.0707243 65.0121 0.108168 64.9966 0.147211 64.9966H83.4377C83.457 64.9965 83.4762 65.0003 83.4941 65.0077C83.5119 65.0151 83.5282 65.0259 83.5419 65.0396C83.5556 65.0532 83.5664 65.0695 83.5738 65.0874C83.5811 65.1053 83.5849 65.1244 83.5849 65.1438Z"
        fill="#CCCCCC"
      />
      <path
        d="M34.5139 58.8677C49.203 58.8677 61.1108 46.9598 61.1108 32.2708C61.1108 17.5817 49.203 5.67383 34.5139 5.67383C19.8248 5.67383 7.91699 17.5817 7.91699 32.2708C7.91699 46.9598 19.8248 58.8677 34.5139 58.8677Z"
        fill="#B9B9B9"
      />
      <path
        d="M57.3996 32.2707C57.4023 35.8418 56.5681 39.3637 54.964 42.5541C53.3599 45.7446 51.0304 48.5147 48.1625 50.6424C47.9707 50.7859 47.7752 50.9257 47.5786 51.0618C43.7434 53.7276 39.1846 55.1565 34.5139 55.1565C29.8432 55.1565 25.2844 53.7276 21.4492 51.0618C21.2525 50.9257 21.0571 50.7859 20.8653 50.6424C17.9994 48.5129 15.6714 45.7423 14.0674 42.5523C12.4635 39.3623 11.6282 35.8413 11.6282 32.2707C11.6282 28.7002 12.4635 25.1792 14.0674 21.9892C15.6714 18.7991 17.9994 16.0286 20.8653 13.8991C21.0571 13.7556 21.2525 13.6158 21.4492 13.4797C25.2844 10.8138 29.8432 9.38501 34.5139 9.38501C39.1846 9.38501 43.7434 10.8138 47.5786 13.4797C47.7752 13.6158 47.9707 13.7556 48.1625 13.8991C51.0304 16.0267 53.3599 18.7969 54.964 21.9873C56.5681 25.1778 57.4023 28.6997 57.3996 32.2707Z"
        fill="white"
      />
      <path
        d="M34.5138 53.1773C46.0601 53.1773 55.4203 43.8172 55.4203 32.2709C55.4203 20.7246 46.0601 11.3645 34.5138 11.3645C22.9675 11.3645 13.6074 20.7246 13.6074 32.2709C13.6074 43.8172 22.9675 53.1773 34.5138 53.1773Z"
        fill="#E5E5E5"
      />
      <path
        opacity="0.2"
        d="M34.5685 11.4229C34.2516 11.4229 33.9366 11.4311 33.6231 11.4451C38.9071 11.9094 43.817 14.3649 47.3581 18.3142C50.8992 22.2634 52.8067 27.4111 52.6941 32.7142C52.5816 38.0174 50.4574 43.0795 46.7519 46.8749C43.0464 50.6703 38.0367 52.9153 32.7378 53.155C33.3412 53.2074 33.9515 53.2357 34.5685 53.2357C40.1132 53.2357 45.4308 51.0331 49.3516 47.1123C53.2723 43.1916 55.4749 37.874 55.4749 32.3293C55.4749 26.7846 53.2723 21.4669 49.3516 17.5462C45.4308 13.6255 40.1132 11.4229 34.5685 11.4229Z"
        fill="#949494"
      />
      <path
        d="M34.5757 9.87988C34.3953 9.8802 34.2224 9.95198 34.0949 10.0795C33.9674 10.207 33.8956 10.3799 33.8953 10.5603V13.5292C33.8953 13.7097 33.9669 13.8827 34.0945 14.0103C34.2221 14.1379 34.3952 14.2096 34.5757 14.2096C34.7561 14.2096 34.9292 14.1379 35.0568 14.0103C35.1844 13.8827 35.256 13.7097 35.256 13.5292V10.5603C35.2557 10.3799 35.1839 10.207 35.0564 10.0795C34.9289 9.95198 34.756 9.8802 34.5757 9.87988Z"
        fill="white"
      />
      <path
        d="M48.1624 13.8991C47.9707 13.7556 47.7752 13.6158 47.5785 13.4797C47.4605 13.472 47.3425 13.4952 47.2362 13.5471C47.1299 13.5989 47.039 13.6776 46.9724 13.7754L45.3011 16.2285C45.1995 16.3776 45.1614 16.5609 45.195 16.7382C45.2287 16.9154 45.3314 17.072 45.4805 17.1736C45.6296 17.2751 45.8129 17.3133 45.9902 17.2796C46.1674 17.246 46.324 17.1433 46.4256 16.9942L48.0969 14.5411C48.1604 14.448 48.1998 14.3406 48.2112 14.2284C48.2227 14.1163 48.2059 14.0031 48.1624 13.8991Z"
        fill="white"
      />
      <path
        d="M55.7555 25.0846C55.7219 25.0019 55.6723 24.9266 55.6096 24.8631C55.5469 24.7996 55.4723 24.749 55.39 24.7143C55.3078 24.6796 55.2195 24.6615 55.1302 24.6609C55.0409 24.6603 54.9524 24.6774 54.8697 24.711L52.1185 25.8281C51.9515 25.8961 51.8183 26.0277 51.7483 26.1938C51.6782 26.36 51.677 26.5472 51.7449 26.7142C51.8127 26.8813 51.9442 27.0146 52.1102 27.0848C52.2763 27.155 52.4635 27.1564 52.6306 27.0887L55.3819 25.9716C55.5488 25.9032 55.6818 25.7715 55.7518 25.6053C55.8219 25.439 55.8232 25.2518 55.7555 25.0846Z"
        fill="white"
      />
      <path
        d="M23.7266 16.2285L22.0553 13.7754C21.9887 13.6776 21.8978 13.5989 21.7915 13.5471C21.6852 13.4952 21.5672 13.472 21.4492 13.4797C21.2525 13.6158 21.057 13.7556 20.8653 13.8991C20.8218 14.0031 20.805 14.1163 20.8165 14.2284C20.8279 14.3406 20.8672 14.448 20.9308 14.5411L22.6021 16.9942C22.7037 17.1433 22.8603 17.246 23.0375 17.2796C23.2148 17.3133 23.3981 17.2751 23.5472 17.1736C23.6963 17.072 23.799 16.9154 23.8327 16.7382C23.8663 16.5609 23.8281 16.3776 23.7266 16.2285Z"
        fill="white"
      />
      <path
        d="M16.9092 25.8282L14.158 24.7111C13.9908 24.6432 13.8035 24.6445 13.6373 24.7147C13.4711 24.7849 13.3396 24.9182 13.2717 25.0854C13.2038 25.2525 13.2051 25.4398 13.2752 25.606C13.3454 25.7722 13.4787 25.9037 13.6458 25.9717L16.397 27.0888C16.5642 27.1565 16.7513 27.1551 16.9174 27.0849C17.0835 27.0146 17.2149 26.8814 17.2828 26.7143C17.3507 26.5472 17.3495 26.3601 17.2794 26.1939C17.2093 26.0278 17.0762 25.8962 16.9092 25.8282Z"
        fill="white"
      />
      <path
        d="M34.5757 50.332C34.3953 50.3323 34.2224 50.4041 34.0949 50.5317C33.9674 50.6592 33.8956 50.8321 33.8953 51.0124V53.9814C33.8953 54.1618 33.9669 54.3349 34.0945 54.4625C34.2221 54.5901 34.3952 54.6618 34.5757 54.6618C34.7561 54.6618 34.9292 54.5901 35.0568 54.4625C35.1844 54.3349 35.256 54.1618 35.256 53.9814V51.0124C35.2557 50.8321 35.1839 50.6592 35.0564 50.5317C34.9289 50.4041 34.756 50.3323 34.5757 50.332Z"
        fill="white"
      />
      <path
        d="M48.0969 50.0005L46.4256 47.5474C46.3753 47.4735 46.311 47.4103 46.2363 47.3613C46.1616 47.3124 46.0779 47.2786 45.9902 47.2619C45.9024 47.2453 45.8122 47.2461 45.7248 47.2643C45.6373 47.2825 45.5543 47.3177 45.4805 47.368C45.4066 47.4183 45.3434 47.4826 45.2944 47.5573C45.2455 47.632 45.2117 47.7156 45.195 47.8034C45.1784 47.8912 45.1792 47.9813 45.1974 48.0688C45.2156 48.1562 45.2508 48.2393 45.3011 48.3131L46.9724 50.7662C47.039 50.864 47.1299 50.9426 47.2362 50.9945C47.3425 51.0463 47.4605 51.0696 47.5785 51.0619C47.7752 50.9258 47.9707 50.786 48.1624 50.6425C48.2059 50.5385 48.2227 50.4253 48.2112 50.3132C48.1998 50.201 48.1605 50.0935 48.0969 50.0005Z"
        fill="white"
      />
      <path
        d="M55.3818 38.5699L52.6306 37.4528C52.4635 37.3849 52.2762 37.3862 52.11 37.4564C51.9438 37.5266 51.8123 37.6599 51.7444 37.8271C51.6765 37.9942 51.6777 38.1815 51.7479 38.3477C51.818 38.5139 51.9513 38.6454 52.1185 38.7134L54.8697 39.8305C55.0368 39.8982 55.224 39.8968 55.3901 39.8266C55.5562 39.7563 55.6876 39.6231 55.7555 39.456C55.8234 39.2889 55.8221 39.1018 55.7521 38.9356C55.682 38.7695 55.5488 38.6379 55.3818 38.5699Z"
        fill="white"
      />
      <path
        d="M23.5472 47.3679C23.3981 47.2664 23.2148 47.2283 23.0375 47.2619C22.8603 47.2956 22.7037 47.3982 22.6021 47.5473L20.9308 50.0004C20.8672 50.0935 20.8279 50.201 20.8165 50.3131C20.805 50.4252 20.8218 50.5384 20.8653 50.6424C21.057 50.7859 21.2525 50.9257 21.4492 51.0618C21.5672 51.0695 21.6852 51.0463 21.7915 50.9944C21.8978 50.9426 21.9887 50.8639 22.0553 50.7661L23.7266 48.313C23.7769 48.2392 23.8121 48.1562 23.8303 48.0687C23.8485 47.9813 23.8493 47.8911 23.8327 47.8033C23.816 47.7156 23.7822 47.6319 23.7333 47.5572C23.6843 47.4825 23.6211 47.4182 23.5472 47.3679Z"
        fill="white"
      />
      <path
        d="M17.2828 37.8266C17.2492 37.7439 17.1996 37.6686 17.1369 37.605C17.0742 37.5415 16.9996 37.4909 16.9174 37.4562C16.8351 37.4216 16.7468 37.4034 16.6575 37.4028C16.5683 37.4023 16.4798 37.4193 16.3971 37.453L13.6458 38.5701C13.4788 38.6381 13.3457 38.7696 13.2756 38.9358C13.2056 39.1019 13.2043 39.2891 13.2722 39.4562C13.3401 39.6232 13.4715 39.7565 13.6376 39.8267C13.8037 39.8969 13.9909 39.8983 14.158 39.8306L16.9092 38.7136C17.0761 38.6452 17.2092 38.5135 17.2792 38.3472C17.3492 38.181 17.3505 37.9938 17.2828 37.8266Z"
        fill="white"
      />
      <path
        d="M35.4585 31.9333C35.5213 32.3052 35.434 32.6869 35.2158 32.9945C34.9976 33.3022 34.6663 33.5107 34.2945 33.5744L22.221 35.6281C22.0368 35.6594 21.8483 35.6541 21.6661 35.6126C21.484 35.5711 21.3118 35.4941 21.1594 35.386C21.007 35.2779 20.8774 35.1409 20.778 34.9827C20.6786 34.8245 20.6113 34.6483 20.58 34.4641C20.5486 34.28 20.5539 34.0914 20.5954 33.9093C20.637 33.7271 20.714 33.555 20.822 33.4026C20.9301 33.2502 21.0672 33.1206 21.2253 33.0212C21.3835 32.9217 21.5597 32.8544 21.7439 32.8231L33.8174 30.7694C34.1893 30.7066 34.571 30.7939 34.8786 31.0121C35.1863 31.2303 35.3948 31.5616 35.4585 31.9333Z"
        fill="#48384C"
      />
      <path
        d="M48.1656 45.3097C47.9921 45.4841 47.7566 45.5826 47.5106 45.5836C47.2646 45.5846 47.0283 45.4879 46.8535 45.3149L33.8565 32.4198C33.6821 32.2464 33.5836 32.0109 33.5828 31.765C33.5819 31.5191 33.6787 31.2829 33.8519 31.1083C34.0251 30.9337 34.2606 30.8351 34.5065 30.834C34.7524 30.8329 34.9887 30.9295 35.1634 31.1026L48.1604 43.9976C48.3348 44.171 48.4334 44.4066 48.4343 44.6526C48.4353 44.8986 48.3386 45.1349 48.1656 45.3097Z"
        fill="#48384C"
      />
      <path
        d="M34.5139 33.879C35.4021 33.879 36.1221 33.159 36.1221 32.2708C36.1221 31.3826 35.4021 30.6626 34.5139 30.6626C33.6258 30.6626 32.9058 31.3826 32.9058 32.2708C32.9058 33.159 33.6258 33.879 34.5139 33.879Z"
        fill="#949494"
      />
      <path
        d="M23.4368 4.38464C20.9683 8.26563 17.2018 10.8412 12.5568 12.5332C12.4651 12.602 12.3606 12.652 12.2495 12.6804C12.1384 12.7088 12.0228 12.715 11.9093 12.6987C11.7958 12.6824 11.6866 12.6439 11.588 12.5854C11.4893 12.5269 11.4032 12.4496 11.3344 12.3578C8.81132 8.98895 9.38104 4.17421 12.711 1.60002C13.5161 0.978754 14.4365 0.523594 15.419 0.260968C16.4014 -0.00165853 17.4262 -0.0665213 18.4339 0.0701471C19.4416 0.206816 20.4121 0.542291 21.289 1.0571C22.166 1.57191 22.932 2.25579 23.5425 3.06905L23.6123 3.16223C23.681 3.25401 23.731 3.35844 23.7594 3.46955C23.7878 3.58065 23.794 3.69626 23.7777 3.80977C23.7614 3.92329 23.7229 4.03248 23.6644 4.13111C23.606 4.22975 23.5286 4.3159 23.4368 4.38464Z"
        fill="#B9B9B9"
      />
      <path
        d="M45.4853 3.06905C46.0958 2.25579 46.8618 1.57191 47.7388 1.0571C48.6157 0.54229 49.5862 0.206816 50.5939 0.0701476C51.6016 -0.0665208 52.6264 -0.00165988 53.6088 0.260967C54.5912 0.523593 55.5117 0.978753 56.3168 1.60002C59.6468 4.1742 60.2165 8.98895 57.6934 12.3578C57.6246 12.4496 57.5385 12.5269 57.4398 12.5854C57.3412 12.6439 57.232 12.6824 57.1185 12.6987C57.005 12.715 56.8894 12.7088 56.7783 12.6804C56.6672 12.652 56.5627 12.602 56.4709 12.5332C52.1589 10.8823 48.6227 8.02544 45.591 4.38464C45.4056 4.2458 45.283 4.03902 45.2501 3.80977C45.2172 3.58052 45.2767 3.34759 45.4155 3.16222L45.4853 3.06905Z"
        fill="#B9B9B9"
      />
      <path
        d="M44.4034 55.3135C44.2461 55.4019 44.1303 55.5489 44.0813 55.7225C44.0323 55.8961 44.0541 56.082 44.1419 56.2395L48.0686 63.2565C48.1567 63.4139 48.3038 63.5299 48.4774 63.5789C48.651 63.6279 48.837 63.6059 48.9945 63.5178C49.1519 63.4297 49.2679 63.2827 49.317 63.1091C49.366 62.9354 49.3441 62.7494 49.256 62.592L45.3294 55.575C45.241 55.4178 45.0939 55.302 44.9204 55.2529C44.7468 55.2039 44.5609 55.2257 44.4034 55.3135Z"
        fill="#B9B9B9"
      />
      <path
        d="M23.6983 55.5748L19.7716 62.5918C19.6836 62.7493 19.6616 62.9353 19.7107 63.1089C19.7597 63.2825 19.8757 63.4295 20.0332 63.5177C20.1906 63.6058 20.3766 63.6277 20.5503 63.5787C20.7239 63.5297 20.871 63.4137 20.9591 63.2563L24.8858 56.2393C24.9295 56.1614 24.9573 56.0756 24.9678 55.9868C24.9783 55.8981 24.9712 55.8081 24.9469 55.7221C24.9227 55.6361 24.8817 55.5557 24.8263 55.4856C24.771 55.4154 24.7024 55.3568 24.6244 55.3132C24.5464 55.2696 24.4606 55.2417 24.3718 55.2312C24.2831 55.2208 24.1932 55.2279 24.1072 55.2522C24.0212 55.2765 23.9408 55.3175 23.8706 55.3728C23.8005 55.4282 23.7419 55.4968 23.6983 55.5748Z"
        fill="#B9B9B9"
      />
      <path
        d="M48.8638 65.0532C50.0252 65.0532 50.9668 64.1116 50.9668 62.9502C50.9668 61.7887 50.0252 60.8472 48.8638 60.8472C47.7023 60.8472 46.7607 61.7887 46.7607 62.9502C46.7607 64.1116 47.7023 65.0532 48.8638 65.0532Z"
        fill="#B9B9B9"
      />
      <path
        d="M50.3484 11.2407C51.5098 11.2407 52.4514 10.2991 52.4514 9.13768C52.4514 7.97622 51.5098 7.03467 50.3484 7.03467C49.1869 7.03467 48.2454 7.97622 48.2454 9.13768C48.2454 10.2991 49.1869 11.2407 50.3484 11.2407Z"
        fill="#B9B9B9"
      />
      <path
        d="M34.3901 6.29245C35.5516 6.29245 36.4931 5.3509 36.4931 4.18944C36.4931 3.02798 35.5516 2.08643 34.3901 2.08643C33.2287 2.08643 32.2871 3.02798 32.2871 4.18944C32.2871 5.3509 33.2287 6.29245 34.3901 6.29245Z"
        fill="#B9B9B9"
      />
      <path
        d="M19.1743 10.9934C20.3358 10.9934 21.2773 10.0518 21.2773 8.89037C21.2773 7.7289 20.3358 6.78735 19.1743 6.78735C18.0128 6.78735 17.0713 7.7289 17.0713 8.89037C17.0713 10.0518 18.0128 10.9934 19.1743 10.9934Z"
        fill="#B9B9B9"
      />
      <path
        d="M20.5351 65.0532C21.6966 65.0532 22.6382 64.1116 22.6382 62.9502C22.6382 61.7887 21.6966 60.8472 20.5351 60.8472C19.3737 60.8472 18.4321 61.7887 18.4321 62.9502C18.4321 64.1116 19.3737 65.0532 20.5351 65.0532Z"
        fill="#B9B9B9"
      />
      <path
        d="M47.2142 44.127C47.2971 44.2569 47.4074 44.3669 47.5375 44.4494C47.6675 44.532 47.814 44.585 47.9668 44.6048C48.1195 44.6246 48.2747 44.6106 48.4215 44.5639C48.5683 44.5172 48.703 44.4389 48.8162 44.3345L52.1162 46.1027L51.9392 44.1653L48.8326 42.7832C48.6446 42.6046 48.3977 42.5012 48.1385 42.4925C47.8793 42.4839 47.626 42.5706 47.4265 42.7362C47.227 42.9019 47.0952 43.135 47.0561 43.3913C47.0169 43.6476 47.0732 43.9094 47.2142 44.127Z"
        fill="#FFB7B7"
      />
      <path
        d="M49.6187 44.8031L50.9118 43.0181L57.1417 45.9631L64.894 44.5085C65.2772 44.4366 65.6715 44.45 66.049 44.5476C66.4264 44.6453 66.7778 44.8248 67.078 45.0735C67.3783 45.3222 67.6201 45.6339 67.7864 45.9866C67.9526 46.3393 68.0391 46.7242 68.0399 47.1141C68.0404 47.8183 67.7616 48.494 67.2646 48.9928C66.7675 49.4917 66.0929 49.773 65.3887 49.7751L56.5046 49.7916L49.6187 44.8031Z"
        fill="#48384C"
      />
      <path
        d="M47.627 75.6341L46.3186 75.0084L48.1159 69.5396L50.0469 70.4631L47.627 75.6341Z"
        fill="#FFB7B7"
      />
      <path
        d="M56.3985 74.7526C56.3034 74.8122 56.2083 74.8708 56.1122 74.9294C55.9209 75.0467 55.7277 75.162 55.5346 75.2754C55.4375 75.3321 55.3395 75.3888 55.2424 75.4446V75.4474L55.2366 75.4484L55.2347 75.4493L55.0867 75.4859L54.8743 74.9246L54.7763 74.6661L54.6379 74.2999L54.3842 73.6301L53.0483 70.1031L55.1252 69.5908L55.203 69.9089H55.204L55.2424 70.0665L55.968 73.0093L56.116 73.6109L56.1208 73.6291L56.2102 73.9886L56.2871 74.2999L56.3726 74.6479L56.3985 74.7526Z"
        fill="#FFB7B7"
      />
      <path
        d="M68.7876 62.3664C68.6559 62.5778 68.5213 62.7883 68.3858 62.9968C68.134 63.3851 67.8755 63.7676 67.6103 64.1443C67.6103 64.1453 67.6093 64.1453 67.6083 64.1462C67.4536 64.2896 67.2827 64.4144 67.099 64.5182C67.0913 64.5239 67.0827 64.5278 67.075 64.5326C66.9397 64.6073 66.7958 64.6651 66.6463 64.7046C66.5925 64.7181 66.5377 64.7315 66.4839 64.745C66.2898 64.7911 66.0976 64.8305 65.9063 64.8651C65.5661 64.9257 65.2298 64.9689 64.8953 64.9958C64.8684 64.9987 64.8415 65.0006 64.8146 65.0025C63.4393 65.1034 62.1006 64.941 60.7033 64.6748C60.0132 64.5441 59.3088 64.3875 58.5793 64.225C58.341 64.1712 58.1017 64.1184 57.8595 64.0655C57.8413 64.0617 57.822 64.0569 57.8028 64.053C57.4568 63.9771 57.1051 63.9021 56.7447 63.8281C56.2911 63.7349 55.8231 63.6446 55.3368 63.559L55.3714 64.2443C55.4002 64.7714 55.5097 65.291 55.6962 65.7848C55.9038 66.3922 56.0681 66.8727 55.7212 67.8732L55.554 68.3575C55.6407 68.5981 55.6704 68.8554 55.6409 69.1094C55.6114 69.3633 55.5234 69.607 55.3839 69.8212L55.3502 69.8721L56.7447 73.4001L56.835 73.6289H56.121L55.2426 73.6298H53.7539L51.606 69.3205L51.5032 69.1148V69.1043L51.7252 60.823C51.7281 60.7499 51.7338 60.6759 51.7434 60.6029C51.7826 60.2708 51.8875 59.9499 52.052 59.6589C52.2165 59.3678 52.4373 59.1124 52.7016 58.9076C52.9548 58.7097 53.2449 58.5641 53.5549 58.4793C53.8649 58.3945 54.1887 58.3722 54.5074 58.4136L55.2426 58.5088L56.7447 58.7029L57.8028 58.8403L57.824 58.8432L60.9445 59.2468L61.1684 58.3339L61.2097 58.3185L62.5235 57.837L67.0673 56.1715L67.0788 56.1677L67.3777 56.0581L67.4133 56.1158C67.4238 56.1331 67.4344 56.1504 67.444 56.1677C67.7394 56.6973 67.98 57.2556 68.1619 57.8341C68.4679 58.7791 68.7197 59.7407 68.9163 60.7144C68.925 60.7567 68.9327 60.7951 68.9394 60.8297L68.9423 60.8403C68.9452 60.8566 68.9471 60.8672 68.948 60.8701C69.0528 61.3064 68.9798 61.8388 68.7876 62.3664Z"
        fill="#1E1E1E"
      />
      <path
        d="M68.9792 59.3144C68.9648 59.2529 68.9465 59.1904 68.9273 59.1299C68.7747 58.6713 68.597 58.2215 68.3949 57.7825C68.1681 57.2828 67.8961 56.7475 67.5722 56.1679C67.4156 55.8873 67.2471 55.5955 67.0667 55.2924C66.9764 55.1406 66.8825 54.9859 66.7851 54.8283C66.7813 54.821 66.7771 54.8139 66.7726 54.8071L66.7448 54.761L62.249 55.3626L60.5749 55.5865L60.4749 56.4207L60.4509 56.6273C60.4499 56.6273 60.4499 56.6273 60.4509 56.6283L60.3836 57.1915V57.1924L60.2942 57.9363L60.2866 57.9353L57.8023 57.6134L55.242 57.2818L53.8668 57.104C53.5481 57.0625 53.2243 57.0848 52.9142 57.1696C52.6042 57.2544 52.3142 57.4 52.061 57.598C51.7968 57.8028 51.576 58.0582 51.4115 58.3493C51.247 58.6404 51.142 58.9613 51.1028 59.2933C51.0942 59.3605 51.0884 59.4297 51.0855 59.498L49.7881 62.5724C49.0117 64.411 48.5173 66.3563 48.3215 68.3425L47.3701 71.3842L47.5056 71.3967L49.5219 71.5841L50.0543 71.634L51.2268 69.9224C51.3604 69.7273 51.4872 69.5265 51.6054 69.3208C51.8001 68.9849 51.9731 68.6369 52.1234 68.279L54.6596 62.2437C54.8557 62.2773 55.0498 62.3094 55.242 62.3398C56.131 62.4839 56.9844 62.6015 57.8022 62.6925C60.4615 62.9895 62.7641 63.0125 64.814 62.7607C65.3897 62.6906 65.9461 62.5983 66.4834 62.4839C66.6804 62.4426 66.8748 62.3981 67.0667 62.3504C67.1119 62.3398 67.1561 62.3292 67.2003 62.3177C67.5975 62.2152 67.9626 62.0148 68.2623 61.7348C68.562 61.4547 68.7867 61.104 68.9158 60.7147C69.0699 60.2635 69.0919 59.7777 68.9792 59.3144Z"
        fill="#1E1E1E"
      />
      <path
        d="M48.0448 74.7412L46.6291 74.0627L46.4072 73.9561L46.0525 74.8268L43.5769 75.3717C43.4545 75.3985 43.3438 75.4638 43.2612 75.558C43.1785 75.6521 43.1281 75.7703 43.1174 75.8951C43.1067 76.02 43.1361 76.145 43.2015 76.2519C43.2669 76.3588 43.3647 76.442 43.4808 76.4894L45.8997 77.4764L46.6676 76.7854L46.4792 77.7128L47.3903 78.0847L48.3888 74.9065L48.0448 74.7412Z"
        fill="#48384C"
      />
      <path d="M56.7446 73.4001L56.8351 73.6289H56.7446V73.4001Z" fill="#2F2E41" />
      <path
        d="M56.2518 73.6778L54.7685 74.1918L54.5356 74.2722L54.8963 75.1404L53.5206 77.2695C53.4525 77.3747 53.4198 77.4989 53.4273 77.624C53.4349 77.7491 53.4822 77.8685 53.5624 77.9648C53.6426 78.0611 53.7515 78.1292 53.8732 78.1591C53.9949 78.1891 54.123 78.1794 54.2387 78.1313L56.652 77.1307L56.7115 76.0994L57.2302 76.8909L58.1393 76.5141L56.6125 73.5532L56.2518 73.6778Z"
        fill="#48384C"
      />
      <path
        d="M68.6094 60.7069C68.5213 60.7067 68.4346 60.6852 68.3567 60.6442L60.5292 56.5541L60.4388 56.1839L60.2683 55.0121L60.2707 55.0012L61.0151 51.5691C61.0014 51.5397 60.9729 51.4913 60.9407 51.4367C60.6084 50.8737 59.597 49.1599 61.5272 46.8002L64.9162 42.849L67.8249 42.7239L67.858 42.7657C68.6358 43.7419 69.1603 44.8951 69.385 46.1228C69.6096 47.3506 69.5275 48.6148 69.1458 49.8032L68.843 55.2731L69.1678 56.3777C69.3577 57.0269 69.3263 57.7208 69.0786 58.3502L69.153 60.1388C69.1559 60.2122 69.144 60.2853 69.118 60.3539C69.0921 60.4226 69.0525 60.4852 69.0018 60.5383C68.951 60.5913 68.8902 60.6335 68.8227 60.6625C68.7553 60.6915 68.6828 60.7066 68.6094 60.7069Z"
        fill="#48384C"
      />
      <path
        d="M61.8197 65.218C61.9523 65.1397 62.0661 65.0332 62.1531 64.9061C62.2401 64.779 62.2982 64.6344 62.3233 64.4825C62.3484 64.3305 62.3398 64.1749 62.2983 64.0266C62.2567 63.8783 62.1831 63.7409 62.0827 63.6241L63.9646 60.3877L62.0222 60.4972L60.5329 63.5538C60.3479 63.7354 60.2359 63.9787 60.2183 64.2374C60.2006 64.4961 60.2785 64.7523 60.4371 64.9574C60.5957 65.1626 60.824 65.3024 61.0789 65.3504C61.3337 65.3984 61.5973 65.3513 61.8197 65.218Z"
        fill="#FFB7B7"
      />
      <path
        d="M62.5791 62.8385L60.8401 61.484L64 55.3604L62.816 47.5621C62.7575 47.1766 62.7845 46.783 62.8952 46.4092C63.006 46.0354 63.1976 45.6905 63.4566 45.3991C63.7156 45.1076 64.0356 44.8768 64.3938 44.7229C64.752 44.5691 65.1397 44.4959 65.5294 44.5088C66.2332 44.5327 66.8988 44.8349 67.38 45.349C67.8613 45.863 68.119 46.547 68.0966 47.2509L67.804 56.1302L62.5791 62.8385Z"
        fill="#48384C"
      />
      <path
        d="M63.0505 41.2073V38.329C63.0499 37.3061 63.4556 36.3249 64.1784 35.6011C64.9012 34.8774 65.882 34.4704 66.9049 34.4697C67.9278 34.4691 68.909 34.8748 69.6328 35.5976C70.3565 36.3204 70.7635 37.3012 70.7641 38.3241V38.329C71.4439 39.7455 71.0186 40.419 70.7641 41.2073C70.7639 41.3445 70.7093 41.4761 70.6122 41.5732C70.5152 41.6703 70.3836 41.7249 70.2463 41.7251H63.5686C63.4313 41.725 63.2997 41.6704 63.2026 41.5733C63.1054 41.4762 63.0508 41.3446 63.0505 41.2073Z"
        fill="#1E1E1E"
      />
      <path
        d="M65.6428 41.5381C67.2372 41.5381 68.5298 40.2455 68.5298 38.6511C68.5298 37.0567 67.2372 35.7642 65.6428 35.7642C64.0484 35.7642 62.7559 37.0567 62.7559 38.6511C62.7559 40.2455 64.0484 41.5381 65.6428 41.5381Z"
        fill="#FFB7B7"
      />
      <path
        d="M68.6641 39.4079C68.638 39.4038 68.6118 39.3983 68.5856 39.3942C68.1353 39.3143 67.685 39.2358 67.236 39.1545L67.1947 38.1369L66.6508 39.0485C65.41 39.4603 64.249 39.2275 63.157 38.7139C62.7968 38.5429 62.4472 38.3502 62.1104 38.1369C62.2021 37.574 62.4185 37.0388 62.7437 36.5703C63.069 36.1019 63.4948 35.7121 63.9901 35.4294C64.0315 35.406 64.0714 35.3854 64.1127 35.3647L64.1132 35.3646L64.1137 35.3643L64.114 35.3638L64.1141 35.3633C64.1843 35.3302 64.2559 35.2986 64.3275 35.2697C64.8882 35.0506 65.4995 34.9951 66.0904 35.1097C66.6813 35.2242 67.2276 35.5042 67.6657 35.9169C68.5471 36.7583 68.9437 38.1479 68.6641 39.4079Z"
        fill="#1E1E1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_919_17447">
        <rect width="83.5849" height="78.176" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
