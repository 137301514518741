import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { useMemo } from 'react';

import { TrackingParam } from 'widgets/GlobalDefaults';

import { ProductDetails } from 'modules/parking/utils/getParkingDetails';

import { ParkingProductDetailsProps } from '../ParkingProductDetails/types';

export function useParkingProductDetailsProps(
  parkingDetails: ProductDetails,
  parkingProductDetailsPageQueryParams: {
    searchResultID: any;
    entryDate: any;
    airportDetails: any;
    locale: string | TrackingParam;
    userId: string | TrackingParam;
    tracking: string | TrackingParam;
    touchpoint?: string | TrackingParam;
    entryTime: any;
    exitTime: any;
    fromOffer: any;
    loyaltyProgramId?: string | TrackingParam;
    flightTime: any;
    exitDate: any;
  },
) {
  return useMemo(() => {
    if (!parkingDetails) {
      return null;
    }

    const _props: ParkingProductDetailsProps = {
      providerInfo: {
        providerId: parkingDetails.providerId,
        logo: parkingDetails.logo,
        name: parkingDetails.name,
        rating: parkingDetails.rating,
        features: parkingDetails.features,
        address: parkingDetails.address,
        longitude: parkingDetails?.longitude,
        latitude: parkingDetails?.latitude,

        //TODO: Verify these are within response
        postalCode: parkingDetails?.postalCode || '',
        supportedLanguages: parkingDetails.supportedLanguages || [],
        directions: parkingDetails.directions,
        extendedDescription: parkingDetails.extendedDescription,
        images: parkingDetails.images,
        securityMeasures: parkingDetails.securityMeasures,
        arrivalProcedures: parkingDetails.arrivalProcedures,
        disabledFacilities: parkingDetails.disabledFacilities,
      },
      optionInfo: {
        carparkType: parkingDetails.carParkType,
        productDescription: parkingDetails.productDescription,
      },
    };

    const { flightTime, airportDetails } = parkingProductDetailsPageQueryParams;
    if (flightTime && airportDetails) {
      _props.flightInfo = {
        departureDate: UiUtilities.formatLocalDate(flightTime, UiUtilities.DateFormat.SHORT_DATE),
        departureTime: UiUtilities.formatLocalDate(flightTime, UiUtilities.DateFormat.TIME),
        airportName: airportDetails.name,
      };
    }

    return _props;
  }, [parkingDetails, parkingProductDetailsPageQueryParams]);
}
