import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import './styles.scss';

type CarouselProps = {
  items: React.JSX.Element[];
};

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  return (
    <Swiper modules={[Navigation]} className={`lp-carousel`} navigation={true} loop={true}>
      {items.map((item, index) => (
        <SwiperSlide key={index} className={'lp-carousel-slide'}>
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
