import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { yiluTracking } from 'utils';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { MAX_LOUNGE_GUESTS, PRICING_OPTION } from '../../../utils/constants';

const useProductSummaryProps = (
  currentLounge: SearchItem | undefined,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  isReservationUpdateLoading: boolean,
  pricingOption: PRICING_OPTION,
) => {
  const { t } = useTranslation();

  const {
    name,
    currency,
    price,
    date,
    location,
    economyPremiumPriceAmount,
    otherAirlinePriceAmount,
    otherAirlinePriceCurrency,
    economyPremiumPriceCurrency,
    travellersCount,
    providerId,
    airportCoordinates,
  } = getProductDetails(currentLounge);

  const productSummaryProps = useMemo(() => {
    if (!currentLounge) {
      return null;
    }

    const isHolidayExtra = isSpHolidayExtras(providerId);

    const loungeProductSummaryProps: LoungeInteractiveProductSummaryProps = {
      price,
      currency,
      name,
      entryDate: date,
      displayTimezone:
        airportCoordinates && tzlookup(airportCoordinates?.latitude, airportCoordinates?.longitude),
      travellersCount,
      location,
      isEstimatedPriceActive: pricingOption === PRICING_OPTION.NONE,
      isEditable: false,
      maxTravellersCount: MAX_LOUNGE_GUESTS,
      onSubmit: () => {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          yiluTracking.sendGAEvent({
            event: 'tip_cta_clicked',
            category: 'lounge-tip',
            label: 'lounge_tip_cta_clicked',
          });
        }
      },
      submitBtnLabel: t('Confirm'),
      isLoading: isReservationUpdateLoading,
    };

    if (!isHolidayExtra) {
      if (pricingOption === PRICING_OPTION.PREMIUM) {
        loungeProductSummaryProps.price = economyPremiumPriceAmount / 100;
        loungeProductSummaryProps.currency = economyPremiumPriceCurrency;
      } else if (pricingOption === PRICING_OPTION.OTHER) {
        loungeProductSummaryProps.price = otherAirlinePriceAmount / 100;
        loungeProductSummaryProps.currency = otherAirlinePriceCurrency;
      }
    }

    return loungeProductSummaryProps;
  }, [
    providerId,
    travellersCount,
    currentLounge,
    reservationFormRef,
    isReservationUpdateLoading,
    pricingOption,
    t,
    currency,
    date,
    location,
    name,
    otherAirlinePriceAmount,
    otherAirlinePriceCurrency,
    price,
    economyPremiumPriceAmount,
    economyPremiumPriceCurrency,
    airportCoordinates,
  ]);

  return productSummaryProps;
};

export default useProductSummaryProps;
