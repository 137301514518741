import { ElementsSDK } from '@yiluhub/ui-sdk-react';

export function useSalutations(salutationSelectOptions: ElementsSDK.SelectOption[]) {
  const getDefaultSalutationValue = (fieldName: string): ElementsSDK.SelectOption | undefined => {
    const savedItem = sessionStorage.getItem(fieldName);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && salutationSelectOptions[0].value === parsedSavedItem) {
      return salutationSelectOptions[0];
    } else if (parsedSavedItem && salutationSelectOptions[1].value === parsedSavedItem) {
      return salutationSelectOptions[1];
    }
    return undefined;
  };
  return { salutationSelectOptions, getDefaultSalutationValue };
}
