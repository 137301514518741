import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type BookingFAQSupportProps = {
  faqLink: string;
};

export const BookingFAQSupport: React.FC<BookingFAQSupportProps> = ({ faqLink }) => {
  const { t } = useTranslation();
  if (!faqLink) {
    return null;
  }

  return (
    <div className="yilu-bcp__Support">
      <ElementsSDK.Typography variant="h2" className="yilu-bcp__SupportHeader">
        {t('bcp.faq-support.header')}
      </ElementsSDK.Typography>
      <ElementsSDK.Typography variant="p1" className="yilu-bcp__SupportText">
        {t('bcp.faq-support.text')}
      </ElementsSDK.Typography>
      <ElementsSDK.Link
        Icon={() => <ElementsSDK.Icon.ArrowRight />}
        className="yilu-bcp__SupportLink"
        href={faqLink}
      >
        {t('bcp.faq-support.link')}
      </ElementsSDK.Link>
    </div>
  );
};
