/**
 * # Lounges Directions
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type LoungesDirectionsProps = {
  directions: string;
};

const LoungeDirections: React.FC<LoungesDirectionsProps> = ({ directions }) => {
  const { t } = useTranslation();

  return (
    <>
      <ElementsSDK.Typography variant="h2">{t('Directions')}</ElementsSDK.Typography>
      <ElementsSDK.Typography
        variant="p1"
        className="yilu-LoungeDirections"
        dangerouslySetInnerHTML={{
          __html: directions,
        }}
      />
    </>
  );
};

export default LoungeDirections;
