import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  SetFieldValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AirlineGroup } from 'components/TravellerInformationForm/Lounge/LHGForm';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import CategoryBox from 'components/TravellerInformationForm/components/CategoryBox';
import {
  AIRLINE_GROUP,
  REQUIRED_FIELD_ERROR_MESSAGE_KEY,
  isLoungeFlightCodeValid,
} from 'components/TravellerInformationForm/utils';

type TravelInfoProps = {
  control: Control;
  trigger: UseFormTrigger<FieldValues>;
  setValue: SetFieldValue<any>;
  watch: UseFormWatch<Record<string, string>>;
  flightCode: string;
  isEconomyPremium: boolean;
  iata?: string;
};

const LUFTHANSA_FLIGHT_CODE_LIST = ['lh', 'os', 'sn', 'lx', '4y', 'en', 'wk', 'vl'];

export const isLufthansaAirlineGroup = (flightCode: string): boolean => {
  return (
    LUFTHANSA_FLIGHT_CODE_LIST.filter((flightCodePrefix) =>
      flightCode.toLowerCase().startsWith(flightCodePrefix),
    ).length > 0
  );
};

const TravelInfo: React.FC<TravelInfoProps> = ({
  control,
  setValue,
  watch,
  trigger,
  flightCode,
  isEconomyPremium,
  iata,
}) => {
  const { t } = useTranslation();
  const isPremiumEconomy = watch(LoungeFieldName.IS_ECONOMY_PREMIUM) || false;
  const [premiumEconomySectionHeight, setPremiumEconomySectionHeight] = useState('0px');
  const premiumEconomyRef = useRef<HTMLDivElement>(null);
  const isFlightCodeFromLufthansa = isLufthansaAirlineGroup(flightCode);

  useEffect(() => {
    if (premiumEconomyRef.current) {
      const value = premiumEconomyRef.current['scrollHeight'];
      setPremiumEconomySectionHeight(isFlightCodeFromLufthansa ? `${value}px` : '0px');
    }
    if (isFlightCodeFromLufthansa) {
      setValue(AIRLINE_GROUP, AirlineGroup.LUFTHANSA);
    } else {
      setValue(AIRLINE_GROUP, AirlineGroup.OTHER);
    }
  }, [flightCode, isFlightCodeFromLufthansa, setValue]);

  return (
    <CategoryBox category={t('tip.loungeForm.travelInfoCategory')}>
      <ElementsSDK.Typography variant="p1" className="yilu-LoungeReservationForm__travelInfoLabel">
        {t('tip.loungeForm.travel.info.label')}
      </ElementsSDK.Typography>
      <Controller
        name={LoungeFieldName.FLIGHT_CODE}
        control={control}
        rules={{
          required: REQUIRED_FIELD_ERROR_MESSAGE_KEY,
          validate: (value) => isLoungeFlightCodeValid(value, iata),
        }}
        render={({ field: { onChange, onBlur, name, ref }, fieldState: { isTouched, error } }) => {
          return (
            <ElementsSDK.InputField.TextInput
              label={
                iata
                  ? t('tip.loungeForm.booking.code.label', { iata })
                  : t('tip.loungeForm.booking.code')
              }
              name={name}
              value={flightCode}
              className="yilu-LoungeReservationForm__field"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                sessionStorage.setItem(name, JSON.stringify(e.target.value));
                setValue(name, e.target.value);
                onChange(e);
                isTouched && trigger(name);
                if (!isLufthansaAirlineGroup(e.target.value) && isPremiumEconomy) {
                  setValue(LoungeFieldName.IS_ECONOMY_PREMIUM, false);
                  sessionStorage.setItem(LoungeFieldName.IS_ECONOMY_PREMIUM, JSON.stringify(false));
                }
              }}
              onBlur={() => {
                onBlur();
                trigger(name);
              }}
              inputRef={ref}
              errorMessage={error?.message && t(error.message)}
              placeholder={t('tip.loungeForm.flight.code.placeholder')}
            />
          );
        }}
      />
      <Controller
        defaultValue={false}
        name={LoungeFieldName.IS_ECONOMY_PREMIUM}
        control={control}
        render={({ field: { onChange, name } }) => {
          return (
            <div
              ref={premiumEconomyRef}
              style={{ maxHeight: `${premiumEconomySectionHeight}` }}
              className={clsx('yilu-LoungeReservationForm__premiumEconomy-wrapper', {
                'yilu-LoungeReservationForm__premiumEconomy-wrapper--displayed':
                  isLufthansaAirlineGroup,
              })}
            >
              <div className="yilu-LoungeReservationForm__premiumEconomy-checkbox-wrapper-B">
                <ElementsSDK.Checkbox
                  size="small"
                  checked={!!isEconomyPremium}
                  className="yilu-LoungeReservationForm__premiumEconomy-checkbox-B"
                  testid={'isEconomyPremium'}
                  onChange={(checked: boolean) => {
                    sessionStorage.setItem(name, JSON.stringify(checked));
                    setValue(name, checked);
                    onChange(checked);
                  }}
                />
                {t('tip.loungeForm.travel.premium.economy')}
              </div>
            </div>
          );
        }}
      />
      {iata === 'BRU' && !isEconomyPremium && (
        <Controller
          defaultValue={false}
          name={'isNotEconomyLight'}
          control={control}
          rules={{
            required: REQUIRED_FIELD_ERROR_MESSAGE_KEY,
          }}
          render={({ field: { onChange, name }, fieldState: { error } }) => {
            return (
              <div className={clsx('yilu-LoungeReservationForm__lightEconomy-wrapper')}>
                <div className="yilu-LoungeReservationForm__lightEconomy-checkbox-wrapper">
                  <ElementsSDK.Checkbox
                    size="small"
                    checked={sessionStorage.getItem('isNotEconomyLight') === 'true'}
                    className="yilu-LoungeReservationForm__lightEconomy-checkbox"
                    testid={'isNotEconomyLight'}
                    onChange={(checked: boolean) => {
                      sessionStorage.setItem(name, JSON.stringify(checked));
                      setValue(name, checked);
                      onChange(checked);
                    }}
                  />
                  {t('tip.loungeForm.travel.economy.light')}
                </div>
                {error && (
                  <ElementsSDK.Typography
                    isError
                    className="yilu-LoungeReservationForm__lightEconomy-checkbox-error"
                  >
                    {' '}
                    {t('tip.loungeForm.travel.economy.light.error')}
                  </ElementsSDK.Typography>
                )}
              </div>
            );
          }}
        />
      )}
    </CategoryBox>
  );
};

export default TravelInfo;
