import { YILU_OVERRIDE_KEY } from 'applicationConstants';
import React, { useContext } from 'react';

import { ComponentOverridesContext } from './context';

export const useComponentOverrides = (key: YILU_OVERRIDE_KEY) => {
  const context = useContext(ComponentOverridesContext);

  if (context === undefined) {
    throw new Error(
      'useComponentOverrides() must be used within ComponentOverridesContext.Provider',
    );
  }

  const renderOverrideComponent = context[key];
  const renderFunc = <T>(componentRender: React.FC<T>, props: React.PropsWithChildren<T>) =>
    renderOverrideComponent
      ? renderOverrideComponent(props, componentRender)
      : componentRender(props);

  return renderFunc;
};

export { ComponentOverridesProvider } from './Provider';
