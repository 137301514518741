/**
 * # Parking Search Results
 *
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultCard, SearchResultNotFound } from 'components';

import { ParkingSearchResultCard } from '../ParkingSearchResultCard';
import { useParkingSearchResultsLoader } from './hooks';
import { ParkingSearchResultsProps } from './types';

const PLACEHOLDER_COUNT = 9;

export const ParkingSearchResults: React.FC<ParkingSearchResultsProps> = ({
  searchParams,
  onSearchResults,
  onTipButtonClick,
  onPdpButtonClick,
  onError,
}) => {
  const { t } = useTranslation();
  const { isLoading, pageNumber, totalPages, setLastElement, searchResults } =
    useParkingSearchResultsLoader({
      searchParams,
      onSearchResults,
      onError,
    });

  return (
    <>
      {!isLoading && !searchResults.length && (
        <SearchResultNotFound
          title={t('Parking no results found')}
          message={t('Parking no results found message')}
        />
      )}
      {isLoading &&
        pageNumber === 0 &&
        Array(PLACEHOLDER_COUNT)
          .fill(0)
          .map((_, index) => <SearchResultCard asPlaceholder key={index} />)}
      <section>
        {searchResults.map((searchResult, index) => {
          const isLastElement =
            index === searchResults.length - 1 && !isLoading && pageNumber < totalPages - 1;
          return (
            <ParkingSearchResultCard
              cardRef={isLastElement ? setLastElement : undefined}
              key={searchResult.id}
              data={searchResult}
              onPdpClick={onPdpButtonClick}
              onTipClick={onTipButtonClick}
            />
          );
        })}
        {isLoading && pageNumber !== 0 && <SearchResultCard asPlaceholder />}
      </section>
    </>
  );
};
