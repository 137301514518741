import React from 'react';

import './styles.scss';

const SummaryLayout: React.FC = ({ children }) => {
  return (
    <div className="summary-container">
      <div className="summary">{children}</div>
    </div>
  );
};

export default SummaryLayout;
