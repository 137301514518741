import { useEffect } from 'react';

import { sendGAEvent } from 'utils/tracking';

const useTracking = (isPageReady: boolean) => {
  useEffect(() => {
    if (!isPageReady) {
      return;
    }
    sendGAEvent({
      event: 'page_loaded',
      category: 'airport-transfer-sip',
      label: 'airport_transfer_sip_loaded',
    });
  }, [isPageReady]);
};

export default useTracking;
