import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TransferType } from '../../../utils/constants';
import './styles.scss';

export type DateTimeTogglerProps = {
  transferType: TransferType;
  setTransferType(transferType: TransferType): unknown;
};

const DateTimeToggler: React.FC<DateTimeTogglerProps> = ({ transferType, setTransferType }) => {
  const { t } = useTranslation();

  return (
    <div className={'yilu-DateTimeToggler'}>
      <button
        onClick={() => setTransferType(TransferType.DEPARTURE)}
        className={clsx({
          'yilu-DateTimeToggler__button': true,
          'yilu-DateTimeToggler__button--active': transferType === TransferType.DEPARTURE,
        })}
        data-testid="departure-date-time-toggler-btn"
        role="tab"
        aria-selected={transferType === TransferType.DEPARTURE}
      >
        {t('departAt')}
      </button>
      <button
        onClick={() => setTransferType(TransferType.ARRIVAL)}
        className={clsx({
          'yilu-DateTimeToggler__button': true,
          'yilu-DateTimeToggler__button--active': transferType === TransferType.ARRIVAL,
        })}
        data-testid="arrival-date-time-toggler-btn"
        role="tab"
        aria-selected={transferType === TransferType.ARRIVAL}
      >
        {t('arriveBy')}
      </button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default DateTimeToggler;
