import { ElementsSDK } from '@yiluhub/ui-sdk-react';

export const getDefaultNumberOfPassenger = (
  fieldName: string,
): ElementsSDK.SelectOption | undefined => {
  const savedItem = sessionStorage.getItem(fieldName);
  if (savedItem) {
    return { value: JSON.parse(savedItem), label: JSON.parse(savedItem) };
  }
  return undefined;
};
