/**
 * # Swiss Travel Search Input Page.
 *
 *
 */
import { Typography } from '@yiluhub/ui-elements';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Carousel from 'modules/swiss-travel/components/carousel';

import { SearchForm } from '../../components/SearchForm';
import SwissCard from '../../components/SwissCard';
import BuildingIcon from './assets/building-icon.svg';
import BusIcon from './assets/bus-icon.svg';
import MountainIcon from './assets/mountain-icon.svg';
import OfferIcon from './assets/offer-icon.svg';
import TicketIcon from './assets/ticket-icon.svg';
import TrainIcon from './assets/train-icon.svg';
import './styles.scss';

const SearchInputPage: React.FC = () => {
  const { t } = useTranslation();

  const slides = [
    <div>
      <img src={BusIcon} alt="Bus icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_first_advantage_text')}
      </Typography>
    </div>,
    <div className="lp-train-icon-container">
      <img src={TrainIcon} alt="Train icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_second_advantage_text')}
      </Typography>
    </div>,
    <div>
      <img src={TicketIcon} alt="Ticket icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_third_advantage_text')}
      </Typography>
    </div>,
    <div>
      <img src={BuildingIcon} alt="Builing icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_fourth_advantage_text')}
      </Typography>
    </div>,
    <div>
      <img src={MountainIcon} alt="Mountain icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_fifth_advantage_text')}
      </Typography>
    </div>,
    <div>
      <img src={OfferIcon} alt="Offer icon" />
      <Typography variant="p1" bold={true}>
        {t('sbb.advantages_sixth_advantage_text')}
      </Typography>
    </div>,
  ];

  return (
    <div className="swiss-travel-sip">
      <section className="swiss-form-section">
        <div className="swiss-image-container">
          <img
            src="https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/swiss-travel/mountain-train-large.jpg"
            alt="Train Passing through mountains"
            loading="eager"
          />
        </div>
        <div className="swiss-form-container">
          <div className="section-heading-container">
            <Typography
              variant="h1"
              className="discover_title_text"
              dangerouslySetInnerHTML={{ __html: t('sbb.discover_title_text') }}
            />
            <Typography
              variant="h1"
              className="discover_title_text_newline"
              dangerouslySetInnerHTML={{ __html: t('sbb.discover_title_text_newline') }}
            />
            <Typography variant="p1">{t('sbb.discover_first_paragraph_text')}</Typography>
            <Typography bold={true} variant="p1">
              {t('sbb.discover_second_paragraph_text')}
            </Typography>
          </div>
          <div className="form-container-wrapper">
            <SearchForm />
          </div>
        </div>
      </section>
      <section className="swiss-advantages">
        <Typography className="swiss-advantages-title" variant="h2">
          {t('sbb.advantages_title_text')}
        </Typography>
        <div className="swiss-advantages-grid">{slides}</div>
        <div className="swiss-advantages-carousel">
          <Carousel items={slides} />
        </div>
      </section>
      <section className="swiss-cards-section">
        <Typography variant="h2" className="section-title">
          {t('sbb.cards_section_title_text')}
        </Typography>
        <div className="card-container">
          <SwissCard
            imageUrl="https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/swiss-travel/red-train.jpg"
            imageAlt="Train going up the hill"
            title={t('sbb.swiss_travel_pass_text')}
            tableHeaderValues={[
              t('sbb.card_product_price_title_text'),
              t('sbb.card_second_class_text'),
              t('sbb.card_first_class_text'),
            ]}
            tableValues={[
              t('sbb.swiss_travel_pass_text') + ' 3 ' + t('sbb.travel_pass_days'),
              '244.-',
              '389.-',
              t('sbb.swiss_travel_pass_text') + ' 4 ' + t('sbb.travel_pass_days'),
              '295.-',
              '469.-',
              t('sbb.swiss_travel_pass_text') + ' 6 ' + t('sbb.travel_pass_days'),
              '379.-',
              '602.-',
              t('sbb.swiss_travel_pass_text') + ' 8 ' + t('sbb.travel_pass_days'),
              '419.-',
              '665.-',
              t('sbb.swiss_travel_pass_text') + ' 15 ' + t('sbb.travel_pass_days'),
              '459.-',
              '723.-',
            ]}
          ></SwissCard>
        </div>
      </section>

      <section className="swiss-children-section">
        <div className="text-container">
          <Typography variant="h2" bold={true}>
            {t('sbb.children_section_title_text')}
          </Typography>
          <Typography variant="p1">{t('sbb.children_section_first_paragraph_text')}</Typography>

          <Typography variant="h2" bold={true}>
            {t('sbb.children_second_title_text')}
          </Typography>
          <Typography variant="p1">{t('sbb.children_section_second_paragraph_text')}</Typography>
        </div>
        <div className="image-section">
          <div className="image-container">
            <img
              className="top-image"
              src="https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/swiss-travel/lady-with-boy.jpg"
              alt="Kid riding on trolley"
              loading="lazy"
            />
            <img
              className="bottom-image"
              src="https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/swiss-travel/kid-on-trolley.jpg"
              alt="Boy and a Lady on a hike"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchInputPage;
