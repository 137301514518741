import { AMP_VERTICALS, YILU_FEATURE } from 'applicationConstants';
import { createContext } from 'react';
import { YiluAssetPathOverrides, YiluFeatures } from 'types';

export type EnterprisePartnerConfigurationContextType = {
  enterprisePartnerConfigurationState: {
    isEnterprisePartnerConfigurationLoading: boolean;
    enabledVerticals: Record<AMP_VERTICALS, boolean> | null;
    featureFlags: Partial<YiluFeatures> | null;
    assetPaths?: Partial<YiluAssetPathOverrides>;
  };
  loadEnterprisePartnerConfiguration: () => void;
  isFeatureFlagEnabled: (feature: YILU_FEATURE) => boolean;
  isVerticalEnabled: (ampVertical: AMP_VERTICALS) => boolean;
};

export const EnterprisePartnerConfigurationContext = createContext<
  EnterprisePartnerConfigurationContextType | undefined
>(undefined);
