import { useContext } from 'react';

import { EmbeddedEventType, EmbeddedTIContext } from './context';

export const useEmbeddedTIContext = () => {
  const context = useContext(EmbeddedTIContext);

  if (context === undefined) {
    throw new Error('useEmbeddedTIContext() must be used within EmbeddedTIContext.Provider');
  }

  return context;
};

export { EmbeddedTIContextProvider } from './Provider';
export { EmbeddedEventType };
