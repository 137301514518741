/**
 * # Error Boundary
 *
 * Catching errors.
 *
 */
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | undefined;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div role="alert">
          <p>Something went wrong:</p>
          <pre>{this.state.error && this.state.error.message}</pre>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

const AppErrorBoundary: React.FC = ({ children }) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default AppErrorBoundary;
