/**
 * # Parking Payment Input Page Hooks
 *
 *
 */
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useCurrentProduct } from 'hooks/index';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { yiluTracking } from 'utils/index';

import { useParkingProductSummaryProps } from 'modules/parking/pages/PaymentInputPage/hooks/useParkingProductSummaryProps';
import { usePaymentInfoFormProps } from 'modules/parking/pages/PaymentInputPage/hooks/usePaymentInfoFormProps';

import { getParkingPaymentInputPageQueryParams } from '../../../utils/query-params';

/**
 * Retrieve data for the parking payment input page.
 */
export const useParkingPaymentInputPage = () => {
  const location = useLocation();

  const parkingPaymentInputPageQueryParams = useMemo(() => {
    return getParkingPaymentInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(parkingPaymentInputPageQueryParams.searchResultID);
  const currentParking = currentProduct as SearchItem | undefined;
  const paymentFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(currentProduct);

  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    yiluTracking.sendGAEvent({
      event: 'page_loaded',
      category: 'parking-pip',
      label: 'parking_pip_loaded',
    });
  }, [isPageReady]);

  const paymentInformationFormProps = usePaymentInfoFormProps(
    currentParking,
    paymentFormRef,
    parkingPaymentInputPageQueryParams,
  );

  const parkingProductSummaryProps = useParkingProductSummaryProps(currentParking, paymentFormRef);

  return {
    isPageReady,
    paymentInformationFormProps,
    parkingProductSummaryProps,
  };
};
