/**
 * # Use Rides Search Input Page
 *
 *
 */
import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { sendGAEvent } from 'utils/tracking';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { useAssetPaths } from 'context/EnterprisePartnerConfiguration';

import { RidesSearchFormProps } from '../../../components';
import { useAirportDetails } from '../../../hooks/useAirportDetails';
import { getRidesSearchInputPageQueryParams } from '../../../utils/query-params';

const yiluEnv = getVariables();

/**
 * Retrieve data for the rides search input page.
 */
export const useSearchInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchInputPageQueryParams = useMemo(() => {
    return getRidesSearchInputPageQueryParams(location);
  }, [location]);

  useAirportDetails(searchInputPageQueryParams, routes.RIDES_SEARCH);

  const assetPaths = useAssetPaths();
  const sipImagePaths = assetPaths?.rides?.sipImagePaths;

  const productValueItems = useMemo(() => {
    return [
      {
        title: t('rideTitleOne'),
        text: t('rideSubtitleOne'),
        imageUrl:
          sipImagePaths?.[0] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/rides/usp/travel-ride-1.jpg',
      },
      {
        title: t('rideTitleTwo'),
        text: t('rideSubtitleTwo'),
        imageUrl:
          sipImagePaths?.[1] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/rides/usp/travel-ride-2.jpg',
      },
      {
        title: t('rideTitleThree'),
        text: t('rideSubtitleThree'),
        imageUrl:
          sipImagePaths?.[2] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/rides/usp/travel-ride-3.jpg',
      },
    ];
  }, [t, sipImagePaths]);

  const ridesSearchFormProps = useMemo(() => {
    const _props: RidesSearchFormProps = {
      searchInput: {
        originLocation: searchInputPageQueryParams.originLocation,
        destinationLocation: searchInputPageQueryParams.destinationLocation,
        transferDate: searchInputPageQueryParams.transferDate,
        transferTime: searchInputPageQueryParams.transferTime,
        travellersCount: searchInputPageQueryParams.travellersCount,
      },
      googleMapsAPIKey: yiluEnv.GOOGLE_MAPS_KEY!,
      onOpenDateTimeInput(e) {
        const isMobile = UiUtilities.getMediaMatch('--viewport-lte-m');
        if (!isMobile) {
          return;
        }
      },
      onSubmit(searchParams) {
        sendGAEvent({
          event: 'sip_cta_clicked',
          category: 'ride-sip',
          label: 'ride_sip_cta_clicked',
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const originAddressCoordinates = searchParams.originAddress.coordinates!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const destinationAddressCoordinates = searchParams.destinationAddress.coordinates!;

        const nextSearchInput = {
          originLocation: `${originAddressCoordinates.latitude},${originAddressCoordinates.longitude}`,
          destinationLocation: `${destinationAddressCoordinates.latitude},${destinationAddressCoordinates.longitude}`,
          transferDate: searchParams.transferDate,
          transferTime: searchParams.transferTime,
          travellersCount: searchParams.travellersCount,
        };

        const searchResultsPageQueryParams = {
          ...searchInputPageQueryParams,
          ...nextSearchInput,
        };

        navigate({
          pathname: routes.RIDES_SEARCH_RESULTS,
          search: getURLSearchQuery(searchResultsPageQueryParams),
        });
      },
      onError(error) {
        throw error;
      },
    };
    return _props;
  }, [searchInputPageQueryParams, navigate]);

  useEffect(() => {
    sendGAEvent({
      event: 'page_loaded',
      category: 'ride-sip',
      label: 'ride_sip_loaded',
    });
  }, []);

  return {
    ridesSearchFormProps,
    productValueItems,
  };
};
