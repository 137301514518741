import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'utils/i18n';
import { getCurrentWidgetPartnerId } from 'utils/parnerIds';

import { BookingTerms } from 'components/BookingConfirmation/components';
import 'components/BookingConfirmation/styles.scss';

import { ParkingBookingInfo } from './ParkingBookingInfo';
import './styles.scss';

type ParkingBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'PARKING'>;
};

export const ParkingBookingConfirmation: React.FC<ParkingBookingConfirmationProps> = ({
  booking,
}) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  useEffect(() => {
    window.initYiluWidget();
  }, [bookingData]);

  const isParkVia =
    booking.serviceProvider === 'PARK_VIA' ||
    booking.serviceProvider === 'Park Via' ||
    booking.serviceProvider === 'ParkVia';

  const isHolidayExtras = booking.serviceProvider === 'HolidayExtras Parking';

  const getEmailCheck = (parkingBooking: Booking<'COMPLETED', 'PARKING'>) => {
    let provider = '';

    if (isParkVia) {
      provider = 'ParkVia';
    } else if (isHolidayExtras) {
      return t('parking.bcp.holidayExtras.successMessage', {
        email: parkingBooking.product.data.customerDetails.email,
      });
    } else {
      //The address from Park Aero will always be the Airport's name
      provider = parkingBooking.product.data.parkingDetails.location.address || 'POI';
    }

    return t('parking.bcp.successMessage', {
      email: parkingBooking.product.data.customerDetails.email,
      provider: provider,
    });
  };

  return (
    <>
      {bookingData && (
        <>
          <ElementsSDK.Message
            className="yilu-payment-bcp-success__message"
            type={ElementsSDK.MessageType.SUCCESS}
            content={getEmailCheck(booking)}
          />
          {isParkVia && (
            <ElementsSDK.Button
              className="yilu-payment-bcp-save-confirmation_button"
              Icon={ElementsSDK.Icon.LinkOut}
              iconPosition={ElementsSDK.ButtonIconPositions.RIGHT}
              onClick={() => {
                window.open(bookingData.bookingConfirmationUrl, '_blank');
              }}
            >
              <>{t('parking.bcp.view.voucher.label')}</>
            </ElementsSDK.Button>
          )}
          <ParkingBookingInfo booking={booking} />
          <BookingTerms opensNewtab termsUrl={bookingData.parkingDetails.termsUrl} />
          <div className="yilu-BookingConfirmation__WidgetWrapper">
            <div
              id="yiluWidget"
              data-partner-id={getCurrentWidgetPartnerId() || 'lufthansa'}
              data-currency={bookingData.paymentDetails.totalPrice.currency}
              data-language={getCurrentLanguage()}
              data-passenger-count="1"
              data-touchpoint="simpletrip"
              data-booking-dot-com-display="Disable"
              data-hotels-dot-com-display="Disable"
              data-meili-display="Disable"
              data-cartrawler-display="Disable"
              data-flight-0-departure-iata={bookingData.parkingDetails.location.iata}
              data-flight-0-departure-datetime={bookingData.departureDate}
              data-flight-1-arrival-iata={bookingData.parkingDetails.location.iata}
              data-flight-1-arrival-datetime={bookingData.arrivalDate}
            />
          </div>
        </>
      )}
    </>
  );
};
