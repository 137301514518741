import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { decodeBase64 } from 'utils/base64Utils';

import routes from 'router/routes';

import { EmbeddedEventType, EmbeddedTIContext, EmbeddedTIContextType } from './context';

const YILU_POST_MESSAGE = 'YILU_POST_MESSAGE';

const EXPECTED_MESSAGES = {
  INITIALISE_EMBEDDED_TI: 'INITIALISE_EMBEDDED_TI',
};

const sendPostMessage = (eventType: EmbeddedEventType, data?: any) => {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        type: YILU_POST_MESSAGE,
        eventType,
        data,
      },
      '*',
    );
  }
};

export const EmbeddedTIContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Fire "EMBEDDED_TI_LOADED" event when the provider loads
    sendPostMessage(EmbeddedEventType.EMBEDDED_TI_LOADED);
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === EXPECTED_MESSAGES.INITIALISE_EMBEDDED_TI) {
        // After parsing the data we can figure out the proper booking flow.
        // For now we know we only support Meili booking flow in the embedded mode.
        navigate(routes.MEILI_CAR_RENTAL, { replace: true, state: decodeBase64(event.data.data) });
      }
    };

    // NOTE: This is just a placehodler. Resizing needs to be tested & properly configured ( if needed ).
    const onResizeWindow = () => {
      sendPostMessage(EmbeddedEventType.EMBEDDED_TI_RESIZE);
    };

    window.addEventListener('message', handleMessage);
    window.addEventListener('resize', onResizeWindow);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('resize', onResizeWindow);
    };
  }, [navigate]);

  const providerValue: EmbeddedTIContextType = { sendPostMessage };

  return <EmbeddedTIContext.Provider value={providerValue}>{children}</EmbeddedTIContext.Provider>;
};
