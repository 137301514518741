/**
 * # Analytics
 *
 *
 */
import querystring from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { paramConverters, yiluEnv, yiluTracking } from 'utils';

const HOTJAR_SNIPPET_VERSION = 6;

// Queued handling for potential lazy invocation.
window.dataLayer = window.dataLayer || [];
window.gtag = function gtag() {
  window.dataLayer.push(arguments);
};
window.hj =
  window.hj ||
  function hj() {
    window.hj.q.push(arguments);
  };
window.hj.q = window.hj.q || [];

const Analytics: React.FC = () => {
  const { search, pathname } = useLocation();
  const yiluEnvVariables = yiluEnv.getVariables();
  const googleTagManagerId = yiluEnvVariables.GOOGLE_TAG_MANAGER_CONTAINER_ID;
  const googleAnalyticsId = yiluEnvVariables.GOOGLE_ANALYTICS_MEASUREMENT_ID;
  const hotjarId = yiluEnvVariables.HOTJAR_ID ? Number(yiluEnvVariables.HOTJAR_ID) : undefined;
  const { tracking, touchpoint }: any = querystring.parse(search, {
    ignoreQueryPrefix: true,
  });
  const [isGoogleTagManagerReady, setIsGoogleTagManagerReady] = useState(false);
  const [isGoogleAnalyticsReady, setIsGoogleAnalyticsReady] = useState(false);

  // initialize Google Tag Manager
  useEffect(() => {
    if (!googleTagManagerId || isGoogleTagManagerReady) {
      return;
    }

    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`;
    script.onload = () => {
      setIsGoogleTagManagerReady(true);
    };

    document.body.appendChild(script);
  }, [googleTagManagerId, isGoogleTagManagerReady]);

  // initialize Google Analytics
  useEffect(() => {
    if (!googleAnalyticsId || isGoogleAnalyticsReady) {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.onload = () => {
      window.gtag('js', new Date());
      setIsGoogleAnalyticsReady(true);
    };

    document.body.appendChild(script);
  }, [googleAnalyticsId, isGoogleAnalyticsReady]);

  useEffect(() => {
    const trackingAllowed = tracking ? tracking.allowed === 'true' : false;

    window.gtag('config', googleAnalyticsId, {
      page_location: `${
        window.location.origin
      }${pathname}${paramConverters.omitExternalQueryParameters(search)}`,
      page_path: pathname,
      anonymize_ip: !trackingAllowed,
      custom_map: {
        // Custom dimensions defined for the different Google Analytics properties/projects
        dimension1: 'touchpoint',
      },
    });
  }, [pathname, search, googleAnalyticsId, tracking]);

  useEffect(() => {
    yiluTracking.sendGAEvent({
      event: 'first_page_load',
      category: 'page_load',
      label: 'first_load_success',
    });
  }, []);

  useEffect(() => {
    if (!touchpoint) {
      return;
    }

    const originalGoogleAnalytics = window.gtag;
    window.gtag = (
      command: 'config' | 'event' | 'set' | 'js',
      identifier: string,
      params: object,
    ) => {
      if (command === 'event') {
        Object.assign(params, {
          touchpoint,
        });
      }
      originalGoogleAnalytics(command, identifier, params);
    };
  }, [touchpoint]);

  // initialize Hotjar
  // -> https://help.hotjar.com/hc/en-us/articles/115011639927-Understanding-the-Tracking-Code
  useEffect(() => {
    if (!hotjarId) {
      return;
    }

    window._hjSettings = {
      hjid: hotjarId,
      hjsv: HOTJAR_SNIPPET_VERSION,
    };
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${HOTJAR_SNIPPET_VERSION}`;
    script.onload = () => {
      const hj = window.hj;

      const queryParams: any = querystring.parse(search, {
        ignoreQueryPrefix: true,
      });
      const queryLocale: string | undefined = queryParams.locale;
      const browserLanguage = window.navigator.language;
      const browserLocale = browserLanguage.split('-')[0];

      // An identifier value is omitted as matching user attributes is currently not necessary
      const userId = null;
      hj('identify', userId, {
        Locale: queryLocale || browserLocale,
        // User attribute Frame as simpletrip.io
        Frame: document.referrer,
      });
    };
    document.body.appendChild(script);
  }, [search, hotjarId]);

  return null;
};

export default Analytics;
