import { TFunction } from 'i18next';

/**
 * Formats the interconnections text depending on the number of interconnections
 * @param interconnectionTransfersCount - Number of interconnections
 * @return Returns the formatted interconnection text
 */
export function formatInterconnections(
  t: TFunction,
  interconnectionTransfersCount: number | null | undefined,
): string {
  if (!interconnectionTransfersCount) {
    return t('direct');
  }

  if (interconnectionTransfersCount === 1) {
    return t('1 change');
  }

  return t('{{changesCount}} changes', { changesCount: interconnectionTransfersCount });
}
