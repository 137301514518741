import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Imprint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ElementsSDK.Typography variant="p2">
        <b>Yilu Travel Services GmbH</b>
      </ElementsSDK.Typography>
      <br />
      <ElementsSDK.Typography variant="p2">
        <b>{t('legal.imprint.address')}</b>
        <br />
        Gormannstr. 14
        <br />
        10119 Berlin
        <br />
        Germany
      </ElementsSDK.Typography>
      <br />
      <ElementsSDK.Typography variant="p2">
        <b>Email</b>{' '}
        <ElementsSDK.Link inline style={{ fontSize: 'unset' }} href="mailto:info@yiluhub.com">
          info@yiluhub.com
        </ElementsSDK.Link>
        <br />
        <b>Internet</b>{' '}
        <ElementsSDK.Link inline style={{ fontSize: 'unset' }} href="https://yiluhub.com/">
          https://yiluhub.com/
        </ElementsSDK.Link>
      </ElementsSDK.Typography>
      <br />
      <ElementsSDK.Typography variant="p2">
        <b>{t('legal.imprint.registration-office')}</b> Charlottenburg
        <br />
        <b>{t('legal.imprint.registration-number')}</b> HRB 198186 B<br />
        <b>{t('legal.imprint.headquarter')}</b> Berlin
        <br />
      </ElementsSDK.Typography>
      <br />
      <ElementsSDK.Typography variant="p2">
        <b>{t('legal.imprint.managing-director')}</b>
        <br />
        Mark Meusch
      </ElementsSDK.Typography>
      <ElementsSDK.Typography variant="p2">
        <b>{t('legal.imprint.content-responsible')}</b>
        <br />
        Mark Meusch
      </ElementsSDK.Typography>
    </>
  );
};

export default Imprint;
