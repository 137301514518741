import React from 'react';
import { useTranslation } from 'react-i18next';

import AppleWalletButton from './AppleWallet';
import GoogleWallet, { LangType } from './GoogleWallet';

export type SaveQrToWalletButtonProps = {
  className?: string;
  bookingId: string;
  overrideOS?: 'android' | 'ios';
};
const SaveQrToWalletButton: React.FC<SaveQrToWalletButtonProps> = ({ bookingId, overrideOS }) => {
  const { i18n } = useTranslation();

  const currentLocale = i18n.language.toUpperCase();
  const isAndroid = /Android/.test(navigator.userAgent);

  const isChrome = /Chrome/.test(navigator.userAgent);
  const isSafariDesktop =
    /Safari/i.test(navigator.userAgent) && !isChrome && !/Mobi|Android/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || isSafariDesktop;

  const OS = overrideOS || (isAndroid ? 'android' : isIOS ? 'ios' : false);

  if (!OS) return null;

  return (
    <>
      {OS === 'android' && <GoogleWallet lang={currentLocale as LangType} bookingId={bookingId} />}
      {OS === 'ios' && <AppleWalletButton lang={currentLocale as LangType} bookingId={bookingId} />}
    </>
  );
};

export default SaveQrToWalletButton;
