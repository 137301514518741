import React from 'react';
import { useNavigate } from 'react-router-dom';

type RedirectPageOnConditionProps = {
  condition: boolean;
  redirectPath: string;
  PageElement: React.ReactElement;
};

const RedirectPageOnCondition: React.FC<RedirectPageOnConditionProps> = ({
  condition,
  redirectPath,
  PageElement,
}) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!condition) {
      navigate(redirectPath);
    }
  }, [condition, redirectPath, navigate]);

  return condition ? PageElement : null;
};

export default RedirectPageOnCondition;
