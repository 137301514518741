import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { airportUtils, paramConverters } from 'utils';

import routes from 'router/routes';

import { ParkingSearchResultsPageQueryParams } from '../../../utils/query-params';

const useSetAirportDetails = (
  isPageReady: boolean,
  parkingSearchResultsPageQueryParams: ParkingSearchResultsPageQueryParams,
  parkingAirports: ElementsSDK.FilteredAirportDetails[],
) => {
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const nextParkingSearchInputPageQueryParams = { ...parkingSearchResultsPageQueryParams };
      let selectedAirport;

      if (parkingAirports && parkingAirports.length > 0) {
        selectedAirport = parkingAirports.find(
          (airport) =>
            airport.iataCode.toLowerCase() ===
            parkingSearchResultsPageQueryParams.airportIataCode?.toLowerCase(),
        );

        if (selectedAirport && selectedAirport.localizations) {
          nextParkingSearchInputPageQueryParams.airportDetails =
            populateAirportDetails(selectedAirport);
        } else {
          // Check if Airports details already fetched
          if (Boolean(parkingSearchResultsPageQueryParams.airportDetails)) {
            return false;
          }

          // Check if airportIatacode has been removed from query params
          if (!parkingSearchResultsPageQueryParams.airportIataCode) {
            return false;
          }

          // The specified IATA isn't in the airport parking list. Fetch is directly
          selectedAirport = await airportUtils.getAirportDetails(
            parkingSearchResultsPageQueryParams.airportIataCode,
          );

          if (!selectedAirport) {
            return false;
          }
          nextParkingSearchInputPageQueryParams.airportDetails =
            populateAirportDetails(selectedAirport);
        }

        delete nextParkingSearchInputPageQueryParams.airportIataCode;

        navigate(
          {
            pathname: routes.PARKING_SEARCH_RESULTS,
            search: paramConverters.getURLSearchQuery(nextParkingSearchInputPageQueryParams),
          },
          { replace: true },
        );
      }
    })();
  }, [isPageReady, parkingSearchResultsPageQueryParams, navigate, parkingAirports]);
};

const populateAirportDetails = (selectedAirport: ElementsSDK.FilteredAirportDetails) => {
  return {
    localizations: {
      de: selectedAirport.localizations.de,
      en: selectedAirport.localizations.en,
    },
    iataCode: selectedAirport.iataCode,
    latitude: selectedAirport.latitude,
    longitude: selectedAirport.longitude,
  };
};

export default useSetAirportDetails;
