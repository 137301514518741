import { Airport } from '@yiluhub/yilu-amp-types';

import { getVariables } from 'utils/yiluEnv';

export const prioritizeAirports = (
  allAirports: Pick<Airport, 'iataCode'>[],
  prioritizedAirports: string[],
  numberOfPopularAirports: number,
): string[] => {
  const popularAirports = [];

  for (let i = 0; i < prioritizedAirports.length; i++) {
    if (popularAirports.length < numberOfPopularAirports) {
      const popularAirport = allAirports.find(
        (airport) => airport.iataCode === prioritizedAirports[i],
      );
      if (popularAirport) {
        popularAirports.push(popularAirport.iataCode);
      }
    } else {
      break;
    }
  }

  return popularAirports;
};

export const getAirportDetails = async (iataCode: string) => {
  const yiluEnv = getVariables();
  const baseURL = yiluEnv.YILU_AMP_BACKEND_URL;

  try {
    const response = await fetch(`${baseURL}/datasets/v1/airports/${iataCode}`);
    const airport: Airport = await response.json();
    if (!airport) {
      throw new Error(`Airport not supported: ${iataCode}`);
    }
    return airport;
  } catch (error) {
    console.warn('Error fetching airport data:', error);
    return;
  }
};
