import axios, { AxiosResponse } from 'axios';

import { getVariables } from 'utils/yiluEnv';

import { GetAppleWalletResponse, GetGoogleWalletResponse, GetWalletRequest } from '../utils/types';

export enum LoungeWalletResourcePath {
  GOOGLE_WALLET = '/wallet/google-pass-link/lounge/:bookingId',
  APPLE_WALLET = '/wallet/apple-pass/lounge/:bookingId',
}

export function requestLoungeWallet<T>(
  resourcePath: LoungeWalletResourcePath,
  { bookingId, axiosConfig }: GetWalletRequest,
): Promise<AxiosResponse<T>> {
  const yiluEnv = getVariables();

  if (!bookingId) {
    throw new Error('bookingId is required');
  }

  if (!yiluEnv.YILU_AMP_BACKEND_URL) {
    throw new Error('YILU_AMP_BACKEND_URL is required');
  }

  const url = new URL(yiluEnv.YILU_AMP_BACKEND_URL);

  url.pathname = resourcePath.replace(':bookingId', bookingId);
  url.searchParams.append('amp', '1');

  return axios.get(url.toString(), axiosConfig);
}

export async function getLoungeGoogleWalletLink(walletRequest: GetWalletRequest) {
  return requestLoungeWallet<GetGoogleWalletResponse>(
    LoungeWalletResourcePath.GOOGLE_WALLET,
    walletRequest,
  );
}

export async function getLoungeAppleWalletPass(walletRequest: GetWalletRequest) {
  return requestLoungeWallet<GetAppleWalletResponse>(LoungeWalletResourcePath.APPLE_WALLET, {
    ...walletRequest,
    axiosConfig: { responseType: 'blob' },
  });
}
