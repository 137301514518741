import { getYiluCaches, setYiluCaches } from '@yiluhub/ui-utilities';
import { Airport, GetLocationsByStoreIdResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { yiluEnv } from 'utils';

type AmpAirportLocationResponse = {
  verticalIds: string[];
  catalogIds: string[];
  airport: Airport;
};

const normaliseAirportLocations = (airportResponse: AmpAirportLocationResponse[]): Airport[] => {
  return airportResponse.map((response) => response.airport);
};

export const useStationAirports = () => {
  const AIRPORT_TRANSFER_AIRPORTS_ARRAY_CACHE_KEY = 'airportTransferStationAirports';
  const [network, setNetwork] = useState({ isFetching: false, isFetched: false });

  const cachedAirports = getYiluCaches(
    AIRPORT_TRANSFER_AIRPORTS_ARRAY_CACHE_KEY,
  ) as unknown as Airport[];

  const [stationAirports, setStationAirports] = useState<Airport[]>(
    Array.isArray(cachedAirports) ? cachedAirports : [],
  );

  useEffect(() => {
    if (!network.isFetched && !network.isFetching) {
      setNetwork({ isFetching: true, isFetched: false });
      const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID } =
        yiluEnv.getVariables();

      axios
        .get<GetLocationsByStoreIdResponse>(
          `${YILU_AMP_BACKEND_URL}/store/v1/stores/${YILU_AMP_STORE_ID}/locations?verticalId=${YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID}`,
        )
        .then((res) => {
          const airportsList = normaliseAirportLocations(res.data!);
          setStationAirports(airportsList);
          setYiluCaches(AIRPORT_TRANSFER_AIRPORTS_ARRAY_CACHE_KEY, airportsList);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setNetwork({ isFetching: false, isFetched: true });
        });
    }
  }, [network]);

  return stationAirports;
};
