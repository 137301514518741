/**
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { TransferDirection } from 'applicationConstants';
import { useCallback, useEffect, useState } from 'react';

import {
  AirportTransferSearchFormProps,
  AirportTransferSearchOutputParams,
} from '../../../../components';

const DEFAULT_INITIAL_DIRECTION = TransferDirection.FROM_AIRPORT;

export type AirportTransferCollapsibleFormParams = Pick<
  AirportTransferSearchFormProps,
  'searchInput' | 'googleMapsAPIKey' | 'onError' | 'onSubmit'
> & {
  isExpanded?: boolean;
  onCollapsedClick?(): unknown;
};

export function useAirportTransferCollapsibleForm({
  googleMapsAPIKey,
  searchInput,
  isExpanded: isExpandedExternal,
  onSubmit,
  onCollapsedClick,
  onError,
}: AirportTransferCollapsibleFormParams) {
  const locale = UiUtilities.getYiluConfigLocale();

  // rendered Location
  const locationDetails = ElementsSDK.useAddressDetails(
    googleMapsAPIKey,
    searchInput && searchInput.location,
    onError,
  );
  const [renderedLocationName, setRenderedLocationName] = useState(
    locationDetails ? locationDetails.name : undefined,
  );

  // rendered Direction
  const [renderedDirection, setRenderedDirection] = useState<TransferDirection>(
    searchInput?.direction || DEFAULT_INITIAL_DIRECTION,
  );

  // rendered Airport
  const [renderedAirportName, setRenderedAirportName] = useState<string | undefined>(
    searchInput?.airportDetails
      ? searchInput?.airportDetails.localizations[locale].name
      : undefined,
  );

  const [isExpandedInternal, setIsExpandedInternal] = useState(Boolean(isExpandedExternal));

  const handleOnSubmit = useCallback(
    (searchParams: AirportTransferSearchOutputParams) => {
      // uncontrolled component -> collapse
      if (typeof isExpandedExternal === 'undefined') {
        setIsExpandedInternal(false);
      }
      onSubmit(searchParams);

      setRenderedDirection(searchParams.direction);
      setRenderedAirportName(
        searchParams.airportDetails
          ? searchParams.airportDetails.localizations[locale].name
          : undefined,
      );
      setRenderedLocationName(
        searchParams.locationDetails ? searchParams.locationDetails.name : undefined,
      );
    },
    [onSubmit, locale, isExpandedExternal],
  );

  const handleOnCollapsedClick = useCallback(() => {
    if (typeof onCollapsedClick === 'function') {
      onCollapsedClick();
    }
    // uncontrolled component -> expand
    if (typeof isExpandedExternal === 'undefined') {
      setIsExpandedInternal(true);
    }
  }, [onCollapsedClick, isExpandedExternal]);

  const handleOnCloseClick = useCallback(() => {
    // uncontrolled component -> collapse
    if (typeof isExpandedExternal === 'undefined') {
      setIsExpandedInternal(false);
    }
  }, [isExpandedExternal]);

  useEffect(() => {
    if (typeof isExpandedExternal === 'undefined') {
      return;
    }
    setIsExpandedInternal(isExpandedExternal);
  }, [isExpandedExternal]);

  // update if location data are retrieved
  useEffect(() => {
    if (!locationDetails) {
      return;
    }
    setRenderedLocationName(locationDetails.name);
  }, [locationDetails]);

  return {
    isExpandedInternal,
    renderedLocationName,
    renderedAirportName,
    renderedDirection,
    handleOnSubmit,
    handleOnCollapsedClick,
    handleOnCloseClick,
  };
}
