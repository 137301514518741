import { AMP_VERTICALS, YILU_FEATURE } from 'applicationConstants';
import { useContext } from 'react';

import { EnterprisePartnerConfigurationContext } from './context';

export const useIsFeatureFlagEnabled = (feature: YILU_FEATURE) => {
  const context = useContext(EnterprisePartnerConfigurationContext);

  if (context === undefined) {
    throw new Error(
      'useIsFeatureFlagEnabled() must be used within EnterprisePartnerConfigurationContext.Provider',
    );
  }

  return context.isFeatureFlagEnabled(feature);
};

export const useIsVerticalEnabled = (vertical: AMP_VERTICALS) => {
  const context = useContext(EnterprisePartnerConfigurationContext);

  if (context === undefined) {
    throw new Error(
      'useIsVerticalEnabled() must be used within EnterprisePartnerConfigurationContext.Provider',
    );
  }

  return context.isVerticalEnabled(vertical);
};

export const useEnterprisePartnerConfigurationContext = () => {
  const context = useContext(EnterprisePartnerConfigurationContext);

  if (context === undefined) {
    throw new Error(
      'useEnterprisePartnerConfigurationContext() must be used within EnterprisePartnerConfigurationContext.Provider',
    );
  }

  return context;
};

export const useAssetPaths = () => {
  const context = useContext(EnterprisePartnerConfigurationContext);

  if (context === undefined) {
    throw new Error(
      'useAssetPaths() must be used within EnterprisePartnerConfigurationContext.Provider',
    );
  }

  return context.enterprisePartnerConfigurationState.assetPaths;
};

export { EnterprisePartnerConfigurationContextProvider } from './Provider';
