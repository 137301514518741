/**
 * # Lounge Search Form
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { roundUpToNext15MinutesInLocalTime } from 'utils/dateUtils';

import { MAX_LOUNGE_GUESTS } from '../../utils/constants';
import './styles.scss';
import { LoungeSearchFormProps } from './types';
import { useLoungeSearchForm } from './useLoungeSearchForm';

export const LoungeSearchForm: React.FC<LoungeSearchFormProps> = ({
  className,
  searchInput,
  maxTravellersCount = MAX_LOUNGE_GUESTS,
  popularAirportsIATAs,
  loungeAirports,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    travellersFields,
    handleOnSubmitButtonClick,
    handleOnAirportSelect,
    selectedAirportName,
    enterDateTime,
    setEnterDateTime,
    isFormValid,
  } = useLoungeSearchForm({
    onSubmit,
    searchInput,
  });

  const { isDesktop } = UiUtilities.useIsDesktop();
  const entryDateId = useMemo(() => uuidv4(), []);
  const addressId = useMemo(() => uuidv4(), []);
  const TIME_INTERVAL = 15;

  const normalizedDateTime = roundUpToNext15MinutesInLocalTime(enterDateTime!);

  return (
    <div className={clsx('yilu-LoungeSearchForm', className)}>
      <ElementsSDK.FilteredAirportSearchInput
        classname={clsx([
          'yilu-LoungeSearchForm__InputField',
          'yilu-LoungeSearchForm__Airport-InputField',
        ])}
        label={isDesktop ? t('Location') : undefined}
        onAddressSelect={handleOnAirportSelect}
        id={addressId}
        placeholder={t('City or airport')}
        value={selectedAirportName}
        popularAirportsIATAs={popularAirportsIATAs}
        airportList={loungeAirports}
      />
      <ElementsSDK.InputField.DateTimePicker
        className={'yilu-LoungeSearchForm__InputField'}
        name={entryDateId}
        label={isDesktop ? t('lounges.srp.datepicker.label') : undefined}
        onChange={setEnterDateTime}
        dateFormat={UiUtilities.DateFormat.DATE}
        initialValue={normalizedDateTime}
        minDate={dayjs().format(UiUtilities.DateFormat.SHORT_DATE)}
        timeControlsEnabled
        id={entryDateId}
        timeInterval={TIME_INTERVAL}
        labelTooltip={isDesktop ? t('lounges.srp.datepicker.tooltipText') : undefined}
      />
      <ElementsSDK.TravellersSelector
        className="yilu-LoungeSearchForm__InputField"
        maxTravellersCount={maxTravellersCount}
        countLabelSingular={t('Guest')}
        countLabelPlural={t('Guests')}
        label={isDesktop ? t('Guests') : undefined}
        maxedOutMessage={t('Maximum guests reached', { limit: maxTravellersCount })}
        dataTestId={'lounge-search-form-travellers-selector'}
        travellers={travellersFields}
      />
      <ElementsSDK.Button
        aria-label={t('Search')}
        className={clsx([
          'yilu-LoungeSearchForm__InputField',
          'yilu-LoungeSearchForm__SubmitButton',
        ])}
        disabled={!isFormValid}
        onClick={handleOnSubmitButtonClick}
        Icon={isDesktop ? ElementsSDK.Icon.Magnifier : undefined}
      >
        {isDesktop ? undefined : t('Search')}
      </ElementsSDK.Button>
    </div>
  );
};
