import { yiluEnv } from 'utils/index';

export enum PartnerId {
  LUFTHANSA = 'LH',
  SWISS = 'LX',
  AUSTRIAN = 'OS',
  BRUSSELS = 'SN',
  DISCOVER = '4Y',
  AIRDOLOMITI = 'EN',
}

export enum WidgetPartnerId {
  LUFTHANSA = 'lufthansa',
  SWISS = 'swiss',
  AUSTRIAN = 'austrian',
  BRUSSELS = 'brussels',
  AIRDOLIMITI = 'airdolomiti',
}

export function getCurrentWidgetPartnerId(): WidgetPartnerId | undefined {
  const yiluEnvVariables = yiluEnv.getVariables();
  const partnerId = yiluEnvVariables.YILU_ENTERPRISE_PARTNER;

  switch (partnerId) {
    case PartnerId.LUFTHANSA:
      return WidgetPartnerId.LUFTHANSA;
    case PartnerId.SWISS:
      return WidgetPartnerId.SWISS;
    case PartnerId.AUSTRIAN:
      return WidgetPartnerId.AUSTRIAN;
    case PartnerId.BRUSSELS:
      return WidgetPartnerId.BRUSSELS;
    case PartnerId.AIRDOLOMITI:
      return WidgetPartnerId.AIRDOLIMITI;
    default:
      return undefined;
  }
}
