import { SearchItem } from '@yiluhub/yilu-amp-types';

import { extractAmpProductAttribute } from 'utils/ampUtils';

export type ProductDetails = {
  duration: number;
  startDate: string;
  passengersCount: number;
  price: number;
  currency: string;
  searchResultID: string;
  providerId: string;
  providerName: string;
  termsUrl: string;
  optionId: string;
  distribusionFareClass: string;
  departureTime: string;
  priceOptionId: string;
  catalogId: string;
};

function convertSecondsToDays(seconds: number): number {
  const secondsPerDay = 86400; // 60 seconds * 60 minutes * 24 hours
  return Math.floor(seconds / secondsPerDay);
}

export const getProductDetails = (data: SearchItem | undefined | null): ProductDetails => {
  if (!data) return {} as ProductDetails;

  return {
    termsUrl: extractAmpProductAttribute(data, 'terms_url') || '',
    providerName: extractAmpProductAttribute(data, 'carrier_name') || '',
    providerId: extractAmpProductAttribute(data, 'service_provider') || '',
    duration: convertSecondsToDays(Number(extractAmpProductAttribute(data, 'duration'))),
    startDate: extractAmpProductAttribute(data, 'arrival_time') || '',
    departureTime: extractAmpProductAttribute(data, 'departure_time') || '',
    passengersCount: Number(extractAmpProductAttribute(data, 'number_of_passengers')),
    distribusionFareClass: extractAmpProductAttribute(data, 'distribusion_fare_class') || '',
    catalogId: data.item.catalogId,
    price: data.item.priceOptions[0].price.amount / 100,
    currency: data.item.priceOptions[0].price.currency,
    searchResultID: data.id,
    optionId: data.id,
    priceOptionId: data.item.priceOptions[0].id,
  };
};
