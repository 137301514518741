import {
  CreateStripeSetupIntentResponse,
  GetPaymentOptionsResponse,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { yiluEnv } from 'utils';

export const usePaymentSetup = (
  input: {
    price: number;
    currency: string;
  },
  serviceProviderId: string,
  shoppingCartId: string,
  bookingIntentId: string,
  onError: (error: Error) => unknown,
) => {
  const [paymentOptions, setPaymentOptions] = useState<GetPaymentOptionsResponse>();
  const [clientSecret, setClientSecret] = useState<CreateStripeSetupIntentResponse>();
  const [network, setNetwork] = useState({ isFetching: false, isFetched: false });
  useEffect(() => {
    if (!network.isFetched && !network.isFetching) {
      setNetwork({ isFetching: true, isFetched: false });
      const yiluEnvVariables = yiluEnv.getVariables();

      const getPaymentOptions = axios.get<GetPaymentOptionsResponse>(
        `${yiluEnvVariables.YILU_AMP_BACKEND_URL}/payment/v1/payments/options?shoppingCartId=${shoppingCartId}`,
      );

      const createStripeIntent = axios.post<CreateStripeSetupIntentResponse>(
        `${yiluEnvVariables.YILU_AMP_BACKEND_URL}/payment/v1/payments/stripe/setup-intents`,
        {
          metadata: {
            providerId: serviceProviderId.toUpperCase(),
            price: input.price,
            currency: input.currency,
            bookingIntentId,
          },
        },
      );

      Promise.all([getPaymentOptions, createStripeIntent])
        .then((results) => {
          const [paymentOptionsResponse, paymentIntentResponse] = results;
          setPaymentOptions(paymentOptionsResponse.data);
          setClientSecret(paymentIntentResponse.data);
        })
        .catch((error) => {
          if (typeof onError === 'function') {
            onError(error);
          } else {
            console.error('Could not setup payment intent', error);
          }
        })
        .finally(() => setNetwork({ isFetching: false, isFetched: true }));
    }
  }, [network, bookingIntentId, input, shoppingCartId, serviceProviderId, onError]);

  return {
    paymentOptions,
    clientSecret,
  };
};
