import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import routes from 'router/routes';

import { ParkingSearchFormProps } from '../../../components';
import { ParkingSearchInputPageQueryParams } from '../../../utils/query-params';

/**
 * Retrieve data for the parking search input page.
 */
const useParkingSearchFormProps = (
  parkingSearchInputPageQueryParams: ParkingSearchInputPageQueryParams,
  parkingAirports: ElementsSDK.FilteredAirportDetails[],
  popularAirportsIATAs?: string[],
) => {
  const navigate = useNavigate();
  const parkingSearchFormProps = useMemo(() => {
    const { entryDate, entryTime, exitDate, exitTime, airportDetails, flightTime } =
      parkingSearchInputPageQueryParams;

    const isAirportDetailsParamsValid =
      airportDetails?.iataCode &&
      airportDetails.latitude &&
      airportDetails.longitude &&
      airportDetails.localizations;

    let validatedAirportDetails;

    if (!isAirportDetailsParamsValid && parkingAirports.length > 0) {
      const selectedAirport = parkingAirports.find(
        (airport) => airport.iataCode === airportDetails?.iataCode,
      );

      if (selectedAirport) {
        validatedAirportDetails = {
          iataCode: selectedAirport.iataCode,
          longitude: selectedAirport.longitude,
          latitude: selectedAirport.latitude,
          localizations: selectedAirport.localizations,
        };
      }
    }

    const _props: ParkingSearchFormProps = {
      searchInput: {
        entryDate,
        entryTime,
        exitDate,
        exitTime,
        airportDetails: isAirportDetailsParamsValid ? airportDetails : validatedAirportDetails,
      },
      flightTime,
      parkingAirports,
      popularAirportsIATAs,
      onSubmit(searchInput) {
        const parkingSearchResultsPageQueryParams = {
          ...parkingSearchInputPageQueryParams,
          ...searchInput,
        };

        if (!searchInput.airportDetails) {
          // Ensure to not carry airport details from a previous search
          delete parkingSearchResultsPageQueryParams.airportDetails;
        }

        navigate({
          pathname: routes.PARKING_SEARCH_RESULTS,
          search: paramConverters.getURLSearchQuery(parkingSearchResultsPageQueryParams),
        });

        yiluTracking.sendGAEvent({
          event: 'sip_cta_clicked',
          category: 'parking-sip',
          label: 'parking_sip_cta_clicked',
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [parkingSearchInputPageQueryParams, navigate, parkingAirports, popularAirportsIATAs]);

  return parkingSearchFormProps;
};

export default useParkingSearchFormProps;
