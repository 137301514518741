/**
 * # Parking Booking Info
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatCurrency, formatLocalDate } from '@yiluhub/ui-utilities';
import { Booking } from '@yiluhub/yilu-amp-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getAnchorTagHref } from 'utils/htmlUtils';

import { resolveTimeToPoiText } from 'modules/parking/utils/distanceUtils';

import './styles.scss';

export type ParkingBookingInfoProps = {
  booking: Booking<'COMPLETED', 'PARKING'>;
};

const AdditionalParkingInfo: React.FC<ParkingBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const additionalContent = useMemo(() => {
    const content: {
      title?: string;
      mainText: string | React.ReactNode;
    }[] = [];

    const {
      insurance,
      information,
      childSeats,
      transfer,
      directions,
      securityMeasures,
      supplierTerms,
    } = booking.product.data.parkingDetails;

    if (information) {
      content.push({
        mainText: information,
      });
    }

    if (transfer) {
      content.push({
        mainText: transfer,
        title: t('bcp.parking.transfer.label'),
      });
    }

    if (directions) {
      content.push({
        mainText: directions,
        title: t('bcp.parking.directions.label'),
      });
    }

    if (securityMeasures) {
      content.push({
        mainText: securityMeasures,
        title: t('bcp.parking.securityMeasures.label'),
      });
    }

    if (childSeats) {
      content.push({
        mainText: childSeats,
        title: t('bcp.parking.childSeats.label'),
      });
    }

    if (insurance) {
      content.push({
        mainText: insurance,
        title: t('bcp.parking.insurance.label'),
      });
    }

    if (supplierTerms) {
      const anchorTagHref = getAnchorTagHref(supplierTerms);
      let href = supplierTerms;

      if (anchorTagHref) {
        href = anchorTagHref;
      }

      content.push({
        mainText: (
          <ElementsSDK.Link className="yilu-bcp__SupportLink" href={href} underline target="_blank">
            {t('bcp.parking.supplierTerms.link')}
          </ElementsSDK.Link>
        ),
        title: t('bcp.parking.supplierTerms.label'),
      });
    }

    return content;
  }, [t, booking.product.data.parkingDetails]);

  if (additionalContent?.length === 0) {
    return null;
  }

  return (
    <>
      <span className="yilu-BookingConfirmation__Separator" />
      <ElementsSDK.Typography
        variant="h2"
        className="yilu-Parking-Booking__ConfirmationPage__SummaryLabel"
      >
        {t('bcp.parking.additional.label')}
      </ElementsSDK.Typography>
      {additionalContent.map((content, index) => (
        <div className="yilu-Parking-Booking__ConfirmationPage__Additional__Section">
          {content.title && <ElementsSDK.Typography bold>{content.title}:</ElementsSDK.Typography>}
          {typeof content.mainText == 'string' ? (
            <ElementsSDK.Typography
              variant="p1"
              dangerouslySetInnerHTML={{
                __html: content.mainText,
              }}
            />
          ) : (
            <div>{content.mainText}</div>
          )}
        </div>
      ))}
    </>
  );
};

export const ParkingBookingInfo: React.FC<ParkingBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;
  const { transferTimeByShuttle, providerPhoneNumber, providerPhoneNumberAdditionalInfo } =
    bookingData.parkingDetails;

  const walkingDetails = bookingData.parkingDetails.productDetails?.walkingDistance;

  const address = bookingData.parkingDetails.location.address || 'POI';
  const timeToPOI =
    resolveTimeToPoiText(t, address, transferTimeByShuttle, walkingDetails) || address;

  const isParkVia =
    booking.serviceProvider === 'PARK_VIA' || booking.serviceProvider === 'Park Via';

  const description = bookingData.parkingDetails.productDetails?.description;

  const { totalPrice } = bookingData.paymentDetails;
  const { amount, currency } = totalPrice;

  const paymentMethod =
    'paymentMethod' in bookingData.paymentDetails
      ? bookingData.paymentDetails.paymentMethod
      : undefined;

  const paymentDetailsText = `${formatCurrency(amount / 100, currency)} ${
    paymentMethod ? `${t('charged to')} ${paymentMethod}` : ''
  }`;

  const { firstName, lastName, email, phoneNumber, carPlate } = bookingData.customerDetails;
  const renderedCustomerData = [`${firstName} ${lastName}`, email];
  if (!!phoneNumber) renderedCustomerData.push(phoneNumber);
  if (!!carPlate) renderedCustomerData.push(carPlate);

  const providerPhoneNumberText = useMemo(() => {
    if (!providerPhoneNumber) {
      return [];
    }
    const phoneNumber = providerPhoneNumber.trim();
    const phoneMainText = [phoneNumber];

    if (providerPhoneNumberAdditionalInfo && providerPhoneNumberAdditionalInfo !== phoneNumber) {
      phoneMainText.push(providerPhoneNumberAdditionalInfo);
    }
    return phoneMainText;
  }, [providerPhoneNumber, providerPhoneNumberAdditionalInfo]);

  return (
    <div className="yilu-Parking-Booking__ConfirmationPage">
      {(bookingData?.barcodeUrl || bookingData?.qrCodeUrl) && (
        <div className="yilu-Parking-Booking__codeContainer">
          <div className="yilu-Parking-Booking__codeTitleWrapper">
            {bookingData?.qrCodeUrl ? (
              <ElementsSDK.Typography variant="h2">
                {t('bcp.parking.qrCode.header')}
              </ElementsSDK.Typography>
            ) : (
              <ElementsSDK.Typography variant="h2">
                {t('bcp.parking.barcode.header')}
              </ElementsSDK.Typography>
            )}
          </div>
          <div className="yilu-Parking-Booking__codeImage">
            <img
              src={bookingData?.qrCodeUrl || bookingData?.barcodeUrl}
              alt={t(`Parking ${bookingData?.qrCodeUrl ? 'QR Code' : 'Barcode'} Image`)}
              className="yilu-Parking-Booking__code"
            />
          </div>
        </div>
      )}
      {(bookingData.parkingDetails.arrivalProcedures ||
        bookingData.parkingDetails.departureProcedures) && (
        <div className="yilu-Parking-Booking__ConfirmationPage__Entrance">
          <ElementsSDK.Typography
            variant="h2"
            className="yilu-Parking-Booking__ConfirmationPage__EntranceLabel"
          >
            {t('bcp.parking.entrance-instructions.label')}
          </ElementsSDK.Typography>
          {bookingData.parkingDetails.arrivalProcedures && (
            <ElementsSDK.Typography
              variant={'p1'}
              className="yilu-Parking-Booking__ConfirmationPage__EntranceContent"
              dangerouslySetInnerHTML={{
                __html: bookingData.parkingDetails.arrivalProcedures,
              }}
            ></ElementsSDK.Typography>
          )}
          {bookingData.parkingDetails.departureProcedures && (
            <ElementsSDK.Typography
              variant={'p1'}
              className="yilu-Parking-Booking__ConfirmationPage__EntranceContent"
              dangerouslySetInnerHTML={{
                __html: bookingData.parkingDetails.departureProcedures,
              }}
            ></ElementsSDK.Typography>
          )}
        </div>
      )}

      <span className="yilu-BookingConfirmation__Separator" />
      <ElementsSDK.Typography
        variant="h2"
        className="yilu-Parking-Booking__ConfirmationPage__SummaryLabel"
      >
        {t('bcp.parking.summary.label')}
      </ElementsSDK.Typography>
      <ElementsSDK.ContentBox
        mainTexts={[booking.id]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      {bookingData?.bookingId && (
        <ElementsSDK.ContentBox
          mainTexts={[bookingData.bookingId]}
          subText={t('bcp.spBookingId')}
          icon={<ElementsSDK.Icon.Receipt />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.parkingDetails.name]}
        subText={t('bcp.parking')}
        icon={<ElementsSDK.Icon.Parking />}
      />
      {address && (
        <ElementsSDK.ContentBox
          mainTexts={[isParkVia ? address : timeToPOI]}
          subText={t('bcp.location')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      {providerPhoneNumberText?.length > 0 && (
        <ElementsSDK.ContentBox
          mainTexts={providerPhoneNumberText}
          subText={t('bcp.parking.providerPhoneNumber.label')}
          icon={<ElementsSDK.Icon.Phone />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={[formatLocalDate(bookingData.arrivalDate, DateFormat.DATE_WITH_TIME)]}
        subText={t('bcp.enterParking')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[formatLocalDate(bookingData.departureDate, DateFormat.DATE_WITH_TIME)]}
        subText={t('bcp.exitParking')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={renderedCustomerData}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[paymentDetailsText]}
        subText={t('bcp.paymentDetails')}
        icon={<ElementsSDK.Icon.Card />}
      />
      {description && (
        <>
          <ElementsSDK.Divider />
          <ElementsSDK.Typography variant="h2" className="yilu-Parking_AdditionalInfo__Header">
            {t('Description')}
          </ElementsSDK.Typography>
          <ElementsSDK.Typography
            variant="p1"
            className="yilu-Parking__DescriptionContent"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </>
      )}

      <AdditionalParkingInfo booking={booking} />
    </div>
  );
};
