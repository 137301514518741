import { DateFormat } from '@yiluhub/ui-utilities';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useCurrentProduct } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { formatLocalDate, roundUpToNext15MinutesInLocalTime } from 'utils/dateUtils';
import i18n from 'utils/i18n';
import { paramConverters } from 'utils/index';
import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { constructFlightNumberSessionStorage } from 'modules/lounges/utils/constructFlightNumberSessionStorage';
import { constructLoungeBacklinkProps } from 'modules/lounges/utils/constructLoungeBackLinkProps';
import { fetchLoungeProduct } from 'modules/lounges/utils/fetchLoungeProduct';
import { getProductDetails } from 'modules/lounges/utils/getProductDetails';

import { redirectToLoungeSip, useRedirectOnLoungeSearchResultNotFound } from '../../../hooks';
import {
  LoungeProductDetailsPageQueryParams,
  getLoungeProductDetailsPageQueryParams,
  getLoungeSearchInputPageQueryParams,
} from '../../../utils/query-params';
import useLoungeProductDetailsProps from './useLoungeProductDetailsProps';
import useLoungeProductSummaryProps from './useLoungeProductSummaryProps';
import useTracking from './useTracking';

export const useDeepLinkLoungeProduct = ({
  loungeId,
  searchResultID,
  singleProduct,
  entryDate,
  adults,
  children,
  infants,
  coordinates,
}: LoungeProductDetailsPageQueryParams) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loungeProduct, setLoungeProduct] = useState<SearchItem | null>(null);

  const locale = i18n.language;

  const roundedUpDate = useMemo(
    () => entryDate && roundUpToNext15MinutesInLocalTime(entryDate),
    [entryDate],
  );

  const searchInputPageQueryParams = useMemo(() => {
    return getLoungeSearchInputPageQueryParams(location);
  }, [location]);

  const loadLoungeProduct = useCallback(async () => {
    try {
      if (!loungeId) {
        return;
      }

      const body = await fetchLoungeProduct(
        loungeId,
        locale,
        { adults: adults || 1, children: children || 0, infants: infants || 0 },
        roundedUpDate,
        coordinates,
      );

      const loungeProductDetailsPageQueryParams = {
        searchResultID: body.id,
        singleProduct: singleProduct,
      };

      setLoungeProduct(body);

      navigate(
        {
          pathname: routes.LOUNGES_PDP,
          search: paramConverters.getURLSearchQuery(loungeProductDetailsPageQueryParams),
        },
        { replace: true },
      );
    } catch (error) {
      navigate(
        {
          pathname: routes.LOUNGES_SEARCH,
          search: getURLSearchQuery(searchInputPageQueryParams),
        },
        { replace: true },
      );
    } finally {
      setIsLoading(false);
      setLoaded(true);
    }
  }, [
    searchInputPageQueryParams,
    navigate,
    locale,
    loungeId,
    adults,
    children,
    infants,
    roundedUpDate,
    coordinates,
    singleProduct,
  ]);

  useEffect(() => {
    if (loungeId && !searchResultID && !loaded && !loungeProduct && !isLoading) {
      setIsLoading(true);
      loadLoungeProduct();
    }
  }, [isLoading, loungeProduct, loungeId, searchResultID, loaded, loadLoungeProduct]);

  return { loungeProduct };
};

export const useLoungeDetailsContent = (currentLounge: SearchItem) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const productDetails = getProductDetails(currentLounge);

  // user selected entry date
  const [entryDate, setEntryDate] = useState<string | undefined>(productDetails.date);

  const loungeProductSummaryProps = useLoungeProductSummaryProps(currentLounge, {
    entryDate,
    setEntryDate,
  });
  const loungeProductDetailsProps = useLoungeProductDetailsProps(productDetails, entryDate);

  const iata = loungeProductDetailsProps?.iata ?? undefined;
  const timezone =
    productDetails.airportCoordinates &&
    productDetails.airportCoordinates.latitude &&
    productDetails.airportCoordinates.longitude
      ? tzlookup(
          productDetails.airportCoordinates.latitude,
          productDetails.airportCoordinates.longitude,
        )
      : undefined;
  const date = loungeProductDetailsProps?.date
    ? formatLocalDate(
        loungeProductDetailsProps?.date,
        DateFormat.SHORT_DATE_WITH_FULL_TIME,
        timezone,
      )
    : undefined;

  const backLinkProps = useMemo(() => {
    return constructLoungeBacklinkProps(
      iata ? `${t('lounges.pdp.backlink.label')} ${iata.toUpperCase()}` : '',
      routes.LOUNGES_SEARCH_RESULTS,
      navigate,
      date,
      iata,
      loungeProductDetailsProps?.adults,
      loungeProductDetailsProps?.children,
      loungeProductDetailsProps?.infants,
    );
  }, [t, iata, date, navigate, loungeProductDetailsProps]);

  return { loungeProductDetailsProps, loungeProductSummaryProps, backLinkProps };
};

/**
 * Retrieve data for the lounge product details page
 */
export const useLoungeProductDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    constructFlightNumberSessionStorage(location);
  }, [location]);

  const pdpQueryParams = useMemo(() => {
    return getLoungeProductDetailsPageQueryParams(location);
  }, [location]);

  // redirect to search page if no search result id or lounge id is provided
  useEffect(() => {
    if (location && !pdpQueryParams.searchResultID && !pdpQueryParams.loungeId) {
      redirectToLoungeSip(navigate, location);
    }
  }, [navigate, location, pdpQueryParams]);

  // get the current lounge, either from deeplink or from search results
  const { loungeProduct } = useDeepLinkLoungeProduct(pdpQueryParams);

  const { currentProduct } = useCurrentProduct(pdpQueryParams.searchResultID, loungeProduct);
  useRedirectOnLoungeSearchResultNotFound(pdpQueryParams.searchResultID);

  const currentLounge = currentProduct;

  const isPageReady = Boolean(currentLounge);

  useTracking(isPageReady);

  return { isPageReady, currentLounge };
};
