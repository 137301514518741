import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { getYiluConfig } from '@yiluhub/ui-utilities';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import { ReservationInformationFormContentProps } from 'components/TravellerInformationForm/components/types';
import {
  PhoneInputType,
  REQUIRED_FIELD_ERROR_MESSAGE_KEY,
  getDefaultCountryValue,
  getPhoneNumberFieldValues,
  getSortedCountriesAlpha3Options,
  getValidationRules,
  isEmailValid,
} from 'components/TravellerInformationForm/utils';
import { useSalutations } from 'components/TravellerInformationForm/utils/salutaions';

type LoungeFieldsProps = Pick<
  ReservationInformationFormContentProps,
  'control' | 'fields' | 'trigger' | 'setValue' | 'watch'
>;

const EN_SALUTATIONS = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mstr', label: 'Mstr' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Mr/s', label: 'Mr/s' },
  { value: 'Rev', label: 'Rev' },
  { value: 'Prof', label: 'Prof' },
  { value: 'Sir', label: 'Sir' },
];

const DE_SALUTATIONS = [
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
];

export const LoungeFields = ({ control, trigger, setValue, fields }: LoungeFieldsProps) => {
  const { t } = useTranslation();
  const envVariables = yiluEnv.getVariables();
  const { locale } = getYiluConfig();

  const getAndSetSessionStorageValueInitially = (key: string): string => {
    const savedItem = sessionStorage.getItem(key);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && parsedSavedItem !== '') {
      setValue(key, parsedSavedItem);
    }
    return parsedSavedItem;
  };

  const { salutationSelectOptions, getDefaultSalutationValue } = useSalutations(
    locale === 'en' ? EN_SALUTATIONS : DE_SALUTATIONS,
  );

  return (
    <div className="yilu-LoungeReservationForm__fields">
      {fields.map((field) => {
        const sharedProps = {
          key: field.name,
          name: field.name.replace('.', '_'),
          control: control,
          rules: getValidationRules(field),
        };

        if (field.name === LoungeFieldName.EMAIL) {
          return (
            <Controller
              {...sharedProps}
              rules={{ required: REQUIRED_FIELD_ERROR_MESSAGE_KEY, validate: isEmailValid }}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.Select
                    isEmailDropdown
                    showDropdownIcon={false}
                    dataTestId="lounge-reservation-email"
                    label={
                      <>
                        <span>{t('tip.loungeForm.email')}</span>
                        <ElementsSDK.Tooltip
                          tooltipPosition="top"
                          tooltipText={t('tip.loungeForm.email.tooltip')}
                        >
                          <ElementsSDK.Icon.Help className="yilu-LoungeReservationForm__field-Email-tooltip-icon" />
                        </ElementsSDK.Tooltip>
                      </>
                    }
                    name={name}
                    type="email"
                    value={value || getAndSetSessionStorageValueInitially(name)}
                    className="yilu-LoungeReservationForm__field yilu-LoungeReservationForm__field-Email"
                    onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      sessionStorage.setItem(name, JSON.stringify(e.target.value));
                      setValue(name, e.target.value);
                      onChange(e);
                      isTouched && trigger(name);
                    }}
                    onSelect={(selectedValue: unknown) => {
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                      setValue(name, selectedValue);
                      onChange(selectedValue);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.name === LoungeFieldName.MOBILE_PHONE) {
          return (
            <Controller
              {...sharedProps}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.PhoneInputField
                    dataTestId="customer-phone"
                    codeValue={value?.code || getPhoneNumberFieldValues(PhoneInputType.CODE, name)}
                    phoneValue={
                      value?.number || getPhoneNumberFieldValues(PhoneInputType.NUMBER, name)
                    }
                    label={t('tip.loungeForm.mobileNumber')}
                    className="yilu-LoungeReservationForm__field"
                    onChange={(value) => {
                      sessionStorage.setItem(name, JSON.stringify(value));
                      setValue(name, value);
                      onChange(value);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    phoneRef={ref}
                    errorMessage={error?.message && t(error.message)}
                    defaultCountry={envVariables?.PHONE_COUNTRY_CODE || 'DE'}
                  />
                );
              }}
            />
          );
        } else if (field.name === LoungeFieldName.TITLE) {
          return (
            <Controller
              {...sharedProps}
              render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                return (
                  <ElementsSDK.InputField.Select
                    dataTestId="salutation-select"
                    options={salutationSelectOptions}
                    defaultOption={getDefaultSalutationValue(name)}
                    label={t('tip.lounges.reservation.form.field.salutation.label')}
                    placeholder={t('tip.lounges.reservation.form.field.salutation.placeholder')}
                    className="yilu-LoungeReservationForm__field yilu-LoungeReservationForm__field-Title"
                    onSelect={(selectedValue: unknown) => {
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                      setValue(selectedValue);
                      onChange(selectedValue);
                    }}
                    disabledTyping={true}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.name === LoungeFieldName.COUNTRY) {
          return (
            <Controller
              key={field.name}
              name={field.name.replace('.', '_')}
              control={control}
              rules={getValidationRules(field)}
              render={({ field: { onChange, name }, fieldState: { error } }) => {
                return (
                  <ElementsSDK.InputField.Select
                    dataTestId="country-select"
                    defaultOption={getDefaultCountryValue(name, locale as 'en' | 'de')}
                    options={getSortedCountriesAlpha3Options(locale as 'en' | 'de')}
                    label={t('tip.lounges.reservation.form.field.country.label')}
                    placeholder={t('tip.lounges.reservation.form.field.country.placeholder')}
                    className="yilu-LoungeReservationForm__field"
                    onSelect={(selectedValue: unknown) => {
                      onChange(selectedValue);
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                      setValue(selectedValue);
                    }}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        }

        return (
          <Controller
            {...sharedProps}
            render={({
              field: { onChange, onBlur, name, ref, value },
              fieldState: { isTouched, error },
            }) => {
              return (
                <ElementsSDK.InputField.TextInput
                  label={t(`tip.loungeForm.${field.name}`)}
                  value={value || getAndSetSessionStorageValueInitially(name)}
                  name={name}
                  className={`yilu-LoungeReservationForm__field yilu-LoungeReservationForm__field-${field.name}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e);
                    isTouched && trigger(name);
                    setValue(e.target.value);
                    sessionStorage.setItem(name, JSON.stringify(e.target.value));
                  }}
                  onBlur={() => {
                    onBlur();
                    trigger(name);
                  }}
                  inputRef={ref}
                  errorMessage={error?.message && t(error.message)}
                />
              );
            }}
          />
        );
      })}
    </div>
  );
};
