/**
 * # Collapsed Airport Transfer.
 *
 *
 */
import { TransferDirection } from 'applicationConstants';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const DEFAULT_TRAVELLERS_COUNT = 1;
const DEFAULT_INITIAL_DIRECTION = TransferDirection.FROM_AIRPORT;

export type CollapsedAirportTransferProps = {
  locationAddressName?: string;
  airportName?: string;
  travellersCount?: number;
  className?: string;
  direction?: TransferDirection;
};

const CollapsedAirportTransfer: React.FC<CollapsedAirportTransferProps> = ({
  locationAddressName,
  airportName,
  travellersCount = DEFAULT_TRAVELLERS_COUNT,
  direction = DEFAULT_INITIAL_DIRECTION,
  className,
}) => {
  const { t } = useTranslation();
  const shortLocationAddressName = getShortAddressName(locationAddressName);

  return (
    <div className={clsx('yilu-CollapsibleForm__AirportTransfer__Collapsed', className)}>
      {travellersCount && (
        <div className="yilu-CollapsibleForm__AirportTransfer__Collapsed__transport-criteria">
          <span aria-label={t('Number of travellers')}>
            {t('travellers.count', { travellersCount })}
          </span>
        </div>
      )}
      {shortLocationAddressName && airportName && (
        <div className="yilu-CollapsibleForm__AirportTransfer__Collapsed__transport-address">
          <span className="transport-from">
            {direction === TransferDirection.FROM_AIRPORT ? airportName : shortLocationAddressName}
          </span>
          <span className="transport-to">
            {direction === TransferDirection.TO_AIRPORT ? airportName : shortLocationAddressName}
          </span>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CollapsedAirportTransfer;

/**
 * Get a short address name
 *
 * @param [name] - text to shorten
 *
 * @returns A shorter version of a provided address name
 */
function getShortAddressName(name?: string) {
  if (!name) {
    return null;
  }
  return name.replace(/,.*$/g, '');
}
