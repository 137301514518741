import React from 'react';

import { HolidayExtrasForm } from 'components/TravellerInformationForm/Lounge/HolidayExtrasForm';
import { LHGForm } from 'components/TravellerInformationForm/Lounge/LHGForm';

import { isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { ReservationInformationFormContentProps } from '../components/types';
import './styles.scss';

const LoungeReservationInformationForm: React.FC<ReservationInformationFormContentProps> = (
  props,
) => {
  const Form =
    props.providerId && isSpHolidayExtras(props.providerId) ? HolidayExtrasForm : LHGForm;

  return (
    <div className="yilu-LoungeReservationForm">
      <Form {...props} />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoungeReservationInformationForm;
