import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { paramConverters } from 'utils/index';
import { getProductFromUrl } from 'utils/paramConverters';
import { getSrpSession } from 'utils/searchSession';

import routes, { PRODUCT_ROUTE } from 'router/routes';

import { FormError, FormErrorType } from './types';

export interface useReservationModalParams {
  formError: FormError;
  setFormError: React.Dispatch<React.SetStateAction<FormError>>;
}

export function getSrpByProductRoute(productRoute: PRODUCT_ROUTE) {
  const srpPaths: Record<PRODUCT_ROUTE | string, string> = {
    [PRODUCT_ROUTE.AIRPORT_TRANSFER]: routes.AIRPORT_TRANSFER_SEARCH_RESULTS,
    [PRODUCT_ROUTE.RIDES]: routes.RIDES_SEARCH_RESULTS,
    [PRODUCT_ROUTE.PARKING]: routes.PARKING_SEARCH_RESULTS,
    [PRODUCT_ROUTE.LOUNGES]: routes.LOUNGES_SEARCH_RESULTS,
    [PRODUCT_ROUTE.SWISS_TRAVEL_PASS]: routes.SWISS_TRAVEL_PASS_SEARCH,
  };

  return {
    srpPagePath: srpPaths?.[productRoute],
    backQueryParams: getSrpSession() || {},
  };
}

export const useReservationErrorModal = ({
  formError,
  setFormError,
}: useReservationModalParams) => {
  const { isVisible, toggleModal, onOk } = ElementsSDK.useModal(false);
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Open the modal if there is an api error
   */
  useEffect(() => {
    if (formError.hasError && !isVisible) {
      toggleModal();
    }
  }, [formError.hasError, toggleModal, isVisible]);

  /**
   * Clear form api errors
   */
  const clearFormError = useCallback(() => {
    setFormError({
      hasError: false,
      name: null,
      message: '',
      type: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle on `ok` click on the modal
   */
  const onErrorModalOk = useCallback(() => {
    if (formError.type === FormErrorType.API) {
      const productRoute = getProductFromUrl(location.pathname);
      const {
        srpPagePath,
        backQueryParams,
      }: {
        srpPagePath?: string;
        backQueryParams?: Record<string, any>;
      } = productRoute ? getSrpByProductRoute(productRoute as PRODUCT_ROUTE) : {};

      //on api errors we redirect to the relevant SRP
      if (srpPagePath && backQueryParams) {
        navigate({
          pathname: srpPagePath,
          search: paramConverters.getURLSearchQuery(backQueryParams),
        });
        return;
      }
    }

    clearFormError();
    onOk();
  }, [formError, clearFormError, onOk, navigate, location]);

  return {
    onErrorModalOk,
    isErrorModalVisible: isVisible,
  };
};
