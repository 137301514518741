import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type FaqItem = {
  question: string;
  answer: string;
};

export type FaqProps = {
  faqs: FaqItem[];
  contactLink?: string;
};

const LoungeFaq: React.FC<FaqProps> = ({ faqs, contactLink }) => {
  const { t } = useTranslation();
  const faqTitle = t('loungeFaq.title');

  return (
    <div className="yilu-FaqContainer">
      <ElementsSDK.Accordion
        expandButtonAlignment="right"
        showBottomBorder
        showIcon
        labelVariant="h2"
        label={{
          open: faqTitle,
          closed: faqTitle,
        }}
        expandUp={false}
      >
        {faqs.map(({ question, answer }, index) => (
          <div className="yilu-LoungeFaq_QuestionAnswerWrapper">
            <ElementsSDK.Typography
              variant="p1"
              bold
              dangerouslySetInnerHTML={{ __html: question }}
            />
            <ElementsSDK.Typography
              className="yilu-LoungeFaq_Answer"
              variant="p1"
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        ))}
        {contactLink && (
          <div className="yilu-LoungeFaq_ContactButtonWrapper">
            <ElementsSDK.Typography className="yilu-LoungeFaq_ContactButtonTitle" variant="p1" bold>
              {t('loungeFaq.contactButtonTitle')}
            </ElementsSDK.Typography>
            <ElementsSDK.Button outline onClick={() => window.open(contactLink, '_blank')}>
              {t('loungeFaq.contactButtonLabel')}
            </ElementsSDK.Button>
          </div>
        )}
      </ElementsSDK.Accordion>
    </div>
  );
};

export default LoungeFaq;
