import React from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentInputContextProvider } from 'context';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { SBBProductSummary } from '../../components/SBBProductSummary';
import { usePaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { isPageReady, paymentInformationFormProps, swissTravelProductSummaryProps } =
    usePaymentInputPage();

  return (
    <div className="airport-transfer-sbb-pip">
      {isPageReady && (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar />
                <PageHeading text={t('Header - Payment')} />
                {paymentInformationFormProps && (
                  <PaymentInformation {...paymentInformationFormProps} />
                )}
              </div>
              {swissTravelProductSummaryProps && (
                <SummaryLayout>
                  <SBBProductSummary {...swissTravelProductSummaryProps} />
                </SummaryLayout>
              )}
            </div>
          </div>
        </PaymentInputContextProvider>
      )}
    </div>
  );
};

export default PaymentInputPage;
