import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

export type BannerSIPProps = {
  title: string;
  subtitle?: string;
};

const BannerSIP: React.FC<BannerSIPProps> = ({ title, subtitle }) => (
  <div className="sip-wrapper">
    <ElementsSDK.Typography variant="h1" className="sip-title">
      {title}
    </ElementsSDK.Typography>
    {subtitle && (
      <ElementsSDK.Typography variant="p2" className="sip-subtitle">
        {subtitle}
      </ElementsSDK.Typography>
    )}
  </div>
);

export default BannerSIP;
