export const extractUserAgentInfo = (userAgent: string): string => {
  if (!userAgent) {
    return '';
  }
  let deviceType: string;
  // mobiles
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)
  ) {
    if (userAgent.indexOf('GROUPAPP') !== -1) {
      //Identify Lufthansa group mobile apps
      deviceType = 'Mobile App';
      if (userAgent.indexOf('Android') !== -1) deviceType = 'Android App';
      else if (userAgent.indexOf('iPhone') !== -1) deviceType = 'iPhone App';
      return deviceType;
    } else {
      deviceType = 'Mobile Web';
      if (userAgent.indexOf('Android') !== -1) deviceType = 'Android Web';
      else if (userAgent.indexOf('iPhone') !== -1) deviceType = 'iPhone Web';
      return deviceType;
    }
  }
  // desktops
  deviceType = 'Desktop';
  if (userAgent.indexOf('Windows') !== -1) deviceType = 'Windows';
  else if (userAgent.indexOf('Mac') !== -1) deviceType = 'Mac';
  else if (userAgent.indexOf('X11') !== -1) deviceType = 'UNIX';
  else if (userAgent.indexOf('Linux') !== -1) deviceType = 'Linux';
  return deviceType;
};
