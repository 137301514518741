import React from 'react';

export const GooglePayCard = (
  <svg width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6001 0H10.0001C4.50012 0 0.00012207 4.5 0.00012207 10C0.00012207 15.5 4.50012 20 10.0001 20H27.6001C33.1001 20 37.6001 15.5 37.6001 10C37.6001 4.5 33.1001 0 27.6001 0Z"
      fill="white"
    />
    <path
      d="M27.6001 0.81C28.8351 0.81 30.0351 1.055 31.1651 1.535C32.2601 2 33.2401 2.665 34.0901 3.51C34.9351 4.355 35.6001 5.34 36.0651 6.435C36.5451 7.565 36.7901 8.765 36.7901 10C36.7901 11.235 36.5451 12.435 36.0651 13.565C35.6001 14.66 34.9351 15.64 34.0901 16.49C33.2451 17.335 32.2601 18 31.1651 18.465C30.0351 18.945 28.8351 19.19 27.6001 19.19H10.0001C8.76512 19.19 7.56512 18.945 6.43512 18.465C5.34012 18 4.36012 17.335 3.51012 16.49C2.66512 15.645 2.00012 14.66 1.53512 13.565C1.05512 12.435 0.810122 11.235 0.810122 10C0.810122 8.765 1.05512 7.565 1.53512 6.435C2.00012 5.34 2.66512 4.36 3.51012 3.51C4.35512 2.665 5.34012 2 6.43512 1.535C7.56512 1.055 8.76512 0.81 10.0001 0.81H27.6001ZM27.6001 0H10.0001C4.50012 0 0.00012207 4.5 0.00012207 10C0.00012207 15.5 4.50012 20 10.0001 20H27.6001C33.1001 20 37.6001 15.5 37.6001 10C37.6001 4.5 33.1001 0 27.6001 0Z"
      fill="#3C4043"
    />
    <path
      d="M17.9303 10.7099V13.7349H16.9703V6.26489H19.5153C20.1603 6.26489 20.7103 6.47989 21.1603 6.90989C21.6203 7.33989 21.8503 7.86489 21.8503 8.48489C21.8503 9.11989 21.6203 9.64489 21.1603 10.0699C20.7153 10.4949 20.1653 10.7049 19.5153 10.7049H17.9303V10.7099ZM17.9303 7.18489V9.78989H19.5353C19.9153 9.78989 20.2353 9.65989 20.4853 9.40489C20.7403 9.14989 20.8703 8.83989 20.8703 8.48989C20.8703 8.14489 20.7403 7.83989 20.4853 7.58489C20.2353 7.31989 19.9203 7.18989 19.5353 7.18989H17.9303V7.18489Z"
      fill="#3C4043"
    />
    <path
      d="M24.3601 8.45496C25.0701 8.45496 25.6301 8.64496 26.0401 9.02496C26.4501 9.40496 26.6551 9.92496 26.6551 10.585V13.735H25.7401V13.025H25.7001C25.3051 13.61 24.7751 13.9 24.1151 13.9C23.5501 13.9 23.0801 13.735 22.7001 13.4C22.3201 13.065 22.1301 12.65 22.1301 12.15C22.1301 11.62 22.3301 11.2 22.7301 10.89C23.1301 10.575 23.6651 10.42 24.3301 10.42C24.9001 10.42 25.3701 10.525 25.7351 10.735V10.515C25.7351 10.18 25.6051 9.89996 25.3401 9.66496C25.0751 9.42996 24.7651 9.31496 24.4101 9.31496C23.8751 9.31496 23.4501 9.53996 23.1401 9.99496L22.2951 9.46496C22.7601 8.78996 23.4501 8.45496 24.3601 8.45496ZM23.1201 12.165C23.1201 12.415 23.2251 12.625 23.4401 12.79C23.6501 12.955 23.9001 13.04 24.1851 13.04C24.5901 13.04 24.9501 12.89 25.2651 12.59C25.5801 12.29 25.7401 11.94 25.7401 11.535C25.4401 11.3 25.0251 11.18 24.4901 11.18C24.1001 11.18 23.7751 11.275 23.5151 11.46C23.2501 11.655 23.1201 11.89 23.1201 12.165Z"
      fill="#3C4043"
    />
    <path
      d="M31.8753 8.61987L28.6753 15.9799H27.6853L28.8753 13.4049L26.7653 8.61987H27.8103L29.3303 12.2899H29.3503L30.8303 8.61987H31.8753Z"
      fill="#3C4043"
    />
    <path
      d="M14.1119 10.1C14.1119 9.78696 14.0839 9.48746 14.0319 9.19946H10.0079V10.8495L12.3254 10.85C12.2315 11.399 11.9289 11.867 11.4654 12.179V13.2495H12.8449C13.6504 12.504 14.1119 11.402 14.1119 10.1Z"
      fill="#4285F4"
    />
    <path
      d="M11.4657 12.1789C11.0817 12.4379 10.5872 12.5894 10.0087 12.5894C8.89122 12.5894 7.94322 11.8364 7.60372 10.8214H6.18073V11.9254C6.88573 13.3244 8.33472 14.2844 10.0087 14.2844C11.1657 14.2844 12.1377 13.9039 12.8452 13.2489L11.4657 12.1789Z"
      fill="#34A853"
    />
    <path
      d="M7.46978 10.0025C7.46978 9.71748 7.51728 9.44198 7.60378 9.18298V8.07898H6.18078C5.88928 8.65748 5.72528 9.31048 5.72528 10.0025C5.72528 10.6945 5.88978 11.3475 6.18078 11.926L7.60378 10.822C7.51728 10.563 7.46978 10.2875 7.46978 10.0025Z"
      fill="#FABB05"
    />
    <path
      d="M10.0087 7.41497C10.6402 7.41497 11.2057 7.63247 11.6522 8.05747L12.8747 6.83597C12.1322 6.14447 11.1642 5.71997 10.0087 5.71997C8.33522 5.71997 6.88573 6.67997 6.18073 8.07897L7.60372 9.18297C7.94322 8.16797 8.89123 7.41497 10.0087 7.41497Z"
      fill="#E94235"
    />
  </svg>
);
