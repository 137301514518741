import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentLanguage } from 'utils/i18n';
import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { getProductDetails } from '../../../utils/getProductDetails';

const useTravellerInformationFormProps = (
  currentProduct: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  setIsReservationUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const locale = getCurrentLanguage() || 'en';
  const navigate = useNavigate();
  const yiluEnv = getVariables();
  const { searchResultID, priceOptionId, catalogId } = getProductDetails(currentProduct);
  const travellerInformationFormProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const _props: TravellerInformationFormProps<AMP_VERTICALS.AIRPORT_TRANSFER_PASSES> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.AIRPORT_TRANSFER_PASSES,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId,
        locale,
        quantity: 1,
        selectedPriceId: priceOptionId,
        searchResultId: searchResultID,
      },
      isSBBCarrier: true,
      onUpdateBookingIntentSuccess(data) {
        const paymentInformationPageQueryParams = {
          searchResultID,
          bookingIntentId: data.bookingIntentId,
          shoppingCartId: data.shoppingCartId,
        };
        navigate({
          pathname: routes.SWISS_TRAVEL_PASS_PIP,
          search: getURLSearchQuery(paymentInformationPageQueryParams),
        });
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading: boolean) {
        setIsReservationUpdateLoading(isFormLoading);
      },
    };
    return _props;
  }, [
    yiluEnv.YILU_AMP_STORE_ID,
    currentProduct,
    reservationFormRef,
    searchResultID,
    locale,
    priceOptionId,
    catalogId,
    navigate,
    setIsReservationUpdateLoading,
  ]);

  return travellerInformationFormProps;
};

export default useTravellerInformationFormProps;
