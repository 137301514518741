import React from 'react';

import './styles.scss';

const InteractiveSummaryLayout: React.FC = ({ children }) => {
  return (
    <div className="interactive-summary-container">
      <div className="interactive-summary">{children}</div>
    </div>
  );
};

export default InteractiveSummaryLayout;
