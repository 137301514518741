import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { TransferDirection } from 'applicationConstants';
import dayjs from 'dayjs';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import {
  CommonQueryParams,
  getCommonQueryParams,
  parseDirection,
  parseTravellersCount,
} from 'utils/paramConverters';

export type RideSearchInputPageQueryParams = CommonQueryParams & {
  transferDate: string;
  transferTime: string;
  travellersCount: number;
  originLocation?: string;
  destinationLocation?: string;
  direction?: TransferDirection;
  airportIataCode?: string;
};

const RIDES_SRP_SESSION = 'yilu__rides__srp__session';
const queryParamsFromSession = JSON.parse(sessionStorage.getItem(RIDES_SRP_SESSION) || '{}');

const DEFAULT_MAX_TRAVELLERS_COUNT = 9;
const DEFAULT_TRAVELLERS_COUNT = queryParamsFromSession['travellersCount'] || 1;
const DEFAULT_INITIAL_TIME_OFFSET_VALUE = 3;
const DEFAULT_INITIAL_TIME_OFFSET_UNIT = 'hour';

/**
 * Returns the query parameters for the Rides Search Input page.
 *
 * @param location - location descriptor
 */
export const getRidesSearchInputPageQueryParams = (
  location: Location = window.location as any,
): RideSearchInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const searchInputRidesParams: any = {
    originLocation: queryParams.originLocation,
    destinationLocation: queryParams.destinationLocation,
    transferDate: queryParams.transferDate || dayjs().format(UiUtilities.DateFormat.SHORT_DATE),
    transferTime:
      queryParams.transferTime ||
      dayjs()
        .add(DEFAULT_INITIAL_TIME_OFFSET_VALUE, DEFAULT_INITIAL_TIME_OFFSET_UNIT)
        .format(UiUtilities.DateFormat.TIME),
    travellersCount: parseTravellersCount(
      queryParams.travellersCount,
      DEFAULT_TRAVELLERS_COUNT,
      DEFAULT_MAX_TRAVELLERS_COUNT,
    ),
    direction: parseDirection(queryParams.direction, TransferDirection.FROM_AIRPORT),
    airportIataCode: queryParams.airportIataCode || '',
  };

  // if all keys are already present we have the right
  // query params and we can update stale session state.
  if (
    Object.keys(searchInputRidesParams).every(
      (searchInpuRidesParamKey) => searchInputRidesParams[searchInpuRidesParamKey] !== undefined,
    )
  ) {
    sessionStorage.setItem(RIDES_SRP_SESSION, JSON.stringify(searchInputRidesParams));
  } else {
    // some keys are missing and we need to get them from session storage
    Object.keys(searchInputRidesParams).forEach((param) => {
      if (searchInputRidesParams[param] === undefined) {
        searchInputRidesParams[param] = queryParamsFromSession[param];
      }
    });
  }
  return {
    ...commonQueryParams,
    ...searchInputRidesParams,
  };
};

export type RideSearchResultsPageQueryParams = RideSearchInputPageQueryParams;

/**
 * Returns the query parameters for the Rides Search Results page.
 *
 * @param location - location descriptor
 */
export const getRidesSearchResultsPageQueryParams = (
  location: Location = window.location as any,
): RideSearchResultsPageQueryParams => {
  return getRidesSearchInputPageQueryParams(location);
};

export type RidesTravellerInputPageQueryParams = CommonQueryParams & {
  searchResultID: string;
  originAddressName: string;
  destinationAddressName: string;
  transferTime: string;
  transferDate: string;
  itemId?: string;
  shoppingCartId?: string;
  catalogId?: string;
};

/**
 * Returns the query parameters for the Traveller Input/Information page.
 *
 * @param location - location descriptor
 */
export const getRidesTravellerInputPageQueryParams = (
  location: Location = window.location as any,
): RidesTravellerInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const ridesProductDetailsParams = {
    searchResultID: queryParams.searchResultID,
    originAddressName: queryParams.originAddressName,
    destinationAddressName: queryParams.destinationAddressName,
    transferTime: queryParams.transferTime,
    transferDate: queryParams.transferDate,
    shoppingCartId: queryParams.shoppingCartId,
    itemId: queryParams.itemId,
    catalogId: queryParams.catalogId,
  };

  return {
    ...commonQueryParams,
    ...ridesProductDetailsParams,
  };
};

/**
 * Returns the query parameters for the Rides Payment Input Page.
 *
 * @param location - location descriptor
 */
export const getRidesPaymentInputPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);
  const paymentInputPageParams = {
    searchResultID: queryParams.searchResultID,
    bookingIntentId: queryParams.bookingIntentId,
    originAddressName: queryParams.originAddressName,
    destinationAddressName: queryParams.destinationAddressName,
    transferTime: queryParams.transferTime,
    transferDate: queryParams.transferDate,
    shoppingCartId: queryParams?.shoppingCartId,
  };
  return {
    ...commonQueryParams,
    ...paymentInputPageParams,
  };
};
