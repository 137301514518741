import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';

import { sendGAEvent } from 'utils/tracking';

import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';

const useProductSummaryProps = (
  currentParking: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  isReservationUpdateLoading: boolean,
) => {
  const parkingDetails = getParkingDetails(currentParking);

  const productSummaryProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    return {
      price: parkingDetails.price,
      currency: parkingDetails.currency,
      onClick() {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'tip_cta_clicked',
            category: 'parking-tip',
            label: 'parking_tip_cta_clicked',
          });
        }
      },
      isLoading: isReservationUpdateLoading,
      enterDate: UiUtilities.formatLocalDate(parkingDetails.arrival, 'YYYY-MM-DD'),
      enterTime: UiUtilities.formatLocalDate(parkingDetails.arrival, 'HH:mm'),
      exitDate: UiUtilities.formatLocalDate(parkingDetails.departure, 'YYYY-MM-DD'),
      exitTime: UiUtilities.formatLocalDate(parkingDetails.departure, 'HH:mm'),
      parkingName: parkingDetails.name,
      address: parkingDetails.address,
    };
  }, [currentParking, reservationFormRef, isReservationUpdateLoading, parkingDetails]);

  return productSummaryProps;
};

export default useProductSummaryProps;
