import { YILU_FEATURE } from 'applicationConstants';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  isBookingConfirmationPage,
  isHighPassPage,
  isPaymentInformationPage,
  isProductDetailsPage,
  isSearchInputPage,
  isTravellerInformationPage,
} from 'utils/headerUtils';
import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { useIsFeatureFlagEnabled } from 'context';

import HeaderNav from './HeaderNav';
import LocaleSelect from './LocaleSelect';
import Logo from './Logo';
import Tagline from './Tagline';
import './styles.scss';

type HeaderProps = {
  showNav?: boolean;
  showLocale?: boolean;
};

function useHeaderProps() {
  const headerEl = useRef<HTMLElement>(null);
  const logoLink = `${routes.BASE}${getURLSearchQuery({})}`;

  useEffect(() => {
    const setHeaderHeight = () => {
      let header = headerEl.current;
      if (!header) {
        document.documentElement.style.setProperty('--full-header-height', '0px');
        return;
      }

      const fullHeaderHeight = header.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--full-header-height', `${fullHeaderHeight}px`);
    };

    setHeaderHeight();
    window.addEventListener('resize', setHeaderHeight);
    window.addEventListener('orientationchange', setHeaderHeight);

    return () => {
      window.removeEventListener('resize', setHeaderHeight);
      window.removeEventListener('orientationchange', setHeaderHeight);
    };
  }, []);

  return {
    isNavBarEnabled: useIsFeatureFlagEnabled(YILU_FEATURE.SHOW_NAVIGATION_BAR),
    logoLink,
    headerEl,
  };
}

const Header: React.FC<HeaderProps> = ({ showNav }) => {
  const { isNavBarEnabled, logoLink, headerEl } = useHeaderProps();

  const [show, setShow] = useState({
    showNav: true,
    showLocale: true,
  });

  const { pathname } = useLocation();
  useEffect(() => {
    setShow({
      showNav: isSearchInputPage(pathname) || isHighPassPage(pathname) || pathname === routes.BASE,
      showLocale:
        !isHighPassPage(pathname) &&
        !isBookingConfirmationPage(pathname) &&
        !isPaymentInformationPage(pathname) &&
        !isProductDetailsPage(pathname) &&
        !isTravellerInformationPage(pathname),
    });
  }, [pathname]);

  return (
    <header
      className={clsx('header', show.showNav && isNavBarEnabled && 'with-header-nav')}
      ref={headerEl}
    >
      <div className="logo-container">
        <Logo link={logoLink} />
        {show.showNav && isNavBarEnabled && <HeaderNav />}
        {show.showLocale && <LocaleSelect />}
      </div>
      <Tagline />
    </header>
  );
};

export default Header;
