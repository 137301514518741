import { Typography } from '@yiluhub/ui-elements';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'modules/landing-page/components/card';

import './styles.scss';
import { EventParam, EventType, triggerEvent } from './utils/tracking';

enum Images {
  AirportTransfers = 'airport-transfers.jpg',
  AirportParking = 'airport-parking.jpg',
  AirportLounge = 'airport-lounges.jpg',
  AirportTaxi = 'airport-taxi.jpg',
  ESim = 'e-sim.jpg',
  VipService = 'VIP-services.jpeg',
  Tickets = 'tickets.jpeg',
  MeetGreet = 'meet-greet.jpeg',
  FastLane = 'fast-lane.jpeg',
  BannerImage = 'banner-image.jpg',
  BannerImageMobile = 'banner-image-mobile.jpg',
}

const TRACKING_PARAM = '?touchpoint=landing_page';

const combineImageWithBasePath = (imageName: Images) => {
  const assetsUrl =
    'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/landingpage/assets/';
  return `${assetsUrl}${imageName}`;
};

export const LandingPageBody: React.FC = () => {
  // fire load event once page laods
  useEffect(() => {
    triggerEvent(EventType.PAGEVIEW, EventParam.VIEW);
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <section className="lp-container">
        <div className="lp-header-search-box">
          <Typography variant="h1" className="lp-heading" bold={true}>
            {t('landingpage.searchbox.title.text')}
          </Typography>
          <Typography variant="h5" className="lp-subheading">
            {t('landingpage.searchbox.subtitle.text')}
          </Typography>
        </div>
        <div className="lp-header-image">
          <picture>
            <source
              media="(max-width: 500px"
              srcSet={combineImageWithBasePath(Images.BannerImageMobile)}
            />
            <source
              media="(max-width: 900px"
              srcSet={combineImageWithBasePath(Images.BannerImage)}
            />
            <img src={combineImageWithBasePath(Images.BannerImage)} alt="header" />
          </picture>
        </div>
        <div className="lp-header-text-container">
          <Typography variant="p2" className="lp-header-text_text" bold={true}>
            {t('landingpage.herosection.subtitle.text')}
          </Typography>
        </div>
      </section>
      <section className="lp-cards-section">
        <div className="lp-cards-container">
          <Card
            dataTestId="airport-parking"
            heading={t('landingpage.card.airportparking.heading.text')}
            subHeading={t('landingpage.card.airportparking.subHeading.text')}
            image={combineImageWithBasePath(Images.AirportParking)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/parking/search${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_AP}
          />
          <Card
            dataTestId="airport-lounges"
            heading={t('landingpage.card.airportlounge.heading.text')}
            subHeading={t('landingpage.card.airportlounge.subHeading.text')}
            image={combineImageWithBasePath(Images.AirportLounge)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/lounges/search${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_AL}
          />
          <Card
            dataTestId="airport-transfers"
            heading={t('landingpage.card.airporttransfers.heading.text')}
            subHeading={t('landingpage.card.airporttransfers.subHeading.text')}
            image={combineImageWithBasePath(Images.AirportTransfers)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/airport-transfer/search${TRACKING_PARAM}`}
            MaMLogo={true}
            clickEventParam={EventParam.CLICK_AT}
          />
          <Card
            dataTestId="rides"
            heading={t('landingpage.card.rides.heading.text')}
            subHeading={t('landingpage.card.rides.subHeading.text')}
            image={combineImageWithBasePath(Images.AirportTaxi)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/rides/search${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_RI}
          />
          <Card
            dataTestId="esim"
            heading={t('landingpage.card.esim.heading.text')}
            subHeading={t('landingpage.card.esim.subHeading.text')}
            image={combineImageWithBasePath(Images.ESim)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://www.discover-airlines.com/en/information/additional-services/esim/${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_SIM}
          />
          <Card
            dataTestId="fastlane"
            heading={t('landingpage.card.fastlane.heading.text')}
            subHeading={t('landingpage.card.fastlane.subHeading.text')}
            image={combineImageWithBasePath(Images.FastLane)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/fasttrack/product${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_FT}
          />
          <Card
            dataTestId="highpass"
            heading={t('landingpage.card.highpass.heading.text')}
            subHeading={t('landingpage.card.highpass.subHeading.text')}
            image={combineImageWithBasePath(Images.MeetGreet)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/highpass-meet-and-greet${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_HPM}
          />
          <Card
            dataTestId="vipservice"
            heading={t('landingpage.card.highpassvip.heading.text')}
            subHeading={t('landingpage.card.highpassvip.subHeading.text')}
            image={combineImageWithBasePath(Images.VipService)}
            price={t('landingpage.card.generic.booknow.text')}
            linkUrl={`https://partneroffer.discover-airlines.com/highpass-vip${TRACKING_PARAM}`}
            clickEventParam={EventParam.CLICK_HPV}
          />
          {/* Uncomment & update the linkUrl once the discover setup from Coras is done */}
          {/* <Card
            dataTestId="tickets"
            heading={t('landingpage.card.tickets.heading.text')}
            subHeading={t('landingpage.card.tickets.subHeading.text')}
            image={combineImageWithBasePath(Images.Tickets)}
            price={t('landingpage.card.generic.buynow.text')}
            linkUrl={`https://discover-airlines.travelintegration.com/tickets/search${TRACKING_PARAM}`}
            MaMLogo={true}
            clickEventParam={EventParam.CLICK_CS}
          /> */}
        </div>
      </section>
    </>
  );
};

const LandingPage: React.FC = () => {
  return (
    <div className="lp-layout">
      <LandingPageBody />
    </div>
  );
};

export default LandingPage;
