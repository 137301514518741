import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getErrorMessage, getErrorTitle } from 'components/PaymentInformation/utils/errors';

export type PaymentModalProps = {
  isVisible: boolean;
  errorMessage?: string;
  onConfirm: () => void;
};

const PaymentModal: React.FC<PaymentModalProps> = ({ isVisible, errorMessage, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <ElementsSDK.Modal
      isVisible={isVisible}
      title={errorMessage ? getErrorTitle(errorMessage, t) : t('Loading Indicator Title')}
      size={ElementsSDK.ModalSize.SMALL}
      isContentCentered
      type={errorMessage ? ElementsSDK.ModalType.ERROR : ElementsSDK.ModalType.LOADING}
      {...(errorMessage ? { onConfirm, confirmText: t('Close') } : {})}
    >
      <ElementsSDK.Typography variant="p1">
        {errorMessage ? getErrorMessage(errorMessage, t) : t('Loading Indicator Body')}
      </ElementsSDK.Typography>
    </ElementsSDK.Modal>
  );
};

export default PaymentModal;
