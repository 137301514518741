import { isLufthansaAirlineGroup } from 'components/TravellerInformationForm/Lounge/TravelInfo';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';

import { PRICING_OPTION } from 'modules/lounges/utils/constants';

export const LOUNGE_SESSION_BOOKING_KEY = 'LoungeSessionBooking';

export enum sessionBookingState {
  FILLED = 'FILLED',
  UNFILLED = 'UNFILLED',
}

export const setSessionDeeplinkFilledState = (
  bookingSessionId: string,
  state: sessionBookingState,
) => {
  window.sessionStorage.setItem(`${LOUNGE_SESSION_BOOKING_KEY}-${bookingSessionId}`, state);
};

export const getSessionDeeplinkFilledState = (bookingSessionId: string) => {
  return window.sessionStorage.getItem(`${LOUNGE_SESSION_BOOKING_KEY}-${bookingSessionId}`);
};

export const setSessionFlightNumber = (flightNumber: string) => {
  try {
    // Save the first 10 characters of the flight number
    window.sessionStorage.setItem(
      LoungeFieldName.FLIGHT_CODE,
      JSON.stringify(flightNumber.substring(0, 10)),
    );
  } catch (e) {
    console.error('Error setting flight number in session storage', e);
  }
};

export const getSessionFlightNumber = () => {
  try {
    const flightNumber = window.sessionStorage.getItem(LoungeFieldName.FLIGHT_CODE);
    return flightNumber ? JSON.parse(flightNumber) : '';
  } catch (e) {}
};

export const getSessionEconomyPremium = () => {
  const savedEconomyPremium = sessionStorage.getItem(LoungeFieldName.IS_ECONOMY_PREMIUM);
  return savedEconomyPremium ? JSON.parse(savedEconomyPremium) : false;
};

export const getSessionPricingOption = (): PRICING_OPTION => {
  const savedFlightCode = sessionStorage.getItem(LoungeFieldName.FLIGHT_CODE);
  const parsedFlightCode = savedFlightCode ? JSON.parse(savedFlightCode) : '';
  const isLufthansaFlightCode = isLufthansaAirlineGroup(parsedFlightCode);
  const savedEconomyPremium = getSessionEconomyPremium();

  if (savedEconomyPremium) return PRICING_OPTION.PREMIUM;
  else if (isLufthansaFlightCode) return PRICING_OPTION.LUFTHANSA;
  return PRICING_OPTION.OTHER;
};
