function limitValueWithinMaxRange(str: string, max: number) {
  let value = str;
  // eslint-disable-next-line
  if (value.charAt(0) !== '0' || value == '00') {
    let num = parseInt(value);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    value =
      // eslint-disable-next-line
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? '0' + num
        : num.toString();
  }
  return value;
}

// Formats incoming input to 'DD / MM / YYYY' text format with auto appending ` / `
// and converting single digit days or months to double digits e.g 1 -> 01
export function birthdayAutoFormatter(input: string) {
  let inputValue = input;
  if (/\D\/$/.test(inputValue)) inputValue = inputValue.substr(0, inputValue.length - 3);
  const values = inputValue.split('/').map((v) => v.replace(/\D/g, ''));
  if (values[0]) values[0] = limitValueWithinMaxRange(values[0], 31);
  if (values[1]) values[1] = limitValueWithinMaxRange(values[1], 12);
  // eslint-disable-next-line
  const output = values.map((v, i) => (v.length == 2 && i < 2 ? v + ' / ' : v));
  return output.join('').substr(0, 14);
}
