import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { NavigateFunction } from 'react-router-dom';

import { paramConverters } from 'utils';

export const constructParkingBacklinkProps = (
  label: string,
  route: string,
  navigate: NavigateFunction,
  entryDate?: string,
  entryTime?: string,
  exitDate?: string,
  exitTime?: string,
  airportIataCode?: string,
  flightTime?: string,
) => {
  const backLinkProps: ElementsSDK.BacklinkProps = {
    label,
    onClick: () => {
      const backParkingQueryParams = {
        entryDate,
        entryTime,
        exitDate,
        exitTime,
        airportIataCode,
        flightTime,
      };

      navigate({
        pathname: route,
        search: paramConverters.getURLSearchQuery(backParkingQueryParams),
      });
    },
  };
  return backLinkProps;
};
