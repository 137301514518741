/**
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import {
  DateFormat,
  concatenateDateTime,
  formatLocalDate,
  handleValidateDateTimeInputError,
  isDateTimeInputValid,
} from '@yiluhub/ui-utilities';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_INITIAL_TIME_OFFSET_VALUE = 3;
const DEFAULT_INITIAL_TIME_OFFSET_UNIT = 'hour';
const DEFAULT_INITIAL_TRAVELLERS_COUNT = 1;

/**
 *
 */
export type RidesSearchInput = {
  originLocation?: string;
  destinationLocation?: string;
  transferDate?: string;
  transferTime?: string;
  travellersCount?: number;
};

/**
 *
 */
export type RidesSearchOutputParams = {
  originAddress: ElementsSDK.GoogleSearchAddress;
  destinationAddress: ElementsSDK.GoogleSearchAddress;
  transferDate: string;
  transferTime: string;
  travellersCount: number;
};

export type SearchInputParams = {
  onSubmit(searchParams: RidesSearchOutputParams): unknown;
  googleMapsAPIKey: string;
  searchInput?: RidesSearchInput;
  onError?: (error: Error) => unknown;
};

export function useSearchInput({
  googleMapsAPIKey,
  searchInput,
  onError,
  onSubmit,
}: SearchInputParams) {
  const initialOriginDetails = ElementsSDK.useAddressDetails(
    googleMapsAPIKey,
    searchInput && searchInput.originLocation,
    onError,
  ) as ElementsSDK.GoogleSearchAddress;
  const initialDestinationDetails = ElementsSDK.useAddressDetails(
    googleMapsAPIKey,
    searchInput && searchInput.destinationLocation,
    onError,
  );

  const onOriginAddressSelect: (address: any) => void = (
    address: ElementsSDK.GoogleSearchAddress,
  ) => {
    setOriginAddress(address);
  };

  const onDestinationAddressSelect: (address: any) => void = (
    address: ElementsSDK.GoogleSearchAddress,
  ) => {
    setDestinationAddress(address);
  };

  const originAddressInputId = useMemo(() => `generated-${uuidv4()}`, []);
  const [originAddress, setOriginAddress] = useState<ElementsSDK.GoogleSearchAddress | null>(
    initialOriginDetails,
  );

  const destinationAddressInputId = useMemo(() => `generated-${uuidv4()}`, []);
  const [destinationAddress, setDestinationAddress] =
    useState<ElementsSDK.GoogleSearchAddress | null>(initialDestinationDetails);

  const [isSearchInputValid, setIsSearchInputValid] = useState(() => {
    return Boolean(originAddress) && Boolean(destinationAddress);
  });

  const transferId = useMemo(() => `generated-${uuidv4()}`, []);

  const [transferDateTime, setTransferDateTime] = useState(() => {
    const initialDate =
      (searchInput && searchInput.transferDate) || dayjs().format(DateFormat.SHORT_DATE);

    const initialTime =
      (searchInput && searchInput.transferTime) ||
      dayjs()
        .add(DEFAULT_INITIAL_TIME_OFFSET_VALUE, DEFAULT_INITIAL_TIME_OFFSET_UNIT)
        .format(DateFormat.TIME);

    return concatenateDateTime(initialDate, initialTime);
  });

  const [travellersCount, setTravellersCount] = useState(() => {
    return (searchInput && searchInput.travellersCount) || DEFAULT_INITIAL_TRAVELLERS_COUNT;
  });

  const handleTransferDateTimeChange = useCallback(
    (dateTime: string) => {
      if (!isDateTimeInputValid(dateTime)) {
        handleValidateDateTimeInputError(onError);
      } else {
        setTransferDateTime(dateTime);
      }
    },
    [onError],
  );

  const handleFormInputValidate = useCallback((setValue) => {
    return (e: React.FocusEvent) => {
      const target = e.target as HTMLInputElement;
      if (!target.value) {
        setValue(null);
      }
    };
  }, []);

  const handleFormSubmit = useCallback(() => {
    const timeModal = document.querySelector('.yilu-DateTimeInput__Modal');

    if (!isSearchInputValid || !originAddress || !destinationAddress || timeModal) {
      return;
    }

    const searchOutputParams: RidesSearchOutputParams = {
      originAddress,
      destinationAddress,
      transferDate: formatLocalDate(transferDateTime, DateFormat.SHORT_DATE),
      transferTime: formatLocalDate(transferDateTime, DateFormat.TIME),
      travellersCount,
    };

    onSubmit(searchOutputParams);
  }, [
    isSearchInputValid,
    originAddress,
    destinationAddress,
    transferDateTime,
    travellersCount,
    onSubmit,
  ]);

  // update if data are retrieved
  useEffect(() => {
    if (!initialOriginDetails) {
      return;
    }
    setOriginAddress(initialOriginDetails);
  }, [initialOriginDetails]);

  useEffect(() => {
    if (!initialDestinationDetails) {
      return;
    }
    setDestinationAddress(initialDestinationDetails);
  }, [initialDestinationDetails]);

  useEffect(() => {
    if (!originAddress || !destinationAddress) {
      setIsSearchInputValid(false);
      return;
    }
    setIsSearchInputValid(true);
  }, [originAddress, destinationAddress]);

  // update search input parts
  useEffect(() => {
    if (!searchInput) {
      return;
    }

    if (searchInput.travellersCount) {
      setTravellersCount(searchInput.travellersCount);
    }
  }, [searchInput]);

  return {
    originAddressInputId,
    originAddress,
    destinationAddressInputId,
    destinationAddress,
    transferId,
    transferDateTime,
    travellersCount,
    isSearchInputValid,
    setOriginAddress,
    setDestinationAddress,
    setTravellersCount,
    handleTransferDateTimeChange,
    handleFormInputValidate,
    handleFormSubmit,
    onDestinationAddressSelect,
    onOriginAddressSelect,
  };
}
