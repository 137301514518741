import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAssetPaths } from 'context/EnterprisePartnerConfiguration';

import { useLoungeAirports } from '../../../hooks';
import { getLoungeSearchInputPageQueryParams } from '../../../utils/query-params';
import useLoungeSearchFormProps from './useLoungeSearchFormProps';
import useSetAirportDetails from './useSetAirportDetails';
import useTracking from './useTracking';

/**
 * Retrieve data for the lounge search input page.
 */
export const useLoungeSearchInputPage = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const loungeSearchInputPageQueryParams = useMemo(() => {
    return getLoungeSearchInputPageQueryParams(location);
  }, [location]);

  const loungeAirports = useLoungeAirports();

  const isPageReady =
    Boolean(loungeSearchInputPageQueryParams.airportDetails) ||
    !loungeSearchInputPageQueryParams.airportIataCode;

  useTracking(isPageReady);

  useSetAirportDetails(isPageReady, loungeSearchInputPageQueryParams, loungeAirports.allAirports);

  const loungeSearchFormProps = useLoungeSearchFormProps(
    loungeSearchInputPageQueryParams,
    loungeAirports.allAirports,
    loungeAirports.popularAirportIATAs,
  );

  const assetPaths = useAssetPaths();
  const sipImagePaths = assetPaths?.lounges?.sipImagePaths;

  const productValueItems = useMemo(() => {
    return [
      {
        title: t('SIMPLETRIP LOUNGES USP1 TITLE'),
        text: t('SIMPLETRIP LOUNGES USP1 BODY'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[0] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/lounges/usp/shower.jpg',
      },
      {
        title: t('SIMPLETRIP LOUNGES USP2 TITLE'),
        text: t('SIMPLETRIP LOUNGES USP2 BODY'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[1] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/lounges/usp/amenities.jpg',
      },
      {
        title: t('SIMPLETRIP LOUNGES USP3 TITLE'),
        text: t('SIMPLETRIP LOUNGES USP3 BODY'),
        imageUrl:
          // eslint-disable-next-line max-len
          sipImagePaths?.[2] ||
          'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/lounges/usp/welcome.jpg',
      },
    ];
  }, [t, sipImagePaths]);

  return {
    productValueItems,
    loungeSearchFormProps,
    isPageReady,
  };
};
