/**
 * # Swiss Travel Searh Form
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useSwissTravelSearch from 'modules/swiss-travel/components/SearchForm/hooks/useSwissTravelSearch';
import { TAB_TYPES } from 'modules/swiss-travel/types/SearchForm';

import './styles.scss';

export const SearchForm = () => {
  const { t } = useTranslation();

  const {
    form,
    setCurrentTab,
    currentTab,
    classFareOptions,
    passengerOptions,
    onSearchResultClick,
    onFormChange,
    startDate,
    durationOptions,
    changeStartDate,
    loading,
    formError,
    resetFormError,
    onPublicTransportButtonClick,
    priceLabel,
  } = useSwissTravelSearch();

  return (
    <div className="swiss-search-form">
      <div className="search-form-tabs">
        <div className="search-form-tabs-container">
          <button
            onClick={() => setCurrentTab(TAB_TYPES.SWISS_TRAVEL_PASS)}
            className={clsx(currentTab === TAB_TYPES.SWISS_TRAVEL_PASS && `selected-tab`)}
          >
            {t('sbb.tabTextOne')}
          </button>
          {currentTab === TAB_TYPES.SWISS_TRAVEL_PASS && <div className="active-form-tab"></div>}
        </div>
        <div className="search-form-tabs-container">
          <button
            onClick={() => setCurrentTab(TAB_TYPES.PUBLIC_TRANSPORT_TICKET)}
            className={clsx(currentTab === TAB_TYPES.PUBLIC_TRANSPORT_TICKET && `selected-tab`)}
          >
            {t('sbb.tabTextTwo')}
          </button>
          {currentTab === TAB_TYPES.PUBLIC_TRANSPORT_TICKET && <div className="active-form-tab" />}
        </div>
      </div>

      <div className="search-form-main-container">
        {currentTab === TAB_TYPES.SWISS_TRAVEL_PASS && (
          <>
            {formError && (
              <div className="search-noresults-container">
                <img
                  height={80}
                  /* eslint-disable max-len */
                  src={
                    'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/generic-icons/no-results-box.svg'
                  }
                  alt={'emply box'}
                />
                <div>
                  <ElementsSDK.Typography variant="h4">
                    {t('sbb.no_results_heading.text')}
                  </ElementsSDK.Typography>
                  <ElementsSDK.Typography variant="p2">
                    {t('sbb.no_results_message.text')}
                  </ElementsSDK.Typography>
                  <div className="noresults-cta-container">
                    <ElementsSDK.Button onClick={resetFormError}>
                      {t('sbb.cta_back.text')}
                    </ElementsSDK.Button>
                  </div>
                </div>
              </div>
            )}
            {!formError && (
              <>
                <div className="search-form-fields-container">
                  <ElementsSDK.InputField.Select
                    className="SBBSearchForm__InputField"
                    label={t('sbb.pass_duration')}
                    onSelect={(selectedValue) =>
                      onFormChange({ ...form, duration: selectedValue as number })
                    }
                    options={durationOptions}
                    value={form.duration}
                    disabledTyping={true}
                  ></ElementsSDK.InputField.Select>
                  <ElementsSDK.InputField.DateTimePicker
                    className="SBBSearchForm__InputField"
                    label={t('sbb.start_date')}
                    initialValue={startDate}
                    value={startDate}
                    minDate={dayjs().add(-1, 'days').toString()}
                    maxDate={dayjs().add(6, 'months').toString()}
                    onChange={changeStartDate}
                    timeControlsEnabled={false}
                  />
                  <ElementsSDK.InputField.Select
                    className="SBBSearchForm__InputField"
                    label={t('sbb.class_fare')}
                    onSelect={(selectedValue) =>
                      onFormChange({ ...form, classFare: selectedValue as number })
                    }
                    options={classFareOptions}
                    value={
                      classFareOptions.find((option) => option.value === form.classFare)?.label
                    }
                    disabledTyping={true}
                  ></ElementsSDK.InputField.Select>
                  <ElementsSDK.InputField.Select
                    className="SBBSearchForm__InputField"
                    label={
                      <>
                        {t('sbb.passengers')}
                        <span className="passenger-tooltip-wrapper">
                          <ElementsSDK.Tooltip
                            tooltipText={t('sbb.tooltip_text')}
                            tooltipPosition="bottom"
                            className="passenger-tooltip-content"
                          >
                            <ElementsSDK.Icon.InfoOutlined className="passenger-tooltip-icon-help" />
                          </ElementsSDK.Tooltip>
                        </span>
                      </>
                    }
                    name="Number of passengers"
                    onSelect={(selectedValue) =>
                      onFormChange({ ...form, numberOfPassengers: selectedValue as number })
                    }
                    options={passengerOptions}
                    value={form.numberOfPassengers}
                    disabledTyping={true}
                  ></ElementsSDK.InputField.Select>
                </div>
                <ElementsSDK.Button
                  loading={loading}
                  onClick={() => onSearchResultClick()}
                  className={'buy-now-cta'}
                >
                  {priceLabel ? priceLabel : t('sbb.buy_now')}
                </ElementsSDK.Button>
              </>
            )}
          </>
        )}

        {currentTab === TAB_TYPES.PUBLIC_TRANSPORT_TICKET && (
          <>
            <ElementsSDK.Typography variant="p1">
              {t('sbb.link_to_partner_text')}
            </ElementsSDK.Typography>
            <ElementsSDK.Button
              className="buy-now-cta"
              onClick={() => onPublicTransportButtonClick()}
            >
              {t('sbb.cta_text')}
            </ElementsSDK.Button>
          </>
        )}
      </div>
    </div>
  );
};
