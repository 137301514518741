import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';

import { BookingConfirmation } from 'components/BookingConfirmation';

import { RideBookingConfirmation } from './RideBookingConfirmation';
import { useBookingConfirmation } from './hooks';

const BookingConfirmationPage: React.FC = () => {
  const { bookingConfirmationPageProps } = useBookingConfirmation();

  return (
    <div className="bcp">
      <div className="form-container-wrapper">
        <div className="container form-container">
          <div className="content">
            <BookingConfirmation {...bookingConfirmationPageProps}>
              {(booking: Booking<'COMPLETED', 'RIDE'>) => (
                <RideBookingConfirmation booking={booking} />
              )}
            </BookingConfirmation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationPage;
