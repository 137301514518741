import { extractUserAgentInfo } from 'utils/extractDeviceType';
import { getVariables } from 'utils/yiluEnv';

export enum EventType {
  PAGEVIEW = 'PageView',
  EVENT = 'Event',
}

export enum EventParam {
  LOAD = 'Load',
  VIEW = 'View',
  CLICK = 'Click',

  VIEW_AP = 'View-AP',
  VIEW_AL = 'View-AL',
  VIEW_AT = 'View-AT',
  VIEW_RI = 'View-RI',
  VIEW_SIM = 'View-SIM',
  VIEW_FT = 'View-FT',
  VIEW_HPM = 'View-HPM',
  VIEW_HPV = 'View-HPV',
  VIEW_CS = 'View-CS',

  CLICK_AP = 'CLICK-AP',
  CLICK_AL = 'CLICK-AL',
  CLICK_AT = 'CLICK-AT',
  CLICK_RI = 'CLICK-RI',
  CLICK_SIM = 'CLICK-SIM',
  CLICK_FT = 'CLICK-FT',
  CLICK_HPM = 'CLICK-HPM',
  CLICK_HPV = 'CLICK-HPV',
  CLICK_CS = 'CLICK-CS',
}

const TOUCHPOINT_TYPE = 'landing_page';

const createBasePayload = () => {
  const yiluEnv = getVariables();
  const ep = yiluEnv.YILU_ENTERPRISE_PARTNER?.toLowerCase();
  const url = window.location.origin + window.location.pathname;
  const deviceType = extractUserAgentInfo(window.navigator.userAgent);
  const touchPoint = TOUCHPOINT_TYPE;

  const productCardOrder = 'AP-AL-AT-RI-SIM-FT-HPM-HPV-CS';

  return {
    url,
    deviceType,
    integrationType: touchPoint,
    productCardOrder,
    ep,
  };
};

export async function postTracking(payload: any) {
  const yiluEnv = getVariables();
  const baseURL = yiluEnv.YILU_AMP_BACKEND_URL;

  try {
    await fetch(`${baseURL}/v1/tracking/promotion/event`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.warn('The tracking does not work');
  }
}

export const triggerEvent = (eventType: EventType, eventParam: EventParam): void => {
  if (typeof window !== 'undefined') {
    const payload = {
      ...createBasePayload(),
      eventType,
      eventParam,
    };
    postTracking(payload);
  }
};
