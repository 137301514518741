import { Airport, SearchItem } from '@yiluhub/yilu-amp-types';
import { TransferDirection } from 'applicationConstants';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';
import { getURLSearchQuery } from 'utils/paramConverters';
import { setSrpSession } from 'utils/searchSession';

import routes from 'router/routes';

import { AirportTransferSearchResultsPageQueryParams } from '../../../utils/query-params';
import { AirportTransferSearchResultsProps } from '../AirportTransferSearchResults/types';

/**
 * Retrieve the coordinates from a location
 *
 * @param [location] - location descriptor in the form of "latitude,longitude"
 *
 * @returns Potential coordinates of a location
 */
export function getLocationCoordinates(location?: string) {
  if (!location) return;
  const [latitude, longitude] = location.split(',');

  return {
    latitude: Number(latitude),
    longitude: Number(longitude),
  };
}

export const useAirportTransferSearchResultsProps = (
  airportTransferSearchResultsPageQueryParams: AirportTransferSearchResultsPageQueryParams,
  stationAirports: Airport[],
) => {
  const [searchResultsCurrent, setSearchResultsCurrent] = useState<SearchItem[]>([]);
  const navigate = useNavigate();

  const searchResults = useRef<SearchItem[]>([]);
  const airportTransferSearchResultsProps = useMemo(() => {
    const { iataCode } = airportTransferSearchResultsPageQueryParams.airportDetails || {};
    const searchParams = {
      iata: iataCode!,
      direction: airportTransferSearchResultsPageQueryParams.direction!,
      transferType: airportTransferSearchResultsPageQueryParams.transferType!,
      transferDate: airportTransferSearchResultsPageQueryParams.transferDate!,
      transferTime: airportTransferSearchResultsPageQueryParams.transferTime!,
      locationCoordinates: getLocationCoordinates(
        airportTransferSearchResultsPageQueryParams.location!,
      ),
      passengerCount: airportTransferSearchResultsPageQueryParams.travellersCount,
      currency: airportTransferSearchResultsPageQueryParams.currency,
    };

    const isMissingRequiredParameters = Object.keys(searchParams).some((key) => {
      return (searchParams as Record<string, unknown>)[key] === undefined;
    });

    if (isMissingRequiredParameters) return null;

    // Set the session for the airport transfer search results
    const setTransferSrpSession = () => {
      setSrpSession({
        airportIataCode: searchParams.iata,
        direction: searchParams.direction,
        transferType: searchParams.transferType,
        transferDate: searchParams.transferDate,
        transferTime: searchParams.transferTime,
        location: airportTransferSearchResultsPageQueryParams.location,
        travellersCount: searchParams.passengerCount,
      });
    };

    const _props: AirportTransferSearchResultsProps = {
      searchParams: {
        ...(searchParams as AirportTransferSearchResultsProps['searchParams']),
        carrier: airportTransferSearchResultsPageQueryParams.carrier,
        stationId: airportTransferSearchResultsPageQueryParams.stationId,
      },
      onSearchResults(results: SearchItem[]) {
        setTransferSrpSession();
        setSearchResultsCurrent(results);
        searchResults.current = results;
        const eventCategory = results.length ? 'airport-transfer-srp' : 'airport-transfer-srp-null';
        const eventLabel = results.length
          ? 'airport_transfer_srp_loaded'
          : 'airport_transfer_srp_null_loaded';

        yiluTracking.sendGAEvent({
          event: 'page_loaded',
          category: eventCategory,
          label: eventLabel,
        });
      },
      onNoSearchResults() {
        const selectedAirport = stationAirports.find((airport) => airport.iataCode === iataCode);
        const airportCoordinates = Boolean(selectedAirport)
          ? `${selectedAirport?.latitude},${selectedAirport?.longitude}`
          : '';

        const airportTransferParams = {
          originLocation: '',
          destinationLocation: '',
          transferType: searchParams.transferType!,
          transferDate: searchParams.transferDate,
          transferTime: searchParams.transferTime,
          travellersCount: searchParams.passengerCount,
          direction: searchParams.direction,
        };

        if (searchParams.direction === TransferDirection.FROM_AIRPORT) {
          airportTransferParams.originLocation = airportCoordinates;
          airportTransferParams.destinationLocation =
            airportTransferSearchResultsPageQueryParams.location!;
        } else {
          airportTransferParams.originLocation =
            airportTransferSearchResultsPageQueryParams.location!;
          airportTransferParams.destinationLocation = airportCoordinates;
        }

        navigate({
          pathname: routes.RIDES_SEARCH_RESULTS,
          search: getURLSearchQuery(airportTransferParams),
        });
      },
      onSearchResultClick(searchResult: SearchItem) {
        setTransferSrpSession();
        yiluTracking.sendSelectedIndexSearchEvent(
          searchResult.id,
          searchResults.current,
          'airport_transfer_srp',
        );

        const airportTransferProductDetailsPageQueryParams = {
          searchResultID: searchResult.id,
        };

        navigate({
          pathname: routes.AIRPORT_TRANSFER_PDP,
          search: paramConverters.getURLSearchQuery(airportTransferProductDetailsPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };

    return _props;
  }, [airportTransferSearchResultsPageQueryParams, navigate, stationAirports]);

  return { airportTransferSearchResultsProps, searchResultsCurrent };
};
