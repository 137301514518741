/**
 * # Lounge Booking Confirmation Hook
 *
 *
 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import { BookingConfirmationProps } from 'components/BookingConfirmation';

/**
 * Retrieve data for the lounge booking confirmation (page)
 */
export const useBookingConfirmation = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const bookingConfirmationPageQueryParams = useMemo(() => {
    return paramConverters.getBookingConfirmationPageQueryParams(location);
  }, [location]);

  const bookingConfirmationPageProps = useMemo(() => {
    const bookingConfirmationPageProps: BookingConfirmationProps = {
      bookingId: bookingConfirmationPageQueryParams.bookingId,
      headerStateText: { completed: { subtitle: t('bcp.lounges.headerSubtitle') } },
      onError(error) {
        throw error;
      },
      onResponse() {
        yiluTracking.sendGAEvent({
          event: 'page_loaded',
          category: 'lounge-bcp',
          label: 'lounge_bcp_loaded',
        });
      },
    };
    return bookingConfirmationPageProps;
  }, [bookingConfirmationPageQueryParams, t]);

  return {
    bookingConfirmationPageProps,
  };
};
