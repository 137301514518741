import React, { useState } from 'react';

import useStickyState from 'utils/useStickyState';

import { PaymentInputContext, PaymentInputContextType, PaymentMethods } from './context';

export const PaymentInputContextProvider: React.FC = ({ children }) => {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useStickyState<
    PaymentMethods | undefined
  >('payment-method', undefined);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods[]>([]);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isSubmitFormTriggered, setIsSubmitFormTriggered] = useState(false);
  const [paymentMethodOnSubmit, setPaymentMethodOnSubmit] = useState<() => void>(() => () => {});

  const providerValue: PaymentInputContextType = {
    paymentMethodOnSubmit,
    setPaymentMethodOnSubmit,
    isTermsAccepted,
    onTermsAcceptedChange: (terms: boolean) => setIsTermsAccepted(terms),
    paymentMethods,
    setPaymentMethods: (methods: PaymentMethods[]) => setPaymentMethods(methods),
    currentPaymentMethod,
    setCurrentPaymentMethod: (method: PaymentMethods) => setCurrentPaymentMethod(method),
    hasFormTriggered: isSubmitFormTriggered,
    setHasFormTriggered: (hasSubmitted: boolean) => setIsSubmitFormTriggered(hasSubmitted),
  };

  return (
    <PaymentInputContext.Provider value={providerValue}>{children}</PaymentInputContext.Provider>
  );
};
