/**
 * # Scroll Behavior
 *
 * Handle the specified behavior of scrolling after a navigation transition (-> to the top).
 */
import { useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';

const ScrollBehavior = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const didMountRef = useRef(false);

  const scrollToAnchorOrTop = (currentLocation: Location, previousLocation?: Location) => {
    if (currentLocation.hash) {
      const anchorNode = document.getElementById(currentLocation.hash.slice(1));
      if (anchorNode) {
        anchorNode.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }

    if (
      previousLocation &&
      getLocationPath(currentLocation) !== getLocationPath(previousLocation)
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      scrollToAnchorOrTop(location, prevLocation);
    } else {
      didMountRef.current = true;
      // Initial rendering check
      scrollToAnchorOrTop(location);
    }
  }, [location, prevLocation]);

  return null;
};

export default ScrollBehavior;

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

/**
 * Get the full path information including query parameters
 *
 * @param location - navigation state descriptor
 * @returns Full location path
 */
function getLocationPath(location: Location): string {
  return `${location.pathname}${location.search}`;
}
