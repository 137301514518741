import { useContext } from 'react';

import { UserProfileContext } from './context';

export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error('useUserProfileContext() must be used within UserProfileContext.Provider');
  }

  return context;
};

export { UserProfileContextProvider } from './Provider';
