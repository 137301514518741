/**
 * # HighPass Lounges Embed Page.
 *
 *
 */
import React from 'react';

import { HighPassWidget } from 'modules/highpass/components/HighPassWidget';
import { useHighPassLoungesPage } from 'modules/highpass/pages/LoungesPage/hooks';

import '../styles.scss';

const HighPassLoungesPage: React.FC = () => {
  const { isPageReady, highPassProps } = useHighPassLoungesPage();

  return (
    <>
      {isPageReady && (
        <div className="hp-embed-page">
          <section className="hp-container">
            {highPassProps && <HighPassWidget {...highPassProps} />}
          </section>
        </div>
      )}
    </>
  );
};

export default HighPassLoungesPage;
