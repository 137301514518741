import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { AMP_VERTICALS } from 'applicationConstants';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import routes from 'router/routes';

import { useIsVerticalEnabled } from 'context';

import './styles.scss';

type HeaderNavProps = {
  customClassName?: string;
};

type HeaderNavLinkItem = {
  text: string;
  link: string;
  linkClasses?: string;
  Icon: React.FC;
  onClickHandler?: () => void | undefined;
};

type HeaderNavLinkProps = HeaderNavLinkItem & {
  currentPath: string;
};

const HeaderNavLink: React.FC<HeaderNavLinkProps> = ({
  link,
  text,
  Icon,
  currentPath,
  linkClasses,
  onClickHandler = undefined,
}) => {
  return (
    <li className={clsx({ active: currentPath.startsWith(link) }, linkClasses && linkClasses)}>
      <Link to={link} data-testid="navigation-bar-link" onClick={onClickHandler}>
        <Icon />
        <span>{text}</span>
      </Link>
    </li>
  );
};

const HeaderNav: React.FC<HeaderNavProps> = ({ customClassName }) => {
  const hasEnabledRideGroundTransportServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.RIDES);
  const hasEnabledParkingServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.PARKING);
  const hasEnabledLoungeServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.LOUNGES);
  const hasEnabledAirportTransferServiceProviders = useIsVerticalEnabled(
    AMP_VERTICALS.AIRPORT_TRANSFERS,
  );

  const headerLinks: HeaderNavLinkItem[] = [];

  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (hasEnabledParkingServiceProviders) {
    headerLinks.push({
      text: t('SIMPLETRIP NAVBAR LINK PARKING'),
      link: routes.PARKING_SEARCH,
      linkClasses: 'parking-nav-link',
      Icon: () => <ElementsSDK.Icon.Parking />,
    });
  }

  if (hasEnabledRideGroundTransportServiceProviders) {
    headerLinks.push({
      text: t('SIMPLETRIP NAVBAR LINK RIDES'),
      link: routes.RIDES_SEARCH,
      linkClasses: 'rides-nav-link',
      Icon: () => <ElementsSDK.Icon.Taxi />,
    });
  }

  if (hasEnabledLoungeServiceProviders) {
    headerLinks.push({
      text: t('SIMPLETRIP NAVBAR LINK LOUNGES'),
      link: routes.LOUNGES_SEARCH,
      linkClasses: 'lounges-nav-link',
      Icon: () => <ElementsSDK.Icon.Lounge />,
    });
  }

  if (hasEnabledAirportTransferServiceProviders) {
    headerLinks.push({
      text: t('SIMPLETRIP NAVBAR LINK AIRPORT TRANSFER'),
      link: routes.AIRPORT_TRANSFER_SEARCH,
      linkClasses: 'airport-transfer-nav-link',
      Icon: () => <ElementsSDK.Icon.BusTrain />,
    });
  }

  // If only one entry is linked nothing should be rendered
  if (headerLinks.length <= 1) {
    return null;
  }

  return (
    <div className={clsx('sip-main-nav', customClassName)} data-testid="navigation-bar">
      <ul>
        {headerLinks.map((headerLink) => (
          <HeaderNavLink key={headerLink.text} currentPath={pathname} {...headerLink} />
        ))}
      </ul>
    </div>
  );
};

export default HeaderNav;
