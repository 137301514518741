import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { PaymentOption } from '@yiluhub/yilu-amp-types';
import React, { useCallback, useEffect, useState } from 'react';

import { usePaymentInputContext } from 'context';
import { PaymentMethods } from 'context/PaymentInput/context';

import { getErrorMessage } from 'components/PaymentInformation/utils/errors';
import { onLoadTracking } from 'components/PaymentInformation/utils/tracking';

import { PaymentInformationProps } from '../index';
import { usePaymentSetup } from './useSetupPayment';
import useStripe from './useStripe';

const enum PAYMENT_PROVIDER {
  STRIPE = 'STRIPE',
}

const getStripeKey = (paymentOptions: PaymentOption[]): string | null => {
  let stripeKey: string | null = null;

  paymentOptions.some((option) => {
    if (option.provider === PAYMENT_PROVIDER.STRIPE) {
      stripeKey = option.apiKey;
    }

    return Boolean(stripeKey);
  });

  return stripeKey;
};

type PaymentInformationHookProps = {
  productTrackingId: string;
  shoppingCartId: string;
  bookingIntentId: string;
  serviceProviderId: string;
  stripeMerchantLabel: string;
  amount: PaymentInformationProps['amount'];
  onError: (error: Error) => void;
};

export const usePaymentInformation = ({
  stripeMerchantLabel,
  productTrackingId,
  shoppingCartId,
  bookingIntentId,
  serviceProviderId,
  amount,
  onError,
}: PaymentInformationHookProps) => {
  const {
    currentPaymentMethod,
    setPaymentMethods,
    setCurrentPaymentMethod,
    setHasFormTriggered,
    paymentMethodOnSubmit,
    setPaymentMethodOnSubmit,
  } = usePaymentInputContext();
  const [hasInitialized, setHasInitialized] = useState(false);
  const [paymentRequest, setPaymentRequest] =
    useState<stripe.paymentRequest.StripePaymentRequest>();
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isCreateBookingLoading, setIsCreateBookingLoading] = useState(false);
  const [createBookingError, setCreateBookingError] = useState<Error | undefined>(undefined);
  //setup payment options and client secret for payment intent api
  const { paymentOptions, clientSecret } = usePaymentSetup(
    amount,
    serviceProviderId,
    shoppingCartId,
    bookingIntentId,
    onError,
  );
  const stripeKey = getStripeKey(paymentOptions ? paymentOptions.paymentOptions : []);
  const stripe = useStripe(stripeKey);

  //setup payment request button if available and set payment methods
  const setupPaymentRequest = useCallback((): stripe.paymentRequest.StripePaymentRequest | null => {
    if (!stripe) return null;

    const { currency, price } = amount;

    const paymentRequest = stripe.paymentRequest({
      country: 'DE',
      currency: currency.toLowerCase(),
      total: {
        label: stripeMerchantLabel,
        amount: Math.round(price * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    setPaymentRequest(paymentRequest);
    return paymentRequest;
  }, [stripeMerchantLabel, amount, stripe]);

  const setAvailablePaymentMethods = useCallback(async () => {
    try {
      const validPaymentMethods = [];
      let defaultPaymentMethod = PaymentMethods.CREDIT_CARD;

      const paymentRequest = setupPaymentRequest();

      if (paymentRequest) {
        // Check the availability of the Payment Request API first.
        const res = await paymentRequest.canMakePayment();

        if (res?.applePay) {
          validPaymentMethods.push(PaymentMethods.APPLE_PAY);
          defaultPaymentMethod = PaymentMethods.APPLE_PAY;
        } else if (res?.googlePay) {
          validPaymentMethods.push(PaymentMethods.GOOGLE_PAY);
          defaultPaymentMethod = PaymentMethods.GOOGLE_PAY;
        }
      }

      validPaymentMethods.push(PaymentMethods.CREDIT_CARD);
      setPaymentMethods(validPaymentMethods);

      // set current payment method to default payment method if it is not set or not available
      if (
        (currentPaymentMethod && !validPaymentMethods.includes(currentPaymentMethod)) ||
        !currentPaymentMethod
      ) {
        setCurrentPaymentMethod(defaultPaymentMethod);
        onLoadTracking({
          paymentMethod: defaultPaymentMethod,
          productTrackingId,
        });
      } else {
        onLoadTracking({
          paymentMethod: currentPaymentMethod,
          productTrackingId,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);

      // if an error occurred set payment methods to credit card
      setPaymentMethods([PaymentMethods.CREDIT_CARD]);
      setCurrentPaymentMethod(PaymentMethods.CREDIT_CARD);
    } finally {
      setIsFormLoading(false);
    }
  }, [
    productTrackingId,
    setCurrentPaymentMethod,
    setPaymentMethods,
    setupPaymentRequest,
    currentPaymentMethod,
  ]);

  // set payment methods and payment request button if available
  useEffect(() => {
    if (stripe && setAvailablePaymentMethods && !hasInitialized) {
      setAvailablePaymentMethods();
      setHasInitialized(true);
    }
  }, [stripe, setAvailablePaymentMethods, setHasInitialized, hasInitialized]);

  // show modal if payment failed or loading
  const {
    isVisible: isPaymentModalVisible,
    onOk,
    onShow: onPopupShow,
  } = ElementsSDK.useModal(false);

  const handleOnOk = useCallback(() => {
    setCreateBookingError(undefined);
    onOk();
  }, [onOk]);

  // show modal if payment failed or loading
  useEffect(() => {
    isCreateBookingLoading || createBookingError ? onPopupShow() : onOk();
  }, [isCreateBookingLoading, createBookingError, onPopupShow, onOk]);

  const handlePaymentFailedError = useCallback(
    (error: Error | undefined) => {
      setCreateBookingError(error);
      console.error(error || 'Unknown error');
    },
    [setCreateBookingError],
  );

  const isCreditCard = currentPaymentMethod === PaymentMethods.CREDIT_CARD && stripe;
  const isPaymentRequestButton =
    (currentPaymentMethod === PaymentMethods.APPLE_PAY ||
      currentPaymentMethod === PaymentMethods.GOOGLE_PAY) &&
    paymentRequest &&
    stripe;

  const handleOnSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setHasFormTriggered(true);
      paymentMethodOnSubmit && paymentMethodOnSubmit();
    },
    [paymentMethodOnSubmit, setHasFormTriggered],
  );

  return {
    handleOnSubmit,
    setPaymentMethodOnSubmit,
    stripe,
    clientSecret,
    paymentRequest,
    isFormLoading,
    isCreditCard,
    isPaymentRequestButton,
    isPaymentModalVisible,
    setIsCreateBookingLoading,
    createBookingError,
    handlePaymentFailedError,
    handleOnOk,
    getErrorMessage,
  };
};
