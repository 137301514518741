import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type LoungeAdditionalInfoProps = {
  additionalInfo?: {
    title: string;
    content: string;
  }[];
  needToKnow?: {
    title: string;
    content: string;
  }[];
};

const AdditionalInfoItem: React.FC<{ title: string; content?: string }> = ({
  title,
  content,
  children,
}) => (
  <div className="yilu-Lounges_AdditionalInfoContainer--InfoItem">
    <ElementsSDK.Typography variant="h3" size="large">
      {title}
    </ElementsSDK.Typography>
    {content && (
      <ElementsSDK.Typography variant="p1" dangerouslySetInnerHTML={{ __html: content }} />
    )}
    {children}
  </div>
);

const LoungeAdditionalInformation: React.FC<LoungeAdditionalInfoProps> = ({
  additionalInfo,
  needToKnow,
}) => {
  const { t } = useTranslation();

  const firstAdditionalInfo = additionalInfo && additionalInfo[0];
  const restAdditionalInfo = additionalInfo && additionalInfo.slice(1);

  const needToKnowList = needToKnow?.map(({ title, content }) => (
    <ElementsSDK.Typography variant="p1">
      <strong>{title}</strong> - <span dangerouslySetInnerHTML={{ __html: content }}></span>
    </ElementsSDK.Typography>
  ));

  return (
    <div className="yilu-Lounges_AdditionalInfoContainer">
      <ElementsSDK.Typography variant="h2">{t('Additional Information')}</ElementsSDK.Typography>
      {firstAdditionalInfo && AdditionalInfoItem(firstAdditionalInfo)}

      <ElementsSDK.Accordion
        labelVariant={'p1'}
        label={{ open: t('See less'), closed: t('See more') }}
        expandUp={true}
      >
        {restAdditionalInfo &&
          restAdditionalInfo?.length > 0 &&
          restAdditionalInfo.map((amenity) => <AdditionalInfoItem {...amenity} />)}
        {needToKnowList && (
          <AdditionalInfoItem title={t('Lounges.needToKnow')}>{needToKnowList}</AdditionalInfoItem>
        )}
      </ElementsSDK.Accordion>
    </div>
  );
};
export default LoungeAdditionalInformation;
