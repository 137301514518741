/**
 * # Collapsed Parking
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { concatenateDateTime, formatLocalDate, getYiluConfigLocale } from '@yiluhub/ui-utilities';
import React from 'react';

import { ParkingSearchInput } from '../../../../components';
import './styles.scss';

function formatDate(date: string, time?: string) {
  const dateTime = time ? concatenateDateTime(date, time) : date;
  return formatLocalDate(dateTime, 'D MMM, HH:mm');
}

const CollapsedParking: React.FC<CollapsedParkingProps> = ({
  flightTime,
  searchInput = {} as ParkingSearchInput,
}) => {
  const locale = getYiluConfigLocale();

  const { entryDate, entryTime, exitDate, exitTime, airportDetails } = searchInput;

  const entryDateTime = entryTime && entryDate ? formatDate(entryDate, entryTime) : '--';
  const exitDateTime = exitTime && exitDate ? formatDate(exitDate, exitTime) : '--';

  return (
    <section className="yilu-CollapsibleForm__Parking__Collapsed">
      <div className="yilu-CollapsibleForm__Parking__Collapsed--dates">
        <span className="yilu-CollapsibleForm__Parking__Collapsed--entryDate">{entryDateTime}</span>
        <span className="yilu-CollapsibleForm__Parking__Collapsed--exitDate">{exitDateTime}</span>
        {flightTime && (
          <span className="yilu-CollapsibleForm__Parking__Collapsed--flightTime">
            <ElementsSDK.Icon.Airplane />
            {formatDate(flightTime)}
          </span>
        )}
      </div>
      {airportDetails && (
        <div className="yilu-CollapsibleForm__Parking__Collapsed--address">
          {airportDetails.localizations[locale].name}
        </div>
      )}

      <ElementsSDK.Icon.ArrowRightChevron className="yilu-CollapsibleForm__Parking__Collapsed--caret" />
    </section>
  );
};

type CollapsedParkingProps = {
  searchInput?: ParkingSearchInput;
  flightTime?: string;
};

export default CollapsedParking;
