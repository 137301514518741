import { SearchItem } from '@yiluhub/yilu-amp-types';

import { extractAmpProductAttribute } from 'utils/ampUtils';

import { isSearchItemHolidayExtras } from 'modules/lounges/utils/getProductDetails';

export function isHolidayExtrasAvailable(date: string) {
  const endOfDayDate = new Date();
  endOfDayDate.setHours(23, 59, 59, 999);

  const loungeStartDate = new Date(date);
  const isSameDay = loungeStartDate.getTime() <= endOfDayDate.getTime();

  return !isSameDay;
}

export function isLoungeLocked(searchItem: SearchItem) {
  const isHolidayExtras = isSearchItemHolidayExtras(searchItem);

  //visible is a LHG specific attribute, it is not available in Holiday Extras
  const visibleAttr = extractAmpProductAttribute(searchItem, 'visible');
  let isLocked = false;

  if (visibleAttr !== undefined && !isHolidayExtras) {
    isLocked = visibleAttr === 'false';
  }

  return isLocked;
}

export function isLoungeBookable(searchItem: SearchItem) {
  const isHolidayExtras = isSearchItemHolidayExtras(searchItem);

  const startDate = searchItem.item.startDate;
  let isBookable = true;

  if (isHolidayExtras) {
    if (startDate) {
      isBookable = isHolidayExtrasAvailable(startDate);
    }
  } else {
    //isBookable is a LHG specific attribute, it is not available in Holiday Extras
    const isBookableAttr = extractAmpProductAttribute(searchItem, 'isBookable');

    if (isBookableAttr !== undefined && !isHolidayExtras) {
      isBookable = isBookableAttr === 'true';
    }
  }

  return isBookable;
}
