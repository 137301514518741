import { Airport } from '@yiluhub/yilu-amp-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { AirportTransferSearchResultsPageQueryParams } from '../../../utils/query-params';

const useSetAirportDetails = (
  isPageReady: boolean,
  airportTransferSearchResultsPageQueryParams: AirportTransferSearchResultsPageQueryParams,
  airportTransferAirports: Airport[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const nextAirportTransferSearchResultsPageQueryParams = {
      ...airportTransferSearchResultsPageQueryParams,
    };
    let selectedAirportStation;

    if (airportTransferAirports && airportTransferAirports.length > 0) {
      selectedAirportStation = airportTransferAirports.find(
        (airportStation) =>
          airportStation.iataCode.toLowerCase() ===
          airportTransferSearchResultsPageQueryParams.airportIataCode?.toLowerCase(),
      );

      if (selectedAirportStation && selectedAirportStation.localizations) {
        nextAirportTransferSearchResultsPageQueryParams.airportDetails = {
          localizations: {
            de: selectedAirportStation.localizations!.de,
            en: selectedAirportStation.localizations!.en,
          },
          iataCode: selectedAirportStation.iataCode,
        };

        delete nextAirportTransferSearchResultsPageQueryParams.airportIataCode;
        navigate(
          {
            pathname: routes.AIRPORT_TRANSFER_SEARCH_RESULTS,
            search: getURLSearchQuery(nextAirportTransferSearchResultsPageQueryParams),
          },
          { replace: true },
        );
      }
    }
  }, [isPageReady, airportTransferSearchResultsPageQueryParams, navigate, airportTransferAirports]);
};

export default useSetAirportDetails;
