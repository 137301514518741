import { GetUserProfileResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import React, { useCallback, useState } from 'react';

import { yiluCookies } from 'utils';
import { yiluEnv } from 'utils';

import { UserProfileContext, UserProfileContextType } from './context';

type UserProfileState = UserProfileContextType['userProfileState'];

export const UserProfileContextProvider: React.FC = ({ children }) => {
  const cookieConsent = yiluCookies.getConsentFromCookie();
  const [isInitialising, setIsInitialising] = useState(false);
  const [userProfileState, setUserProfileState] = useState<UserProfileState>({
    data: {
      consent: {
        necessary: false,
        statistics: false,
        personalization: false,
        comfort: false,
        ...cookieConsent?.consent,
      },
      userId: '',
      storeId: '',
    },
    isUserProfileFetched: false,
    isUserProfileUpserted: false,
    userProfileUpsertedError: null,
  });

  const upsertUserProfile = useCallback(
    async (userId?: string) => {
      const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();
      const cookieConsent = yiluCookies.getConsentFromCookie();

      try {
        const requestInput = {
          consent: cookieConsent?.consent
            ? cookieConsent?.consent
            : { ...userProfileState.data.consent },
          userId: userId || userProfileState.data.userId,
          storeId: YILU_AMP_STORE_ID,
        };
        await axios.post(`${YILU_AMP_BACKEND_URL}/user-profile/v1/user-profiles`, requestInput);

        setUserProfileState({
          ...userProfileState,
          isUserProfileUpserted: true,
          userProfileUpsertedError: null,
          data: { ...userProfileState.data, ...requestInput },
        });
        return true;
      } catch (e) {
        console.error(`Failed to upsert User Profile with error: ${e}`);
        setUserProfileState({
          ...userProfileState,
          isUserProfileUpserted: false,
          userProfileUpsertedError: e as Error,
        });
        return false;
      }
    },
    [userProfileState],
  );

  const getUserProfile = useCallback(
    async (userId: string) => {
      const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();

      try {
        const userPreferencesResponse = await axios.get<GetUserProfileResponse>(
          `${YILU_AMP_BACKEND_URL}/user-profile/v1/user-profiles/${userId}?storeId=${YILU_AMP_STORE_ID}`,
        );

        if (!userPreferencesResponse || !userPreferencesResponse.data) {
          return null;
        }

        setUserProfileState({
          ...userProfileState,
          data: { ...userPreferencesResponse.data },
          isUserProfileFetched: true,
        });

        return userPreferencesResponse.data;
      } catch (e) {
        return null;
      }
    },
    [userProfileState],
  );

  const initialiseUserProfile = useCallback(
    async (userId: string) => {
      const { isUserProfileFetched, isUserProfileUpserted, userProfileUpsertedError } =
        userProfileState;

      if (!isUserProfileFetched && !isInitialising) {
        setIsInitialising(true);
        const userProfile = await getUserProfile(userId);

        if (userProfile === null && !isUserProfileUpserted && !userProfileUpsertedError) {
          const isUpserted = await upsertUserProfile(userId);
          if (isUpserted) await getUserProfile(userId);
        }
      }
    },
    [getUserProfile, upsertUserProfile, userProfileState, isInitialising],
  );

  const providerValue: UserProfileContextType = {
    userProfileState,
    initialiseUserProfile,
    upsertUserProfile,
  };

  return (
    <UserProfileContext.Provider value={providerValue}>{children}</UserProfileContext.Provider>
  );
};
