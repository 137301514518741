import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { LegalContentType } from 'applicationConstants';
import React from 'react';

import Imprint from 'widgets/Legal/Imprint';

import Privacy from './Privacy';
import Terms from './Terms';
import Tracking from './Tracking';
import './styles.scss';

const getLegalComponent = (contentType: string): React.FC | null => {
  switch (contentType) {
    case LegalContentType.PRIVACY:
      return Privacy;
    case LegalContentType.TERMS:
      return Terms;
    case LegalContentType.IMPRINT:
      return Imprint;
    case LegalContentType.TRACKING:
    default:
      return Tracking;
  }
};

interface ILegal {
  contentType: string;
  title: string;
  showClose?: boolean;
  handleClose: () => void;
  handleCloseBackBtn: () => void;
  isVisible: boolean;
}

const Legal: React.FC<ILegal> = ({
  contentType,
  title,
  showClose,
  handleClose,
  handleCloseBackBtn,
  isVisible,
}) => {
  const LegalComponent = getLegalComponent(contentType);
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');

  return (
    <ElementsSDK.Modal
      className="tracking-preferences-modal"
      isVisible={isVisible}
      onClickOutside={handleClose}
      title={title}
      onClose={showClose ? handleCloseBackBtn : undefined}
      size={isDesktop ? ElementsSDK.ModalSize.EXTRA_LARGE : ElementsSDK.ModalSize.LARGE}
      isCSSAnimationEnabled
    >
      {LegalComponent && <LegalComponent />}
    </ElementsSDK.Modal>
  );
};

export default Legal;
