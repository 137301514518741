/**
 * # Traveller Input Page Hooks
 *
 *
 */
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useCurrentProduct } from 'hooks';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRedirectOnLoungeSearchResultNotFound } from '../../../hooks';
import { getLoungeTravellerInputPageQueryParams } from '../../../utils/query-params';
import { getSessionPricingOption } from '../../../utils/sessionUtilities';
import useProductSummaryProps from './useProductSummaryProps';
import useReservationInformationFormProps from './useReservationInformationFormProps';
import useTracking from './useTracking';

/**
 * Retrieve data for the lounge traveller input page.
 */
export const useTravellerInputPage = () => {
  const location = useLocation();
  const [isReservationUpdateLoading, setIsReservationUpdateLoading] = useState(false);
  const [pricingOption, setPricingOption] = useState(getSessionPricingOption());

  const travellerInputPageQueryParams = useMemo(() => {
    return getLoungeTravellerInputPageQueryParams(location);
  }, [location]);

  const { searchResultID } = travellerInputPageQueryParams;

  const { currentProduct } = useCurrentProduct(searchResultID);

  useRedirectOnLoungeSearchResultNotFound(searchResultID);

  const currentLounge = currentProduct as SearchItem | undefined;

  const reservationFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(currentLounge);

  useTracking(isPageReady);

  const travellerInformationFormProps = useReservationInformationFormProps(
    currentLounge,
    reservationFormRef,
    setIsReservationUpdateLoading,
    setPricingOption,
    pricingOption,
  );

  const productSummaryProps = useProductSummaryProps(
    currentLounge,
    reservationFormRef,
    isReservationUpdateLoading,
    pricingOption,
  );

  return {
    isPageReady,
    travellerInformationFormProps,
    productSummaryProps,
  };
};
