import { useEffect, useState } from 'react';

// Generic type T allows the hook to handle any type of value
function useStickyState<T>(key: string, defaultValue: T) {
  // Initialize state from localStorage or with a default value
  const [value, setValue] = useState<T>(() => {
    const stickyValue = localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue || '';
  });

  // Update localStorage when the state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const; // TypeScript 'as const' makes the array read-only
}

export default useStickyState;
