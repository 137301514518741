import { AMP_LOUNGE_PRICING_OPTION, PRICING_OPTION } from 'modules/lounges/utils/constants';

export const getPricingOptionLabel = (option: PRICING_OPTION): AMP_LOUNGE_PRICING_OPTION => {
  switch (option) {
    case PRICING_OPTION.PREMIUM:
      return AMP_LOUNGE_PRICING_OPTION.ECONOMY_PREMIUM;
    case PRICING_OPTION.OTHER:
      return AMP_LOUNGE_PRICING_OPTION.OTHER_AIRLINE;
    default:
      return AMP_LOUNGE_PRICING_OPTION.DEFAULT;
  }
};

export const getSelectedPriceId = (
  option: PRICING_OPTION,
  defaultPriceId: string,
  premiumPriceId: string,
  otherAirlineId: string,
): string => {
  switch (option) {
    case PRICING_OPTION.PREMIUM:
      return premiumPriceId;
    case PRICING_OPTION.OTHER:
      return otherAirlineId;
    default:
      return defaultPriceId;
  }
};
