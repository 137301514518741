import { useCurrentProduct } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { getURLSearchQuery } from 'utils/paramConverters';
import { sendGAEvent, sendPaymentSuccessfulEvent } from 'utils/tracking';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { AirportTransferProductSummaryProps } from 'modules/airport-transfer/components';
import { getAirportTransferProductData } from 'modules/airport-transfer/utils/getAmpAirportTransferData';

import { getAirportTransferPaymentInputPageQueryParams } from '../../utils/query-params';

/**
 * Retrieve data for the Airport Transfer PaymentInputPage.
 */
export const usePaymentInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const airportTransferPaymentInputPageQueryParams = useMemo(() => {
    return getAirportTransferPaymentInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(
    airportTransferPaymentInputPageQueryParams.searchResultID,
  );

  const paymentFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(currentProduct);

  // Define PaymentInputForm Props
  const paymentInformationFormProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const { carrierName, currency, price, providerId } =
      getAirportTransferProductData(currentProduct);

    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      amount: {
        price: price,
        currency: currency,
      },
      stripeMerchantLabel: carrierName,
      productTrackingId: 'transfers',
      serviceProviderId: providerId,
      bookingIntentId: airportTransferPaymentInputPageQueryParams.bookingIntentId,
      userId: airportTransferPaymentInputPageQueryParams.userId as string,
      touchPointId: airportTransferPaymentInputPageQueryParams.touchpoint as string,
      decisionId: airportTransferPaymentInputPageQueryParams.decisionId as string,
      shoppingCartId: airportTransferPaymentInputPageQueryParams.shoppingCartId,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        sendPaymentSuccessfulEvent(airportTransferPaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.AIRPORT_TRANSFER_BCP,
          search: getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [currentProduct, airportTransferPaymentInputPageQueryParams, navigate]);

  // Define Airport Transfer ProductSummary Props
  const airportTransferProductSummaryProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const {
      id,
      carrierName,
      travellersCount,
      arrivalDateTime,
      departureDateTime,
      currency,
      price,
      origin,
      destination,
      interconnectionTransfersCount,
      termsUrl,
    } = getAirportTransferProductData(currentProduct);

    const _props: AirportTransferProductSummaryProps = {
      originName: origin.name!,
      destinationName: destination.name!,
      departureDateTime: departureDateTime,
      arrivalDateTime: arrivalDateTime,
      displayingTimezone: tzlookup(destination.lat as number, destination.long as number),
      price: price,
      currency: currency,
      travellersCount: travellersCount,
      carrierName: carrierName,
      interconnectionTransfersCount: interconnectionTransfersCount || 0,
      termsDetails: {
        termsUrl: termsUrl,
        productDetails: {
          providerId: id,
          providerName: carrierName,
        },
      },
      onClick() {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'pip_cta_clicked',
            category: 'airport-transfer-pip',
            label: 'airport_transfer_pip_cta_clicked',
          });
        }
      },
      isDisabled: false,
      isLoading: false,
      submitBtnLabel: t('Pay now'),
    };
    return _props;
  }, [currentProduct, paymentFormRef, t]);

  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    sendGAEvent({
      event: 'page_loaded',
      category: 'airport-transfer-pip',
      label: 'airport_transfer_pip_loaded',
    });
  }, [isPageReady]);

  return {
    isPageReady,
    paymentInformationFormProps,
    airportTransferProductSummaryProps,
  };
};
