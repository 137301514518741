import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductValues from 'components/ProductValues';

import './styles.scss';

type LoungeProductValuesProps = {
  productValueItems: {
    title: string;
    text: string;
    imageUrl: string;
  }[];
};

const LoungeProductValues: React.FC<LoungeProductValuesProps> = ({ productValueItems }) => {
  const { t } = useTranslation();

  return (
    <div className="lounge-product-values">
      <ProductValues title={t('SIMPLETRIP LOUNGES USP TITLE')} items={productValueItems} />
    </div>
  );
};

export default LoungeProductValues;
