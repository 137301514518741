import { Location } from 'react-router-dom';

import { getFlightNumberPageParam } from 'modules/lounges/utils/query-params';
import {
  getSessionFlightNumber,
  setSessionFlightNumber,
} from 'modules/lounges/utils/sessionUtilities';

export const constructFlightNumberSessionStorage = (
  location: Location = window.location as any,
) => {
  //do not overwrite the flight number if it is already set
  if (getSessionFlightNumber()) {
    return;
  }

  const flightNumber = getFlightNumberPageParam(location);

  if (flightNumber) {
    setSessionFlightNumber(flightNumber);
  }
};
