import { UseTranslationResponse } from 'react-i18next';

import { CustomErrorCodes, StripeErrorCodes } from 'components/PaymentInformation/types';

export const getErrorTitle = (errorCode: string, t: UseTranslationResponse['t']) => {
  let errorTitle;

  switch (errorCode) {
    case StripeErrorCodes.EXPIRED_CARD:
      errorTitle = t('stripe.api.expired.card');
      break;
    case StripeErrorCodes.GENERIC_DECLINE:
      errorTitle = t('stripe.api.generic.decline');
      break;
    case StripeErrorCodes.INSUFFICIENT_FUNDS:
      errorTitle = t('stripe.api.insufficient.funds');
      break;

    case StripeErrorCodes.INCOMPLETE_CVC:
    case StripeErrorCodes.INVALID_CVC:
    case StripeErrorCodes.INCORRECT_CVC:
      errorTitle = t('stripe.api.incorrect.cvc');
      break;
    case StripeErrorCodes.CARD_DECLINED:
      errorTitle = t('stripe.card.declined');
      break;
    case StripeErrorCodes.API_CONNECTION:
    case StripeErrorCodes.PROCESSING_ERROR:
    case StripeErrorCodes.STOLEN_CARD:
    case StripeErrorCodes.LOST_CARD:
    case StripeErrorCodes.INCOMPLETE_CARD:
    case StripeErrorCodes.INCOMPLETE_NUMBER:
    case StripeErrorCodes.INCORRECT_NUMBER:
    case StripeErrorCodes.INVALID_NUMBER:
    case StripeErrorCodes.INVALID_EXPIRY_MONTH:
    case StripeErrorCodes.INVALID_EXPIRY_MONTH_PAST:
    case StripeErrorCodes.INVALID_EXPIRY_YEAR:
    case StripeErrorCodes.INVALID_EXPIRY_YEAR_PAST:
    case StripeErrorCodes.INCOMPLETE_EXPIRY:
    case CustomErrorCodes.INPUT_REQUIRED:
    default:
      errorTitle = t('Payment Failed Title');
  }

  return errorTitle;
};
export const getErrorMessage = (errorCode: string, t: UseTranslationResponse['t']) => {
  let errorMessage;

  switch (errorCode) {
    case CustomErrorCodes.INPUT_REQUIRED:
      errorMessage = t('inputRequired');
      break;
    case StripeErrorCodes.INCOMPLETE_CARD:
    case StripeErrorCodes.INCOMPLETE_NUMBER:
    case StripeErrorCodes.INCORRECT_NUMBER:
    case StripeErrorCodes.INVALID_NUMBER:
      errorMessage = t('Credit Card Number Error');
      break;
    case StripeErrorCodes.INVALID_EXPIRY_MONTH:
    case StripeErrorCodes.INVALID_EXPIRY_MONTH_PAST:
    case StripeErrorCodes.INVALID_EXPIRY_YEAR:
    case StripeErrorCodes.INVALID_EXPIRY_YEAR_PAST:
    case StripeErrorCodes.INCOMPLETE_EXPIRY:
      errorMessage = t('Expiration Date Error');
      break;
    case StripeErrorCodes.EXPIRED_CARD:
      errorMessage = t('stripe.api.expired.card.message');
      break;
    case StripeErrorCodes.GENERIC_DECLINE:
      errorMessage = t('stripe.api.generic.decline.message');
      break;
    case StripeErrorCodes.LOST_CARD:
    case StripeErrorCodes.STOLEN_CARD:
      errorMessage = t('stripe.api.stolen.card.message');
      break;
    case StripeErrorCodes.INSUFFICIENT_FUNDS:
      errorMessage = t('stripe.api.insufficient.funds.message');
      break;
    case StripeErrorCodes.INCOMPLETE_CVC:
    case StripeErrorCodes.INVALID_CVC:
    case StripeErrorCodes.INCORRECT_CVC:
      errorMessage = t('stripe.api.incorrect.cvc.message');
      break;
    case StripeErrorCodes.API_CONNECTION:
      errorMessage = t('stripe.api.connection.error');
      break;
    case StripeErrorCodes.CARD_DECLINED:
      errorMessage = t('stripe.card.declined.message');
      break;
    case StripeErrorCodes.PROCESSING_ERROR:
      errorMessage = t('stripe.processing.error.message');
      break;
    default:
      errorMessage = t('Payment Failed Body');
  }

  return errorMessage;
};
