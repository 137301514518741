/**
 * # Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatCurrency, useIsDesktop } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TermsCheck, { TermsCheckProps } from '../TermsCheck';
import './styles.scss';

export type ProductSummaryProps = {
  onClick: () => unknown;
  price: number;
  currency: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  extendedView: React.ReactElement;
  submitBtnLabel?: string;
  termsDetails?: {
    termsUrl: TermsCheckProps['termsUrl'];
    productDetails: TermsCheckProps['productDetails'];
  };
};

const ProductSummary: React.FC<ProductSummaryProps> = ({
  price,
  currency,
  onClick,
  isLoading,
  isDisabled,
  extendedView,
  submitBtnLabel,
  children,
  termsDetails,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  return (
    <div className={'yilu-ProductSummary'}>
      {isDesktop && extendedView}
      {children}
      <div className={'yilu-ProductSummary__Row yilu-ProductSummary__Price'}>
        <div className={'yilu-ProductSummary__Column'}>
          <ElementsSDK.Typography size="large" font="bold">
            {t('Total')}
          </ElementsSDK.Typography>
          <ElementsSDK.Typography
            size="small"
            className={'yilu-ProductSummary__Minor yilu-ProductSummary__MinorOffset'}
          >
            {t('VAT included')}
          </ElementsSDK.Typography>
        </div>
        <ElementsSDK.Typography size="large" font="bold">
          {formatCurrency(price, currency)}
        </ElementsSDK.Typography>
      </div>
      {termsDetails && (
        <TermsCheck
          termsUrl={termsDetails.termsUrl || ''}
          productDetails={termsDetails.productDetails}
        />
      )}
      <ElementsSDK.Button
        className={'yilu-ProductSummary__Button'}
        disabled={isDisabled}
        onClick={onClick}
        loading={isLoading}
      >
        {submitBtnLabel || t('Continue')}
      </ElementsSDK.Button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductSummary;
