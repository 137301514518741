import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'utils/i18n';
import { yiluEnv } from 'utils/index';
import { getCurrentWidgetPartnerId } from 'utils/parnerIds';

import { BookingTerms } from 'components/BookingConfirmation/components';
import 'components/BookingConfirmation/styles.scss';

import LoungeFaq from 'modules/lounges/components/LoungeFaq';
import { HolidayExtraServiceProvider } from 'modules/lounges/utils/constants';

import { BookingEmailSupport } from './BookingEmailSupport';
import { BookingFAQSupport } from './BookingFAQSupport';
import { LoungesBookingInfo } from './LoungesBookingInfo';
import { getFaqLink } from './utils';

type LoungeBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'LOUNGE'>;
};

export const LoungeBookingConfirmation: React.FC<LoungeBookingConfirmationProps> = ({
  booking,
}) => {
  const { t } = useTranslation();
  const yiluEnvVariables = yiluEnv.getVariables();
  const partnerId = yiluEnvVariables.YILU_ENTERPRISE_PARTNER || 'LH';

  const faqs = Array.from({ length: 3 }, (_, i) => ({
    question: t(`bcp.loungeFaq.question${i + 1}`),
    answer: t(`bcp.loungeFaq.answer${i + 1}`),
  }));

  const isHolidayExtras = booking?.serviceProvider === HolidayExtraServiceProvider;
  const bookingData = booking?.product.data;

  const currentLanguage = getCurrentLanguage();
  const faqLink = getFaqLink(
    currentLanguage,
    booking.serviceProvider,
    bookingData.loungeDetails.iata,
    bookingData.loungeDetails.name,
    bookingData.loungeDetails.terminal,
  );

  useEffect(() => {
    window.initYiluWidget();
  }, [bookingData]);

  return (
    <>
      {bookingData && (
        <>
          <ElementsSDK.Message
            className="yilu-payment-bcp-email-confirmation"
            type={ElementsSDK.MessageType.SUCCESS}
            icon={ElementsSDK.Icon.Email}
            content={t('bcp.lounges.emailConfirmation', {
              email: bookingData.customerDetails.email,
            })}
          />
          <LoungesBookingInfo booking={booking} />
          {booking.product.data.termsUrl && (
            <BookingTerms termsUrl={bookingData.termsUrl} opensNewtab={isHolidayExtras} />
          )}
          {faqLink && !isHolidayExtras && (partnerId === 'LH' || partnerId === 'ST') && (
            <LoungeFaq contactLink={faqLink} faqs={faqs} />
          )}
          {faqLink && !isHolidayExtras && partnerId !== 'LH' && (
            <BookingFAQSupport faqLink={faqLink} />
          )}
          {isHolidayExtras && (
            <BookingEmailSupport supportEmailAddress="lounge-support@yiluhub.com" />
          )}
          <div className="yilu-BookingConfirmation__WidgetWrapper">
            <div
              id="yiluWidget"
              data-partner-id={getCurrentWidgetPartnerId() || 'lufthansa'}
              data-currency={bookingData.paymentDetails.totalPrice.currency}
              data-language={currentLanguage}
              data-passenger-count={bookingData.numberOfPassengers}
              data-passenger-count-per-type={`{"ADT":${bookingData.adults}, "CHD":${bookingData.children}, "IFT":${bookingData.infants}}`}
              data-touchpoint="simpletrip"
              data-booking-dot-com-display="Disable"
              data-hotels-dot-com-display="Disable"
              data-meili-display="Disable"
              data-cartrawler-display="Disable"
              data-flight-0-number={sessionStorage.getItem('flightCode')}
              data-flight-0-departure-iata={bookingData.loungeDetails.iata}
              data-flight-0-departure-datetime={bookingData.arrivalDate}
            />
          </div>
        </>
      )}
    </>
  );
};
