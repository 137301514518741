import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoungesOpeningHoursProps } from 'modules/lounges/components/LoungeOpeningHours/index';
import { dayTranslation } from 'modules/lounges/components/LoungeOpeningHours/utils';
import { OpeningHours } from 'modules/lounges/types';

export const OpenHoursAccordion: React.FC<LoungesOpeningHoursProps> = ({
  openingHours,
  searchedDateOfWeek,
}) => {
  const { t } = useTranslation();

  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(isExpanded ? 'auto' : '0px');
  const contentRef = useRef<HTMLDivElement>(null);

  if (!openingHours || !openingHours.length) return null;

  const openingHourListItem = (openingHour: OpeningHours, index?: number) => {
    if (!openingHour) return null;

    const day = dayTranslation(openingHour.day, t);

    return (
      <div key={index} className="yilu-Lounges__OpeningHour">
        <ElementsSDK.Typography className="yilu-Lounges__OpeningHour-day">
          {day}:
        </ElementsSDK.Typography>
        <div className="yilu-Lounges__OpeningHourTime">
          <ElementsSDK.Typography>
            {openingHour.intervals.length === 0 ? (
              <span className="closed">{t('Closed')}</span>
            ) : (
              <>
                {openingHour.intervals.map((interval, index) => (
                  <span>{`${interval.startTime}-${interval.endTime}`}</span>
                ))}
              </>
            )}
          </ElementsSDK.Typography>
        </div>
      </div>
    );
  };

  const onClick = () => {
    setExpanded(!isExpanded);
    if (contentRef.current) {
      const { scrollHeight } = contentRef?.current;
      setContentHeight(isExpanded ? '0px' : `${scrollHeight}px`);
    }
  };

  const offsetOpeningHours = openingHours
    .slice(searchedDateOfWeek)
    .concat(openingHours.slice(0, searchedDateOfWeek));

  const firstOpeningHour = offsetOpeningHours[0];
  const remainingOpeningHours = offsetOpeningHours.slice(1);

  return (
    <div className="yilu-Lounge-Accordion yilu-Lounges__OpeningHours">
      <div
        onClick={onClick}
        onKeyDown={onClick}
        className="yilu-Lounge-Accordion__button"
        role="button"
        tabIndex={0}
      >
        {openingHourListItem(firstOpeningHour)}
        <ElementsSDK.Icon.ArrowDownChevron
          className={clsx('yilu-Lounge-Accordion__arrow-icon', {
            'yilu-Lounge-Accordion__arrow-icon--open': isExpanded,
          })}
        />
      </div>
      <div
        className="yilu-Lounge-Accordion__content"
        ref={contentRef}
        style={{ maxHeight: `${contentHeight}` }}
      >
        {remainingOpeningHours.map((openingHour, index) => {
          return openingHourListItem(openingHour, index);
        })}
      </div>
    </div>
  );
};
