import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type SBBBookingInfoProps = {
  booking: Booking<'COMPLETED', 'BUS'>;
};

export const SBBBookingInfo: React.FC<SBBBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  const { firstName, lastName, email } = bookingData.customerDetails;

  return (
    <div className="yilu-SBBBookingInfo">
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[UiUtilities.formatLocalDate(bookingData.departureTime, 'dddd D MMMM YYYY')]}
        subText={t('bcp.sbb.startDate')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[`${firstName} ${lastName}`, email]}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[`${bookingData.passInfo!.duration.toString()} ${t('sbb.travel_pass_days')}`]}
        subText={t('bcp.sbb.duration')}
        icon={<ElementsSDK.Icon.Time />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[
          bookingData.passInfo!.fareClass === 1
            ? t('sbb.card_first_class_text')
            : t('sbb.card_second_class_text'),
        ]}
        subText={t('bcp.sbb.fare_class.text')}
        icon={<ElementsSDK.Icon.Lounge />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[`${bookingData.numberOfPassengers}`]}
        subText={t('bcp.sbb.no_of_passengers.text')}
        icon={<ElementsSDK.Icon.Person />}
      />
    </div>
  );
};
