/**
 * # Airport Transfer Search Form
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { Airport } from '@yiluhub/yilu-amp-types';
import { TransferDirection } from 'applicationConstants';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { TransferType } from '../../utils/constants';
import DateTimeToggler from './DateTimeToggler';
import { useAirportTransferSearchForm } from './hooks';
import './styles.scss';
import { AirportTransferSearchFormProps } from './types';

const DEFAULT_MAX_TRAVELLERS_COUNT = 9;

export const AirportTransferSearchForm: React.FC<AirportTransferSearchFormProps> = ({
  className,
  onSubmit,
  googleMapsAPIKey,
  searchInput,
  stationAirports,
  onError,
  minDateTimeInput,
  maxTravellersCount = DEFAULT_MAX_TRAVELLERS_COUNT,
  onOpenDateTimeInput,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = UiUtilities.useIsDesktop();

  const locationAddressInputId = useMemo(() => uuidv4(), []);
  const airportSelectionId = useMemo(() => uuidv4(), []);
  const transferId = useMemo(() => uuidv4(), []);

  const {
    locationAddress,
    selectedAirportName,
    transferDirection,
    transferType,
    transferDateTime,
    travellersCount,
    isSearchInputValid,
    setLocationAddress,
    setTravellersCount,
    setTransferType,
    handleOnAirportSelect,
    handleTransferDateTimeChange,
    handleTransferDirectionChange,
    handleFormInputValidate,
    handleFormSubmit,
    onChangeLocationAddress,
    connectedStationsData,
  } = useAirportTransferSearchForm({
    googleMapsAPIKey,
    onSubmit,
    searchInput,
    onError,
  });
  const locationInput = (
    <ElementsSDK.AddressInput
      classname={clsx([
        'yilu-AirportTransferSearchForm__InputField',
        'yilu-AirportTransferSearchForm__Address-InputField',
      ])}
      id={locationAddressInputId}
      label={
        isDesktop
          ? transferDirection === TransferDirection.TO_AIRPORT
            ? t('airportTransfer.search.labelFrom')
            : t('airportTransfer.search.labelTo')
          : undefined
      }
      googleMapsAPIKey={googleMapsAPIKey}
      onAddressSelect={onChangeLocationAddress}
      value={locationAddress && locationAddress.name}
      data={connectedStationsData}
      ariaLabel={'Transit Address Input'}
      autoCompleteOption={ElementsSDK.AUTOCOMPLETE_ADDRESS_INPUT.NESTED_LAYOUT_MENU}
      onError={onError}
      Icon={ElementsSDK.Icon.Pin}
      geolocationEnabled={false}
      placeholder={t('City, address, station')}
      onBlur={handleFormInputValidate(setLocationAddress)}
    />
  );

  const filteredAirportList = useMemo(() => {
    return (stationAirports as Airport[]).map(({ localizations, iataCode }) => ({
      localizations: { en: localizations!.en, de: localizations!.de },
      iataCode,
    }));
  }, [stationAirports]);

  const airportInput = (
    <ElementsSDK.FilteredAirportSearchInput
      classname={clsx([
        'yilu-AirportTransferSearchForm__InputField',
        'yilu-AirportTransferSearchForm__Airport-InputField',
      ])}
      label={
        isDesktop
          ? transferDirection === TransferDirection.TO_AIRPORT
            ? t('airportTransfer.search.labelToAirport')
            : t('airportTransfer.search.labelFromAirport')
          : undefined
      }
      onAddressSelect={handleOnAirportSelect}
      id={airportSelectionId}
      placeholder={t('airportTransfer.search.airportPlaceholder')}
      icon={<ElementsSDK.Icon.Airplane />}
      value={selectedAirportName}
      airportList={filteredAirportList}
    />
  );

  return (
    <div className={clsx(className, 'yilu-AirportTransferSearchForm')}>
      <div className="yilu-AirportTransferSearchForm__Main">
        {transferDirection === TransferDirection.TO_AIRPORT ? locationInput : airportInput}
        <ElementsSDK.Button
          className={clsx([
            'yilu-AirportTransferSearchForm__InputField',
            'yilu-AirportTransferSearchForm__SwapButton',
          ])}
          Icon={ElementsSDK.Icon.ArrowSwap}
          onClick={handleTransferDirectionChange}
        ></ElementsSDK.Button>
        {transferDirection === TransferDirection.TO_AIRPORT ? airportInput : locationInput}
        <ElementsSDK.InputField.DateTime
          className={clsx([
            'yilu-AirportTransferSearchForm__InputField',
            'yilu-AirportTransferSearchForm__DateTime-InputField',
          ])}
          id={transferId}
          name={transferId}
          label={t('when')}
          onChange={handleTransferDateTimeChange}
          format={UiUtilities.DateFormat.SHORT_DATE_WITH_TIME_WITHOUT_DAY}
          initialValue={transferDateTime}
          minDate={minDateTimeInput || dayjs().format(UiUtilities.DateFormat.SHORT_DATE)}
          controlsEnabled={false}
          dateTimeToggler={
            <DateTimeToggler transferType={transferType} setTransferType={setTransferType} />
          }
          title={transferType === TransferType.DEPARTURE ? t('depart') : t('arrive')}
          placeholder={t('Select entry time')}
          onOpen={onOpenDateTimeInput}
        />
        <ElementsSDK.TravellersCounterDropdown
          className={clsx([
            'yilu-AirportTransferSearchForm__InputField',
            'yilu-AirportTransferSearchForm__TravellersCounter-InputField',
          ])}
          travellersCount={travellersCount}
          maxTravellersCount={maxTravellersCount}
          onChange={setTravellersCount}
          travellerCounterLabel={t('Travellers')}
          generateOptionLabel={
            !isDesktop
              ? (option) => (option.value === 1 ? t('Traveller') : t('Travellers'))
              : undefined
          }
        />
        <ElementsSDK.Button
          className={clsx([
            'yilu-AirportTransferSearchForm__InputField',
            'yilu-AirportTransferSearchForm__SubmitButton',
          ])}
          disabled={!isSearchInputValid}
          onClick={handleFormSubmit}
          Icon={isDesktop ? ElementsSDK.Icon.Magnifier : undefined}
        >
          {isDesktop ? undefined : t('Search')}
        </ElementsSDK.Button>
      </div>
    </div>
  );
};
