import { useCallback } from 'react';

import { useCreateBooking } from './useCreateBooking';

type CreateBookingResponse = {
  id: string;
};

type useCreateBookingParamsType = {
  stripe: stripe.Stripe | null;
  clientSecret: string | null;
  bookingIntentId: string;
  paymentProviderId: string;
  userId: string;
  onPaymentResults: (response: CreateBookingResponse) => void;
  onError: (error: Error) => void;
  setIsLoading: (isLoading: boolean) => void;
  touchPointId: string;
  decisionId?: string;
};

export const useCreatePaymentRequestButtonBooking = ({
  stripe,
  clientSecret,
  bookingIntentId,
  paymentProviderId,
  userId,
  onPaymentResults,
  onError,
  setIsLoading,
  touchPointId,
  decisionId,
}: useCreateBookingParamsType) => {
  const { createBooking } = useCreateBooking({
    bookingIntentId,
    paymentProviderId,
    userId,
    onPaymentResults,
    touchPointId,
    decisionId,
  });

  const onPaymentRequestConfirm = useCallback(
    async (ev: stripe.paymentRequest.StripePaymentMethodPaymentResponse) => {
      try {
        if (!stripe || !clientSecret) {
          onError(new Error('Missing data while creating a booking!'));
          return;
        }

        setIsLoading(true);

        const stripeApiMethod = stripe.confirmCardSetup;

        const stripeSetup = await stripeApiMethod(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );

        const { error: confirmError } = stripeSetup;
        let isSuccessful = false;

        if (confirmError) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          if (stripeSetup.setupIntent?.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripeApiMethod(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              throw new Error(error.message);
            } else {
              isSuccessful = true;
            }
          } else {
            isSuccessful = true;
          }
        }

        isSuccessful && (await createBooking(stripeSetup));
      } catch (error) {
        onError(error as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [stripe, clientSecret, onError, createBooking, setIsLoading],
  );

  return { onPaymentRequestConfirm };
};
