import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { TFunction } from 'i18next';
import React from 'react';

import { WarningModalType } from 'components/TravellerInformationForm/hooks/useReservation';

type LoungePEModalType = {
  t: TFunction;
};

export function getLoungePEModal({ t }: LoungePEModalType): WarningModalType {
  return {
    children: (
      <ElementsSDK.Typography
        variant="p1"
        dangerouslySetInnerHTML={{
          __html: t('tip.loungeForm.premium.modal.text'),
        }}
      />
    ),
  };
}
