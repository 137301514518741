import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { NavigateFunction } from 'react-router-dom';

import { paramConverters } from 'utils';

import { LoungeSearchInput } from 'modules/lounges/components';

export const constructLoungeBacklinkProps = (
  label: string,
  route: string,
  navigate: NavigateFunction,
  entryDate?: string,
  airportIataCode?: string,
  adults?: number,
  children?: number,
  infants?: number,
) => {
  const backLinkProps: ElementsSDK.BacklinkProps = {
    label,
    onClick: () => {
      const backLoungeQueryParams: LoungeSearchInput = {
        entryDate,
        airportIataCode,
        adults,
        children,
        infants,
      };

      navigate({
        pathname: route,
        search: paramConverters.getURLSearchQuery(backLoungeQueryParams),
      });
    },
  };
  return backLinkProps;
};
