import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';
import { setSrpSession } from 'utils/searchSession';

import routes from 'router/routes';

import { setParkingSrpSession } from 'modules/parking/utils/searchSession';

import { ParkingSearchResultsPageQueryParams } from '../../../utils/query-params';
import { ParkingSearchResultsProps } from '../ParkingSearchResults/types';

export const useParkingSearchResultsProps = (
  parkingSearchResultsPageQueryParams: ParkingSearchResultsPageQueryParams,
) => {
  const navigate = useNavigate();
  const searchResults = useRef<SearchItem[]>([]);

  const parkingSearchResultsProps = useMemo(() => {
    const coordinates = {
      latitude: parkingSearchResultsPageQueryParams?.airportDetails?.latitude!,
      longitude: parkingSearchResultsPageQueryParams?.airportDetails?.longitude!,
    };

    const dateTimeParams = {
      entryDate: parkingSearchResultsPageQueryParams.entryDate,
      entryTime: parkingSearchResultsPageQueryParams.entryTime,
      exitDate: parkingSearchResultsPageQueryParams.exitDate,
      exitTime: parkingSearchResultsPageQueryParams.exitTime,
    };

    const isMissingRequiredParameters = Object.keys(dateTimeParams).some((key) => {
      return (dateTimeParams as Record<string, string>)[key] === undefined;
    });

    const isMissingCoordinates = Object.keys(coordinates).some((key) => {
      return (coordinates as Record<string, number>)[key] === undefined;
    });

    if (isMissingRequiredParameters || isMissingCoordinates) {
      return null;
    }

    const searchParams = {
      ...dateTimeParams,
      coordinates: { ...coordinates },
      airportIataCode: parkingSearchResultsPageQueryParams.airportDetails?.iataCode as string,
    };

    const setSearchSessionOnClick = () => {
      const sessionData = {
        entryDate: parkingSearchResultsPageQueryParams?.entryDate,
        entryTime: parkingSearchResultsPageQueryParams?.entryTime,
        exitDate: parkingSearchResultsPageQueryParams?.exitDate,
        exitTime: parkingSearchResultsPageQueryParams?.exitTime,
        airportDetails: parkingSearchResultsPageQueryParams?.airportDetails,
      };
      setParkingSrpSession(sessionData);
      setSrpSession(sessionData);
    };

    const _props: ParkingSearchResultsProps = {
      searchParams,
      onSearchResults(results: SearchItem[]) {
        searchResults.current = results;

        if (!results.length) {
          yiluTracking.sendGAEvent({
            event: 'page_loaded',
            category: 'parking-srp-null',
            label: `parking_srp_null_loaded`,
          });
        } else {
          yiluTracking.sendGAEvent({
            event: 'page_loaded',
            category: 'parking-srp',
            label: `parking_srp_loaded`,
          });
        }
      },
      onTipButtonClick(searchResult: SearchItem) {
        setSearchSessionOnClick();
        const travellerInformationPageQueryParams = {
          searchResultID: searchResult.id,
        };

        navigate({
          pathname: routes.PARKING_TIP,
          search: paramConverters.getURLSearchQuery(travellerInformationPageQueryParams),
        });
      },
      onPdpButtonClick(searchResult: SearchItem) {
        setSearchSessionOnClick();

        yiluTracking.sendSelectedIndexSearchEvent(
          searchResult.id,
          searchResults.current,
          'parking_srp',
        );

        const parkingProductDetailsPageQueryParams = {
          flightTime: parkingSearchResultsPageQueryParams.flightTime,
          airportDetails: parkingSearchResultsPageQueryParams.airportDetails,
          searchResultID: searchResult.id,
          entryDate: parkingSearchResultsPageQueryParams.entryDate,
          entryTime: parkingSearchResultsPageQueryParams.entryTime,
          exitDate: parkingSearchResultsPageQueryParams.exitDate,
          exitTime: parkingSearchResultsPageQueryParams.exitTime,
        };

        navigate({
          pathname: routes.PARKING_PDP,
          search: paramConverters.getURLSearchQuery(parkingProductDetailsPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };

    return _props;
  }, [parkingSearchResultsPageQueryParams, navigate]);

  return parkingSearchResultsProps;
};
