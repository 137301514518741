import { ParkingSearchInputPageQueryParamsForm } from 'modules/parking/utils/query-params';

const SEARCH_SESSION_KEY = 'yilu_parking_search_session';

export function getParkingSrpSession() {
  return JSON.parse(
    sessionStorage.getItem(SEARCH_SESSION_KEY) || '{}',
  ) as ParkingSearchInputPageQueryParamsForm;
}

export function setParkingSrpSession(data: ParkingSearchInputPageQueryParamsForm) {
  return sessionStorage.setItem(SEARCH_SESSION_KEY, JSON.stringify(data));
}
