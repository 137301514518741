/**
 * # Lounges Opening Hours
 *
 *
 */
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OpenHoursAccordion } from 'modules/lounges/components/LoungeOpeningHours/OpenHoursAccordion';
import { dayTranslation } from 'modules/lounges/components/LoungeOpeningHours/utils';

import { OpeningHours } from '../../types';
import './styles.scss';

export enum SameMessageType {
  DAILY = 'DAILY',
  PRECISE = 'PRECISE',
}
export type LoungesOpeningHoursProps = {
  openingHours: OpeningHours[];
  searchedDateOfWeek: number;
  sameMessageType?: SameMessageType;
};

const openingHourSameMessage = (
  openingHour: OpeningHours,
  sameMessageType: SameMessageType,
  t: TFunction,
) => {
  if (!openingHour) return null;

  switch (sameMessageType) {
    case SameMessageType.DAILY:
      return openingHourSameMessageDaily(openingHour, t);
    case SameMessageType.PRECISE:
    default:
      return openingHourSameMessagePrecise(openingHour, t);
  }
};

const openingHourSameMessagePrecise = (openingHour: OpeningHours, t: TFunction) => {
  const mondayTitle = dayTranslation('Monday', t);
  const sundayTitle = dayTranslation('Sunday', t);
  const intervalConcat = openingHour.intervals
    .map((interval) => `${interval.startTime}-${interval.endTime}`)
    .join(', ');
  return `${mondayTitle}-${sundayTitle}: ${intervalConcat}`;
};

const openingHourSameMessageDaily = (openingHour: OpeningHours, t: TFunction) => {
  const intervalConcat = openingHour.intervals
    .map((interval) => `${interval.startTime} ${t('to')} ${interval.endTime}`)
    .join(', ');

  return t('Lounge.openingSameMessageDaily', {
    intervalConcat,
  });
};

const LoungeOpeningHours: React.FC<LoungesOpeningHoursProps> = ({
  openingHours,
  searchedDateOfWeek,
  sameMessageType = SameMessageType.PRECISE,
}) => {
  const { t } = useTranslation();

  const openingHoursAreDifferent = useMemo(() => {
    return openingHours?.some((openingHour, index) => {
      const notSameIntervals = openingHours[0].intervals.every((firstInterval, intervalIndex) => {
        return (
          firstInterval.startTime !== openingHour.intervals[intervalIndex]?.startTime ||
          firstInterval.endTime !== openingHour.intervals[intervalIndex]?.endTime
        );
      });
      return !openingHour.intervals?.length || notSameIntervals;
    });
  }, [openingHours]);

  return (
    <>
      {openingHoursAreDifferent ? (
        <OpenHoursAccordion openingHours={openingHours} searchedDateOfWeek={searchedDateOfWeek} />
      ) : (
        <>{openingHourSameMessage(openingHours[0], sameMessageType, t)}</>
      )}
    </>
  );
};

export default LoungeOpeningHours;
