import { Vertical } from '@yiluhub/yilu-amp-types';

import { AMP_VERTICALS, yiluEnv } from 'utils';

export function getEnabledVerticals(
  apiResponseVerticals: Vertical[],
): Record<AMP_VERTICALS, boolean> {
  // Mapping of environment variable IDs to vertical names
  const envVariables = yiluEnv.getVariables();
  const verticalNameIdMap: Record<AMP_VERTICALS, string> = {
    [AMP_VERTICALS.LOUNGES]: envVariables.YILU_AMP_LOUNGE_VERTICAL_ID,
    [AMP_VERTICALS.PARKING]: envVariables.YILU_AMP_PARKING_VERTICAL_ID,
    [AMP_VERTICALS.AIRPORT_TRANSFERS]: envVariables.YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
    [AMP_VERTICALS.AIRPORT_TRANSFER_PASSES]:
      envVariables.YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
    [AMP_VERTICALS.RIDES]: envVariables.YILU_AMP_RIDES_VERTICAL_ID,
    [AMP_VERTICALS.CAR_RENTAL]: envVariables.YILU_AMP_CAR_RENTAL_VERTICAL_ID,
  };

  const enabledVerticals: Record<AMP_VERTICALS, boolean> = {
    [AMP_VERTICALS.LOUNGES]: false,
    [AMP_VERTICALS.PARKING]: false,
    [AMP_VERTICALS.AIRPORT_TRANSFERS]: false,
    [AMP_VERTICALS.AIRPORT_TRANSFER_PASSES]: false,
    [AMP_VERTICALS.RIDES]: false,
    [AMP_VERTICALS.CAR_RENTAL]: false,
  };

  // Convert verticals array to a set of IDs for quick lookup
  const verticalIds = new Set(
    apiResponseVerticals.map((apiResponseVertical) => apiResponseVertical.id),
  );

  // Iterate through the vertical mapping
  for (const [verticalName, id] of Object.entries(verticalNameIdMap)) {
    enabledVerticals[verticalName as AMP_VERTICALS] = verticalIds.has(id);
  }

  return enabledVerticals;
}
