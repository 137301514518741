import { GetFeatureFlagsResponse, GetVerticalsByStoreIdResponse } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS, SupportedFeatureFlags, YILU_FEATURE } from 'applicationConstants';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { YiluAssetPathOverrides, YiluFeatures } from 'types';

import { yiluEnv } from 'utils';

import {
  EnterprisePartnerConfigurationContext,
  EnterprisePartnerConfigurationContextType,
} from './context';
import { getEnabledVerticals } from './utils';

type EnterprisePartnerConfigurationContextProviderProps = {
  features: Partial<YiluFeatures>;
  assetPathOverrides?: Partial<YiluAssetPathOverrides>;
};
type EnterprisePartnerConfigurationState =
  EnterprisePartnerConfigurationContextType['enterprisePartnerConfigurationState'];

export const EnterprisePartnerConfigurationContextProvider: React.FC<
  EnterprisePartnerConfigurationContextProviderProps
> = ({ children, features, assetPathOverrides }) => {
  const [epConfigState, setEpConfigState] = useState<EnterprisePartnerConfigurationState>({
    isEnterprisePartnerConfigurationLoading: false,
    featureFlags: features,
    enabledVerticals: null,
    assetPaths: assetPathOverrides,
  });

  const loadEnterprisePartnerConfiguration = useCallback(async () => {
    const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();

    setEpConfigState({
      ...epConfigState,
      isEnterprisePartnerConfigurationLoading: true,
    });

    try {
      // Perform both API calls in parallel using Promise.all
      const [featureFlagsResponse, enabledVerticalsResponse] = await Promise.all([
        axios.get<GetFeatureFlagsResponse>(
          `${YILU_AMP_BACKEND_URL}/configuration/v1/feature-flags?storeId=${YILU_AMP_STORE_ID}`,
        ),
        axios.get<GetVerticalsByStoreIdResponse>(
          `${YILU_AMP_BACKEND_URL}/store/v1/stores/${YILU_AMP_STORE_ID}/verticals`,
        ),
      ]);

      // Handle the feature flags response
      const featureFlags = featureFlagsResponse.data
        .filter((item) => SupportedFeatureFlags.includes(item.featureFlag.name as YILU_FEATURE))
        .reduce((acc: Record<string, boolean>, item) => {
          acc[item.featureFlag.name] = item.enabled;
          return acc;
        }, {});

      // Handle the enabled verticals response
      const enabledVerticals = getEnabledVerticals(enabledVerticalsResponse.data);

      // Update the state with the results
      setEpConfigState({
        isEnterprisePartnerConfigurationLoading: false,
        featureFlags: { ...features, ...featureFlags },
        enabledVerticals,
        assetPaths: assetPathOverrides,
      });
    } catch (e) {
      // Handle any errors that occur during the API calls
      setEpConfigState({
        ...epConfigState,
        isEnterprisePartnerConfigurationLoading: false,
      });
      console.error('Error loading enterprise partner configuration:', e);
    }
  }, [features, epConfigState, assetPathOverrides]);

  const isFeatureFlagEnabled = useCallback(
    (feature: YILU_FEATURE) =>
      Boolean(epConfigState.featureFlags && epConfigState.featureFlags[feature]),
    [epConfigState],
  );

  const isVerticalEnabled = useCallback(
    (vertical: AMP_VERTICALS) => {
      return Boolean(epConfigState.enabledVerticals && epConfigState.enabledVerticals[vertical]);
    },
    [epConfigState],
  );

  const providerValue: EnterprisePartnerConfigurationContextType = {
    enterprisePartnerConfigurationState: epConfigState,
    loadEnterprisePartnerConfiguration,
    isFeatureFlagEnabled,
    isVerticalEnabled,
  };

  return (
    <EnterprisePartnerConfigurationContext.Provider value={providerValue}>
      {children}
    </EnterprisePartnerConfigurationContext.Provider>
  );
};
