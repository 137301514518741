export const PARKING_POPULAR_AIRPORTS_PRIORITIZED = [
  'FRA',
  'MUC',
  'DUS',
  'JFK',
  'HAM',
  'HAJ',
  'LHR',
  'BOS',
  'DTW',
  'ZRH',
  'VIE',
  'GVA',
  'MXP',
  'EWR',
  'IAD',
  'CGN',
];

export const enum CarparkType {
  GARAGE = 'GARAGE',
  UNDERGROUND = 'UNDERGROUND',
  OUTDOOR = 'OUTDOOR',
}

export const enum TravellingIcon {
  WALK = 'WALK',
  BUS = 'BUS',
  TRAIN = 'TRAIN',
}

export const enum ParkingType {
  AIRPORT = 'P',
  PORT = 'M',
  STATION = 'T',
  CITY_CENTER = 'C',
}
