import { yiluEnv } from 'utils/index';

export enum VariantId {
  VARIANT_A = 'VARIANT_A',
  VARIANT_B = 'VARIANT_B',
}

// this will enable ab testing in every environment and EP
const DEFAULT_ABTEST_ENABLED = true;

export const integrateABTesting = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COOKIE_PREFIX: 'yilu_simpletrip_',
  COOKIE_SUFFIX: 'ab_test_tip_v1',
  isEnabled(): boolean {
    const yiluEnvVariables = yiluEnv.getVariables();
    return yiluEnvVariables.ABTEST_ENABLED === 'true' || DEFAULT_ABTEST_ENABLED;
  },
  getCookie(name: string = 'yilu_simpletrip_ab_test_tip_v1'): string | undefined {
    if (!this.isEnabled()) {
      return undefined;
    }

    const documentCookies = document.cookie.split(';');
    if (!documentCookies.length) {
      return undefined;
    }
    for (let i = 0; i < documentCookies.length; i++) {
      const currentCookie = documentCookies[i].split('=');
      if (currentCookie[0].trim() !== name.trim()) {
        continue;
      }
      return currentCookie.length > 1 ? decodeURI(currentCookie[1].trim()) : undefined;
    }
    return undefined;
  },
  getVariantName(): string | undefined {
    const variant = this.getCookie();
    return variant ? (variant === VariantId.VARIANT_A ? 'radio-button' : 'flight_no') : undefined;
  },
  setCookie(
    name: string,
    value: string,
    expires: string,
    path: string,
    domain: string,
    secure: boolean,
  ): void {
    document.cookie =
      name +
      '=' +
      encodeURI(value) +
      (expires ? '; expires=' + expires : '') +
      (path ? '; path=' + path : '') +
      (domain ? '; domain=' + domain : '') +
      (secure ? '; secure' : '');
  },
  drawForAB(): boolean {
    return Math.random() < 0.5;
  },

  // General Test if AB test is enabled. Return base variant in case previous cookie exists.
  initiateABTest(forceVersion?: string): VariantId | undefined {
    const COOKIE_FULL_NAME = this.COOKIE_PREFIX + this.COOKIE_SUFFIX;
    const COOKIE_DURATION = 31536000000; // one year
    const ONE_YEAR = new Date(new Date().getTime() + COOKIE_DURATION).toUTCString();
    if (!this.isEnabled()) {
      return undefined;
    }

    if (forceVersion) {
      if (forceVersion === VariantId.VARIANT_A) {
        this.setCookie(COOKIE_FULL_NAME, VariantId.VARIANT_A, ONE_YEAR, '/', '', true);
        return VariantId.VARIANT_A;
      } else if (forceVersion === VariantId.VARIANT_B) {
        this.setCookie(COOKIE_FULL_NAME, VariantId.VARIANT_B, ONE_YEAR, '/', '', true);
        return VariantId.VARIANT_B;
      }
      console.warn('Warn: wrong variant id');
    }
    /* Check if user has previous cookie with version */
    const cookieValue = this.getCookie(COOKIE_FULL_NAME);

    if (cookieValue) {
      switch (cookieValue) {
        case VariantId.VARIANT_B:
          return VariantId.VARIANT_B;
        default:
          return VariantId.VARIANT_A;
      }
    } else {
      const draw = this.drawForAB();
      const cookieValue = draw ? VariantId.VARIANT_A : VariantId.VARIANT_B;
      this.setCookie(COOKIE_FULL_NAME, cookieValue, ONE_YEAR, '/', '', true);
      return cookieValue;
    }
  },
};
