/**
 * # Airport Transfer Search Input Page.
 *
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerSIP from 'components/BannerSIP';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import ProductValues from 'components/ProductValues';

import { AirportTransferSearchForm } from '../../components';
import { useAirportTransferSearchInputPage } from './hooks';
import './styles.scss';

const SearchInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { airportTransferSearchFormProps, productValueItems, isPageReady } =
    useAirportTransferSearchInputPage();

  return (
    <div className="airport-transfer-sip">
      {isPageReady ? (
        <>
          <BannerSIP title={t('SIMPLETRIP SIP AIRPORT TRANSFER TITLE')} />
          <div className="form-container-wrapper">
            <div
              className="form-container"
              aria-label="Book your airport transfer"
              role="heading"
              aria-level={1}
            >
              {airportTransferSearchFormProps && (
                <AirportTransferSearchForm {...airportTransferSearchFormProps} />
              )}
            </div>
          </div>
          <div className="airport-transfer-product-values">
            <ProductValues title={t('Mobility')} items={productValueItems} />
          </div>
        </>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};
export default SearchInputPage;
