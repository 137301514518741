/**
 * # Product Values
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import ValueComposition, { ValueCompositionProps } from './ValueComposition';
import './styles.scss';

export type ProductValuesProps = {
  title?: string;
  items: ValueCompositionProps[];
};

const ProductValues: React.FC<ProductValuesProps> = ({ title, items }) => {
  return (
    <div className="product-values">
      {title && (
        <ElementsSDK.Typography variant="h2" className="product-values__header">
          {title}
        </ElementsSDK.Typography>
      )}
      <div className="product-values__items">
        {items.map((item, index) => (
          <ValueComposition
            key={`${index}-${item.title}`}
            title={item.title}
            text={item.text}
            imageUrl={item.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductValues;
